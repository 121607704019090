import { getLorryTypeList } from '_services/accountsServices/lrServices'
import { CustomSelect, Heading } from 'components'
import React, { useContext, useEffect, useRef, useState } from 'react';
import { FaStar } from 'react-icons/fa6'
import StorageService from 'utils/StorageServices/StorageServices'
import bidderImg from "../../../src/assets/img/bidderImg.png"
import { IoCall } from 'react-icons/io5'
import { IoChevronBack, IoChevronForward } from "react-icons/io5";
import { createBidMarketLoadData, getFavoriteLocation, getMarketLoadListData, recentSearchList, saveFavoriteLocation, updateBidMarketLoadData } from '_services'
import { FavoriteRouteModal, PlaceBidModal } from 'modals'
import toast from 'react-hot-toast';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { UserContext } from 'context';
import LoadingBar from 'react-top-loading-bar';
import images from 'constants/images';
import { State } from "country-state-city";
import { Images } from 'constants';
import FromAndToLoaction from 'modals/FromAndToLocation';
import ToAndFromLocation from 'modals/FromAndToLocation/ToAndFromLocation';


var totalCount = 0;
const formObj = {
  company_id: StorageService.getCompanyId(),
  lorry_type_id: "",
  from_location: '',
  to_location: '',
  limit: 10,
  my_connection: 0,

  is_favorite: 0 // 1=favorite, 0=un-favorite
}

const MarketLoad = () => {

  const loadingBarRef = useRef(null);
  const { cities } = useContext(UserContext);
  const divRef = useRef(null);
  const [page, setPage] = useState(1);

  const [showPlaceBid, setShowPlaceBid] = useState(false);
  const [showFavRoute, setShowFavRoute] = useState(false);
  const [showFromTo, setShowFromTo] = useState(false);
  const [showToFrom, setShowToFrom] = useState(false);
  const [filters, setFilters] = useState(formObj);
  console.log(filters)
  const [labelFormValue, setLabelFormValue] = useState({
    from_location: '',
    to_location: '',
  })
  const [isFilterLoading, setIsFilterLoading] = useState(false);

  const [lorryType, setLorryType] = useState([]);
  const [activeTab, setActiveTab] = useState({});

  const [loading, setLoading] = useState(false);
  const [marketLoadData, setMarketLoadData] = useState([]);

  const [favoriteRoute, setFavoriteRoute] = useState([]);
  const [recentSearchData, setRecentSearchData] = useState([]);

  let favRouteModal = {
    isOpen: showFavRoute,
    closeModal: (e) => setShowFavRoute(false),
    modalMainClass: "_dashCommon_Modal _favRoute_Modal",
    modalTitle: "Favorite",
    submitTitle: "",
  }

  const getDataLorryTypeList = async () => {
    try {
      const res = await getLorryTypeList();
      if (res?.responseCode == 200) {
        setLorryType(res?.data?.lorry_type)
      }
    } catch (error) {
      throw error;
    }
  }

  const changeActiveTab = async (event, value) => {
    event.preventDefault();
    setActiveTab(value);
    setFilters((s) => ({ ...s, lorry_type_id: value?._id }))
  };

  useEffect(() => {
    getDataLorryTypeList();
    recentSearchDataList();
  }, [StorageService.getCompanyId()])

  const handleCreateBid = async (data) => {
    setLoading(true);
    try {
      if (showPlaceBid?.isBidPlaced === 1) {
        let res = await updateBidMarketLoadData(data);
        if (res?.responseCode === 200) {
          getListMarketLoad();
          setShowPlaceBid(false);
          setLoading(false);
          toast.success('Place Bid Successfully');
        }
      } else {
        let res = await createBidMarketLoadData(data);
        if (res?.responseCode === 200) {
          getListMarketLoad();
          setShowPlaceBid(false);
          setLoading(false);
          toast.success('Place Bid Successfully');
        }
      }
    } catch (error) {
      setLoading(false);
      toast.error(`${error?.response?.data?.responseMessage}`);
    }
  }



  const getListMarketLoad = async (data = {}) => {
    setLoading(true);
    try {
      if (loadingBarRef.current) {
        loadingBarRef.current.continuousStart(); // Start the loading bar
      }
      let object = {
        ...filters,
        company_id: StorageService.getCompanyId(),
        from_location: data?.from_location ? data?.from_location : filters?.from_location?.value,
        to_location: data?.to_location ? data?.to_location : filters?.to_location?.value,
        offset: page,
      }
      delete object.is_favorite;
      let res = await getMarketLoadListData(object);
      if (res?.responseCode === 200) {
        if ((page - 1) * filters?.limit === 0) {
          setMarketLoadData(res?.data?.plan_list);
        } else {
          setMarketLoadData((prevItems) => [...prevItems, ...res?.data?.plan_list]);
        }
        totalCount = res?.data?.total_record
        setLoading(false);
        setIsFilterLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setIsFilterLoading(false);
    } finally {
      setLoading(false);
      setIsFilterLoading(false);
      if (loadingBarRef.current) {
        loadingBarRef.current.complete(); // Complete the loading bar
      }
    }
  }

  useEffect(() => {
    if (StorageService.getCompanyId()) {
      getListMarketLoad();
    }
  }, [page,filters])

  const handleScroll = () => {
    if (divRef.current.scrollTop + divRef.current.clientHeight >= divRef.current.scrollHeight) {
      setPage((prevPage) => {
        if (+totalCount >= filters?.limit * (prevPage)) {
          return prevPage + 1;
        } else {
          return prevPage;
        }
      });
    }
  };

  useEffect(() => {
    const div = divRef.current;
    div.addEventListener("scroll", handleScroll);
    return () => div.removeEventListener("scroll", handleScroll);
  }, []);

  const onhandleSelect = async (type, data) => {
    console.log("Data received:", data);

   
    let stateDetails;

   
    if (type === "from") {
        stateDetails = State.getStateByCodeAndCountry(
            data?.stateCode,
            data?.countryCode
        );
    } else if (type === "to") {
        stateDetails = State.getStateByCodeAndCountry(
            data?.stateCode,
            data?.countryCode
        );
    }
    
    await setFilters((prevFilters) => {
        console.log("Previous Filters:", prevFilters);
        
        const updatedFilters = { ...prevFilters, is_favorite: 0 };

       
        if (type === "from" && stateDetails) {
            updatedFilters.from_location = {
                label: stateDetails.name,
                value: stateDetails.name,
            };
        } else if (type === "to" && stateDetails) {
            updatedFilters.to_location = {
                label: stateDetails.name,
                value: stateDetails.name,
            };
        }

        return updatedFilters;
    });
};

  const filterSearchEvent = async (e) => {
    e.preventDefault();
    getListMarketLoad();
    setPage(1);
    setIsFilterLoading(true);
  }

  const recentSearchDataList = async () => {
    try {
      let object = {
        company_id: StorageService.getCompanyId(),
        limit: 5,
        offset: 0,
      }
      let res = await recentSearchList(object);
      if (res?.responseCode === 200) {
        setRecentSearchData(res?.data);
      }
    } catch (error) {
      toast.error(`${error?.response?.data?.responseMessage}`);
    }
  }

  const getFavoriteLocationData = async () => {
    try {
      let object = {
        company_id: StorageService.getCompanyId(),
        limit: 5,
        offset: 0,
      }
      let res = await getFavoriteLocation(object);
      if (res?.responseCode === 200) {
        setFavoriteRoute(res?.data);
      }
    } catch (error) {
      toast.error(`${error?.response?.data?.responseMessage}`);
    }
  }

  const saveFavorite = async (data) => {
    setFilters((s) => ({ ...s, is_favorite: data?.value }))
    try {
      let object = {
        company_id: StorageService.getCompanyId(),
        from_location: data?.from_location,
        to_location: data?.to_location,
        is_favorite: data?.value
      }
      let res = await saveFavoriteLocation(object);
      if (res?.responseCode === 200) {
        getFavoriteLocationData();
        toast.success(`${data?.value === 1 ? 'Favorite Successfully' : 'Un-favorite Successfully'}`);
      }
    } catch (error) {
      toast.error(`${error?.response?.data?.responseMessage}`);
    }
  }

  useEffect(() => {
    getFavoriteLocationData();
  }, [])

  useEffect(() => {
    if (!showFavRoute) {
      getFavoriteLocationData();
    }
  }, [showFavRoute])

  const callBackDataMap =  (data) => {
    console.log("Received data:", data);
  
    if (data?.fromDetails ) {
       onhandleSelect( "from",data.fromDetails );
    } else {
      console.log("One or both location details are missing.");
    }

    if (data?.toDetails) {
       onhandleSelect( "to",data.toDetails );
    } else {
      console.log("One or both location details are missing.");
    }
  };

  let placeBidModal = {
    isOpen: showPlaceBid,
    isMarketLoad: true,
    closeModal: (e) => setShowPlaceBid(false),
    modalMainClass: "_dashCommon_Modal _smModal",
    nextAction: (value) => handleCreateBid(value),
    loading: loading,
    modalTitle: showPlaceBid?.isBidPlaced === 1 ? "Update Bid" : "Place Bid",
    submitTitle: showPlaceBid?.isBidPlaced === 1 ? "Update Bid" : "Place Bid",
  }

  let fromToProps = {
    isOpen: showFromTo,
    isMarketLoad: true,
    closeModal: (e) => setShowFromTo(false),
    modalMainClass: "_dashCommon_Modal _smModal",
    nextAction: (value) => handleCreateBid(value),
    loading: loading,
    modalTitle: showPlaceBid?.isBidPlaced === 1 ? "Update Bid" : "Select Location",
    submitTitle: showPlaceBid?.isBidPlaced === 1 ? "Update Bid" : "Select Location",
    callBackDataMap:callBackDataMap
  }

  let toFromProps = {
    isOpen: showToFrom,
    isMarketLoad: true,
    closeModal: (e) => setShowToFrom(false),
    modalMainClass: "_dashCommon_Modal _smModal",
    nextAction: (value) => handleCreateBid(value),
    loading: loading,
    modalTitle: showPlaceBid?.isBidPlaced === 1 ? "Update Bid" : "Select Location",
    submitTitle: showPlaceBid?.isBidPlaced === 1 ? "Update Bid" : "Select Location",
    callBackDataMap:callBackDataMap
  }

  return (
    <>
      <LoadingBar
        color="#ffa800"
        ref={loadingBarRef}
        height={4}
        shadow={true}
        loaderSpeed={1000}
      />
      <Heading title="Market Load" />

      <div className='_mrktLoad_mainBx'>
        <div className='_mrktFilt_mainBx'>
          <div className='_mrktInfilts_bx'>
            <div className='head_load'>
              <span className='search_loads'> Search Load </span>
              {(filters?.from_location?.label && filters?.to_location?.label) ?
                <span className='icon_favorite'>
                  {(filters?.is_favorite === 0) ? 'Mark Favorite ' : 'Favorite '}
                  {(filters?.is_favorite === 0) ? <div className='_favSave'>
                    &nbsp;
                    <img
                      src={Images.BOOKMARK_LOAD}
                      className='icon_book_size p-1'
                      onClick={(e) => saveFavorite({
                        from_location: filters?.from_location?.value,
                        to_location: filters?.to_location?.value,
                        value: 1
                      })} />
                  </div> :
                    <div className='_favSave'>
                      &nbsp;
                      <img
                        src={Images.BOOKMARK_MARK_LOAD}
                        className='icon_book_size p-1'
                        onClick={(e) => saveFavorite({
                          from_location: filters?.from_location?.value,
                          to_location: filters?.to_location?.value,
                          value: 0
                        })}
                      />
                    </div>}
                </span> : <></>}
            </div>
            <div className='_optFilts' 
           
            >
              <div style={{ marginBottom: '12px' }}  onClick={()=> setShowFromTo(true)}>
                <p>FROM LOCATION</p>
                <CustomSelect
                  options={cities}
                  value={filters?.from_location}
                  openList={false}
                  onSelect={(data) => {
                    console.log(data)
                    onhandleSelect(data, 'from_location')
                   
                    // setLabelFormValue((s) => ({
                    //   ...s,
                    //   from_location: data,
                    // }));
                  }}
                  placeholder="From location"
                  isClearOption={filters?.from_location?.label ? true : false}
                  isClearHandleChange={(event) => {
                    event.stopPropagation();
                    setFilters((s) => ({ ...s, from_location: { label: '', value: '' } }))
                    // setLabelFormValue((s) => ({
                    //   ...s,
                    //   from_location: { label: '', value: '' }
                    // }));
                  }}
                />
              </div>

              <div  onClick={()=> setShowToFrom(true)}>
                <p>TO LOCATION</p>
                <CustomSelect
                  options={cities}
                  value={filters?.to_location}
                  openList={false}
                  onSelect={(data) => {
                    onhandleSelect(data, 'to_location')
                    // setLabelFormValue((s) => ({
                    //   ...s,
                    //   to_location: data,
                    // }));
                  }}
                  placeholder="To location"
                  isClearOption={filters?.to_location?.label ? true : false}
                  isClearHandleChange={(event) => {
                    event.stopPropagation();
                    setFilters((s) => ({ ...s, to_location: { label: '', value: '' } }))
                    // setLabelFormValue((s) => ({
                    //   ...s,
                    //   to_location: { label: '', value: '' },
                    // }));
                  }}
                />
              </div>
            </div>
            <div style={{ display: 'flex' }}>
              <div className={filters?.my_connection === 1 ? 'connect_color' : '_optFilts connect_out_color'}>
                <img src={images.CLOSEICONLOAD} className='font_icn_anj' onClick={(e) => setFilters((s) => ({ ...s, my_connection: 0 }))} />
                <button className='_tglrBtn' onClick={(e) => setFilters((s) => ({ ...s, my_connection: 1 }))}> My Connection </button>
              </div>
            </div>

            <div className='_optFilts' >
              <p>Fleet Type</p>
              <div className='addPr_flBx'>
                <ul className="nav nav-tabs cstNew_tabs _truckSelection" id="addParty" role="tablist">
                  {lorryType?.length > 0 ? lorryType?.map((item, indx) => (
                    <li
                      className="nav-item"
                      role="presentation"
                      key={indx}
                    >
                      <button id={item?.lorry_type}
                        className={
                          "nav-link " + (activeTab?.lorry_type === item?.lorry_type ? "active" : "")
                        }
                        type="button"
                        aria-selected={activeTab?.lorry_type === item?.lorry_type}
                        onClick={(e, val) => changeActiveTab(e, item)}
                      >
                        {item?.lorry_type}
                        <img
                          src={images.CLOSEICONLOAD}
                          className='font_icn_anj'
                          onClick={(e) => {
                            e.stopPropagation();
                            setActiveTab({});
                            setFilters((s) => ({ ...s, lorry_type_id: '' }))
                          }}
                        />
                      </button>
                    </li>
                  )) : <p className='p-2 mt-3'> Loading... </p>}
                </ul>
              </div>
            </div>

            <div className='_optFilts'>
              <button className='anime-btn _mrkt_searchBtn' onClick={(e) => filterSearchEvent(e)}>{isFilterLoading ? 'Loading...' : 'Search'}</button>
            </div>
          </div>

          <div className='_mrktInfilts_bx'>
            <div className='_optFilts' >
              <div style={{ marginBottom: '8px' }}>
                <p>Favorite Route <button className='_showAllRoute' onClick={(e) => setShowFavRoute(true)}>Show all</button></p>
              </div>
              {
                favoriteRoute?.length > 0 ?
                  favoriteRoute?.map((el, ind) => {
                    return (
                      <React.Fragment key={ind}>
                        <div className='_favRouteBx'>
                          <div className='_favRoute_inTb'>
                            <div className='_favSave' onClick={(e) => saveFavorite({ ...el, value: 0 })}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                                <rect width="26" height="26" rx="2.36364" transform="matrix(-1 0 0 1 26 0)" fill="#DDE6FF" />
                                <path d="M7.68182 19.7954V7.35682C7.68182 6.94411 7.82009 6.59979 8.09662 6.32386C8.37316 6.04792 8.71748 5.90965 9.12959 5.90906H16.9857C17.3978 5.90906 17.7421 6.04732 18.0187 6.32386C18.2952 6.60039 18.4332 6.94471 18.4326 7.35682V19.7954L13.0572 17.4867L7.68182 19.7954Z" fill="#1956F6" />
                              </svg>
                            </div>
                            <div
                              className='_fr_txt'
                              onClick={(e) => { getListMarketLoad({ from_location: el?.from_location, to_location: el?.to_location }); setFilters((s) => ({ ...s, from_location: { label: el?.from_location, value: el?.from_location }, to_location: { label: el?.to_location, value: el?.to_location } })) }}
                            >
                              <span>{el?.from_location} &gt; {el?.to_location}</span>
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    )
                  })
                  : <div className='_favRouteBx'>
                    <div className='_favRoute_inTb'>
                      <div className='_favSave'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                          <rect width="26" height="26" rx="2.36364" transform="matrix(-1 0 0 1 26 0)" fill="#DDE6FF" />
                          <path d="M7.68182 19.7954V7.35682C7.68182 6.94411 7.82009 6.59979 8.09662 6.32386C8.37316 6.04792 8.71748 5.90965 9.12959 5.90906H16.9857C17.3978 5.90906 17.7421 6.04732 18.0187 6.32386C18.2952 6.60039 18.4332 6.94471 18.4326 7.35682V19.7954L13.0572 17.4867L7.68182 19.7954Z" fill="#1956F6" />
                        </svg>
                      </div>

                      <div className='_fr_txt'> <span> No Favorite Route </span></div>
                    </div>
                  </div>
              }
            </div>
          </div>

          <div className='_mrktInfilts_bx'>
            <div className='_optFilts' >
              <div style={{ marginBottom: '8px' }}>
                <p> Recent </p>
              </div>

              {
                recentSearchData?.length > 0 ?
                  recentSearchData?.map((el, ind) => {
                    return (
                      <React.Fragment key={ind}>
                        <div className='_favRouteBx'>
                          <div className='_favRoute_inTb'>
                            &nbsp; <div className='_fr_txt'> <span>{el?.from_location} &gt; {el?.to_location}</span></div>
                          </div>
                        </div>
                      </React.Fragment>
                    )
                  })
                  : <div className='_favRouteBx'>
                    <div className='_favRoute_inTb'>
                      &nbsp; <div className='_fr_txt'> <span> No Recent </span></div>
                    </div>
                  </div>
              }
            </div>
          </div>
        </div>

        <div className='_marktLoad_listBx' id="is_marketLoad" ref={divRef}>

          {(marketLoadData?.length > 0 &&
            marketLoadData?.map((el, ind) => {
              return (
                <React.Fragment key={ind}>
                  <div className='_mrkList_otrBx'>

                    <div className='_rtrBxs'>
                      {el?.from_location?.length > 0 && el?.from_location?.map((elem, idx) => {
                        return (
                          <div className='_routDetl' key={idx}>
                            <span className="_rtLocates"><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none"><circle cx="6" cy="6" r="5" stroke="#22A57A" strokeWidth="2"></circle></svg></span>
                            <div className="_rct_st"><span className="_rtCity">{elem?.from_city}</span><span className="_rtState"> <span> | </span> {elem?.from_state} </span></div>
                          </div>
                        )
                      })}
                      {el?.to_location?.length > 0 && el?.to_location?.map((elem, idx) => {
                        return (
                          <div className='_routDetl' key={idx}>
                            <span className="_rtLocates"><svg xmlns="http://www.w3.org/2000/svg" width="12" height="16" viewBox="0 0 12 16" fill="none"><g clipPath="url(#clip0_7368_6226)"><path d="M11.0853 2.73106C10.1198 1.27452 8.75268 0.388306 6.98397 0.0853333C6.81846 0.0571499 6.64768 0.0567584 6.48744 0C6.14222 0 5.79659 0 5.45137 0C5.44204 0.034055 5.41242 0.0274006 5.38727 0.031315C5.13211 0.071633 4.87532 0.0986422 4.6234 0.158141C3.10824 0.51748 1.89449 1.31445 1.03042 2.56C-0.00929966 4.05764 -0.249454 5.69463 0.252355 7.43261C0.585406 8.58618 1.12251 9.65441 1.7379 10.6858C2.81657 12.4943 4.10943 14.1559 5.51993 15.7346C5.83634 16.0889 6.16656 16.0885 6.48338 15.735C7.48578 14.6167 8.42327 13.4498 9.27517 12.2203C10.1169 11.0053 10.8743 9.74483 11.4252 8.37793C11.7132 7.66356 11.9376 6.93001 11.9871 6.16162C12.0666 4.9282 11.7773 3.77541 11.0853 2.73106ZM6.00145 8.70948C4.33741 8.70909 2.97843 7.39151 2.98533 5.78544C2.99222 4.18055 4.3439 2.87706 6.00186 2.87746C7.6659 2.87785 9.02488 4.19543 9.01798 5.80149C9.01068 7.40639 7.659 8.70987 6.00145 8.70948Z" fill="#D62727"></path></g><defs><clipPath id="clip0_7368_6226"><rect width="12" height="16" fill="white"></rect></clipPath></defs></svg></span>
                            <div className="_rct_st" ><span className="_rtCity">{elem?.to_city}</span><span className="_rtState"> <span> | </span> {elem?.to_state}</span></div>
                          </div>
                        )
                      })}
                    </div>

                    <div className='_fleerBxs'>
                      <p className="tsm_txt_gry">Fleet Type</p>
                      <p className="tl_txt">{el?.lorry_type_id?.lorry_type} &gt; {el?.vehicle_type_id?.name}</p>
                    </div>

                    <div className='_materialBxs'>
                      <p className="tsm_txt_gry">Material</p>
                      <p className="tl_txt">{el?.material_type_id?.name != null ? el?.material_type_id?.name : '-'}</p>
                    </div>

                    <div className='_payTerms_bxs'>
                      <p className="tsm_txt_gry">Payment Terms</p>
                      <p className="tl_txt">{el?.payment_terms === 1 ? 'Advance' : 'To pay'} {el?.payment_percentage ? `${(el?.payment_percentage).toFixed(1)}%` : ''}</p>
                    </div>

                    <div className='_whtBxs'>
                      <p className="tsm_txt_gry">Weight</p>
                      <p className="tl_txt">{el?.weight != null ? `${el?.weight} Ton` : '-'} </p>
                    </div>

                    <div className='_peDate'>
                      <p className="tsm_txt_gry">Plan Date</p>
                      <p className="tl_txt"> {moment(el?.indent_date).format('DD MMM YYYY')}</p>
                    </div>

                    <div className='_peDate'>
                      <p className="tsm_txt_gry">Expiry Date</p>
                      <p className="tl_txt"> {moment(el?.indent_date).format('DD MMM YYYY')} </p>
                    </div>

                    <div className='_plc_Nbid'>
                      <div className='_bidR_bxs'>
                        <div className='__bidR_usrBx'>
                          <span className='_bidR_verifyIcon'>
                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                              <circle cx="9.5" cy="9.5" r="6.5" fill="white" />
                              <path fillRule="evenodd" clipRule="evenodd" d="M9 18C10.1819 18 11.3522 17.7672 12.4442 17.3149C13.5361 16.8626 14.5282 16.1997 15.364 15.364C16.1997 14.5282 16.8626 13.5361 17.3149 12.4442C17.7672 11.3522 18 10.1819 18 9C18 7.8181 17.7672 6.64778 17.3149 5.55585C16.8626 4.46392 16.1997 3.47177 15.364 2.63604C14.5282 1.80031 13.5361 1.13738 12.4442 0.685084C11.3522 0.232792 10.1819 -1.76116e-08 9 0C6.61305 3.55683e-08 4.32387 0.948211 2.63604 2.63604C0.948212 4.32387 0 6.61305 0 9C0 11.3869 0.948212 13.6761 2.63604 15.364C4.32387 17.0518 6.61305 18 9 18ZM8.768 12.64L13.768 6.64L12.232 5.36L7.932 10.519L5.707 8.293L4.293 9.707L7.293 12.707L8.067 13.481L8.768 12.64Z" fill="#00934C" />
                            </svg>
                          </span>
                          {/* <img src={bidderImg} alt="" /> */}
                          {el?.company_id?.company ? <div className='_fstLetter'> {(el?.company_id?.company[0]).toUpperCase()} </div> : ''}

                          {(el?.company_id?.rating && el?.company_id?.rating > 0) ?
                            <div className='_ratingBx'>
                              <FaStar />
                              <span> {el?.company_id?.rating} </span>
                            </div> :
                            <div className='_ratingBx new_starCls'>
                              <span> New </span>
                            </div>
                          }

                        </div>
                        <div className='w-100'>
                          <p className='tl_txt'>{el?.company_id?.company ? el?.company_id?.company : '-'}</p>
                          <p className='tsm_txt'>{el?.company_id?.city?.label}, {el?.company_id?.state?.label}</p>
                        </div>
                      </div>

                      <div className='_actionInr_bx'>
                        <Link to={`tel:${el?.user_id?.mobile_no ? el?.user_id?.mobile_no : ''}`} data-rel="external">
                          <button className='_callBtn'>
                            <IoCall />
                          </button>
                        </Link>
                        <button onClick={(e) => setShowPlaceBid(el)} className='_placeBid_btn'> {el?.isBidPlaced === 1 ? 'Update Bid' : 'Place Bid'} </button>
                      </div>
                    </div>

                  </div>
                </React.Fragment>
              )
            })
            //  : 'No Data Found'
          )}
          {loading && <p> Loading... </p>}
          {(marketLoadData?.length === 0 && !loading) && <p className='p-2'> No Data Found </p>}
        </div>
      </div>

      {showPlaceBid
        && (
          <PlaceBidModal {...placeBidModal} />
        )
      }

      {showFavRoute
        && (
          <FavoriteRouteModal {...favRouteModal} />
        )
      }


{showFromTo
        && (
          <FromAndToLoaction {...fromToProps} />
        )
      }
{showToFrom
        && (
          <ToAndFromLocation {...toFromProps} />
        )
      }
    </>
  )
}

export default MarketLoad