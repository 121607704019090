import { handleKeyDown } from "_services";
import { CommonDatePicker, CommonInput, CommonModal } from "components";
import { Images } from "constants";
import { UserContext } from "context";
import { endTrip } from "features";
import { errorSchema, utils } from "helper";
import moment from "moment";
import Loading from "pages/Loading";
import React, { useContext, useEffect, useState } from "react";
import { useDispatch } from "react-redux";

const EndTripModal = (props) => {
  const { closeModal, callBackEnd, lastTripDetails,endTripFrom } = props;

  const initialFrom = {
    company_id: "",
    trip_id: "",
    end_date: new Date(),
    manage_trip_kms:0,
    start_kms: "",
    end_kms: "",
    fleet_average:"",
    fuel_out:"",
   
  };

  const { masterData, globTripDetails } = useContext(UserContext);
  const dispatch = useDispatch();
  const [form, setForm] = useState(initialFrom);
  const [formError, setFormError] = useState("");
  const [isLoadding, setIsLoadding] = useState(false);
  const [totalKm,setTotalKm] = useState(0)

  useEffect(() => {
    if (lastTripDetails) {
      setForm({
        ...form,
        company_id: lastTripDetails?.company_id,
        trip_id: lastTripDetails?._id,
        manage_trip_kms:lastTripDetails?.vehicle_id?.manage_trip_kms?lastTripDetails?.vehicle_id?.manage_trip_kms:0,
        start_kms: lastTripDetails?.start_kms,
        end_kms: '',
        fleet_average:lastTripDetails?.vehicle_id?.vehicle_average,
        manage_fuel_out:lastTripDetails?.vehicle_id?.fuel_out?lastTripDetails?.vehicle_id?.fuel_out:0,
        fuel_out:"",
        manage_fuel:lastTripDetails?.vehicle_id?.manage_fuel,
      });
    }
  }, [lastTripDetails]);
  
  useEffect(()=>{
    const totalRunning = form.end_kms ? form.end_kms - form.start_kms : 0;
    setTotalKm(Number(totalRunning))

    if (totalRunning >0) {
      if (form.fleet_average && form.fuel_out) {
        
        setForm((prevForm) => ({
          ...prevForm,
          fuel_out: totalRunning / prevForm.fleet_average,
        }));
      } else if (form.fleet_average && !form.fuel_out) {
        
        setForm((prevForm) => ({
          ...prevForm,
          fuel_out: totalRunning / prevForm.fleet_average,
        }));
      } else if (form.fuel_out && !form.fleet_average) {
        
        setForm((prevForm) => ({
          ...prevForm,
          fleet_average: totalRunning / prevForm.fuel_out,
        }));
      }
    }else{
 
      setForm((prevForm) => ({
        ...prevForm,
        fuel_out: 0,
      }));
    }
  },[form.end_kms])
  
  const handleOnChange = async (name, value) => {
    const stateObj = {
      ...form,
      [name]: value,
    };


let formattedValue = value;

  // Apply formatting only to `start_kms` and `end_kms`
  if (name === "start_kms" || name === "end_kms"||name === "fleet_average"||name === "fuel_out") {
    // Remove any non-numeric characters except for decimal points
    formattedValue = value.replace(/[^0-9.]/g, '');

    // Allow only up to two decimal places
    if (formattedValue.includes('.')) {
      const [integerPart, decimalPart] = formattedValue.split('.');
      formattedValue = `${integerPart}.${decimalPart.slice(0, 2)}`;
    }
  }

  // Update the form state
  setForm((prevForm) => ({
    ...prevForm,
    [name]: formattedValue,
  }));

    if (!!formError) {
      const validationResult = await utils.checkFormError(
        { ...stateObj },
        errorSchema.endTripSchema
      );
      if (validationResult === true) {
        setFormError("");
      } else {
        setFormError(validationResult);
      }
    }

    if (name === "fuel_out" && totalKm > 0) {
      if(value){
        const fleetAvg = totalKm / value;
        setForm(prevForm => ({
          ...prevForm,
          fleet_average: fleetAvg ? fleetAvg.toFixed(2) : "",
        }));
      }else{
        setForm(prevForm => ({
          ...prevForm,
          fleet_average:"",
        }));
      }
      // Update fleet_average when fuel_out changes
     
    } else if (name === "fleet_average" && totalKm > 0) {
      // Update fuel_out when fleet_average changes
      if(value){
        const fuelOut = totalKm / value;
        setForm(prevForm => ({
          ...prevForm,
          fuel_out: fuelOut ? fuelOut.toFixed(2) : "",
        }));
      }else{
        setForm(prevForm => ({
          ...prevForm,
          fuel_out:  "",
        }));
      }
     
    }
  };

  const onSubmitEnd = async () => {
    const validationResult = await utils.checkFormError(
      { ...form },
      errorSchema.endTripSchema
    );

   
    const payload = {
      company_id: form?.company_id,
      trip_id: form?.trip_id,
      end_date: form?.end_date,
      start_kms: form?.start_kms,
      end_kms: Number(form?.end_kms),
      fleet_average:Number(form?.fleet_average),
      fuel_out:Number(form?.fuel_out)
    };

    if (validationResult === true) {
      setIsLoadding(true);
      try {
        const res = await dispatch(endTrip(payload));
        if (res?.payload?.responseCode === 200) {
          setIsLoadding(false);
          closeModal();
          callBackEnd(res?.payload?.data?.trip);
        }
      } catch {
        setIsLoadding(false);
      }
    } else {
      setIsLoadding(false);
      setFormError(validationResult);
    }
  };

  const propsEndTrip = {
    ...props,
    isLoadding: isLoadding,
    disabled: isLoadding,
    onSubmit: onSubmitEnd,
  };
  

  
  
  return (
    <>
      <CommonModal {...propsEndTrip}>
        <div className="addFreight_body">
        {lastTripDetails ? (
          <>
            <div className="endTrip_bxs">
              <div className="_endBr_head">
                <div className="_endBrc_flx">
                  <span>
                    <img src={Images.BRANCHICON} alt="" />
                  </span>
                  <h4>
                    {lastTripDetails?.branch_id?.branch
                      ? `${lastTripDetails?.branch_id?.branch} Branch`
                      : ""}
                  </h4>
                </div>

                <p>
                  {lastTripDetails?.trip_number
                    ? `# ${lastTripDetails?.trip_number}`
                    : ""}
                </p>
              </div>

              <div className="_transDetail_bx">
                <h4>{lastTripDetails?.supplier_id?.name}</h4>

                <div className="_trkDetail">
                  <h6 className="_trkNum">
                    {lastTripDetails?.vehicle_id?.vehicle_no.slice(0, 4) +
                      " " +
                      lastTripDetails?.vehicle_id?.vehicle_no.slice(4, 6) +
                      " " +
                      lastTripDetails?.vehicle_id?.vehicle_no.slice(6)}{" "}
                  </h6>
                  <div className="_trkMark _lrPartyPoint_status">
                    {
                      masterData?.vehicle_ownership_list?.find(
                        (i) => i.id === lastTripDetails?.vehicle_ownership
                      )?.name
                    }
                  </div>
                  <div className="_trkTyre">
                    <span>{lastTripDetails?.vehicle_type_id?.name}</span>
                  </div>
                </div>

                <div>
                  {lastTripDetails?.from_location?.map((i) => (
                    <div className="_routDetl">
                      <span className="_rtLocates">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="12"
                          viewBox="0 0 12 12"
                          fill="none"
                        >
                          <circle
                            cx="6"
                            cy="6"
                            r="5"
                            stroke="#22A57A"
                            strokeWidth="2"
                          />
                        </svg>
                      </span>

                      <div className="_rct_st">
                        <span className="_rtCity">{i?.from_city}</span>
                        <span className="_rtState">
                          {" "}
                          <span> | </span>
                          {i?.from_state}
                        </span>
                      </div>
                    </div>
                  ))}

                  {lastTripDetails?.to_location?.map((i) => (
                    <div className="_routDetl">
                      <span className="_rtLocates">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="12"
                          height="16"
                          viewBox="0 0 12 16"
                          fill="none"
                        >
                          <g clipPath="url(#clip0_7368_6226)">
                            <path
                              d="M11.0853 2.73106C10.1198 1.27452 8.75268 0.388306 6.98397 0.0853333C6.81846 0.0571499 6.64768 0.0567584 6.48744 0C6.14222 0 5.79659 0 5.45137 0C5.44204 0.034055 5.41242 0.0274006 5.38727 0.031315C5.13211 0.071633 4.87532 0.0986422 4.6234 0.158141C3.10824 0.51748 1.89449 1.31445 1.03042 2.56C-0.00929966 4.05764 -0.249454 5.69463 0.252355 7.43261C0.585406 8.58618 1.12251 9.65441 1.7379 10.6858C2.81657 12.4943 4.10943 14.1559 5.51993 15.7346C5.83634 16.0889 6.16656 16.0885 6.48338 15.735C7.48578 14.6167 8.42327 13.4498 9.27517 12.2203C10.1169 11.0053 10.8743 9.74483 11.4252 8.37793C11.7132 7.66356 11.9376 6.93001 11.9871 6.16162C12.0666 4.9282 11.7773 3.77541 11.0853 2.73106ZM6.00145 8.70948C4.33741 8.70909 2.97843 7.39151 2.98533 5.78544C2.99222 4.18055 4.3439 2.87706 6.00186 2.87746C7.6659 2.87785 9.02488 4.19543 9.01798 5.80149C9.01068 7.40639 7.659 8.70987 6.00145 8.70948Z"
                              fill="#D62727"
                            />
                          </g>
                          <defs>
                            <clipPath id="clip0_7368_6226">
                              <rect width="12" height="16" fill="white" />
                            </clipPath>
                          </defs>
                        </svg>
                      </span>

                      <div className="_rct_st">
                        <span className="_rtCity">{i?.to_city}</span>
                        <span className="_rtState">
                          {" "}
                          <span> | </span> {i?.to_state}
                        </span>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="_endTr_body">
              <div className="singleDiv">
                {/* <CommonInput
                  type="date"
                  label="End Trip Date"
                  name="end_date"
                  value={form?.end_date}
                  onChange={({ target: { name, value } }) => {
                    handleOnChange(name, value);
                  }}
                  focus={!!formError?.end_date}
                  error={formError.end_date}
                /> */}

                <CommonDatePicker
                  name="date"
                  label="End Trip Date"
                  startDate={form?.end_date}
                  setStartDate={(data) => {
                    handleOnChange("date", data);
                  }}
                  error={!form?.end_date ? "Required" : ""}
                />
              </div>

              {form?.manage_trip_kms !=0 && <>
                <div className="halfDiv">
                <CommonInput
                  type="text"
                  label="Start KM"
                  name="start_kms"
                  value={form?.start_kms}
                  onChange={({ target: { name, value } }) => {
                    handleOnChange(name, value);
                  }}
                  focus={!!formError?.start_kms}
                  error={formError.start_kms }
                  onKeyDown={handleKeyDown}
                />
              </div>

              <div className="halfDiv">
                <CommonInput
                  type="text"
                  label="End KM"
                  name="end_kms"
                  value={form?.end_kms}
                  onChange={({ target: { name, value } }) => {
                    handleOnChange(name, value);
                  }}
                  focus={!!formError?.end_kms}
                  error={formError.end_kms ||(form?.end < form?.start_kms && "End Km Higher Then Start Km")}
                  onKeyDown={handleKeyDown}
                />
              </div>
              <div className="singleDiv">
                <div className="_endTrp_totalIng">
                  <p>Total Running</p>{" "}
                  <p>
                  {form?.end_kms
            ? `${((form?.end_kms - form?.start_kms) || 0).toFixed(2)} KM`
            : "0.00 KM"}
                  </p>
                </div>
              </div>
              </>}
              
            { form?.manage_fuel_out !=0 && <>
             <div className="halfDiv">
                <CommonInput
                  type="text"
                  label="Fleet Average"
                  name="fleet_average"
                  value={form?.fleet_average}
                  onChange={({ target: { name, value } }) => {
                    handleOnChange(name, value);
                  }}
                  disabled={form?.manage_fuel_out==2}
                  focus={!!formError?.fleet_average}
                  error={formError.fleet_average }
                  onKeyDown={handleKeyDown}
                />
              </div>
              <div className="halfDiv">
                <CommonInput
                  type="text"
                  label="Fuel Out"
                  name="fuel_out"
                  value={form?.fuel_out}
                  onChange={({ target: { name, value } }) => {
                    handleOnChange(name, value);
                  }}
                  disabled={form?.manage_fuel_out==2}
                  focus={!!formError?.fuel_out}
                  error={formError.fuel_out }
                  onKeyDown={handleKeyDown}
                />
              </div>
             </>}
            </div>
          </>
        ) : (
          <Loading />
        )}
        </div>
      </CommonModal>
    </>
  );
};

export default EndTripModal;
