import React, { useEffect } from "react";
import { RxCross2 } from "react-icons/rx";

const CommonInput = (props) => {

  const {
    type = "text",
    name,
    label,
    error,
    focus,
    labelClass,
    error_class = "",
    onChange,
    value,
    placeholder = '',
    className = "",
    min,
    max = 1000,
    onInput = () => { },
    maxLength = 1000,
    disabled = false,
    ref = null,
    preAddon = false,
    postAddon = false,
    onKeyDown,
    onClick,
    onFocus,
    labelRedStar=false,
    accept,
    isClearOption = false,
  isClearHandleChange,
  errorShow=true
  } = props;

  const invalid = !!(focus && error);
  const valid = !!Boolean(focus && !error);

  useEffect(() => {
    const inputFields = document.querySelectorAll("input");
    inputFields.forEach((input) => {
      input.setAttribute("autocomplete", "off");
    });
  }, []);

  return (
    <div className={error ? "anvCustom_floatInput form-floating anvInputError" : "anvCustom_floatInput form-floating "}>
      <input
        autoComplete="off"
        disabled={disabled}
        ref={ref}
        name={name}
        type={type}
        onFocus={onFocus}
        id={name}
        className={"form-control" + className}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        min={min}
        max={max}
        onClick={onClick}
        maxLength={maxLength}
        onInput={onInput}
        onKeyDown={onKeyDown}
        accept={accept}
 
        
        // invalid={invalid}
        // valid={valid}
      />
      {isClearOption && value!= "All" && (
          <span className="clear-icon" onClick={isClearHandleChange}>
            <RxCross2 size={20} color="black" />
          </span>
        )}
      <label htmlFor="floatingInput">
        <span>{label} {labelRedStar && <span className="requiredLable"> * </span>}</span>
      </label>
     {errorShow &&  <>
      {error && (
        <span className={"input_drop_error" + error_class}>
          {error}
        </span>
      )}
      </>}
      
    </div>
  );
};

export default CommonInput;