import React, { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useLocation } from "react-router-dom";
import { RiDeleteBin5Line } from "react-icons/ri";
import { GrFormAdd, GrFormSubtract } from "react-icons/gr";
import { MdOutlineEdit } from "react-icons/md";
import StorageService from "utils/StorageServices/StorageServices";
import { deleteChartOfAccount, getChartOfAccountDetails } from "_services/accountingServices/accountingServices";
import { ButtonHeading } from "components";
import { AddChartAccountModal } from "modals";

const ChartOfAccount = () => {

    const company_id = StorageService.getCompanyId();
    const [modalShow, setModalShow] = useState({ boolean: '', name: '', });
    const [consignorKey, setConsignorKey] = useState('')
    const location = useLocation()
    const [open, setOpen] = useState(false)
    const [deleteDisabled, setDeleteDisabled] = useState(false)
    const [editOpen, setEditOpen] = useState(false)
    const [chartOfAccountList, setChartOfAccountList] = useState([])
    const [openIndex, setOpenIndex] = useState(null);
    const [mainIndex, setMainIndex] = useState(null);
    const [selectedValue, setSelectedValue] = useState({
        cat_name: "",
        sub_cat_name: "",
        account_name: "",
        code: "",
        desc: "",
        is_attachment: "",
        is_manual: "",
    });
    const [editForm, setEditForm] = useState({
        account_id: '',
        category_id: '',
        sub_category_id: '',
        name: "",
        code: "",
        desc: "",
        parent_account_id: '',
        is_manual: 1,
        is_attachment: 1,
        company_id: company_id
    })


    const modaOpenClose = async (val) => {
        await setModalShow({ ...modalShow, trueFalse: val.trueFalse, name: val.name })
    }

    const handleClicOpenModal = (e) => {
        setOpen(true)
        e.preventDefault();
        modaOpenClose({ trueFalse: true, name: "addplan" })

    };

    useEffect(() => {
        // getAccountListFun()
        getChartOfAccounts()

    }, [])

    // const getAccountListFun = async () => {
    //     try {

    //         const res = await dispatch(getAccountList())
    //         if (res.payload.responseCode === 200) {
    //             // setAccountList(res.payload.data)

    //         } else {
    //             toast.error(res?.payload?.responseMessage)
    //         }

    //     } catch (error) {
    //         toast.error(error)
    //     }
    // }

    const getChartOfAccounts = async () => {
        try {

            const res = await getChartOfAccountDetails({ company_id: company_id })

            if (res.responseCode === 200) {
                setChartOfAccountList(res.data.chart_of_account)
            } else {
                toast.error(res?.responseMessage)
            }
        } catch (error) {
            // toast.error(error)
        }

    }
    const chartOfAccountDeleteFunction = async (category_id, sub_category_id, account_id) => {
        try {
            setDeleteDisabled(true)
            const res = await deleteChartOfAccount({
                category_id: category_id,
                sub_category_id: sub_category_id,
                account_id: account_id,
                company_id: company_id
            })
            if (res.responseCode === 200) {
                getChartOfAccounts();
                toast.success("Deleted")
                setDeleteDisabled(false)
            } else {
                toast.error(res?.responseMessage)
                setDeleteDisabled(false)
            }

        } catch (error) {
            toast.error(error)
            setDeleteDisabled(false)
        }
    }
    // const updateChartOfAccountsFunction = async (data) => {
    //     try {

    //         if (editForm.category_id && editForm.sub_category_id && editForm.account_id && editForm.name) {
    //             const res = await updateChartOfAccount(editForm)
    //             if (res.payload.data.responseCode === 200) {
    //                 getChartOfAccounts()
    //                 setEditOpen(false)
    //                 setEditForm({
    //                     account_id: '',
    //                     category_id: '',
    //                     sub_category_id: '',
    //                     name: "",
    //                     code: "",
    //                     desc: "",
    //                     parent_account_id: '',
    //                     is_manual: 1,
    //                     is_attachment: 1,
    //                     company_id: company_id
    //                 })
    //                 toast.success(res?.payload?.data?.responseMessage)
    //             } else {
    //                 toast.error(res?.payload?.data?.responseMessage)
    //             }
    //         } else {
    //             toast.error("Account Name Required ")
    //         }


    //     } catch (error) {
    //         toast.error(error)
    //     }
    // }


    const handleEdit = async (category_id, sub_category_id, account_id, selecteValue) => {
        setEditOpen(true)
        setEditForm(prevState => ({
            ...prevState,
            category_id: category_id,
            sub_category_id: sub_category_id,
            account_id: account_id,

        }));
        if (selecteValue) {
            setSelectedValue(prevState => ({
                ...prevState,
                cat_name: selecteValue.cat_name,
                sub_cat_name: selecteValue.sub_cat_name,
                account_name: selecteValue.account.name,
                code: selecteValue.account.code,
                desc: selecteValue.account.desc,
                is_attachment: selecteValue.account.is_attachment,
                is_manual: selecteValue.account.is_manual,
            }));
            setEditForm(prevState => ({
                ...prevState,
                name: selecteValue.account.name,
                code: selecteValue.account.code,
                desc: selecteValue.account.desc,
                parent_account_id: selecteValue.account.id,
                is_manual: selecteValue.account.is_manual,
                is_attachment: selecteValue.account.is_attachment,

            }));
        }

    }

    // const toggleClass = (index, index2) => {
    //     console.log("index",index,"index2",index2);

    //     setMainIndex(index)
    //     setOpenIndex(openIndex === index2 ? -1 : index2);
    // };

    const [openIndices, setOpenIndices] = useState({});
    const toggleClass = (index, subIndex) => {
        const isOpen = (openIndices[index] || []).includes(subIndex);

        setOpenIndices(prevState => ({
            ...prevState,
            [index]: isOpen
                ? (prevState[index] || []).filter(idx => idx !== subIndex)
                : [...(prevState[index] || []), subIndex]
        }));
    };
    const getLengt = (data) => {
        const lengths = data.filter((item, ind) => item.status === 1)
        return lengths.length
    }

    // const [show, setShow] = useState(false);

    // let AddChartOfAccountModal = {
    //     isOpen: show,
    //     closeModal: (e) => setShow(false),
    //     modalMainClass: "_dashCommon_Modal _mdModal",
    //     modalTitle: "Add Account",
    //     // headerPart: true,
    //     footerPart: true,
    //     submitTitle: "Add Account",
    // }

    let AddChartOfAccountModal = {
        isOpen: editOpen,
        closeModal: (e) => setEditOpen(false),
        modalMainClass: "_dashCommon_Modal _mdModal",
        modalTitle: "Add Account",
        // headerPart: true,
        footerPart: true,
        submitTitle: "Add Account",
    }

    return (
        <>
            <ButtonHeading
                title="Chart Of Account"
                onClick={() => setEditOpen(!editOpen)}
                buttonTitle="Add Account"
            />

            {/* Collapse tab section */}
            <div className="_chrt_mainBg">
                <div className="_allColapse_bx">
                    {chartOfAccountList && chartOfAccountList?.length > 0 ? (
                        chartOfAccountList?.map((item, index) => (
                            <div className="otrCollapse_bx" key={index}>
                                <div className="tileTitle">{item?.name}</div>
                                {item?.sub_category && item?.sub_category?.length > 0 && item?.sub_category?.map((subItem, subIndex) => (
                                    subItem?.status === 1 ? (
                                        <>
                                            <div className={(openIndices[index] || []).includes(subIndex) ? 'tileData _toggleData' : 'tileData'} key={`subIndex${subIndex}`}>
                                                {subItem?.accounts && subItem?.accounts?.length > 0 ? (
                                                    <a onClick={() => toggleClass(index, subIndex)} >
                                                        <span className="openCollaps">
                                                            <GrFormAdd />
                                                        </span>
                                                        <span className="closeCollaps">
                                                            <GrFormSubtract />
                                                        </span>
                                                        {subItem?.name}
                                                        ({getLengt(subItem?.accounts)})
                                                    </a>
                                                ) : (
                                                    <p className="subCategory_item">{subItem.name}</p>
                                                )}

                                                {subItem?.accounts && subItem?.accounts?.length > 0 && (
                                                    <ul className="subCategory">
                                                        {subItem?.accounts.map((i, ixd) => (
                                                            i?.status === 1 ? (
                                                                <li key={ixd}>
                                                                    <span className='li_cont'>
                                                                        <span>{i?.name}</span>
                                                                        {i?.is_manual === 1 && (
                                                                            <span className='edit_delet_cont'>
                                                                                <span className="edit_sub_category_item" onClick={() => handleEdit(item?.id, subItem?.id, i?.id, { cat_name: item.name, sub_cat_name: subItem.name, account: i })}>
                                                                                        <MdOutlineEdit />
                                                                                    </span>
                                                                                <span className="delete_sub_category_item" onClick={() => chartOfAccountDeleteFunction(item?.id, subItem?.id, i?.id)}>
                                                                                    <button disabled={deleteDisabled}>
                                                                                        <RiDeleteBin5Line />
                                                                                    </button>
                                                                                </span>
                                                                            </span>
                                                                        )}
                                                                    </span>
                                                                </li>
                                                            ) : null
                                                        ))}
                                                    </ul>
                                                )}
                                            </div>
                                        </>
                                    ) : null
                                ))}
                            </div>
                        ))
                    ) : (<> Loading... </>)}
                </div>
            </div>

            {editOpen
                && (
                    <AddChartAccountModal {...AddChartOfAccountModal} />
                )
            }

        </>
    );
};

export default ChartOfAccount;