import React, { createContext, useContext } from "react";
import { useJsApiLoader } from "@react-google-maps/api";

const GoogleMapsContext = createContext();

export const GoogleMapsProvider = ({ children }) => {
  const { isLoaded, loadError } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyAkWxbuO-maU16USeyELd3UP0hPLITP3Ec",
    libraries: ["places"], // Add required libraries here
    
  });

  if (loadError) {
    return <div>Error loading Google Maps</div>;
  }

  return (
    <GoogleMapsContext.Provider value={isLoaded}>
      {children}
    </GoogleMapsContext.Provider>
  );
};

// Custom hook to use Google Maps context
export const useGoogleMaps = () => useContext(GoogleMapsContext);
