import { Heading } from 'components';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { constant } from 'constants/constant';
import { FaStar } from 'react-icons/fa6';
import bidderImg from "../../../../src/assets/img/bidderImg.png"
import { IoChevronBack, IoChevronForward } from "react-icons/io5";
import StorageService from 'utils/StorageServices/StorageServices';
import { changeBidStatus, getBidCounts, getBidListData, updateBidMarketLoadData } from '_services';
import moment from 'moment';
import { utils } from 'helper';
import { PlaceBidModal } from 'modals'
import toast from 'react-hot-toast';
import { UserContext } from 'context';
import { IoCall } from 'react-icons/io5';
import { Link } from 'react-router-dom';
import LoadingBar from 'react-top-loading-bar';

let myBidsTabs = [
    { id: 0, title: "New Bids", value: "new_bids", count: 0 },
    { id: 1, title: "Accepted", value: "accepted_bids", count: 0 },
    { id: 3, title: "Closed", value: "closed_bids", count: 0 },
]

var totalCount = 0;

var totalItems1 = 0;
var totalItems2 = 0;
var totalItems3 = 0;

const BidsRecieved = () => {

    const loadingBarRef = useRef(null);

    const divRef = useRef(null);

    const { masterData } = useContext(UserContext);
    const [activeTab, setActiveTab] = useState(0); // 0 = active, 1 = accepted, 3 = closed
    const [page, setPage] = useState(1);
    const [loading, setLoading] = useState(false);
    const [bidList, setBidList] = useState([]);

    // const [showPlaceBid, setShowPlaceBid] = useState(false);

    const [loadingStatus, setLoadingStatus] = useState({});
    const [countArray, setCountArray] = useState(myBidsTabs);

    const [filter, setFilter] = useState({
        company_id: StorageService.getCompanyId(),
        limit: 10,
        type: 2, // 1=my bid, 2=bid received
        // status: 1, // 0=active, 1 = accepted, 3 = closed
    });

    const fetchBidListData = async (pageValue) => {
        setLoading(true);
        try {
            if (loadingBarRef.current) {
                loadingBarRef.current.continuousStart(); // Start the loading bar
            }
            let object = {
                ...filter,
                offset: pageValue,
                status: activeTab
            }
            let res = await getBidListData(object);
            if (res?.responseCode === 200) {
                if ((page - 1) * filter?.limit === 0) {
                    setBidList(res?.data);
                } else {
                    setBidList((prevItems) => [...prevItems, ...res?.data]);
                }
                totalCount = ((activeTab === 0) ? totalItems1 : (activeTab === 1 ? totalItems2 : totalItems3))
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
        } finally {
            setLoading(false);
            if (loadingBarRef.current) {
                loadingBarRef.current.complete(); // Complete the loading bar
            }
        }
    }

    const fetchBidCountsData = async () => {
        setLoading(true);
        try {
            let object = {
                type: 2,   // 1=my bid, 2=bid received
                company_id: StorageService.getCompanyId(),
            }
            let res = await getBidCounts(object);
            if (res?.responseCode === 200) {
                if (activeTab === 0 && page === 1) {
                    totalCount = res?.data?.new_bid;
                }
                totalItems1 = res?.data?.new_bid;
                totalItems2 = res?.data?.accept_bid;
                totalItems3 = res?.data?.cancel_bid;
                let dataArray = []
                for (let i = 0; i < myBidsTabs?.length; i++) {
                    for (let j = 0; j < Object.values(res?.data)?.length; j++) {
                        if (i == j) {
                            dataArray.push({ ...myBidsTabs[i], count: Object.values(res?.data)[j] })
                        }
                    }
                }
                setCountArray(dataArray);
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
        }
    }

    useEffect(() => {
        fetchBidCountsData();
    }, [])

    useEffect(() => {
        fetchBidListData(1);
    }, [activeTab])

    const handleStatusLoading = (id, isLoading, actionType) => {
        setLoadingStatus((s) => ({
            ...s,
            [id]: {
                ...s[id],
                [actionType]: isLoading,
            }
        }));
    };

    const handleStatusChange = async (data, tab_Status, action) => {
        handleStatusLoading(data?._id, true, action);
        try {
            let object = {
                plan_id: data?.plan_id?._id,
                bid_id: data?._id,
                status: tab_Status,
            }
            let res = await changeBidStatus(object);
            if (res?.responseCode === 200) {
                const listArray = [...bidList];
                const ind = listArray.findIndex((item) => item?._id === data?._id);
                listArray.splice(ind, 1);
                setBidList(listArray);
                // fetchBidListData(page);
                handleStatusLoading(data?._id, false, action);
                fetchBidCountsData();
            }
        } catch (error) {
            handleStatusLoading(data?._id, false, action);
        }
    }

    const handleScroll = () => {
        if (divRef.current.scrollTop + divRef.current.clientHeight >= divRef.current.scrollHeight) {
            setPage((prevPage) => {
                if (+totalCount >= filter?.limit * (prevPage)) {
                    return prevPage + 1;
                } else {
                    return prevPage;
                }
            });
        }
    };

    useEffect(() => {
        const div = divRef.current;
        div.addEventListener("scroll", handleScroll);
        return () => div.removeEventListener("scroll", handleScroll);
    }, []);

    const statusReturn = (key) => {
        switch (key) {
            case 0:
                return (
                    <div className="_bidStatus_bxs">
                        <span className="_pending_bidSts">Pending</span>
                    </div>
                );
                break;
            case 1:
                return (
                    <div className="_bidStatus_bxs">
                        <span className="_accepted_bidSts">Accepted</span>
                    </div>
                );
                break;
            case 2:
                return (
                    <div className="_bidStatus_bxs">
                        <span className="_rejected_bidSts">Rejected</span>
                    </div>
                );
                break;
            case 3:
                return (
                    <div className="_bidStatus_bxs">
                        <span className="_closed_bidSts">Closed</span>
                    </div>
                );
                break;
            default:
                break;
        }
    };

    const handleTabOnChange = (e, _id) => {
        e.preventDefault();
        setBidList([]);
        setPage(0);
        setActiveTab(_id);
    }

    useEffect(() => {
        if (page) {
            fetchBidListData(page);
        }
    }, [page])

    return (
        <>
            <LoadingBar
                color="#ffa800"
                ref={loadingBarRef}
                height={4}
                shadow={true}
                loaderSpeed={1000}
            />
            <Heading title='Bids Received' />

            <div>
                <div className='addPr_flBx _indentView'>
                    <ul className="nav nav-tabs cstNew_tabs px-0" id="addParty" role="tablist">
                        {countArray?.map((item, indx) => (
                            <li
                                className="nav-item"
                                role="presentation"
                                key={indx + item?.value}
                            >
                                <button
                                    className={
                                        "nav-link " + (activeTab === item?.id ? "active" : "")
                                    }
                                    type="button"
                                    aria-selected={activeTab === item?.id}
                                    onClick={(e) => handleTabOnChange(e, item?.id)}
                                >
                                    <span className='_tbTitle'>{item?.title}</span>
                                    <span className='_counts'> {item?.count} </span>

                                </button>
                            </li>
                        ))}
                    </ul>

                    <div className='_myBid_listBx' id="is_ReceivedBids" ref={divRef}>

                        {bidList?.length > 0 && bidList?.map((el, ind) => {
                            return (
                                <React.Fragment key={ind}>
                                    <div className='_mrkList_otrBx'>

                                        <div className='_rtrBxs'>
                                            {el?.plan_id?.from_location?.length > 0 && el?.plan_id?.from_location?.map((elem, idx) => {
                                                return (
                                                    <div className='_routDetl' key={idx}>
                                                        <span class="_rtLocates"><svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none"><circle cx="6" cy="6" r="5" stroke="#22A57A" stroke-width="2"></circle></svg></span>
                                                        <div class="_rct_st"><span class="_rtCity">{elem?.from_city}</span><span class="_rtState"> <span> | </span> {elem?.from_state} </span></div>
                                                    </div>
                                                )
                                            })}
                                            {el?.plan_id?.to_location?.length > 0 && el?.plan_id?.to_location?.map((elem, idx) => {
                                                return (
                                                    <div className='_routDetl' key={idx}>
                                                        <span class="_rtLocates"><svg xmlns="http://www.w3.org/2000/svg" width="12" height="16" viewBox="0 0 12 16" fill="none"><g clip-path="url(#clip0_7368_6226)"><path d="M11.0853 2.73106C10.1198 1.27452 8.75268 0.388306 6.98397 0.0853333C6.81846 0.0571499 6.64768 0.0567584 6.48744 0C6.14222 0 5.79659 0 5.45137 0C5.44204 0.034055 5.41242 0.0274006 5.38727 0.031315C5.13211 0.071633 4.87532 0.0986422 4.6234 0.158141C3.10824 0.51748 1.89449 1.31445 1.03042 2.56C-0.00929966 4.05764 -0.249454 5.69463 0.252355 7.43261C0.585406 8.58618 1.12251 9.65441 1.7379 10.6858C2.81657 12.4943 4.10943 14.1559 5.51993 15.7346C5.83634 16.0889 6.16656 16.0885 6.48338 15.735C7.48578 14.6167 8.42327 13.4498 9.27517 12.2203C10.1169 11.0053 10.8743 9.74483 11.4252 8.37793C11.7132 7.66356 11.9376 6.93001 11.9871 6.16162C12.0666 4.9282 11.7773 3.77541 11.0853 2.73106ZM6.00145 8.70948C4.33741 8.70909 2.97843 7.39151 2.98533 5.78544C2.99222 4.18055 4.3439 2.87706 6.00186 2.87746C7.6659 2.87785 9.02488 4.19543 9.01798 5.80149C9.01068 7.40639 7.659 8.70987 6.00145 8.70948Z" fill="#D62727"></path></g><defs><clipPath id="clip0_7368_6226"><rect width="12" height="16" fill="white"></rect></clipPath></defs></svg></span>
                                                        <div class="_rct_st" ><span class="_rtCity">{elem?.to_city}</span><span class="_rtState"> <span> | </span> {elem?.to_state}</span></div>
                                                    </div>
                                                )
                                            })}
                                        </div>

                                        <div className='_whtBxs'>
                                            <p class="tsm_txt_gry"> Indent Number </p>
                                            <p class="tl_txt"> {el?.plan_id?.indent_number != null ? el?.plan_id?.indent_number : '-'} </p>
                                        </div>

                                        <div className='_fleerBxs'>
                                            <p class="tsm_txt_gry">Fleet Type</p>
                                            <p class="tl_txt">{el?.plan_id?.lorry_type_id?.lorry_type ? el?.plan_id?.lorry_type_id?.lorry_type : '-'} &gt; {el?.plan_id?.vehicle_type_id?.name ? el?.plan_id?.vehicle_type_id?.name : '-'}</p>
                                        </div>

                                        <div className='_materialBxs'>
                                            <p class="tsm_txt_gry">Material</p>
                                            <p class="tl_txt">{el?.plan_id?.material_type_id?.name != null ? el?.plan_id?.material_type_id?.name : '-'}</p>
                                        </div>

                                        <div className='_payTerms_bxs'>
                                            <p class="tsm_txt_gry">Payment Terms</p>
                                            <p class="tl_txt">{el?.plan_id?.payment_terms === 1 ? 'Advance' : 'To pay'} {el?.plan_id?.payment_percentage ? `${(el?.plan_id?.payment_percentage).toFixed(1)}%` : ''}</p>
                                        </div>

                                        <div className='_whtBxs'>
                                            <p class="tsm_txt_gry">Weight</p>
                                            <p class="tl_txt">{el?.plan_id?.weight != null ? `${el?.plan_id?.weight} Ton` : '-'} </p>
                                        </div>

                                        <div className='_peDate'>
                                            <p class="tsm_txt_gry">Plan Date</p>
                                            <p class="tl_txt"> {moment(el?.plan_id?.createdAt).format('DD MMM YYYY')}</p>
                                        </div>

                                        <div className='_whtBxs'>
                                            <p class="tsm_txt_gry"> Party </p>
                                            <p class="tl_txt"> {el?.plan_id?.consignor_id?.name ? el?.plan_id?.consignor_id?.name : '-'} </p>
                                        </div>

                                        <div className='_plc_Nbid'>
                                            <div className='_myPlcBix'>
                                                <div className='_bidR_bxs'>
                                                    <div className='__bidR_usrBx'>
                                                        <span className='_bidR_verifyIcon'>
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                                <circle cx="9.5" cy="9.5" r="6.5" fill="white" />
                                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M9 18C10.1819 18 11.3522 17.7672 12.4442 17.3149C13.5361 16.8626 14.5282 16.1997 15.364 15.364C16.1997 14.5282 16.8626 13.5361 17.3149 12.4442C17.7672 11.3522 18 10.1819 18 9C18 7.8181 17.7672 6.64778 17.3149 5.55585C16.8626 4.46392 16.1997 3.47177 15.364 2.63604C14.5282 1.80031 13.5361 1.13738 12.4442 0.685084C11.3522 0.232792 10.1819 -1.76116e-08 9 0C6.61305 3.55683e-08 4.32387 0.948211 2.63604 2.63604C0.948212 4.32387 0 6.61305 0 9C0 11.3869 0.948212 13.6761 2.63604 15.364C4.32387 17.0518 6.61305 18 9 18ZM8.768 12.64L13.768 6.64L12.232 5.36L7.932 10.519L5.707 8.293L4.293 9.707L7.293 12.707L8.067 13.481L8.768 12.64Z" fill="#00934C" />
                                                            </svg>
                                                        </span>
                                                        {/* <img src={bidderImg} alt="" /> */}
                                                        {el?.company_id?.company ? <div className='_fstLetter'> {(el?.company_id?.company[0]).toUpperCase()} </div> : ''}

                                                        {(el?.company_id?.rating && el?.company_id?.rating > 0) ?
                                                            <div className='_ratingBx'>
                                                                <FaStar />
                                                                <span> {el?.company_id?.rating} </span>
                                                            </div> :
                                                            <div className='_ratingBx new_starCls'>
                                                                <span> New </span>
                                                            </div>
                                                        }

                                                        {/* <div className='_ratingBx'>
                                                            <FaStar />
                                                            <span>{el?.company_id?.rating ? el?.company_id?.rating : ''} </span>
                                                        </div> */}
                                                    </div>
                                                    <div className='w-100'>
                                                        <p className='tl_txt'>{el?.company_id?.company ? el?.company_id?.company : '-'}</p>
                                                        <p className='tsm_txt'>{el?.company_id?.city?.label}, {el?.company_id?.state?.label}</p>
                                                    </div>
                                                </div>

                                                {/* <div class="_bidStatus_bxs" ><span class={(el?.status === 0) ? "_pending_bidSts" : (el?.status === 1 ? "_accepted_bidSts" : (el?.status === 2 ? '_rejected_bidSts' : '_rejected_bidSts'))}> {(el?.status === 0) ? "Pending" : (el?.status === 1 ? "Accepted" : (el?.status === 2 ? 'Rejected' : 'Closed'))}</span></div> */}
                                                <div>{statusReturn(el?.status)}</div>

                                                <div class="_dobBid" ><p class="tsm_txt_gry">Bid on</p><p class="tl_txt">{utils?.formateDateCalander(el?.createdAt)}</p></div>

                                                {/* <div class="_rateBid" ><p class="tsm_txt_gry">User</p><p class="tl_txt">{el?.user_id?.full_name ? el?.user_id?.full_name : '-'}</p></div> */}

                                                <div class="_rateBid" ><p class="tsm_txt_gry">Amount</p><p class="tl_txt">Rs. {(el?.amount).toFixed(2)}</p></div>
                                            </div>

                                            <div className='_actionOtr_bx'>
                                                {(activeTab !== 3) &&
                                                    <Link to={`tel:${el?.user_id?.mobile_no}`} data-rel="external">
                                                        <button className='_callBtn'>
                                                            <IoCall />
                                                        </button>
                                                    </Link>
                                                }

                                                <div className='_actionInr_bx'>
                                                    {(activeTab !== 1 && activeTab !== 3) &&
                                                        <button className=' _acceptSts' onClick={(e) => handleStatusChange(el, 1, 'accept')} disabled={loadingStatus[el?._id]?.accept}>{loadingStatus[el?._id]?.accept ? 'Loading...' : 'Accept'}</button>
                                                    }
                                                    {(activeTab !== 3) &&
                                                        <button className=' _rejectSts' onClick={(e) => handleStatusChange(el, 3, 'reject')} disabled={loadingStatus[el?._id]?.reject}>{loadingStatus[el?._id]?.reject ? 'Loading...' : 'Reject'}</button>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </React.Fragment>
                            )
                        })}

                        {loading ? <p className='p-2'> Loading... </p> : <></>}
                        {(bidList?.length === 0 && !loading) && <p className='p-2'> No Data Found </p>}

                    </div>

                    {/* <div className='paginationFt_avt '>
                        {renderPagination()}
                    </div> */}

                </div>
            </div>

            {/* {showPlaceBid
                && (
                    <PlaceBidModal {...placeBidModal} />
                )
            } */}
        </>
    )
}

export default BidsRecieved