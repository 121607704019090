import { getAddressListApi, handleKeyDown } from "_services";
import { getAddressDetailsApi } from "_services/accountsServices/addressBookService";
import {
  CommonDatePicker,
  CommonInput,
  CommonModal,
  CustomSelect,
} from "components";
import images from "constants/images";
import { UserContext } from "context";
import { State } from "country-state-city";
import { errorSchema, utils } from "helper";
import AddAddressBook from "modals/AddAddressBook";
import ConfirmModalFor from "modals/ConfirmModalFor";
import React, { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FiEdit } from "react-icons/fi";
import StorageService from "utils/StorageServices/StorageServices";

const AddPoModal = (props) => {
  const { poDetails, isPoShow } = props;
  const initialForm = {
    po_date: new Date(),
    po_number: "",
    from_location: "",
    from_state: "",
    to_location: "",
    to_state: "",
    unit_id: null,
    consignee_id: null,
    unit_details: "",
    consignee_detials: "",
  };

  const citie = [
    { label: "Indore", value: "01" },
    { label: "Bhopal", value: "02" },
  ];

  const { masterData, cities, setModalZIndex } = useContext(UserContext);
  const [form, setForm] = useState(initialForm);
  const [formError, setFormError] = useState("");
  const [addressShow, setAddressShow] = useState({
    open: false,
    title: "",
  });
  const [addressList, setAddressList] = useState([]);
  const [unitDetails, setUnitDetails] = useState("");
  const [consigneeDetails, setConsigneeDetails] = useState("");
  const [addressLoad, setAddressLoad] = useState(false);
  const [picupList, setPicupList] = useState([]);
  const [dropList, setDropList] = useState([]);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [addressId,setAddressId] = useState({
    id:"",
    title:"plan"
  })
  useEffect(() => {
    getAddress();
  }, [isPoShow]);

  useEffect(() => {
   
    if (props?.fromLoaction) {
      setPicupList(
        props?.fromLoaction?.map((i) => {
          return {
            ...i,
            label: i?.from_city ? `${i?.from_city}, ${i?.from_state}` : "",
            value: i?.from_city ? i?.from_city : "",
          };
        })
      );
    }

    if (props?.toLoaction) {
      setDropList(
        props?.toLoaction?.map((i) => {
          return {
            ...i,
            label: i?.to_city ? `${i?.to_city}, ${i?.to_state}` : "",
            value: i?.to_city ? i?.to_city : "",
          };
        })
      );

      if (isPoShow?.title == "add")
        handleOnChange("to_location", props?.toLoaction[props?.toIndex]);
    }
  }, [props?.fromLoaction, props?.toLoaction]);


  useEffect(() => {
    if (picupList.length > 0 && isPoShow?.title == "add") {
      handleOnChange("from_location", picupList[0]);
    }else if(picupList.length > 0 && poDetails?.details && isPoShow?.title == "edit"){
      setForm({
        ...form,
        po_date: poDetails?.details?.po_date,
        po_number: poDetails?.details?.po_number,
        from_location: poDetails?.details?.from_location?poDetails?.details?.from_location:picupList[0].from_city,
        from_state: poDetails?.details?.from_state?poDetails?.details?.from_state:picupList[0].from_state,
        to_location: poDetails?.details?.to_location?poDetails?.details?.to_location:dropList[poDetails?.mainIn].to_city,
        to_state: poDetails?.details?.to_state?poDetails?.details?.to_state:dropList[poDetails?.mainIn].to_state,
        unit_id: poDetails?.details?.unit_id,
        consignee_id: poDetails?.details?.consignee_id,
        unit_details: poDetails?.details?.unit_details,
        consignee_detials: poDetails?.details?.consignee_detials,
      });

      setUnitDetails(poDetails?.details?.unit_details);
      setConsigneeDetails(poDetails?.details?.consignee_detials);
    }
  }, [picupList]);

  // useEffect(() => {
  //   if (poDetails?.details && isPoShow?.title == "edit") {
  //     setForm({
  //       ...form,
  //       po_date: poDetails?.po_date,
  //       po_number: poDetails?.po_number,
  //       from_location: poDetails?.from_location,
  //       from_state: poDetails?.from_state,
  //       to_location: poDetails?.to_location,
  //       to_state: poDetails?.to_state,
  //       unit_id: poDetails?.unit_id,
  //       consignee_id: poDetails?.consignee_id,
  //       unit_details: poDetails?.unit_details,
  //       consignee_detials: poDetails?.consignee_detials,
  //     });

  //     setUnitDetails(poDetails?.unit_details);
  //     setConsigneeDetails(poDetails?.consignee_detials);
  //   }
  // }, []);

  const getAddress = async () => {
    let object = {
      company_id: StorageService.getCompanyId(),
      limit: 100,
      offset: 1,
      search: "",
    };

    try {
      const res = await getAddressListApi(object);

      if (res?.responseCode === 200) {
        setAddressList(
          res?.data?.list?.map((i) => {
            return {
              ...i,
              label: i.name,
              value: i._id,
            };
          })
        );
      } else {
        toast.error(res?.responseMessage);
      }
    } catch (error) {}
  };

  const handleOnChange = async (name, value) => {
    const stateObj = {
      ...form,
      [name]: value,
    };

    if (name === "to_location") {
      stateObj.to_location = value?.to_city;
      stateObj.to_state = value.to_state;
    }

    if (name === "from_location") {
      stateObj.from_location = value?.from_city;
      stateObj.from_state = value?.from_state;
    }

    setForm(stateObj);

    if (!!formError) {
      const validationResult = await utils.checkFormError(
        {
          ...stateObj,
        },
        errorSchema.addPoSchema
      );

      if (validationResult === true) {
        setFormError("");
      } else {
        setFormError(validationResult);
      }
    }
  };

  const handleAddressDetaisl = (data) => {
    if (addressShow.title == "unit") {
      handleOnChange("unit_id", data?.value);
    } else {
      handleOnChange("consignee_id", data?.value);
    }
  };

  const handleSubmitPo = async () => {
    const validationResult = await utils.checkFormError(
      {
        ...form,
      },
      errorSchema.addPoSchema
    );
    if (validationResult == true) {
      props?.callBackPo(form);
      props?.closeModal();
    } else {
      setFormError(validationResult);
    }
  };

  const getAddressDetails = async () => {
    try {
      setAddressLoad(true);
      const res = await getAddressDetailsApi({
        company_id: StorageService?.getCompanyId(),
        addressbook_id:
          addressShow?.title == "unit" ? form?.unit_id : form?.consignee_id,
      });

      if (res?.responseCode == 200) {
        setAddressLoad(false);
        if (addressShow?.title == "unit") {
          setUnitDetails(res?.data?.book_detail);
          setForm({ ...form, unit_details: res?.data?.book_detail });
        } else {
          setConsigneeDetails(res?.data?.book_detail);
          setForm({ ...form, consignee_detials: res?.data?.book_detail });
        }
        setAddressShow({ ...addressShow, open: false, title: "" });
      }
    } catch (error) {
      setAddressLoad(false);
    }
  };

const callBackAddressData = (data)=>{

if (addressShow?.title == "unit") {
  setUnitDetails(data?.data?.addressbook);
  setForm({ ...form,unit_id:data?.data?.addressbook?._id, unit_details: data?.data?.addressbook });
} else {
  setConsigneeDetails(data?.data?.addressbook);
  setForm({ ...form, consignee_id:data?.data?.addressbook?._id,consignee_detials: data?.data?.addressbook });
}
}
  
  const handleAddAddress = () => {
    getAddressDetails();
  };

  const handleDeleteUnit = () => {
    setUnitDetails("");
    setForm({ ...form, unit_details: "" });
  };

  const handleDeleteConsignee = () => {
    setConsigneeDetails("");
    setForm({ ...form, consignee_detials: "" });
  };

  const handleEditAddress = (data) => {
    setAddressId({...addressId,
      id:data?._id
    })
    setAddressShow({ ...addressShow, open: false });
    setShowOffcanvas(true)
  };

  const propsAddPO = {
    ...props,
    isOpen: isPoShow.open,
    onSubmit: handleSubmitPo,
  };

  let addressProps = {
    show: addressShow,
    closeModal: (e) =>
      setAddressShow({ ...addressShow, open: false, title: "" }),
    title: "Select Address",
    submitTitle: "Submit",
    onSubmit: handleAddAddress,
    icon: images.EDIT,
    isLoadding: addressLoad,
    disabled: addressLoad
      ? addressLoad
      : addressShow.title == "unit"
      ? !form?.unit_id
      : !form?.consignee_id,
  };

  return (
    <>
      <CommonModal {...propsAddPO}>
        <div className="_addPo_mbx">
          <div className="flxInr">
            <div className="halfDiv">
              <CommonDatePicker
                name="po_date"
                label="PO Date"
                startDate={form?.po_date}
                setStartDate={(data) => {
                  handleOnChange("po_date", data);
                  // setStartDate(data);
                }}
                error={formError.po_date}
                value={form.po_date}
              />
            </div>

            <div className="halfDiv">
              <CommonInput
                type="text"
                label="PO Number"
                placeholder="PO Number"
                name="po_number"
                onChange={({ target: { name, value } }) =>
                  handleOnChange(name, value)
                }
                error={formError.po_number}
                value={form.po_number}
                onKeyDown={handleKeyDown}
              />
            </div>

            <div className="halfDiv">
              <CustomSelect
                options={picupList}
                label="From Location"
                placeholder="Select From Location"
                onSelect={(data) => {
                  handleOnChange("from_location", data);
                }}
                value={{
                  label: form?.from_location
                    ? `${form?.from_location}, ${form?.from_state}`
                    : "",
                  value: `${form?.from_location}, ${form?.from_state}`,
                }}
                error={formError?.from_location}
              />
            </div>

            <div className="halfDiv">
              <CustomSelect
                options={dropList}
                label="To Location"
                placeholder="Select To Location"
                // onSelect={(data) => {
                //   handleOnChange("to_location", data);
                // }}
                disabled={true}
                value={{
                  label: form?.to_location
                    ? `${form?.to_location}, ${form?.to_state}`
                    : "",
                  value: `${form?.to_location}, ${form?.to_state}`,
                }}
                error={formError?.to_location}
              />
            </div>

            {unitDetails ? (
              <div className="halfDiv">
                <div className="_frtLocation_bx">
                  <div>
                    <h6>Unit</h6>
                    <p>{`${unitDetails?.code ? unitDetails?.code : ""}, ${
                      unitDetails?.name
                    }`}</p>
                    <p>{`${unitDetails?.city?.value} ,${
                      unitDetails?.state ? unitDetails?.state?.label : ""
                    } `}</p>
                    <p>{`Phone: ${
                      unitDetails?.contact_no ? unitDetails?.contact_no : ""
                    }`}</p>
                  </div>
                  <div className="po_button_align">
                    <button
                      className="__edit_btn_po"
                      onClick={() =>
                        setAddressShow({
                          ...addressShow,
                          open: true,
                          title: "unit",
                        })
                      }
                    >
                      <FiEdit />
                    </button>
                    <button
                      className="__delM_btn_po"
                      onClick={() => handleDeleteUnit()}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="17"
                        viewBox="0 0 16 17"
                        fill="none"
                      >
                        <path
                          d="M2.83333 17C2.31389 17 1.86937 16.8152 1.49978 16.4456C1.13019 16.076 0.945074 15.6312 0.944444 15.1111V2.83333H0V0.944444H4.72222V0H10.3889V0.944444H15.1111V2.83333H14.1667V15.1111C14.1667 15.6306 13.9819 16.0754 13.6123 16.4456C13.2427 16.8158 12.7979 17.0006 12.2778 17H2.83333ZM12.2778 2.83333H2.83333V15.1111H12.2778V2.83333ZM4.72222 13.2222H6.61111V4.72222H4.72222V13.2222ZM8.5 13.2222H10.3889V4.72222H8.5V13.2222Z"
                          fill="#C41F1F"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="halfDiv">
                <button
                  className="_addConsigneeBtn"
                  onClick={() =>
                    setAddressShow({
                      ...addressShow,
                      open: true,
                      title: "unit",
                    })
                  }
                >
                  + Add Unit
                </button>
              </div>
            )}

            {consigneeDetails ? (
              <div className="halfDiv">
                <div className="_frtLocation_bx">
                  <div>
                    <h6>Consignee</h6>
                    <p>{`${
                      consigneeDetails?.code ? consigneeDetails?.code : ""
                    }, ${consigneeDetails?.name}`}</p>
                    <p>{`${consigneeDetails?.city?.value} ,${
                      consigneeDetails?.state
                        ? consigneeDetails?.state?.label
                        : ""
                    } `}</p>
                    <p>{`Phone: ${
                      consigneeDetails?.contact_no
                        ? consigneeDetails?.contact_no
                        : ""
                    }`}</p>
                  </div>
                  <div className="po_button_align">
                    <button
                      className="__edit_btn_po"
                      onClick={() => {
                        // setModalZIndex(999);
                        setAddressShow({
                          ...addressShow,
                          open: true,
                          title: "consignee",
                        });
                      }}
                    >
                      <FiEdit />
                    </button>
                    <button
                      className="__delM_btn_po"
                      onClick={() => handleDeleteConsignee()}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="17"
                        viewBox="0 0 16 17"
                        fill="none"
                      >
                        <path
                          d="M2.83333 17C2.31389 17 1.86937 16.8152 1.49978 16.4456C1.13019 16.076 0.945074 15.6312 0.944444 15.1111V2.83333H0V0.944444H4.72222V0H10.3889V0.944444H15.1111V2.83333H14.1667V15.1111C14.1667 15.6306 13.9819 16.0754 13.6123 16.4456C13.2427 16.8158 12.7979 17.0006 12.2778 17H2.83333ZM12.2778 2.83333H2.83333V15.1111H12.2778V2.83333ZM4.72222 13.2222H6.61111V4.72222H4.72222V13.2222ZM8.5 13.2222H10.3889V4.72222H8.5V13.2222Z"
                          fill="#C41F1F"
                        />
                      </svg>
                    </button>
                  </div>
                </div>
              </div>
            ) : (
              <div className="halfDiv">
                <button
                  className="_addConsigneeBtn"
                  onClick={() => {
                    // setModalZIndex(999);
                    setAddressShow({
                      ...addressShow,
                      open: true,
                      title: "consignee",
                    });
                  }}
                >
                  + Add Consignee
                </button>
              </div>
            )}
          </div>
        </div>
      </CommonModal>

      {addressShow?.open && (
        <ConfirmModalFor {...addressProps}>
          <>
            <div className="colFlx">
              <CustomSelect
                label={
                  addressShow?.title == "unit"
                    ? "Unit Address"
                    : "Consignree Address"
                }
                placeholder="Select"
                options={addressList}
                onSelect={(data) => {
                  handleAddressDetaisl(data);
                }}
                value={addressList?.find((i) =>
                  addressShow?.title == "unit"
                    ? i?.value == form?.unit_id
                    : i?.value == form?.consignee_id
                )}
                onEdit={handleEditAddress}
                // error={formError.driver_id}
              />
            </div>
          </>
        </ConfirmModalFor>
      )}
<div
          className={`offcanvas offcanvas-end common_offCanvas ${
            showOffcanvas ? "show" : ""
          }`}
          tabIndex={999999}
          style={{ visibility: showOffcanvas ? "visible" : "hidden" }}
        >
          {showOffcanvas && (
            <AddAddressBook
              isOpen={showOffcanvas}
              onClose={(e) => setShowOffcanvas(false)}
              id={addressId}
              callBackAddressData={callBackAddressData}
            />
          )}
        </div>
        <div className={showOffcanvas ? "offBack_drop show" : ""}></div>
      
    </>
  );
};

export default AddPoModal;
