import React, { useEffect, useState } from 'react'
import { CommonModal } from 'components'
import toast from 'react-hot-toast';
import { getFavoriteLocation, saveFavoriteLocation } from '_services';
import StorageService from 'utils/StorageServices/StorageServices';

const FavoriteRouteModal = (props) => {

  let { isOpen } = props;

  const [favoriteRoute, setFavoriteRoute] = useState([]);
  const [loading, setLoading] = useState(false);

  const getFavoriteLocationData = async () => {
    setLoading(true);
    try {
      let object = {
        company_id: StorageService.getCompanyId(),
        limit: 100,
        offset: 0,
      }
      let res = await getFavoriteLocation(object);
      if (res?.responseCode === 200) {
        setFavoriteRoute(res?.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(`${error?.response?.data?.responseMessage}`);
    }
  }

  const saveFavorite = async (data) => {
    try {
      let object = {
        company_id: StorageService.getCompanyId(),
        from_location: data?.from_location,
        to_location: data?.to_location,
        is_favorite: 0  // 1=favorite, 0=un-favorite
      }
      let res = await saveFavoriteLocation(object);
      if (res?.responseCode === 200) {
        getFavoriteLocationData();
        toast.success(`Un-favorite Successfully`);
      }
    } catch (error) {
      toast.error(`${error?.response?.data?.responseMessage}`);
    }
  }

  const propsFavRoute = {
    ...props,
  };

  useEffect(() => {
    if (StorageService.getCompanyId()) {
      getFavoriteLocationData();
    }
  }, [isOpen])

  return (
    <>
      <CommonModal {...propsFavRoute}>
        <div className='_favRoute_body'>
          {loading ? <div className='_fr_txt'> <span> Loading... </span></div> :
            (favoriteRoute?.length > 0 ?
              favoriteRoute?.map((el, ind) => {
                return (
                  <React.Fragment key={ind}>
                    <div className='_favRouteBx'>
                      <div className='_favRoute_inTb'>
                        <div className='_favSave' onClick={(e) => saveFavorite(el)}>
                          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                            <rect width="26" height="26" rx="2.36364" transform="matrix(-1 0 0 1 26 0)" fill="#DDE6FF" />
                            <path d="M7.68182 19.7954V7.35682C7.68182 6.94411 7.82009 6.59979 8.09662 6.32386C8.37316 6.04792 8.71748 5.90965 9.12959 5.90906H16.9857C17.3978 5.90906 17.7421 6.04732 18.0187 6.32386C18.2952 6.60039 18.4332 6.94471 18.4326 7.35682V19.7954L13.0572 17.4867L7.68182 19.7954Z" fill="#1956F6" />
                          </svg>
                        </div>

                        <div className='_fr_txt'> <span>{el?.from_location} &gt; {el?.to_location}</span></div>
                      </div>
                    </div>
                  </React.Fragment>
                )
              })
              : <div className='_favRouteBx'>
                <div className='_favRoute_inTb'>
                  <div className='_favSave'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                      <rect width="26" height="26" rx="2.36364" transform="matrix(-1 0 0 1 26 0)" fill="#DDE6FF" />
                      <path d="M7.68182 19.7954V7.35682C7.68182 6.94411 7.82009 6.59979 8.09662 6.32386C8.37316 6.04792 8.71748 5.90965 9.12959 5.90906H16.9857C17.3978 5.90906 17.7421 6.04732 18.0187 6.32386C18.2952 6.60039 18.4332 6.94471 18.4326 7.35682V19.7954L13.0572 17.4867L7.68182 19.7954Z" fill="#1956F6" />
                    </svg>
                  </div>

                  <div className='_fr_txt'> <span> No Favorite Route </span></div>
                </div>
              </div>)
          }
        </div>
      </CommonModal>
      <div className='offBack_drop'></div>
    </>
  )
}

export default FavoriteRouteModal