import { CommonInput } from "components";
import React, { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import LoadingBar from "react-top-loading-bar";
import StorageService from "utils/StorageServices";
import { AddButton } from "components/common/CommonButton";
import {
  fetchConsignorDetailsData,
  updateConsignorOtherSetting,
} from "_services/accountsServices/consignorServices";

const OtherSetting = () => {
  const navigate = useNavigate();
  let { id } = useParams();
  const loadingBarRef = useRef(null);

  const formObj = {
    company_id: StorageService.getCompanyId(),
    consignor_id: id,
    item_description: "",
  };

  const [formValue, setFormValue] = useState(formObj);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (id && StorageService.getCompanyId()) {
      getDataConsignorDetails(id, StorageService.getCompanyId());
    }
  }, [id]);

  const submitSettings = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const res = await updateConsignorOtherSetting(formValue);
      if (res?.responseCode == 200) {
        toast.success(`Settings Update`);
        setLoading(false);
        getDataConsignorDetails(id, StorageService.getCompanyId());
      } else {
        toast.error("Error , Settings Not Updated");
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error("Error , Settings Not Updated");
      // throw error;
    }
  };

  const getDataConsignorDetails = async (id_1, id_2) => {
    try {
      if (loadingBarRef.current) {
        loadingBarRef.current.continuousStart(); // Start the loading bar
      }
      let object = {
        consignor_id: id_1,
        company_id: id_2,
      };
      const res = await fetchConsignorDetailsData(object);
      if (res?.responseCode == 200) {
        let data = res?.data?.consignor;
        console.log("Data coming from consigner Api :-", data);
        setFormValue((s) => ({
          ...s,
          item_description: data?.item_description,
        }));
      }
    } catch (error) {
      throw error;
    } finally {
      if (loadingBarRef.current) {
        loadingBarRef.current.complete(); // Complete the loading bar
      }
    }
  };
  return (
    <>
      <div>
        <LoadingBar
          color="#ffa800"
          ref={loadingBarRef}
          height={4}
          shadow={true}
          loaderSpeed={1000}
        />
        {/* <ButtonHeading title="Parle Transportation" buttonTitle="Edit" /> */}

        {/* <NavTabs id={id} /> */}

        <div>
          <div className="_addContainer _shrtY_height">
            <div className="_allUrs_bx">
              <div className="_mainRl_table">
                <div className="_addRl_headCheck">
                  <p className="anvLabel">Permissions</p>
                </div>

                <div className="_addRl_checkList">
                  <div className="_permisionBx_ls">
                    <div className="_brxFlex">
                      <div className="_dLass">
                        <div>
                          <CommonInput
                            label="Description"
                            placeholer="Description"
                            name="item_description"
                            onChange={({ target: { name, value } }) =>
                              setFormValue({
                                ...formValue,
                                item_description: value,
                              })
                            }
                            value={formValue?.item_description}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="bottomFix">
            <AddButton
              onClick={(e) => submitSettings(e)}
              name={loading ? "Loading..." : "Save Changes"}
              type="button"
              disabled={loading || !formValue?.item_description}
            />
            <button className="_cancel_btn" onClick={(e) => navigate(-1)}>
              {" "}
              Cancel{" "}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default OtherSetting;
