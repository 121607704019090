import { BiltyFormat1, BiltyFormat2 } from "BiltyFomates";
import { getBuiltyApi } from "_services";
import { CommonModal, CustomSelect } from "components";
import React, { useEffect, useState } from "react";
import StorageService from "utils/StorageServices/StorageServices";

const BiltyPrintModal = (props) => {
  const { freightId } = props;
  const [lrList, setlrList] = useState([]);
  const [newlrList, setNewLrList] = useState([]);
  const [state, setState] = useState({
    formate: 1,
    page: 0,
  });

  useEffect(() => {
    handleBuiltyPrint();
  }, []);

  useEffect(() => {
    if (lrList?.length) {
      setState({ ...state, page: 1 });
    }
  }, [lrList]);

  useEffect(() => {
    copyAndAddUsers(state?.page);
  }, [state?.page]);


  const bilyFormates = [
    { label: "Formate 1", value: 1 },
    { label: "Formate 2", value: 2 },
  ];

  const pageofPint = [
    { label: "Orignal", value: 1 },
    { label: "Duplicate", value: 2 },
    { label: "Triplicate", value: 3 },
  ];

  const handleBuiltyPrint = async () => {
    try {
      const res = await getBuiltyApi({
        company_id: StorageService.getCompanyId(),
        trip_id: freightId,
      });

      if (res?.responseCode === 200) {
        const updatedLrMaterialList = res?.data?.lr_list?.map((k,index) =>
          k?.lr_material_list?.map((item) => ({
            ...item,
            eway_bill_number: res?.data?.lr_list[index]?.eway_bill_number,
            invoice_number: res?.data?.lr_list[index]?.invoice_number,
            gst_invoice_number: res?.data?.lr_list[index]?.gst_invoice_number,
          }))
        );
      
        const newList = res?.data?.lr_list.map((i,index) => {
          return {
            ...i,
            type: "Orignal Copy",
            lr_material_list: updatedLrMaterialList[index],
          };
        });
        setlrList(newList);
      } else {
        console.log(res?.responseMessage);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // const handlePrint = ()=>{
  //   let contents = document.getElementById('print-area').innerHTML;
  //   let orignal = document.body.innerHTML;

  //   document.body.innerHTML = contents

  //   window.print()

  //   document.body.innerHTML = orignal
  // }

  const copyAndAddUsers = (n) => {
    if (n !== 1) {
      const newUsers = lrList.flatMap((user, index) => {
        return Array.from({ length: n }, (_, i) => {
          let userType;
          let userNumber;
          
          if (i === 0) {
            userType = "Original";
            userNumber = 1;
          } else if (i === 1) {
            userType = "Duplicate";
            userNumber = 2;
          } else {
            userType = "Triplicate";
            userNumber = 3;
          }
          
          return { ...user, type: userType, number: userNumber };
        });
      });
      setNewLrList(newUsers);
    } else {
      const originalUsers = lrList.map((user, index) => ({ ...user, type: "Original", number: 1 }));
      setNewLrList(originalUsers);
    }
  };



  const handlePrint = () => {
    let contents = document.getElementById('print-area').innerHTML;
    let frame1 = document.createElement('iframe');
    frame1.name = "frame1";
    frame1.style.position = "absolute";
    frame1.style.top = "1000000px";
    document.body.appendChild(frame1);
    let frameDoc = frame1.contentWindow ? frame1.contentWindow : frame1.contentDocument.document ? frame1.contentDocument.document : frame1.contentDocument;
    frameDoc.document.open();
    frameDoc.document.write(`<html><head><style>
      @page {
    margin: 0px;
    padding: 0px;
}

/* Note: Necessary for the pdf */
@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700,900&display=swap');

body {
    margin: 0;
}

/* Invoice 1 */

.invoice-box {
    font-size: 12px;
    line-height: 16px;
    font-family: 'Roboto', sans-serif;
    color: #222;
    padding: 0px;
}

.height_410 {
    position: relative;
}

table.border_t_b.Extra_p.p_b_0 td {
    padding-bottom: 5px !important;
}

.overly_table tr:last-child td {
    border-bottom: none;
}

.address_outer p {
    height: 30px;
    margin-bottom: 5px !important;
}

.bold_text {
    font-weight: bold;
}

.trip_no_detailsa {
    position: absolute;
    bottom: 0px;
    font-size: 10px;
    margin-bottom: 0;
}

.border_box {
    border: 1px solid #cccc;
}

.termsand_condiotn_relative {
    position: relative;
}

.p_0 {
    padding: 0px !important;
}

.Gst_in {
    font-weight: 900 !important;
}

.Extra_p td {
    padding: 5px 10px !important;
}

.address_outer p {
    margin: 0;
}

.Extra_p {
    border-top: 1px solid #ccc;
    position: relative;
    border-bottom: 0;
}

.Signature_height {
    height: 2.3cm;
}

.invoice-box table {
    width: 100%;
    text-align: left;
    border-collapse: collapse;
}


.top_heading p {
    margin-top: 0px;
    margin-bottom: 0px;
    font-size: 11px;
    font-weight: 400;
    line-height: 16px;
}



.top_heading .h1 {
    margin: 0;
    font-size: 20px !important;
    margin-bottom: 0;
    margin-top: 0;
    font-weight: bold;
}



.left_table {
    border-right: 1px solid #ccc;
}

.invoice-box table td {
  vertical-align: top;
  font-size: 12px;
}



.text-left {
    text-align: left;
}

.border_table thead td {
    font-weight: bold;
    padding: 2px 5px;
    font-size: 12px;
}

.top_heading {
    padding: 6px 0 4px 15px;
}


.top_heading_P {
    font-size: 20px !important;
    margin-bottom: 0 !important;
    margin-top: 0 !important;
    font-weight: 700 !important;
}

span.number-with-bg {
    line-height: 16px;
    width: 16px;
    height: 16px;
    display: inline-block;
    background-color: #000 !important;
    color: #fff !important;
    text-align: center;
    border-radius: 30px;
    margin-left: 4px;
    margin-top: -30px;
    -webkit-print-color-adjust: exact;
}

.invoice-box table tr.information table td {
    padding-bottom: 40px;
}

.invoice-box table tr.heading td {
    background: #eee;
    border-bottom: 1px solid #ddd;
    font-weight: bold;
}



.border_table td {
    border: 1px solid #ccc;
    padding: 0px 5px;
}

.invoice-box table tr.details td {
    padding-bottom: 20px;
}



.Heading_right {
    margin-bottom: 2px !important;
    background: #efefef !important;
    margin: 0;
    padding: 4px 8px;
    font-size: 16px;
    border-bottom: 1px solid #ccc;
    -webkit-print-color-adjust: exact;
    border-top: 1px solid #ccc;
    margin-top: -1px;
    border-right: 1px solid #ccc;
}

@media print {
    .Heading_right {
        background: #efefef !important;
        -webkit-print-color-adjust: exact;
        border-top: 1px solid #ccc !important;
    }
}

.tfoot_bg td {
    padding: 2px 10px;
    background: #efefef !important;
    font-size: 12px;
    -webkit-print-color-adjust: exact;
}

@media print {
    .tfoot_bg td {
        padding: 2px 10px;
        background: #efefef !important;
        font-size: 12px;
        -webkit-print-color-adjust: exact;
    }
}

.invoice-box table tr.total td:nth-child(2) {
    border-top: 2px solid #eee;
    font-weight: bold;
}



table.table_right thead td {
    padding: 0px;
}

.table_right td {
    padding: 2px 10px;
}

.termsAndCondiotion {
    font-size: 10px;
    margin-top: 3px;
    margin-bottom: 3px;
}

.b_0 {
    border-bottom: 0 !important;
    border-top: 0 !important;
}

.width_35 {
    width: 19%;
}

.Address {
    height: 26px;
    line-height: 14px !important;
    margin-bottom: 5px !important;
    margin-top: 5px !important;
}

.Address2 span {
    display: block;
    margin-bottom: 2px;
}

table.border_table tr td:nth-child(1) {
    border-left: 0;
    width: 20%;
}

table.border_table tr td:nth-child(2) {
    width: 52%;
}

table.border_table tr td:nth-child(3) {
    width: 11%;
    /*border-right: 0px;*/
}

table.border_table tr td:nth-child(4) {
    width: 11%;
}

span.bold_text.width_100 {
    width: 100%;
    text-align: center;
    float: left;
    position: absolute;
    bottom: 2px;
}

.address_bottom {
    margin: 0;
    height: 38px;
    line-height: 12px;
    padding-top: 0px;
    margin-top: 0px;
    font-size: 10px;
    padding-bottom: 4px;
}

.address_bottom p {
    margin-bottom: 0px;
}

table.border_table .tfoot_bg td:nth-child(3) {
    border-right: 1px solid #ccc;
}

.height_410 {
    height: 328px;
    /*border-right: 1px solid #ccc;*/
    position: relative;
    font-size: 11px;
    overflow: hidden;
}

.height_410 td {
    height: 16.9px;
    line-height: 16.9px;
}

.overly_table {
    /*position: absolute;*/
    width: 100%;
    top: 0px;
}

.fixed_width {
    width: 200px;
    overflow: hidden;
    white-space: nowrap;
    margin-bottom: 0px;
    text-overflow: ellipsis;
}

.oP_none {
    opacity: 0;
}

.lrCopy_space td {
    padding-top: 4.1px;
    padding-bottom: 5.1px;
}

span.poweredby {
    position: absolute;
    bottom: -20px;
    text-align: center;
    width: 100%;
    right: 0px;
    font-size: 9px;
    display: block;
}

span.orignal_copy {
    text-align: right;
    float: right;
    font-size: 10px;
    line-height: 17px;
}

tr.lrCopy_space.last_tr td {
    padding-bottom: 8px;
}

table.border_table.Table_border_none td {
    border-color: #fff;
}

.fixed_width {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 250px;
}

td.bold_text.text-right.total_div {
    width: 88.5%;
}

.sheet.padding-2mm {
    width: 210mm;
    margin: 0 auto;
    /* height: 147mm;*/
    /* padding: 2mm; */
    background: white;
    /*  box-shadow: 0 0.5mm 2mm rgba(0,0,0,.3);*/

}

.invoice-box table.border_table tbody td {
    font-size: 11px;
}

/* Invoice 1  */


/* Invoice 2 */

.sheet2.padding-2mm {
    width: 210mm;
    margin: 0 auto 25px;
    position: relative;
    /* height: 147mm;*/
    /*padding: 2mm;*/
    background: white;
    /*  box-shadow: 0 0.5mm 2mm rgba(0,0,0,.3);*/

}

.width_50 {
    width: 50%;
}

.invoice-box2 {
    font-size: 12px;
    line-height: 16px;
    font-family: 'Roboto', sans-serif;
    color: #222;
    /*padding: 3px;*/
}

.invoice-box2 table {
    width: 100%;
    text-align: left;
    border-collapse: collapse;
}

.invoice-box2 table td {
    vertical-align: top;
    line-height: 20px;
    font-size: 12px;
}

.invoice-box2 table tr.information table td {
    padding-bottom: 40px;
}

.invoice-box2 table tr.heading td {
    background: #eee;
    border-bottom: 1px solid #ddd;
    font-weight: bold;
}

.invoice-box2 table tr.details td {
    padding-bottom: 20px;
}

.invoice-box2 table tr.total td:nth-child(2) {
    border-top: 2px solid #eee;
    font-weight: bold;
}

.top_heading2 {
    padding: 10px 0 5px 15px;
}

.top_heading2 p {
    margin-top: 10px;
    font-size: 12px;
    font-weight: 400;
    line-height: 17px;
    margin-bottom: 10px;
}

.top_heading2 .h1 {
    margin: 0;
    font-size: 20px !important;
    margin-bottom: 0;
    margin-top: 0;
    font-weight: bold;
}

.invoice-box2 table.table_gray_bg {
    background: #efefef !important;
    margin: 0;
    font-size: 13px;
    border-bottom: 1px solid #ccc;
    -webkit-print-color-adjust: exact;
    border-top: 1px solid #ccc;
    margin-top: -1px;
    border-right: 1px solid #ccc;
}

.new_table td {
    line-height: 16px !important;
}

.table_gray_bg td {
    padding: 5px 15px !important;
}

.invoice-box2 td.text-left.width_75 {
    text-align: right;
}

.invoice-box2 .table_right td {
    padding: 3px 15px;
    font-size: 12px;
}

.invoice-box2 table.table_right thead td {
    padding: 0px;
    font-size: 13px;
}


.height_div {
    position: relative;
}
.invoice-box2 .border_table tr td:nth-child(1) {
    width: 80px;
    border-left: 0;
    padding-left: 15px;
}


.invoice-box2 .border_table.border_none td {
    border-color: #fff !important;
    line-height: 24px;
    border: 0;
}

.gst_invoiceno_table td {
    height: 22px !important;
    border-top: 1px solid #efefef;
    line-height: 23px !important;
}

.graY-bg {
    background: #000 !important;
    border-bottom: 1px solid #ccc;
    -webkit-print-color-adjust: exact;
    color: white;
}

.invoice-box2 .border_table thead td {
    font-weight: 700;
    padding: 6px 5px;
    font-size: 12px;
}

.gst_invoiceno_table .border_table tr td:nth-child(1) {
    width: 75px !important;
}

.gst_invoiceno_table .border_table tr td:nth-child(2) {
    width: 110px !important;
}

.gst_invoiceno_table .border_table tr td:nth-child(3) {
    width: 110px !important;
}
.gst_invoiceno_table .border_table tr td:nth-child(4) {
    width: 110px !important;
}
.gst_invoiceno_table .border_table tr td:nth-child(5) {
    width: 60px !important;
}
.gst_invoiceno_table .border_table tr td:nth-child(6) {
    width: 60px !important;
}
.gst_invoiceno_table .border_table tr td:nth-child(7) {
    width: 60px !important;
}
.gst_invoiceno_table .border_table tr td:nth-child(8) {
    width: 70px !important;
}

.invoice-box2 .border_table td {
    padding: 8px 5px;
}

.invoice-box2 .item_description {
    margin: 2px 0;
}

.invoice-box2 .address_new {
    margin: 0px;
    margin-bottom: 5px;
}

.invoice-box2 .poweredby {
    position: absolute;
    right: 0;
    font-size: 11px;
    margin-top: 3px;
    padding-right: 15px;
}

.footer {
    margin-top: 60px;
    font-weight: 700;
    padding-left: 15px;
    padding-right: 15px;
}

.footer.margintop {
    margin-top: 60px !important;
}

.footer p {
    margin: 0;
}

/* Invoice 2  */
</style><title>${document.title}</title>`);
    // frameDoc.document.write('<style></style>');
    frameDoc.document.write('</head><body>');
    frameDoc.document.write(contents);
    frameDoc.document.write('</body></html>');
    frameDoc.document.close();
    setTimeout(function () {
        window.frames["frame1"].focus();
        window.frames["frame1"].print();
        document.body.removeChild(frame1);
    }, 500);
}

  const formatePrps = {
    list: newlrList,
    
  };

  const propsBuiltyPrint = {
    ...props,
    onSubmit:handlePrint
  };

  return (
    <>
      <CommonModal {...propsBuiltyPrint}>
        <div className="bltyPrint_body">
          <div className="bltyPrt_viewPage_bx" id = "print-area">
            {/* <div className='dummyPrt_page'></div> */}

            {state?.formate == 1 && <BiltyFormat1 {...formatePrps} />}

            {state?.formate == 2 && <BiltyFormat2 {...formatePrps} />}
          </div>

          <div className="_prtRight_selctors">
            <div>
              <CustomSelect
                label="Bilty Format"
                options={bilyFormates}
                value={bilyFormates?.find((i) => i?.value === state?.formate)}
                onSelect={(data) =>
                  setState({ ...state, formate: data?.value })
                }
              />
            </div>

            <div>
              <CustomSelect
                label="Number of Pages 1/2/3"
                options={pageofPint}
                value={pageofPint?.find((i) => i?.value === state?.page)}
                onSelect={(data) => setState({ ...state, page: data?.value })}
              />
            </div>
          </div>
        </div>
      </CommonModal>
    </>
  );
};

export default BiltyPrintModal;
