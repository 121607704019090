
import React, { useEffect, useState, useRef } from 'react'
import { ButtonHeading, CommonModal, CustomPagination } from 'components'
import StorageService from "utils/StorageServices/StorageServices";
import toast, { Toaster } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import AddEditSupplier from 'modals/AddEditSupplier';
import { deleteSupplierData, getSupplierListData, changeSupplierStatusData } from '_services/accountsServices/supplierServices';

import BootstrapTable from 'components/BootstrapTable';
import { BsThreeDotsVertical } from "react-icons/bs";
import { AiOutlineEye } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { FaRegTrashCan } from "react-icons/fa6";
import MastersFilterModal from 'modals/MastersFilterModal';
import { IoChevronBack, IoChevronForward } from "react-icons/io5";
import RupeeIcon from './RupeeIcon';
import LoadingBar from 'react-top-loading-bar';
import { changeUserStatusData, deleteUserData, getUser } from '_services';
import { useNavigate } from 'react-router-dom';
import { UserContext } from 'context';
const User = () => {

  const navigate = useNavigate();
  const loadingBarRef = useRef(null);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [userlist, setUserList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [statusObject, setStatusObject] = useState(null)
  const { settingChild } = React.useContext(UserContext);
  const itemsPerPage = 10;
  const [totalItems, setTotalItems] = useState(0);
  const [filter, setFilter] = useState({
    limit: 10,
    offset: 1,
    search: '',
    status: 1
  });
  const childsUser = settingChild?.filter((i) => i?.label === "Users")[0]
  const [editSupplierData, setEditSupplierData] = useState(null)


  const getDataSupplierList = async () => {
    try {
      if (loadingBarRef.current) {
        loadingBarRef.current.continuousStart(); // Start the loading bar
      }
      let object = {
        ...filter,
        company_id: StorageService.getCompanyId(),
      };
      const res = await getUser(object);
      if (res?.responseCode === 200) {
        setUserList(res.data?.user_list);
        setTotalItems(res?.data?.total);
      }
    } catch (error) {
      toast.error(error)
    } finally {
      if (loadingBarRef.current) {
        loadingBarRef.current.complete();
      }
    }
  };

  useEffect(() => {
    getDataSupplierList();
  }, [filter, showOffcanvas])

  const deleteHandler = (item) => {
    setIsDelete(item)
  }

  const submitDeleteSupplier = async () => {
    setLoading(true);
    try {
      loadingBarRef.current?.continuousStart();
      let object = {
        company_id: isDelete?.company_id?._id,
        param_user_id: isDelete?.user_id?._id,
      }
     
      const res = await deleteUserData(object);
      if (res?.responseCode == 200) {
        toast.success(`${res?.responseMessage}`);
        getDataSupplierList();
        setLoading(false);
        setIsDelete(false);
      } else {
        toast.success('Error !! ');
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error)
    }
  }

  let propsObject = {
    isOpen: isDelete,
    closeModal: setIsDelete,
    modalTitle: "Confirmation",
    submitTitle: "Confirm",
    cancleTitle: "Close",
    onSubmit: submitDeleteSupplier,
    isLoadding: loading,
  }

  const columns = [
    { header: "", accessor: "serial" },
    { header: "User Name", accessor: "supplier_name" },
    { header: "Role", accessor: "role" },
    { header: "Status", accessor: "status" },
    { header: "Mobile", accessor: "contact_number" },
    { header: "Actions", accessor: "action" },
  ];

  

const statusChangeHnadle = async(data,index)=>{
  console.log("third",data)
  const list = [...userlist]
  try {
    const payload = {
      company_id: StorageService.getCompanyId(),
      param_user_id: data?.user_id?._id,
      status: data?.active === 1 ? 0 : 1, 
    };
  
    const res = await changeUserStatusData(payload);
    if (res?.responseCode === 200) {
      getDataSupplierList()
  
    } else {
      console.log(res?.responseCode);
      setTimeout(()=>{
        list[index].active = data?.active === 1 ? 0 : 1
        console.log(list)
        setUserList(list)
      },500)
     
    }
  } catch (error) {
    console.log(error);
  }
}

  const handleStatusEvent = async (data,index) => {
     statusChangeHnadle(data,index)
     const list = [...userlist]
     list[index].active = data?.active === 1 ? 0 : 1
     setUserList(list)
  };

  const RowListData = (data) => {
    return (
      data?.map((item, i) => ({
        ...item,
        serial: (
          <React.Fragment>
            <div className="signDropdown">
              <button className="dropbtn11">
                <BsThreeDotsVertical />
              </button>
              <div className="dropdownContent">

                <Link 
                // to={`/account/supplier/details/${item?._id}`}
                  className='_avPointer'
                  style={{ textDecoration: 'none' }}
                >
                  <span className='_samAnchor'>
                    <span className='viewIcon_avt'>
                      <AiOutlineEye />
                    </span>
                    &nbsp; View
                  </span>
                </Link>
                <Link 
                to={`/setting/user/adduser/${item?.user_id?._id
                }`}
                  className='_avPointer'
                  style={{ textDecoration: 'none' }}
                >
                  <span className='_samAnchor'>
                    <span className='viewIcon_avt'>
                    <FiEdit />
                    </span>
                    &nbsp; Edit
                  </span>
                </Link>
                {/* <div
                  
                  className='_avPointer'
                >
                  <span className='_samAnchor'>
                    <span className='editIcon_avt'>
                      <FiEdit />
                    </span>
                    Edit
                  </span>
                </div> */}

                <div
                  onClick={(e) => deleteHandler(item)}
                  className='_avPointer'
                >
                  <span className='_samAnchor'>
                    <span className='deleteIcon_avt'>
                      <FaRegTrashCan color='red' />
                    </span>
                    Delete
                  </span>
                </div>
              </div>
            </div>
          </React.Fragment>
        ),
        supplier_name: (
          <Link style={{ textDecoration: 'none' }}>
            {item?.company_user_name ? item?.company_user_name : '-'}
          </Link>
        ),
        contact_number: (
          <React.Fragment>
            {item?.user_id?.mobile_no ? item?.user_id?.mobile_no : '-'}
          </React.Fragment>
        ),
        role: (
          <React.Fragment>
            {item?.role_id?.role_name ? item?.role_id?.role_name : '-'}
          </React.Fragment>
        ),
        party_group: (
          <React.Fragment>
            {item?.consignor_group_id?.name ? item?.consignor_group_id?.name : '-'}
          </React.Fragment>
        ),
        city: (
          <React.Fragment>
            {item?.city?.label ? item?.city?.label : '-'}
          </React.Fragment>
        ),
        status: (
          <div className="_anvSwitcherNew form-check form-switch" style={{ width: "125px" }}>
            <input
              className="form-check-input"
              type="checkbox"
              name="actStatus"
              id={`actStatus-${item._id}`}
              autoComplete="off"
              checked={item?.active}
              onChange={() => handleStatusEvent(item,i)}
            />
            <span className={item?.active ? "active_label_masters_status" : "disabled_label_masters_status"}>
              {item?.active ? "Active" : "Disabled"}
            </span>
          </div>
        ),
        balance: item?.balance ? (
          <Link to={`/account/supplier/details/${item?._id}?tab=4`} style={{ textDecoration: 'none' }}>
            <div style={{
              display: 'flex',
              gap: '8px',
              alignItems: 'center',
              color: item.balance < 0 ? '#dc3545' : item.balance > 0 ? '#28a745' : '#1450ec'
            }}>
              <span>
                <RupeeIcon
                  color={item.balance < 0 ? '#dc3545' : item.balance > 0 ? '#28a745' : '#1450ec'}
                  size={20}
                />
              </span>
              <span>
                {item.balance}
              </span>
            </div>
          </Link>
        ) : (
          <Link to={`/account/supplier/details/${item?._id}?tab=4`} style={{ textDecoration: 'none' }}>
            <div style={{
              display: 'flex',
              gap: '8px',
              alignItems: 'center',
              color: '#1450ec'
            }}>
              <span>
                <RupeeIcon
                  color='#1450ec'
                  size={20}
                />
              </span>
              <span>
                0
              </span>
            </div>
          </Link>
        ),
      }))
    );
  };


  const handlePageChange = (page) => {
    setFilter((s) => ({ ...s, offset: page }))
  };
      

  return (
    <div>
      <LoadingBar
        color="#ffa800"
        ref={loadingBarRef}
        height={4}
        shadow={true}
        loaderSpeed={1000}
      />
      <ButtonHeading
        title={"User"}
        onClick={()=>navigate("/setting/user/adduser")} buttonTitle={childsUser?.permissions?.create === 1?"Add User":""}
        showSearchFilter={{ search: true, filterOption: true, filterCount: true }}
        onClickFilterModal={(e) => setShowFilterModal(!showFilterModal)}
        filter={filter}
        setFilter={setFilter}
      />



      <div className='_tabWith_outTab'>
        <BootstrapTable
          columns={columns}
          data={RowListData(userlist?.length > 0 ? userlist : [])}
          isLoading={loading}
        />

        {userlist?.length && <div className='paginationFt_avt '>
        <CustomPagination
          totalItems={totalItems}
          itemsPerPage={itemsPerPage}
          currentPage={filter.offset}
          onPageChange={handlePageChange}
        />
        </div>}
      </div>

      <div
        className={`offcanvas offcanvas-end common_offCanvas ${showOffcanvas ? 'show' : ''}`}
        tabIndex={-1}
        style={{ visibility: showOffcanvas ? 'visible' : 'hidden' }}
      >
        {showOffcanvas &&
          <AddEditSupplier
            isOpen={showOffcanvas}
            onClose={() => setShowOffcanvas(false)}
            supplierData={editSupplierData}
          />
        }
      </div>

      <div className={showOffcanvas ? 'offBack_drop' : ''}></div>

      <div
        className={`offcanvas offcanvas-end common_offCanvas ${showFilterModal ? 'show' : ''}`}
        tabIndex={-1}
        style={{ visibility: showFilterModal ? 'visible' : 'hidden' }}
      >
        {showFilterModal &&
          <MastersFilterModal
            isOpen={showFilterModal}
            onClose={() => setShowFilterModal(false)}
            filter={filter}
            setFilter={setFilter}
          />
        }
      </div>
      <div className={showFilterModal ? 'offBack_drop' : ''}></div>

      {
        isDelete?.company_id && (
          <CommonModal {...propsObject}>
            <p className='p-3'> Do you really want to delete {isDelete?.name} </p>
          </CommonModal>
        )
      }
    </div>
  )
}

export default User
