import React from "react";
import * as XLSX from "xlsx";

const ExcelDownload = ({ tableData }) => {
  const exportToExcel = () => {
    // Convert JSON data to worksheet
    const worksheet = XLSX.utils.json_to_sheet(tableData);
    
    // Create a new workbook
    const workbook = XLSX.utils.book_new();
    
    // Append worksheet to workbook
    XLSX.utils.book_append_sheet(workbook, worksheet, "Table Data");

    // Export the workbook to Excel file
    XLSX.writeFile(workbook, "TableData.xlsx");
  };

  return (
    <button onClick={exportToExcel}>
     Download Excel
    </button>
  );
};

export default ExcelDownload;
