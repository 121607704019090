import { UserContext } from "context";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PieChart, Pie, Cell, Legend, Tooltip, ResponsiveContainer } from "recharts";

const COLORS = ["#ffa800", "#23b7e5", "#36B37E", "#F05050"];

const objNames = {
  at_party: 'At Party',
  in_transit: 'In Transit',
  delivered: 'Delivered',
  cancelled: 'Cancelled',
}

export const PieCharts = (props) => {
  let { dataList } = props;
  const navigate = useNavigate();
  const { tripFilters, setTripFilters } = useContext(UserContext);
  const [IsEmpty, setIsEmpty] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (dataList && dataList?.length > 0) {
      const transformedData = dataList?.map(item => ({
        name: objNames[item.name],
        value: item.value,
      }));
      setData(transformedData);
    } else {
      setData([]);
    }
  }, [dataList]);

  var totalValue = data.reduce((acc, item) => Number(acc) + Number(item.value), 0);

  const renderLegendValue = (value, entry) => {
    let percent = (totalValue !== 0)
      ? Number(entry?.payload?.value) / Number(totalValue)
      : 0;
    return `${entry?.payload?.name} (${(percent * 100).toFixed(0)}%)`;
  };

  useEffect(() => {
    let isValue = dataList.every(item => item.value === 0);
    if (isValue) {
      setIsEmpty(true)
    } else {
      setIsEmpty(false)
    }
  }, [dataList])

  const redirectTripFunction = async (value) => {
    setTripFilters(value)
    navigate('/trip/addtrip')
  }

  return (
    <>
      <div className="chart_pie">
        {IsEmpty && <div className="NoPreviewAvl">No Preview Available</div>}
        <PieChart
          width={380}
          height={250}
          key={dataList.toString()}
          style={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center'
          }}
        >
          <Pie
            data={data}
            // cx={175}
            // cy={120}
            labelLine={false}
            outerRadius={85}
            fill="#8884d8"
            dataKey="value"
            animationBegin={0}
            animationDuration={1000}
            animationEasing="ease-out"
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip />
          {/* <Legend
            layout="vertical"
            align={IsEmpty ? "center" : "right"}
            verticalAlign="middle"
            formatter={(value, entry) => renderLegendValue(value, entry)}
          /> */}
        </PieChart>
      </div>
      <div>
        <div className="llt_dash_dispatch_btns">
          <div className="llt_dash_lr_sts_one _avPointer" onClick={(e) => redirectTripFunction(3)}>
            <span className="llt_dash_span_eight"></span>{" "}
            <p>At Party: {data[0]?.value}</p>
          </div>
          <div className="llt_dash_lr_sts_one _avPointer" onClick={(e) => redirectTripFunction(2)}>
            <span className="llt_dash_span_nine"></span>{" "}
            <p>In Transit: {data[1]?.value}</p>
          </div>
        </div>
        <div className="llt_dash_dispatch_btns">
          <div className="llt_dash_lr_sts_one _avPointer" onClick={(e) => redirectTripFunction(4)}>
            <span className="llt_dash_span_seven"></span>{" "}
            <p>Delivered: {data[2]?.value}</p>
          </div>
          <div className="llt_dash_lr_sts_one _avPointer" onClick={(e) => redirectTripFunction(5)}>
            <span className="llt_dash_span_cancel"></span>{" "}
            <p>Canceled: {data[3]?.value}</p>
          </div>
        </div>
      </div>
    </>
  );
};