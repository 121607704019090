import { Images } from "constants";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import "../../assets/css/modal.css";
import { useDispatch } from "react-redux";
import { allCitiesApi, allStateApi, createRouteApi } from "features";

import StorageService from "utils/StorageServices/StorageServices";

import images from "constants/images";
import toast from "react-hot-toast";
import { State } from "country-state-city";

const CreateRouteModal = ({ isOpen, closeModal, setIsOpenTruck }) => {
  const dispatch = useDispatch();
  const scrollContainerRef = useRef(null);
  const [formValue, setFormValue] = useState([]);
  const [stateForm, setStateForm] = useState([]);
  const [cityStateList, setCityStateList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [isloading, setIsLoading] = useState(false);
  const [filter, setFilter] = useState({
    countryCode: "IN",
    stateCode: "",
    limit: 10,
    offset: 1,
    search: "",
  });
  const [stateFilter, setStateFilter] = useState({
    countryCode: "IN",
    stateCode: "",
  });
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedSearch, setDebouncedSearch] = useState("");
  const [activeTab, setActiveTab] = useState(1);
  const [isFetchingMore, setIsFetchingMore] = useState(false);
  const [suggestList, setSuggestList] = useState([]);
  const handleSearchChange = (e) => {
    const searchValue = e.target.value;
    if (activeTab === 1) {
      setFilter((prevFilter) => ({
        ...prevFilter,
        search: searchValue,
        offset: 1,
      }));
    } else {
      setSearchQuery(searchValue);
    }
  };

  const filteredList = stateList.filter((state) =>
    state.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  useEffect(() => {
    const handler = setTimeout(() => {
      setCityStateList([]);
      setDebouncedSearch(filter.search);
      if (!filter.search) {
        setFilter((prev) => ({ ...prev, offset: 1 }));
      }
    }, 500);

    return () => {
      clearTimeout(handler);
    };
  }, [filter?.search]);

  useEffect(() => {
    getAllCities();
  }, [debouncedSearch, filter?.offset]);

  useEffect(() => {
    getAllStates();
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (scrollContainerRef.current) {
        const { scrollTop, scrollHeight, clientHeight } =
          scrollContainerRef.current;
        if (scrollTop + clientHeight >= scrollHeight - 5 && !isFetchingMore) {
          if (!debouncedSearch) {
            setIsFetchingMore(true);
            setFilter((prev) => ({ ...prev, offset: prev.offset + 1 }));
          }
        }
      }
    };

    if (scrollContainerRef.current) {
      scrollContainerRef.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollContainerRef.current) {
        scrollContainerRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, [isFetchingMore, debouncedSearch]);

  const clearAll = () => {
    setFormValue([]);
    setStateForm([]);
  };

  const getAllCities = async () => {
    const payload = {
      countryCode: "IN",
      stateCode: "",
      limit: 10,
      offset: debouncedSearch ? 1 : filter.offset,
      search: debouncedSearch,
    };
    const res = await dispatch(
      allCitiesApi(debouncedSearch ? payload : filter)
    );
    if (res?.payload?.responseCode === 200) {
      setCityStateList((prevList) => ({
        ...prevList,
        all_cities: debouncedSearch
          ? res?.payload?.data?.all_cities
          : [
              ...(prevList?.all_cities || []),
              ...res?.payload?.data?.all_cities,
            ],
        suggested_cities:
          res?.payload?.data?.suggested_cities || prevList?.suggested_cities,
      }));

      setSuggestList(res?.payload?.data?.suggested_cities);
      setIsFetchingMore(false);
    }
  };

  const getAllStates = async () => {
    const res = await dispatch(allStateApi(stateFilter));
    if (res?.payload?.responseCode === 200);
    setStateList(res?.payload?.data);
  };

  const handleSelectCity = (city) => {
    const stateDetails = State.getStateByCodeAndCountry(
      city?.stateCode,
      city?.countryCode
    );

    setFormValue((prevSelectedCities) => {
      let updatedCities;
      const isCitySelected = prevSelectedCities.some(
        (selected) =>
          selected.name === city.name && selected.stateCode === city.stateCode
      );

      if (isCitySelected) {
        updatedCities = prevSelectedCities.filter(
          (selected) => selected.name !== city.name
        );
      } else {
        updatedCities = [...prevSelectedCities, city];
      }

      // const hasCitiesInSameState = updatedCities.some(
      //   (selected) => selected.stateCode === city.stateCode
      // );

      // setStateForm((prevSelectedStates) => {
      //   const isStateSelected = prevSelectedStates.some(
      //     (selected) => selected.name === stateDetails.name
      //   );

      //   if (hasCitiesInSameState && !isStateSelected) {
      //     return [...prevSelectedStates, stateDetails];
      //   } else if (!hasCitiesInSameState && isStateSelected) {
      //     return prevSelectedStates.filter(
      //       (selected) => selected.name !== stateDetails.name
      //     );
      //   }

      //   return prevSelectedStates;
      // });

      return updatedCities;
    });
  };

  const isCitySelected = (city) => {
    return formValue.some(
      (selected) =>
        selected?.name === city?.name && selected?.stateCode === city?.stateCode
    );
  };

  const handleSelectState = (state) => {
    setStateForm((prevSelectedStates) => {
      const isStateSelected = prevSelectedStates.some(
        (selected) => selected.name === state.name
      );

      if (isStateSelected) {
        // setFormValue((prevSelectedCities) =>
        //   prevSelectedCities.filter((city) => city.stateCode !== state.isoCode)
        // );
        return prevSelectedStates.filter(
          (selected) => selected.name !== state.name
        );
      } else {
        return [...prevSelectedStates, state];
      }
    });
  };

  const isStateSelected = (state) => {
    return stateForm.some((selected) => selected?.name === state?.name);
  };

  const handleSubmitRoute = async () => {
    const payload = {
      company_id: StorageService.getCompanyId(),
      cities_routes: formValue,
      states_routes: stateForm,
      lorry_type_id: "",
    };

    try {
      setIsLoading(true);
      const res = await dispatch(createRouteApi(payload));
      if (res?.payload?.responseCode === 200) {
        setIsLoading(false);
        toast.success(res?.payload?.responseMessage);
        clearAll();
        closeModal();
        setIsOpenTruck(true);
      } else {
        setIsLoading(false);
        toast.error(res?.payload?.responseMessage);
      }
    } catch (error) {
      toast.error(error.message);
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      {isOpen && (
        <div
          className="modal fade show d-block _addAccount_modal"
          tabIndex="-1"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="home-tab-pane"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                  tabIndex={0}
                >
                  <div className="modal-header ">
                    <h1 className="modal-title " id="staticBackdropLabel">
                      <button
                        className="_modBack_btn"
                        onClick={() => {
                          closeModal();
                          clearAll();
                        }}
                      >
                        <img src={Images.BACKICON} alt="" />
                      </button>
                      <span>Select Your Routes</span>
                    </h1>

                    <span
                      onClick={() => {
                        setIsOpenTruck(true);
                        closeModal();
                        clearAll();
                      }}
                      className="_stepNum"
                    >
                      Skip
                    </span>
                  </div>

                  <div className="_rtFilts">
                    <button
                      className={`_rtNrm_btn ${
                        activeTab === 1 ? "_rtNrm_btn_active" : ""
                      }`}
                      value={1}
                      onClick={(e) => setActiveTab(Number(e.target.value))}
                    >
                      {`Cities (${formValue?.length})`}
                    </button>
                    <button
                      className={`_rtNrm_btn ${
                        activeTab === 2 ? "_rtNrm_btn_active" : ""
                      }`}
                      value={2}
                      onClick={(e) => setActiveTab(Number(e.target.value))}
                    >
                      {" "}
                      {` States (${stateForm?.length})`}
                    </button>

                    <div className="_rtF_inpt">
                      <div className="_inpW_search _lrl_inpSrch">
                        <svg
                          className="srcIcon"
                          xmlns="http://www.w3.org/2000/svg"
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                        >
                          <path
                            opacity="0.5"
                            d="M14 13.2678L10.0893 9.35711C11.0291 8.22892 11.4977 6.78185 11.3977 5.31693C11.2977 3.85202 10.6368 2.48205 9.55246 1.49202C8.46811 0.501988 7.04382 -0.0318806 5.57588 0.00147384C4.10793 0.0348283 2.70936 0.632837 1.6711 1.6711C0.632837 2.70936 0.0348283 4.10793 0.00147384 5.57588C-0.0318806 7.04382 0.501988 8.46811 1.49202 9.55246C2.48205 10.6368 3.85202 11.2977 5.31693 11.3977C6.78185 11.4977 8.22892 11.0291 9.35711 10.0893L13.2678 14L14 13.2678ZM1.05418 5.71468C1.05418 4.79292 1.32752 3.89186 1.83962 3.12545C2.35172 2.35903 3.07959 1.76168 3.93118 1.40894C4.78278 1.0562 5.71985 0.963907 6.62389 1.14373C7.52794 1.32356 8.35836 1.76743 9.01014 2.41921C9.66193 3.07099 10.1058 3.90141 10.2856 4.80546C10.4654 5.70951 10.3732 6.64658 10.0204 7.49817C9.66767 8.34976 9.07032 9.07763 8.30391 9.58974C7.53749 10.1018 6.63644 10.3752 5.71468 10.3752C4.47906 10.3738 3.29444 9.88235 2.42072 9.00863C1.54701 8.13492 1.05555 6.9503 1.05418 5.71468Z"
                            fill="black"
                          />
                        </svg>
                        <input
                          type="text"
                          placeholder="Search List"
                          className="simpleFilter_input"
                          value={activeTab === 1 ? filter?.search : searchQuery}
                          onChange={(e) => handleSearchChange(e)}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="__mainRt_listBx" ref={scrollContainerRef}>
                    {activeTab === 1 && !filter.search && (
                      <div className="__inrtList">
                        <p>SUGGESTED</p>
                        <ul>
                          {suggestList?.map((i) => {
                            return (
                              <li
                                className={`_inrt_Li ${
                                  isCitySelected(i) ? "_li_active" : ""
                                }`}
                                onClick={() => handleSelectCity(i)}
                                key={i?.name}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "9px",
                                    alignItems: "center",
                                  }}
                                >
                                  <img src={images.PLACEICON.default} alt="" />
                                  <span>{`${i?.name},${i?.stateCode}`}</span>
                                </div>

                                <div>
                                  <div
                                    className="_not__selected"
                                    style={{
                                      display: isCitySelected(i)
                                        ? "none"
                                        : "block",
                                    }}
                                  ></div>
                                  <div
                                    className="__selected"
                                    style={{
                                      display: isCitySelected(i)
                                        ? "block"
                                        : "none",
                                    }}
                                  >
                                    <img
                                      src={images.CHECKFILLICON2.default}
                                      alt=""
                                    />
                                  </div>
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    )}

                    <div className="__inrtList">
                      <p>ALL LOCATIONS</p>
                      {activeTab === 1 ? (
                        <>
                          <ul>
                            {cityStateList?.all_cities?.map((i) => {
                              return (
                                <li
                                  className={`_inrt_Li ${
                                    isCitySelected(i) ? "_li_active" : ""
                                  }`}
                                  onClick={() => handleSelectCity(i)}
                                  key={i.name}
                                >
                                  <div
                                    style={{
                                      display: "flex",
                                      gap: "9px",
                                      alignItems: "center",
                                    }}
                                  >
                                    <img
                                      src={images.PLACEICON.default}
                                      alt=""
                                    />
                                    <span>{`${i?.name},${i?.stateCode}`}</span>
                                  </div>

                                  <div>
                                    <div
                                      className="_not__selected"
                                      style={{
                                        display: isCitySelected(i)
                                          ? "none"
                                          : "block",
                                      }}
                                    ></div>
                                    <div
                                      className="__selected"
                                      style={{
                                        display: isCitySelected(i)
                                          ? "block"
                                          : "none",
                                      }}
                                    >
                                      <img
                                        src={images.CHECKFILLICON2.default}
                                        alt=""
                                      />
                                    </div>
                                  </div>
                                </li>
                              );
                            })}
                          </ul>
                          {isFetchingMore && (
                            <div className="load-more _rtNrm_btn_active">
                              Loading..
                            </div>
                          )}
                        </>
                      ) : (
                        <ul>
                          {filteredList.map((i) => {
                            return (
                              <li
                                className={`_inrt_Li ${
                                  isStateSelected(i) ? "_li_active" : ""
                                }`}
                                onClick={() => handleSelectState(i)}
                                key={i?.name}
                              >
                                <div
                                  style={{
                                    display: "flex",
                                    gap: "9px",
                                    alignItems: "center",
                                  }}
                                >
                                  <img src={images.PLACEICON.default} alt="" />
                                  <span>{`${i?.name}`}</span>
                                </div>

                                <div>
                                  <div
                                    className="_not__selected"
                                    style={{
                                      display: isStateSelected(i)
                                        ? "none"
                                        : "block",
                                    }}
                                  ></div>
                                  <div
                                    className="__selected"
                                    style={{
                                      display: isStateSelected(i)
                                        ? "block"
                                        : "none",
                                    }}
                                  >
                                    <img
                                      src={images.CHECKFILLICON2.default}
                                      alt=""
                                    />
                                  </div>
                                </div>
                              </li>
                            );
                          })}
                        </ul>
                      )}
                    </div>
                  </div>
                </div>
              </div>

              <div className="modal-footer ">
                <ul
                  className="nav nav-tabs anvTbl_list"
                  id="myTab"
                  role="tablist"
                >
                  <li className="nav-item backTabs" role="presentation">
                    <button
                      className="nav-link active"
                      id="home-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#home-tab-pane"
                      type="button"
                      role="tab"
                      aria-controls="home-tab-pane"
                      aria-selected="true"
                    >
                      <img src={Images.BACKICON} alt="" />
                    </button>
                  </li>

                  <li className="nav-item stepRedirect" role="presentation">
                    <button
                      className="anime-btn _submitOn_modal"
                      type="button"
                      disabled={
                        isloading || (!formValue?.length && !stateForm?.length)
                      }
                      onClick={handleSubmitRoute}
                    >
                      {isloading
                        ? "Creating.."
                        : `Add Route (${
                            formValue?.length + stateForm?.length
                          })`}
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
      {isOpen && (
        <div className="modal-backdrop fade show" onClick={closeModal}></div>
      )}
    </div>
  );
};

export default CreateRouteModal;
