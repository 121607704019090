import { CommonInput, CommonModal, CommonSwitch, CustomSelect } from 'components'
import React from 'react'

const AddOtherDocumentsModal = (props) => {

  let handleProps = {
    ...props
  }

  const arryOptions = [
    { label: 'Arjun', value: "01" },
    { label: 'Anvit', value: "02" },
  ]

  return (
    <>
      <CommonModal {...handleProps}>
        <div className='__addotr_docsBx'>
          <div className='singleDiv'>
            <CustomSelect
              options={arryOptions}
              placeholder="Select a city"
              label="City"
            // onSelect={(data) => handleCityChange(data, 'city')}
            // value={formValue?.city}
            // error={formError["city.label"]}
            />
          </div>

          <div className='singleDiv'>
            <CommonInput
              type="text"
              placeholder="Policy Number"
              label="Policy Number"
            />
          </div>

          <div className='singleDiv'>
            <CommonInput
              type="date"
              placeholder="Select Expiry Date"
              label="Select Expiry Date"
            />
          </div>

          <div className="singleDiv">
            <CommonSwitch
              id="reminder"
              name="Reminder"
              label="Reminder"
              cheked={true}
            // value={freightRadioToggle}
            // checked={freightRadioToggle === true ? true : false}
            // onChange={(e) => setFreightRadioToggle(e.target.checked)}
            />
            <div className='mt-3 w-100'>
              <CommonInput
                type="date"
                placeholder="Reminder Date"
                label="Reminder Date"
              />
            </div>
          </div>

          <div className="singleDiv">
            <div className='_attatchmentBxs'>
              <h4>Attachment</h4>
              <div className='_athBxs' style={{marginTop: '-6px'}}>
                <input type="file" id='__attachMent_file' />
                <label htmlFor="__attachMent_file">
                  <svg xmlns="http://www.w3.org/2000/svg" width="74" height="74" viewBox="0 0 74 74" fill="none">
                    <rect x="0.496242" y="0.496242" width="72.4514" height="72.4514" rx="3.4737" fill="#E1EDFF" stroke="#0066FF" stroke-width="0.992484" />
                    <path opacity="0.8" d="M34.9154 44.1093V28.782H37.965V44.1093H34.9154ZM28.7822 37.9648V34.9152H44.1096V37.9648H28.7822Z" fill="#0066FF" />
                  </svg>
                </label>
              </div>
            </div>
          </div>

        </div>
      </CommonModal>
    </>
  )
}

export default AddOtherDocumentsModal