import React, { useContext, useEffect, useState } from 'react'
import { CommonInput, CommonSwitch } from 'components'
import { UserContext } from 'context';
import { utils } from 'helper';
import { getMaterialCategoryListData } from '_services/materialServices/materialCategoryServices';
import StorageService from 'utils/StorageServices/StorageServices';
import { fetchConsigneeDetailsData } from '_services/accountsServices/consigneeServices';
import { fetchSingleUnitData } from '_services/accountsServices/unitServices';
import { getAddressDetailsApi } from '_services/accountsServices/addressBookService';

const LrDetailsPage = (props) => {

    let { lrData, permissions } = props;

    const { masterData } = useContext(UserContext);
    const [formValue, setFormValue] = useState({});
    const [selectConsigneeData, setSelectConsigneeData] = useState({})
    const [selectUnitData, setSelectUnitData] = useState({})
    const [materialCategoryList, setMaterialCategoryList] = useState([]);
    const [dataSingleConsignee, setDataSingleConsignee] = useState({})
    const [dataSingleUnit, setDataSingleUnit] = useState({})

    const totalCoutFunction = (name) => {
        if (formValue?.lr_material_list?.length > 0) {
            return formValue?.lr_material_list?.reduce((acc, currTeam) => acc + Number(currTeam[name]), 0)
        }
    }

    const getTableMaterialCategoryListData = async () => {
        try {
            let object = {
                company_id: StorageService.getCompanyId(),
                limit: 100,
                offset: 1,
                search: '',
            }
            const res = await getMaterialCategoryListData(object);
            if (res?.responseCode == 200) {
                setMaterialCategoryList(res?.data?.material_categorys)
            }
        } catch (error) {
            // throw error;
        }
    }

    const getSingleConsigneeData = async (id) => {
        try {
            let object = {
                addressbook_id: id,
                company_id: lrData?.company_id,
            };
            const res = await getAddressDetailsApi(object);
            // let object = {
            //     consignee_id: id,
            //     company_id: lrData?.company_id,
            // }
            // const res = await fetchConsigneeDetailsData(object);
            if (res?.responseCode == 200) {
                setDataSingleConsignee(res?.data?.book_detail)
            }
        } catch (error) {
            throw error;
        }
    }

    const getSingleUnitData = async (id) => {
        try {
            let object = {
                addressbook_id: id,
                company_id: lrData?.company_id,
            };
            const res = await getAddressDetailsApi(object);
            // let object = {
            //     unit_id: id,
            //     company_id: lrData?.company_id,
            // }
            // const res = await fetchSingleUnitData(object);
            if (res?.responseCode == 200) {
                setDataSingleUnit(res?.data?.book_detail)
            }
        } catch (error) {
            throw error;
        }
    }

    useEffect(() => {
        if (lrData) {
            setFormValue(lrData);
            setSelectConsigneeData(lrData?.consignee_id)
            setSelectUnitData(lrData?.unit_id);
            getTableMaterialCategoryListData();
        }
        if (lrData?.unit_id?._id && lrData?.consignee_id?._id) {
            getSingleUnitData(lrData?.unit_id?._id);
            getSingleConsigneeData(lrData?.consignee_id?._id);
        }
    }, [lrData])

    const getTodayDate2 = (date) => {
        const today = new Date(date);
        const year = today.getFullYear();
        const month = String(today.getMonth() + 1).padStart(2, "0");
        const day = String(today.getDate()).padStart(2, "0");
        return `${year}-${month}-${day}`;
    }

    console.log('lrData', lrData)

    return (
        <>
            <div className='pr_procssBx' style={{ marginBottom: '9px' }}>
                <div className='_lrdMax'>
                    <div className="flxInr">
                        <div className="halfDiv">
                            <CommonInput
                                disabled
                                type="date"
                                label="LR Date & Time * "
                                placeholder="LR Date & Time * "
                                name='invoice_date'
                                value={formValue?.invoice_date ? getTodayDate2(formValue?.invoice_date) : ''}
                            />
                        </div>

                        <div className="halfDiv">
                            <CommonInput
                                disabled
                                type="text"
                                label="LR Number "
                                placeholder="LR Number "
                                name='lr_number'
                                value={formValue?.lr_number ? formValue.lr_number : ''}
                            />
                        </div>

                        <div className="halfDiv">
                            <CommonInput
                                type="text"
                                label="Party Name *"
                                placeholder="Party Name *"
                                name='name'
                                value={formValue?.consignor_id?.name ? formValue?.consignor_id?.name : ''}
                                disabled
                            />
                        </div>

                        <div className="halfDiv">
                            <CommonInput
                                type="text"
                                label="Branch Name "
                                placeholder="Branch Name "
                                name='name'
                                value={formValue?.branch_id?.branch ? formValue?.branch_id?.branch : ''}
                                disabled
                            />
                        </div>

                        <div className="halfDiv">
                            <CommonInput
                                type="text"
                                label="From Station  "
                                placeholder="From Station  "
                                name='name'
                                value={formValue.from_location ? formValue.from_location : ''}
                                disabled
                            />
                        </div>

                        <div className="halfDiv">
                            <CommonInput
                                type="text"
                                label="To Station "
                                placeholder="To Station "
                                name='name'
                                value={formValue.to_location ? formValue.to_location : ''}
                                disabled
                            />
                        </div>
                    </div>

                    <div className='_csgAdd_bx'>
                        <div className="halfDiv">
                            {
                                selectUnitData?.code ? (
                                    <div className='_unit_detailBxs _avPointer'>
                                        <h6> Unit </h6>
                                        <p> Name : {selectUnitData?.name} </p>
                                        <p> Unit code : {selectUnitData?.code} </p>
                                        <p> Address : {dataSingleUnit?.address_line1 ? dataSingleUnit?.address_line1 : '-'} {dataSingleUnit?.city?.label ? dataSingleUnit?.city?.label : '-'} {dataSingleUnit?.state?.label ? dataSingleUnit?.state?.label : '-'} </p>
                                    </div>
                                ) : (
                                    <button className='addConsignee_lBtn' disabled> + Add Unit </button>
                                )
                            }
                        </div>

                        <div className="halfDiv">
                            {
                                selectConsigneeData?.code ? (
                                    <div className='_unit_detailBxs'>
                                        <h6> Consignee </h6>
                                        <p> Name : {selectConsigneeData?.name} </p>
                                        <p> Consignee code : {selectConsigneeData?.code} </p>
                                        <p> Address : {dataSingleConsignee?.address_line1 ? dataSingleConsignee?.address_line1 : '-'} {dataSingleConsignee?.city?.label ? dataSingleConsignee?.city?.label : '-'} {dataSingleConsignee?.state?.label ? dataSingleConsignee?.state?.label : '-'} </p>
                                    </div>
                                ) : (
                                    <button className='addConsignee_lBtn' disabled>+ Add Consignee</button>
                                )
                            }
                        </div>
                    </div>
                </div>
            </div>

            {permissions?.permission_at_item?.item_group_hsn?.view === 1 && <div className='pr_procssBx' style={{ marginBottom: '9px' }}>
                <div className='_materialTable'>
                    <table>
                        <thead>
                            <tr>
                                <th>Material</th>
                                <th>Material Category</th>
                                <th>HSN Code</th>
                                <th>QTY</th>
                                <th>Weight</th>
                                <th>KOT</th>
                                <th>Amount</th>
                                <th></th>
                            </tr>
                        </thead>

                        <tbody>
                            {formValue?.lr_material_list?.length > 0 ? formValue?.lr_material_list?.map((el, ind) => {
                                return (
                                    <React.Fragment key={ind}>
                                        <tr>
                                            <td>
                                                <CommonInput
                                                    disabled
                                                    type="text"
                                                    label="Material * "
                                                    placeholder="Material"
                                                    name='hsn_code'
                                                    value={el?.name ? el?.name : ''}
                                                />
                                            </td>
                                            <td>
                                                <CommonInput
                                                    disabled
                                                    type="text"
                                                    label="Category * "
                                                    placeholder="Category"
                                                    name='material_category_id'
                                                    value={materialCategoryList?.filter((item) => (item?._id === el?.material_category_id) ? item : '')[0]?.name}
                                                />
                                            </td>
                                            <td>
                                                <CommonInput
                                                    disabled
                                                    type="text"
                                                    label="HSN Code"
                                                    placeholder="HSN Code"
                                                    name='hsn_code'
                                                    value={el?.hsn_code ? el?.hsn_code : ''}
                                                />
                                            </td>
                                            <td>
                                                <div className='_inpSelect'>
                                                    <CommonInput
                                                        disabled
                                                        type="number"
                                                        label="Qty "
                                                        placeholder="Qty "
                                                        name='qty'
                                                        value={el?.qty ? el?.qty : ''}
                                                    />

                                                    <select
                                                        className='_selectInner_bx'
                                                        name='qty_type'
                                                        id='qty_type'
                                                        disabled
                                                        defaultValue={el?.qty_type}
                                                    >
                                                        {
                                                            masterData?.material_qty_type_list?.map((el, ind) => {
                                                                return (
                                                                    <option value={el?.id} defaultValue={ind === 0} key={ind}>{el?.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </td>
                                            <td>
                                                <div className='_inpSelect'>
                                                    <CommonInput
                                                        disabled
                                                        type="number"
                                                        label="Weight "
                                                        placeholder="Weight "
                                                        name='weight'
                                                        value={el?.weight ? el?.weight : ''}
                                                    />
                                                    <select
                                                        className='_selectInner_bx'
                                                        name='weight_type'
                                                        id='weight_type'
                                                        disabled
                                                    >
                                                        {
                                                            masterData?.material_weight_type_list?.map((el, ind) => {
                                                                return (
                                                                    <option value={el?.id} defaultValue={ind === 0} key={ind}>{el?.name}</option>
                                                                )
                                                            })
                                                        }
                                                    </select>
                                                </div>
                                            </td>
                                            <td>
                                                <CommonInput
                                                    disabled
                                                    type="number"
                                                    label="KOT "
                                                    placeholder="KOT "
                                                    name='kot'
                                                    value={el?.kot ? el?.kot : ''}
                                                />
                                            </td>
                                            <td>
                                                <CommonInput
                                                    disabled
                                                    type="number"
                                                    label="Amount "
                                                    placeholder="Amount "
                                                    name='amount'
                                                    value={el?.amount ? el?.amount : ''}
                                                />
                                            </td>
                                        </tr>
                                        {
                                            (formValue?.lr_material_list?.length - 1 === ind) && (
                                                <tr>
                                                    <td>
                                                    </td>

                                                    <td></td>

                                                    <td></td>

                                                    <td>{totalCoutFunction('qty')} Qty</td>

                                                    <td align='right'>{totalCoutFunction('weight')} KG</td>

                                                    <td align='right'> {totalCoutFunction('kot')} </td>

                                                    <td align='right'> {totalCoutFunction('amount')} Rs </td>

                                                    <td></td>
                                                </tr>
                                            )
                                        }
                                    </React.Fragment>
                                )
                            }) : <></>}
                        </tbody>
                    </table>
                </div>
            </div>}

            <div className='pr_procssBx' style={{ marginBottom: '9px' }}>
                <div className="_inrPrt_flx2 ">
                    {permissions?.permission_at_invoice_number?.view === 1 && <div className="_divForth">
                        <CommonInput
                            disabled
                            type="number"
                            label={permissions?.permission_at_invoice_number?.mandatory === 1 ? "Invoice Number * " : "Invoice Number"}
                            placeholder={permissions?.permission_at_invoice_number?.mandatory === 1 ? "Invoice Number * " : "Invoice Number"}
                            name='invoice_number'
                            value={formValue?.invoice_number ? formValue?.invoice_number : ''}
                        />
                    </div>}

                    {permissions?.permission_at_field2?.view === 1 && <div className="_divForth">
                        <CommonInput
                            disabled
                            type="text"
                            label={permissions?.permission_at_field2?.mandatory === 1 ? (permissions?.permission_at_field2?.field_name ? `${permissions?.permission_at_field2?.field_name} *` : "SAP Invoice Number * ") : (permissions?.permission_at_field2?.field_name ? `${permissions?.permission_at_field2?.field_name}` : "SAP Invoice Number ")}
                            placeholder={permissions?.permission_at_field2?.mandatory === 1 ? (permissions?.permission_at_field2?.field_name ? `${permissions?.permission_at_field2?.field_name} *` : "SAP Invoice Number * ") : (permissions?.permission_at_field2?.field_name ? `${permissions?.permission_at_field2?.field_name}` : "SAP Invoice Number ")}
                            name='sap_invoice_number'
                            value={formValue?.sap_invoice_number ? formValue.sap_invoice_number : ""}
                        />
                    </div>}

                    {permissions?.permission_at_field3?.view === 1 && <div className="_divForth">
                        <CommonInput
                            disabled
                            type="text"
                            label={permissions?.permission_at_field3?.mandatory === 1 ? (permissions?.permission_at_field3?.field_name ? `${permissions?.permission_at_field3?.field_name} *` : "GST Number *") : (permissions?.permission_at_field3?.field_name ? `${permissions?.permission_at_field3?.field_name}` : "GST Number ")}
                            placeholder={permissions?.permission_at_field3?.mandatory === 1 ? (permissions?.permission_at_field3?.field_name ? `${permissions?.permission_at_field3?.field_name} *` : "GST Number *") : (permissions?.permission_at_field3?.field_name ? `${permissions?.permission_at_field3?.field_name}` : "GST Number ")}
                            maxLength={permissions?.permission_at_field3?.input_length ? permissions?.permission_at_field3?.input_length : '10'}
                            name='gst_invoice_number'
                            value={formValue?.gst_invoice_number ? formValue.gst_invoice_number : ''}
                        />
                    </div>}

                    {permissions?.permission_at_field4?.view === 1 && <div className="_divForth">
                        <CommonInput
                            disabled
                            type="text"
                            label={permissions?.permission_at_field4?.mandatory === 1 ? (permissions?.permission_at_field4?.field_name ? `${permissions?.permission_at_field4?.field_name} *` : "PO Number *") : (permissions?.permission_at_field4?.field_name ? `${permissions?.permission_at_field4?.field_name}` : "PO Number ")}
                            placeholder={permissions?.permission_at_field4?.mandatory === 1 ? (permissions?.permission_at_field4?.field_name ? `${permissions?.permission_at_field4?.field_name} *` : "PO Number *") : (permissions?.permission_at_field4?.field_name ? `${permissions?.permission_at_field4?.field_name}` : "PO Number ")}
                            maxLength={permissions?.permission_at_field4?.input_length ? permissions?.permission_at_field4?.input_length : '10'}
                            name='po_number'
                            value={formValue?.po_number ? formValue.po_number : ''}
                        />
                    </div>}

                    {permissions?.permission_at_field5?.view === 1 && <div className="_divForth">
                        <CommonInput
                            disabled
                            type="text"
                            label={permissions?.permission_at_field5?.mandatory === 1 ? (permissions?.permission_at_field5?.field_name ? `${permissions?.permission_at_field5?.field_name} *` : "MLR Number *") : (permissions?.permission_at_field5?.field_name ? `${permissions?.permission_at_field5?.field_name}` : "MLR Number ")}
                            placeholder={permissions?.permission_at_field5?.mandatory === 1 ? (permissions?.permission_at_field5?.field_name ? `${permissions?.permission_at_field5?.field_name} *` : "MLR Number *") : (permissions?.permission_at_field5?.field_name ? `${permissions?.permission_at_field5?.field_name}` : "MLR Number ")}
                            maxLength={permissions?.permission_at_field5?.input_length ? permissions?.permission_at_field5?.input_length : '10'}
                            name='mlr_number'
                            value={formValue?.mlr_number ? formValue.mlr_number : ''}
                        />
                    </div>}
                </div>
            </div>

            <div className='pr_procssBx' style={{ marginBottom: '9px' }}>
                <div className="_inrPrt_flx2 ">
                    <div className='singleDiv'>
                        <CommonSwitch
                            id="ewayBill"
                            name="ewaybill"
                            label="Eway Bill"
                            disabled
                        />
                    </div>
                    <div className="_divForth">
                        <CommonInput
                            disabled
                            type="number"
                            label="Eway Bill Number * "
                            placeholder="Eway Bill Number * "
                            name='eway_bill_number'
                            value={formValue?.eway_bill_number ? formValue.eway_bill_number : ""}
                        />
                    </div>

                    <div className="_divForth">
                        <CommonInput
                            disabled
                            type="date"
                            label="Start Date"
                            placeholder="Start Date "
                            name='eway_bill_date'
                            value={formValue.eway_bill_date ? getTodayDate2(formValue.eway_bill_date) : ''}
                        />
                    </div>

                    <div className="_divForth">
                        <CommonInput
                            disabled
                            type="date"
                            label="End Date"
                            placeholder="End Date "
                            name='eway_expiry_date'
                            value={formValue.eway_expiry_date ? getTodayDate2(formValue.eway_expiry_date) : ''}
                        />
                    </div>
                </div>
            </div>

            {(permissions?.contract?.chargeable_on === 1) && <div className='pr_procssBx' style={{ marginBottom: '9px' }}>
                <div className="_inrPrt_flx2 _maxWid-650">
                    <div className='singleDiv'>
                        <CommonSwitch
                            id="freightReceivable"
                            name="Freight Receivable"
                            label="Freight Receivable"
                            disabled
                        />
                    </div>

                    <div className='_divForth'>
                        <CommonInput
                            disabled
                            type="text"
                            label="Freight Type"
                            placeholder="Freight Type "
                            name='freight_type'
                            value={permissions?.contract?.rate_type?.find((el) => (el?.id == formValue?.lr_freight?.freight_type ? el : ''))?.name}
                        />
                    </div>

                    {formValue?.lr_freight?.freight_type != 6 && (
                        <>
                            <div className="_divForth">
                                <CommonInput
                                    disabled
                                    type="number"
                                    label="Qty"
                                    placeholder="Qty "
                                    name='total_qty'
                                    value={formValue?.lr_freight?.qty ? formValue?.lr_freight?.qty : ''}
                                />
                            </div>

                            <div className="_divForth">
                                <CommonInput
                                    disabled
                                    type="number"
                                    label="Rate"
                                    placeholder="Rate "
                                    name='rate'
                                    value={formValue?.lr_freight?.rate ? formValue?.lr_freight?.rate : ''}
                                />
                            </div>
                        </>
                    )}

                    <div className="_divForth">
                        <CommonInput
                            disabled
                            type="number"
                            label="Amount"
                            placeholder="Amount "
                            name='total_amount'
                            value={(formValue?.lr_freight?.rate * formValue?.lr_freight?.qty > 0 && formValue?.lr_freight?.rate * formValue?.lr_freight?.qty != null) ? (formValue?.lr_freight?.rate * formValue?.lr_freight?.qty) : (formValue?.lr_freight?.total_freight ? formValue?.lr_freight?.total_freight : "")}
                        />
                    </div>

                </div>
            </div>}
        </>
    )
}

export default LrDetailsPage
