import { Images } from "constants";
import { UserContext } from "context";
import { OtpVerify } from "features";
import { resetError } from "features/auth/authSlice";
import React, { useContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import StorageServices from "utils/StorageServices";
import "../../assets/css/onboard.css";
import { getDashbordData, handleKeyDown } from "_services";
import StorageService from "utils/StorageServices/StorageServices";
import toast from "react-hot-toast";

const Verify = () => {
  const { genrateOtp, setGenrateOtp, setToken ,setUserData} = useContext(UserContext);
  const { loading, error, userData, data } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [otp, setOtp] = useState('');
  const [otpError, setOtpError] = useState(false);
  const [loginInfo, setLoginInfo] = useState({
    data: "",
    user_agent: "",
  });

  useEffect(() => {
    if (!genrateOtp) {
      navigate("/");
    }
  }, [genrateOtp]);

  useEffect(() => {
    // getIpApiHandler();
  }, []);

  function success(pos) {
    const crd = pos.coords;
    if (crd && crd?.latitude && crd?.longitude) {
      setLoginInfo({
        let: crd?.latitude,
        long: crd?.longitude,
        accuracy: crd?.accuracy,
      });
    }
  }

  function handleError(err) {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  }
  const getIpApiHandler = async () => {
    navigator.geolocation.getCurrentPosition(success, handleError, options);
  };

  const options = {
    enableHighAccuracy: true,
    timeout: 5000,
    maximumAge: 0,
  };

  // useEffect(() => {

  //   if (userData && userData.data && userData.data.token && userData.data.token != null) {
  //     StorageServices.setToken(userData.data.token)
  //     StorageServices.setUseryID(userData?.data?._id);
  //     StorageServices.setCompanyId(userData?.data?.user_detail?.company_list[0]?.company_id?._id);
  //     StorageServices.setCompName(userData?.data?.user_detail?.company_list[0]?.company_id?.company);

  //     //context variables
  //     setToken(userData.data.token)
  //     setGenrateOtp(false)

  //     navigate('/dashboard')
  //   }

  // }, [userData && userData.data && userData.data.token && userData.data.token != null])

  const handleOtpChange = (e) => {
    const value = e.target.value;
    // Allow only numeric values
    if (/^\d*$/.test(value)) {
      setOtp(value);
      setOtpError("");
    }
  };

  const validateOtp = () => {
    if (otp.length !== 4) {
      setOtpError("Otp number must be exactly 4 digits.");
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validateOtp()) {
      let params = {
        mobile_no:
          data && data.data && data.data.mobile_no ? data.data.mobile_no : "",
        otp,
        loginInfo,
        device_type: 1
      };
      try {
        const res = await dispatch(OtpVerify(params));
        if (res?.payload?.responseCode === 200) {
     
          StorageServices.setToken(res?.payload?.data?.token);
          StorageServices.setUseryID(res?.payload?.data?._id);
          StorageServices.setCompanyId(
            res?.payload?.data?.user_detail?.company_list[0]?.company_id?._id
          );
          StorageServices.setCompName(
            res?.payload?.data?.user_detail?.company_list[0]?.company_id?.company
          );
          StorageServices.setUserExist(
            res?.payload?.data?.user_exists
          );

          if( res?.payload?.data?.user_detail?.select_company_id !== "1234" && res?.payload?.data?.user_detail?.select_company_id && res?.payload?.data?.user_detail?.select_company_id !=="undefined"){
        
            getAllDashboardData(res?.payload?.data?.user_detail?.select_company_id)
          }
          
          //context variables
          setToken(res?.payload?.data?.token);
          setGenrateOtp(false);
          StorageService.setLocalItem("userDetais",JSON.stringify(res?.payload?.data))
          StorageService.setLocalItem("userData",JSON.stringify(res?.payload?.data))
          setUserData(res?.payload?.data)
          navigate("/dashboard");
        }else{
          toast.error(res?.payload?.responseMessage)
          // setOtpError(res?.payload?.responseMessage)
        }
      } catch (error) {
        toast.error(error)
        // setOtpError(error)
      }
    }
  };

  const handleResetError = () => {
    dispatch(resetError());
  };

  const getAllDashboardData = async(data)=>{

    const postData = {
      company_id:data,
      select_company_id:1
    };
    try {
        let response = await getDashbordData(postData);
        if(response?.responseCode===200){
          StorageService.setLocalItem("userData",JSON.stringify(response.data))
          setUserData(response.data)
        }else{
          throw response?.responseMessage
        }
    } catch (error) {
        console.error('Error fetching master data:', error);
      throw error 
    } finally {
       
    }
} 

  return (
    <div className="_midOb_bx">
      <div className="_innrMid_bx">
        <img className="_logoOn_board" src={Images.LOGO} alt="" />

        <div className="_midOd_head">
          <h3>Verify Mobile Number</h3>
          <p>
            Enter 6 digit code has been sent to:
            <span className="_numberOtp">
              {" "}
              +91 {data && data.data && data.data.mobile_no}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
              >
                <g clipPath="url(#clip0_6218_718)">
                  <path
                    d="M7.6354 2.9223C8.03968 2.48427 8.24183 2.26526 8.45663 2.13751C8.97492 1.82927 9.61312 1.81968 10.1401 2.11223C10.3585 2.23347 10.5668 2.44632 10.9835 2.87201C11.4002 3.2977 11.6086 3.51054 11.7273 3.73363C12.0137 4.27193 12.0043 4.92387 11.7025 5.45333C11.5775 5.67276 11.3631 5.87925 10.9343 6.29224L5.83253 11.2061C5.01996 11.9887 4.61367 12.38 4.1059 12.5784C3.59813 12.7767 3.03991 12.7621 1.92347 12.7329L1.77157 12.7289C1.43169 12.7201 1.26175 12.7156 1.16297 12.6035C1.06418 12.4914 1.07767 12.3183 1.10464 11.9721L1.11929 11.7841C1.1952 10.8096 1.23316 10.3224 1.42345 9.88444C1.61373 9.44647 1.94196 9.09085 2.59841 8.37962L7.6354 2.9223Z"
                    stroke="#0066FF"
                    strokeWidth="1.22069"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M7.05289 2.98497L10.8506 6.78267"
                    stroke="#0066FF"
                    strokeWidth="1.22069"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M7.59541 12.7505L11.9356 12.7505"
                    stroke="#0066FF"
                    strokeWidth="1.22069"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_6218_718">
                    <rect
                      width="13.0207"
                      height="13.0207"
                      fill="white"
                      transform="translate(0 0.813789)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </span>
          </p>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="_subFrm_fx">
            <div className="_baseInput_bx ">
              <input
                type="text"
                className="form-control"
                placeholder="4 Digit OTP"
                value={otp}
                onChange={handleOtpChange}
                onKeyDown={handleKeyDown}
                required
                maxLength={4}
              />
              
              <p className="_otpMsg">Resend OTP in 13 secs</p>
            </div>
            {/* {otpError && (
              <div>
                <span>{otpError}</span>
                <button onClick={handleResetError}>Dismiss</button>
              </div>
            )} */}
            {otpError && <small className="text-danger">{otpError}</small>}
            <button className="anime-btn _submitOn_board" type="submit">
              {loading ? "Verifying..." : "Verify OTP"}{" "}
            </button>
          </div>
        </form>
      </div>
      <div className="_midBx_foot">
        <p>
          By clicking on Verify you agree to our <a href="">Terms</a> and{" "}
          <a href="">Privacy Policy</a>
        </p>
        <p>© 2024 Eminence Infotech Private Limited. All rights reserved.</p>
      </div>
    </div>
  );
};

export default Verify;
