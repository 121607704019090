import { CommonModal, StartRatings } from 'components';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { FaStar } from 'react-icons/fa6';
import bidderImg from "../../../src/assets/img/bidderImg.png"
import SaveConnectModal from 'modals/SaveConnectModal';
import { UserContext } from 'context';
import AddReviewModal from 'modals/AddReviewModal';
import { FaStarHalfAlt } from 'react-icons/fa';
import { utils } from 'helper';
import Tab from 'components/common/Tab';
import toast from 'react-hot-toast';
import StorageService from 'utils/StorageServices/StorageServices';
import { addRatingReview, getRatingsReviewData } from '_services';
import { constant } from 'constants/constant';
import RatingList from 'pages/Invitations/RatingList';
import moment from 'moment';
import { IoChevronBack, IoChevronForward } from "react-icons/io5";

const formObj = {
    company_id: '',
    to_company_id: '',
    rating: 0,
    review: '',
}

const ViewProfileModal = (props) => {
    const loadingBarRef = useRef(null);
    let { isOpen, nextModal, closeModal, activeTabValue, withdrawalRequest, removeConnection, acceptRequest, loadingRm, saveModalDirect, setSaveModalDirect } = props;
    const { setModalZIndex, modalZIndex, masterData, connectionDetailsGlobal, setConnectionDetailsGlobal } = useContext(UserContext);
    const [listDataInDetails, setListDataInDetails] = useState({});

    const [formValue, setFormValue] = useState(formObj)
    const [showWriteReview, setShowWriteReview] = useState(false);
    const [ratingsList, setRatingsList] = useState([])
    const [loading, setLoading] = useState(false);

    const itemsPerPage = 10;
    const [totalItems, setTotalItems] = useState(0);
    const [filter, setFilter] = useState({
        limit: 10,
        offset: 1,
    });

    // tabs 

    const [activeTab, setActiveTab] = useState(1);

    const tabProps = {
        tabList: constant.vehicle_DetailsTabs,
        callBackTab: (e) => setActiveTab(e),
        activeTab: activeTab
    }

    // tabs

    const propsViewProfile = {
        ...props,
        modalZIndex,
        isOpen,
    }

    const handleEventOnclick = () => {
        nextModal(isOpen);
        setSaveModalDirect((saveModalDirect) => ({ ...saveModalDirect, isOpenDirect: false, isCloseDirect: false, }))
        closeModal(false);
    }

    useEffect(() => {
        if (!showWriteReview) {
            setModalZIndex('')
        }
    }, [showWriteReview])

    const ratingChanged = (newRating) => {
        setFormValue({ ...formValue, rating: newRating });
    };

    const handleAddReview = () => {
        setShowWriteReview(!showWriteReview)
        setModalZIndex(999)
    }

    // useEffect(() => {
    //     if (StorageService.getCompanyId()) {
    //         setFormValue((s) => ({
    //             ...s,
    //             company_id: StorageService.getCompanyId(),
    //             to_company_id: isOpen?._id,
    //             rating: isOpen?.rating > 0 ? isOpen?.rating : 0,
    //             // review: isOpen?.remarks,
    //         }))
    //     }
    // }, [StorageService.getCompanyId(), isOpen])

    useEffect(() => {
        setListDataInDetails(isOpen);
        setConnectionDetailsGlobal(isOpen);
    }, [isOpen?._id])

    const getRatingDataList = async (comp_id) => {
        try {
            if (loadingBarRef.current) {
                loadingBarRef.current.continuousStart(); // Start the loading bar
            }
            let data = {
                ...filter,
                company_id: comp_id,
            }
            const res = await getRatingsReviewData(data);
            if (res?.responseCode === 200) {
                let filterDataObject = res?.data?.list?.find((el) => (el?._id === isOpen?._id) ? el : {})
                setRatingsList(res?.data?.list);
                setTotalItems(res?.data?.total_record);
                if (filterDataObject?._id) {
                    setFormValue((s) => ({
                        ...s,
                        rating: filterDataObject?.rating > 0 ? filterDataObject?.rating : 0,
                        review: filterDataObject?.review,
                    }))
                }
            }
        } catch (error) {
            toast.error('Error !! ');
        } finally {
            if (loadingBarRef.current) {
                loadingBarRef.current.complete(); // Complete the loading bar
            }
        }
    }

    const callStarRatingApi = async () => {
        setLoading(true);
        try {
            if (loadingBarRef.current) {
                loadingBarRef.current.continuousStart(); // Start the loading bar
            }
            const res = await addRatingReview(formValue);
            if (res?.responseCode === 200) {
                setLoading(false);
                setShowWriteReview(false);
                getRatingDataList(isOpen?._id)
            }
        } catch (error) {
            setLoading(false);
            toast.error('Error !! ');
        } finally {
            setLoading(false);
            if (loadingBarRef.current) {
                loadingBarRef.current.complete(); // Complete the loading bar
            }
        }
    }

    useEffect(() => {
        if (isOpen?._id) {
            getRatingDataList(isOpen?._id);
            setFormValue((s) => ({
                ...s,
                company_id: StorageService.getCompanyId(),
                to_company_id: isOpen?._id,
            }))
        }
    }, [isOpen?._id, filter])

    let reviewModal = {
        isOpen: showWriteReview,
        formValue: formValue,
        detailsData: listDataInDetails,
        isLoadding: loading,
        disabled: loading,
        setFormValue: (e) => setFormValue(e),
        closeModal: (e) => setShowWriteReview(false),
        onSubmit: (e) => callStarRatingApi(e),
        modalMainClass: "_dashCommon_Modal _smModal _rvrModal",
        modalTitle: "Write A Review",
        footerPart: true,
    }

    const createAtDate = useMemo(() => {
        const postDate = moment(listDataInDetails?.createdAt).utc().local();
        const currentDate = moment();

        const diffInYears = currentDate.diff(postDate, "years");
        const diffInMonths = currentDate.diff(postDate, "months");
        const diffInDays = currentDate.diff(postDate, "days");

        let displayDate;

        if (diffInYears >= 1) {
            displayDate = diffInYears === 1 ? "1 year ago" : `${diffInYears} years ago`;
        } else if (diffInMonths >= 1) {
            displayDate = diffInMonths === 1 ? "1 month ago" : `${diffInMonths} months ago`;
        } else if (diffInDays >= 1) {
            displayDate = diffInDays === 1 ? "1 day ago" : `${diffInDays} days ago`;
        } else {
            displayDate = postDate.fromNow(); // for durations like "a few hours ago"
        }

        return displayDate;
    }, [listDataInDetails?.createdAt]);

    const handlePageChange = (page) => {
        setFilter((s) => ({ ...s, offset: page }))
    };

    const renderPagination = () => {
        const totalPages = Math.ceil(totalItems / itemsPerPage);
        let pages = [];
        for (let i = 1; i <= totalPages; i++) {
            pages.push(
                <li
                    key={i}
                    className={`page-item ${i === filter.offset ? "active" : ""}`}
                >
                    <a className="page-link _avPointer" onClick={(e) => handlePageChange(i)}>
                        {i}
                    </a>
                </li>
            );
        }

        return (
            <ul className="pagination ">
                <li className={`page-item ${filter?.offset === 1 ? "disabled" : ""}`}>
                    <a
                        className="page-link _avPointer"
                        onClick={() => handlePageChange(filter?.offset - 1)}
                    >
                        <IoChevronBack />
                    </a>
                </li>
                {pages}
                <li
                    className={`page-item ${filter?.offset === totalPages ? "disabled" : ""
                        }`}
                >
                    <a
                        className="page-link _avPointer"
                        onClick={() => handlePageChange(filter?.offset + 1)}
                    >
                        <IoChevronForward />
                    </a>
                </li>
            </ul>
        );
    };

    return (
        <>
            <CommonModal {...propsViewProfile}>
                <div className='__vwPrf_body'>
                    <div className='_flxBidr _wrtReview_bx p-0'>
                        <div className='_bidR_bxs' >
                            <div className='__bidR_usrBx'>
                                <span className='_bidR_verifyIcon'>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                        <circle cx="12.1999" cy="12.1999" r="8.34737" fill="white" />
                                        <path fill-rule="evenodd" clip-rule="evenodd" d="M11.5579 23.1158C13.0757 23.1158 14.5786 22.8168 15.9809 22.236C17.3832 21.6552 18.6573 20.8038 19.7306 19.7306C20.8038 18.6573 21.6552 17.3832 22.236 15.9809C22.8168 14.5786 23.1158 13.0757 23.1158 11.5579C23.1158 10.0401 22.8168 8.53715 22.236 7.13488C21.6552 5.73261 20.8038 4.45848 19.7306 3.38523C18.6573 2.31198 17.3832 1.46063 15.9809 0.879792C14.5786 0.298954 13.0757 -2.2617e-08 11.5579 0C8.49255 4.56772e-08 5.55275 1.2177 3.38523 3.38523C1.2177 5.55275 0 8.49255 0 11.5579C0 14.6232 1.2177 17.563 3.38523 19.7306C5.55275 21.8981 8.49255 23.1158 11.5579 23.1158ZM11.26 16.2324L17.681 8.52716L15.7085 6.88337L10.1864 13.5086L7.32899 10.65L5.51312 12.4658L9.36575 16.3185L10.3597 17.3124L11.26 16.2324Z" fill="#00934C" />
                                    </svg>
                                </span>
                                {listDataInDetails?.company ? <div className='_fstLetter p-2'> {(listDataInDetails?.company[0]).toUpperCase()} </div> : ''}

                                {/* <img src={bidderImg} alt="" /> */}

                                {(formValue?.rating && formValue?.rating > 0) ?
                                    <div className='_ratingBx'>
                                        <FaStar />
                                        <span> {formValue?.rating} </span>
                                    </div> :
                                    <div className='_ratingBx new_starCls'>
                                        <span> New </span>
                                    </div>
                                }

                                {/* <div className='_ratingBx'>
                                    <FaStar />
                                    {formValue?.rating > 0 ? <span> {formValue?.rating} </span> : <></>}
                                </div> */}
                            </div>
                            <div className='w-100'>
                                <p className='tl_txt'> {listDataInDetails?.company ? utils.firstLetterCapital(listDataInDetails?.company) : '-'} </p>
                                <p className='tsm_txt'>{listDataInDetails?.city?.label}, {listDataInDetails?.state?.label}</p>
                            </div>
                        </div>

                        <div>
                            {
                                activeTabValue === 1 && (
                                    <button className='_removRequestBtn' onClick={(e) => removeConnection(listDataInDetails)}>{loadingRm ? 'Loading...' : 'Remove Connection'}</button>
                                )
                            }
                            {
                                activeTabValue === 2 && (
                                    <div className='twiceDiv'><button className='_acceptPrf_btn p-2' onClick={(e) => acceptRequest(listDataInDetails)}> Accept Request </button></div>
                                )
                            }
                            {
                                activeTabValue === 3 && (
                                    <div className='twiceDiv'><button className='_rejctPrf_btn p-2' onClick={(e) => withdrawalRequest(listDataInDetails)}> Withdraw Request </button></div>
                                )
                            }
                            {
                                activeTabValue === 4 && (
                                    <button className='_sndRequestBtn p-2' onClick={(e) => handleEventOnclick(e)}> Connect </button>
                                )
                            }
                        </div>
                    </div>

                    <div className='_cntDetail_fx' style={{ marginTop: "30px" }}>
                        <div className='_cntInner_bxs'>
                            <span>{listDataInDetails?.active_load_count}</span>
                            <p>Views</p>
                        </div>
                        <div className='_cntInner_bxs'>
                            <span>{listDataInDetails?.connection?.connected?.length > 0 ? listDataInDetails?.connection?.connected?.length : 0}</span>
                            <p>Connection</p>
                        </div>
                        <div className='_cntInner_bxs'>
                            <span>{listDataInDetails?.user_count ? listDataInDetails?.user_count : 0}</span>
                            <p>Users</p>
                        </div>
                        <div className='_cntInner_bxs'>
                            <span>{listDataInDetails?.load_count ? listDataInDetails?.load_count : 0}</span>
                            <p>Loads</p>
                        </div>
                        <div className='_cntInner_bxs'>
                            <span>{listDataInDetails?.active_load_count ? listDataInDetails?.active_load_count : 0}</span>
                            <p>Active Loads</p>
                        </div>
                    </div>

                    <div className='addPr_flBx'>
                        <ul className="nav nav-tabs" id="myConnectionTab" role="tablist" style={{ marginTop: '26px' }}>
                            {constant?.connectionRatingsTabs?.map((item, indx) => (
                                <li
                                    className="nav-item"
                                    role="presentation"
                                    key={indx + item?.value}
                                >
                                    <button
                                        className={
                                            "nav-link " + (activeTab === item?.id ? "active" : "")
                                        }
                                        type="button"
                                        aria-selected={activeTab === item?.id}
                                        onClick={(e) => setActiveTab(item.id)}
                                    >
                                        <span className='_tbTitle'>{item?.title} {item?.value === 'reviews' ? ratingsList?.length : ''}</span>
                                    </button>
                                </li>
                            ))}
                        </ul>
                        {activeTab === 1 && (
                            <>
                                <div className='_dtOtr_txtBx' style={{ marginTop: '20px' }}>

                                    {activeTabValue === 1 && <div>
                                        <h4>Rating</h4>
                                        <div className='_starGrp_bx' onClick={() => handleAddReview()}>
                                            <StartRatings
                                                defaultValue={formValue?.rating}
                                                onChangeFn={(e) => ratingChanged(e)}
                                            />
                                        </div>

                                    </div>}

                                    <div>
                                        <h4>About us</h4>
                                        <p> {listDataInDetails?.about_us ? listDataInDetails?.about_us : '-'} </p>
                                        <span className='_joinDa_yr'>Joined {createAtDate} </span>
                                    </div>

                                    <div>
                                        <h4>Operating states ({listDataInDetails?.operating_state?.length})</h4>

                                        {/* <div className='_derCr_blx'>
                                            <span><div className='__insRt'>Goa</div></span>
                                            <span><div className='__insRt'>Madhya Pradesh</div></span>
                                            <span><div className='__insRt'>Andhra Pradesh</div></span>
                                            <span><div className='__insRt'>Delhi</div></span>
                                            <span><div className='__insRt'>Punjab</div></span>
                                            <a href="#">+9 more</a>
                                        </div> */}
                                    </div>

                                    <div>
                                        <h4>Operating routes ({listDataInDetails?.operating_route?.length})</h4>

                                        {/* <div className='_derCr_blx'>
                                            <span><div className='__insRt'>Indore &gt; Delhi</div></span>
                                            <span><div className='__insRt'>Delhi &gt; Indore</div></span>
                                            <span><div className='__insRt'>Bhopal &gt; Indore</div></span>
                                            <a href="#">+9 more</a>
                                        </div> */}
                                    </div>
                                </div>
                            </>
                        )}

                        {activeTab === 2 && <>
                            <div className='_rvBx_lstMain'>
                                {
                                    ratingsList?.length > 0 ? ratingsList?.map((el, ind) => {
                                        return (
                                            <RatingList key={ind + el?.company_id?.company} el={el} />
                                        )
                                    }) :
                                        <div className='_rvBx_lst'>
                                            <span className='no_dataFount'> No Data Found </span>
                                        </div>
                                }
                            </div>
                            <div className='paginationFt_avt mt-3'>
                                {renderPagination()}
                            </div>
                        </>}
                    </div>
                </div>
            </CommonModal>
            {/* <div className='offBack_drop'></div> */}

            {showWriteReview
                && (
                    <AddReviewModal {...reviewModal} />
                )
            }
        </>
    )
}

export default ViewProfileModal