import {
  getAllDriver,
  getAllRoutes,
  getAllSupplier,
  getAllVehicleNumber,
  getDashbordData,
  getLastTrip,
  handleKeyDown,
} from "_services";
import { getConsignorListData } from "_services/accountsServices/consignorServices";
import {
  getDataLrDetails,
  getLorryTypeList,
} from "_services/accountsServices/lrServices";
import {
  CommonDatePicker,
  CommonInput,
  CustomSelect,
  Heading,
  MapInput,
} from "components";

import { Images } from "constants";
import images from "constants/images";
import { UserContext } from "context";
import { State } from "country-state-city";
import { addEwayWill, addTrip, getlorryType } from "features";
import { errorSchema, utils } from "helper";
import {
  AddEditLrModal,
  AddEditOwnDriver,
  AddEditVehicleModal,
  AddLrModal,
  AddRouteModal,
  ConfirmModalFor,
  EndTripModal,
  LrDetailModal,
  LrFreightModal,
  MakeTruckAvailableModal,
  SelectLrModal,
  VehicleTypeModal,
} from "modals";
import AddEditConsignor from "modals/AddEditConsignor/AddEditConsignor";
import AddEditSupplier from "modals/AddEditSupplier";
import moment from "moment";
import Loading from "pages/Loading";
import React, { useContext, useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import StorageService from "utils/StorageServices/StorageServices";

const CreatTrip = () => {
  const {
    masterData,
    cities,
    lrDetialsFrom,
    setLrDetialsFrom,
    dispatchIndent,
  } = useContext(UserContext);

  function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }
  // let startdate = new Date();
  // let enddate = addDays(startdate, 2);

  const intialForm = {
    company_id: StorageService.getCompanyId(),
    branch_id: "",
    dispatch_date: new Date(),
    driver_id: "",
    supplier_id: "",
    vehicle_type_id: "",
    lorry_type_id: "",
    vehicle_id: "",
    start_kms: "",
    end_kms: 0,
    route_name: "",
    from_location: [],
    to_location: [],
  };

  const initiFrom = {
    from_city: "",
    from_state: "",
    lat: "",
    long: "",
  };

  const initiTo = {
    to_city: "",
    to_state: "",
    lat: "",
    long: "",
  };

  const ewayinit = {
    eway_bill_number: "",
    start_date: new Date(),
    end_date: new Date(),
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [formValue, setFormValue] = useState(intialForm);
  const {
    loading: ewayLoading,
    error,
    data,
  } = useSelector((state) => state.trip);
  const [ewayForm, setEwayForm] = useState(ewayinit);
  const [formLabelValue, setFormLabelValue] = useState(intialForm);
  const [formError, setFormError] = useState("");
  const [show, setShow] = useState(false);
  const [vehicleTypeshow, setVehicleTypeShow] = useState(false);
  const [showCreatRoute, setShowCreatRoute] = useState(false);
  const [showSelectLr, setShowSelectLr] = useState(false);
  const [fromLoction, setFromLoction] = useState([initiFrom]);
  const [toLoction, setToLoction] = useState([initiTo]);
  const [fromlocationValue, setFromLocatonValue] = useState([""]);
  const [tolocationValue, setToLocatonValue] = useState([""]);
  const [divCount, setDivCount] = useState(1);
  const [tripTable, setTripTable] = useState([]);
  const [listOfLr, setListOfLr] = useState([{ list: [] }]);
  const [indexofTable, setIndexofTable] = useState(0);
  const [branchList, setBranchList] = useState([]);
  const [consignorList, setConsignorList] = useState([]);
  const [routeList, setRouteList] = useState([]);
  const [driverList, setDriverList] = useState([]);
  const [supplierList, setSupplierList] = useState([]);
  const [vehicleNumbList, setVehicleNumbList] = useState([]);
  const [hasMoreVehicle, setHasMoreVehicle] = useState(true);
  const [page, setPage] = useState(1);
  const [ownerShip, setOwnerShip] = useState("");
  const [callbackDataRoute, setcallbackDataRoute] = useState();
  const [vehicleTypeLabel, setVehicleTypeLabel] = useState({
    label: "",
    value: "",
  });
  const [selectedLr, setSelectedLr] = useState([{ list: [] }]);
  const [allFrightLr, setAllFrightLr] = useState([]);
  const [separateLr, setSeprateLr] = useState([]);
  const [loading, setLoading] = useState(false);
  const [confirmModalShow, setConfirmModalShow] = useState(false);
  const [ewaybillShow, setEwaybillShow] = useState(false);
  const [ewayBillId, setEwayBillId] = useState("");
  const [LrDetele, setLrDetele] = useState({
    item: "",
    outerIndex: "",
    innerIndex: "",
    title: "",
  });
  const [lorryType, setLorryType] = useState([]);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const [showOffcanvasSupplier, setShowOffcanvasSupplier] = useState(false);
  const [showOffcanvasDriver, setShowOffcanvasDriver] = useState(false);
  const [showLrDetail, setShowLrDetail] = useState(false);
  const [showAddLR, setShowAddLR] = useState(false);
  const [selectParty, setSelectParty] = useState(false);
  const [showPrefield, setShowingPrefield] = useState(false);
  const [isAddConsignor, setIsAddConsignor] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [showEndTrip, setShowEndTrip] = useState(false);
  const [showAvailableTruck, setShowAvailableTruck] = useState(false);
  const [lastTripDetails, setLastTripDetails] = useState();
  const [lastReading, setLastReading] = useState("");
  const [vehicleStatus, setVehicleStatus] = useState();
  const hiddenInputRef = useRef(null);
  const [showBackModal, setShowBackModal] = useState(false);
  const [listOfFreight, setListOfFreight] = useState([]);
  const [isSupplier, setIsSupplier] = useState(false);
  const [isDriver, setIsDriver] = useState(false);
  const [showOffcanvasOfSupplier, setShowOffcanvasOfSupplier] = useState(false);
  const [showOffcanvasOfDriver, setShowOffcanvasOfDriver] = useState(false);
  const [showOffcanvasOfVehical, setShowOffcanvasOfVehical] = useState(false);

  useEffect(() => {
    const handlePopState = (event) => {
      event.preventDefault();
      setShowBackModal(true);
      window.history.pushState({ modalOpened: false }, "");
    };

    window.addEventListener("popstate", handlePopState);
    window.history.pushState({ modalOpened: false }, "");

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  const submitLeaveModalProps = () => {
    navigate("/trip/addtrip");
  };

  const backModalProps = {
    show: showBackModal,
    closeModal: (e) => setShowBackModal(false),
    title: "Leave This Page",
    submitTitle: "Confirm",
    onSubmit: submitLeaveModalProps,
    confirmModal: "confirmModal",
  };

  useEffect(() => {
    fetchInitialData();
    fetchVehicleList();
  }, []);

  useEffect(() => {
    if (callbackDataRoute) {
      callbackDataRoute?.from_location?.map((user) => addFromInput());
      callbackDataRoute?.to_location?.map((user) => {
        setToLoction([...toLoction, initiTo]);
        setToLocatonValue([...tolocationValue, ""]);
        setListOfLr([...listOfLr, { list: [] }]);
        setSelectedLr([...selectedLr, { list: [] }]);
        setDivCount(callbackDataRoute?.to_location.length);
      });

      setFromLocatonValue(
        callbackDataRoute?.from_location?.map((user) => ({
          label: `${user.from_city}, ${user?.from_state}`,
          value: `${user.from_city}, ${user?.from_state}`,
        }))
      );
      setToLocatonValue(
        callbackDataRoute?.to_location?.map((user) => ({
          label: `${user.to_city}, ${user?.to_state}`,
          value: `${user.to_city}, ${user?.to_state}`,
        }))
      );
      setFromLoction(callbackDataRoute?.from_location);
      setToLoction(callbackDataRoute?.to_location);

      checkVal(callbackDataRoute);

      setcallbackDataRoute("");
    }
  }, [callbackDataRoute]);

  const checkVal = async () => {
    if (!!formError) {
      const validationResult = await utils.checkFormError(
        {
          ...formValue,
          toLoction: callbackDataRoute?.to_location,
          fromLoction: callbackDataRoute?.from_location,
        },
        errorSchema.createTripSchima
      );

      if (validationResult === true) {
        setFormError("");
      } else {
        setFormError(validationResult);
      }
    }
  };

  useEffect(() => {
    if (LrDetele.item) {
      setConfirmModalShow(true);
    }
  }, [LrDetele]);

  useEffect(() => {
    if (listOfLr) {
      const combinedList = toLoction.map((location, index) => {
        const list = listOfLr[index]?.list || [];
        const ids = list.map((item) => item._id);

        return {
          ...location,
          lr_list: ids,
        };
      });
      setToLoction(combinedList);
    }
  }, [listOfLr]);

  const planFromLoc = (loc) => {
    return loc?.map((item) => item.from_city).join(" + ");
  };

  const planToLoc = (loc) => {
    return loc?.map((item) => item.to_city).join(" + ");
  };

  useEffect(() => {
    if (!dispatchIndent) return;

    const from = planFromLoc(dispatchIndent?.from_location);
    const to = planToLoc(dispatchIndent?.to_location);

    if (dispatchIndent?.to_location?.length) {
      setDivCount(dispatchIndent?.to_location.length);
      dispatchIndent?.to_location.forEach(() => addTable());
    }

    if (from && to) {
      setFormValue((prevFormValue) => ({
        ...prevFormValue,
        branch_id: dispatchIndent?.branch_id?._id,
        route_name: `${from}-${to}`,
        vehicle_type_id: dispatchIndent?.vehicle_type_id?._id,
        vehicle_id: dispatchIndent?.vehicle_id?._id
          ? dispatchIndent?.vehicle_id?._id
          : "",
        lorry_type_id: dispatchIndent?.lorry_type_id?._id,
        supplier_id: dispatchIndent?.vehicle_id?.supplier_id
          ? dispatchIndent?.vehicle_id?.supplier_id?._id
          : "",
        driver_id: dispatchIndent?.vehicle_id?.driver_id
          ? dispatchIndent?.vehicle_id?.driver_id?._id
          : "",
      }));

      setOwnerShip(dispatchIndent?.vehicle_id?.vehicle_ownership);

      setFormLabelValue((prevFormLabelValue) => ({
        ...prevFormLabelValue,
        route_name: { label: `${from}-${to}`, value: `${from}-${to}` },
        vehicle_id: {
          label: dispatchIndent?.vehicle_id?.vehicle_no
            ? `${dispatchIndent?.vehicle_id?.vehicle_no} | ${
                masterData?.vehicle_ownership[
                  dispatchIndent?.vehicle_id?.vehicle_ownership
                ]
              }`
            : "",
          value: dispatchIndent?.vehicle_id?._id
            ? dispatchIndent?.vehicle_id?._id
            : "",
        },
        lorry_type_id: {
          label: dispatchIndent?.lorry_type_id?.lorry_type,
          value: dispatchIndent?.lorry_type_id?._id,
        },
        vehicle_type_id: {
          label: dispatchIndent?.vehicle_type_id?.name,
          value: dispatchIndent?.vehicle_type_id?._id,
        },

        supplier_id: dispatchIndent?.vehicle_id?.supplier_id?._id
          ? {
              label: dispatchIndent?.vehicle_id?.supplier_id?.name,
              value: dispatchIndent?.vehicle_id?.supplier_id?._id,
            }
          : { label: "", value: "" },
        driver_id: dispatchIndent?.vehicle_id?.driver_id?._id
          ? {
              label: dispatchIndent?.vehicle_id?.driver_id?.name,
              value: dispatchIndent?.vehicle_id?.driver_id?._id,
            }
          : { label: "", value: "" },
      }));

      setVehicleTypeLabel({
        label: `${dispatchIndent?.lorry_type_id?.lorry_type} > ${dispatchIndent?.vehicle_type_id?.name}`,
        value: "",
      });

      setFromLoction(
        dispatchIndent?.from_location.map(({ _id, ...rest }) => rest)
      );
      setToLoction(
        dispatchIndent?.to_location?.map(
          ({ _id, lr_list, po_list, ...rest }) => rest
        )
      );

      const transformedLocations = dispatchIndent?.to_location.map(
        (location) => ({
          list: location.lr_list,
        })
      );

      setListOfLr(transformedLocations);
      setSelectedLr(transformedLocations);
    }
  }, [dispatchIndent]);

  const fetchVehicleList = async (nextPage = 1) => {
    try {
      const vehicleRes = await getVehicleList(nextPage, 10);

      if (vehicleRes?.responseCode === 200) {
        const newVehicles = vehicleRes.data?.vehicle_list.map((i) => ({
          ...i,
          label: i.vehicle_no,
          value: i._id,
        }));

        if (nextPage == "add") {
          setVehicleNumbList(newVehicles);
        } else {
          setVehicleNumbList((prevList) => [...prevList, ...newVehicles]);
        }

        if (newVehicles.length < 10) {
          setHasMoreVehicle(false);
        } else {
          setHasMoreVehicle(true);
          setPage(nextPage + 1);
        }
      } else {
        toast.error(vehicleRes?.responseMessage);
      }
    } catch (error) {
      console.error("Error fetching vehicle list:", error);
      toast.error("Error fetching vehicle list.");
    }
  };

  const fetchInitialData = async () => {
    try {
      // Run all API calls in parallel
      const [
        branchRes,
        routeRes,
        // vehicleRes,
        driverRes,
        supplierRes,
        lorryTypeRes,
        consignorRes,
      ] = await Promise.all([
        getBranchList(),
        getRouteList(),
        // getVehicleList(),
        getDriverList(),
        getSupplierList(),
        getDataLorryTypeList(),
        getDataConsignorList(),
      ]);

      if (branchRes?.responseCode === 200) {
        handleListResponse(
          branchRes.data?.branch_list,
          "branch",
          setBranchList
        );
      } else {
        toast.error(branchRes?.responseMessage);
      }

      if (routeRes?.responseCode === 200) {
        
        handleListResponse(routeRes.data?.route, "route_name", setRouteList);
      } else {
        toast.error(routeRes?.responseMessage);
      }

      // if (vehicleRes?.responseCode === 200) {
      //   setVehicleNumbList(
      //     vehicleRes.data?.vehicle_list.map((i) => ({
      //       ...i,
      //       label: i.vehicle_no,
      //       value: i._id,
      //     }))
      //   );
      // } else {
      //   toast.error(vehicleRes?.responseMessage);
      // }

      if (driverRes?.responseCode === 200) {
        setDriverList(
          driverRes.data?.driver_list.map((i) => ({
            ...i,
            label: i.name,
            value: i._id,
          }))
        );
      } else {
        toast.error(driverRes?.responseMessage);
      }

      if (supplierRes?.responseCode === 200) {
        setSupplierList(
          supplierRes.data?.supplier_list.map((i) => ({
            ...i,
            label: i.name,
            value: i._id,
          }))
        );
      } else {
        toast.error(supplierRes?.responseMessage);
      }

      if (lorryTypeRes?.responseCode === 200) {
        setLorryType(lorryTypeRes.data?.lorry_type);
      }

      if (consignorRes?.responseCode === 200) {
        handleListResponse(
          consignorRes.data?.consignor_list,
          "name",
          setConsignorList
        );
      }
    } catch (error) {
      console.error("Error fetching initial data:", error);
      toast.error("Error fetching initial data.");
    }
  };

  const handleListResponse = (listData, labelKey, setter) => {
    const list = listData.map((i) => ({
      ...i,
      label: i[labelKey],
      value: i._id,
    }));

    setter(list);

    if (list.length === 1 && !dispatchIndent) {
      setFormValue((prev) => ({
        ...prev,
        [labelKey == "branch" ? "branch_id" : labelKey]:
          labelKey == "route_name" ? list[0]?.label : list[0].value,
      }));
      setFormLabelValue((prev) => ({ ...prev, [labelKey]:labelKey == "route_name"?"": list[0] }));
    }
  };

  const getBranchList = () => {
    return getDashbordData({
      company_id: StorageService.getCompanyId(),
      select_company_id: 1,
    });
  };

  const getRouteList = () => {
    return getAllRoutes({
      company_id: StorageService.getCompanyId(),
    });
  };

  const getVehicleList = (page = 1, limit = 10) => {
    return getAllVehicleNumber({
      company_id: StorageService.getCompanyId(),
      offset: page,
      limit,
    });
  };

  const getDriverList = () => {
    return getAllDriver({
      company_id: StorageService.getCompanyId(),
    });
  };

  const getSupplierList = () => {
    return getAllSupplier({
      company_id: StorageService.getCompanyId(),
    });
  };

  const getDataLorryTypeList = () => {
    return getLorryTypeList();
  };

  const getDataConsignorList = () => {
    const object = {
      company_id: StorageService.getCompanyId(),
      limit: 100,
      offset: 1,
      search: "",
      status: 1,
    };
    return getConsignorListData(object);
  };

  const getLastTripHandle = async (data) => {
    const res = await getLastTrip({
      company_id: StorageService.getCompanyId(),
      vehicle_id: data?._id,
    });
    if (res?.responseCode === 200) {
      setLastTripDetails(res.data?.trip);
      if (vehicleStatus?.vehicle_status == 1) {
        if (res.data?.trip?.end_kms) {
          setFormValue({ ...formValue, start_kms: res.data?.trip?.end_kms });
          setLastReading(res.data?.trip?.end_kms);
        } else {
          setFormValue({ ...formValue, start_kms: "" });
          setLastReading("");
        }
      }
      // setShowEndTrip(res.data?.trip);
    } else {
      toast.error(res?.responseMessage);
    }
  };

  const callbackSelectRoute = (data) => {
    setcallbackDataRoute(data);
    setFormValue({ ...formValue, route_name: data?.route_name });
    setFormLabelValue({
      ...formLabelValue,
      route_name: { ...data, label: data?.route_name },
    });
    getRouteList();
  };

  const callbackSelectLr = (data) => {
    const newList = [...listOfLr];
    const newSelect = [...selectedLr];
    newList[indexofTable]?.list.push(data);
    newSelect[indexofTable]?.list.push(data);
    setListOfLr(newList);
    setSelectedLr(newSelect);
  };

  const addDiv = () => {
    setDivCount((prevCount) => prevCount + 1);
  };

  // const addTable = () => {
  //   setTripTable([...tripTable, ""]);
  // };

  const addTable = () => {
    setTripTable((prevTripTable) => [...prevTripTable, ""]);
  };

  const [flag, setFlag] = useState("");

  const addFromInput = (e) => {
    // e.preventDefault();

    setFromLoction((preState) => {
      return [...preState, initiFrom];
    });

    setFromLocatonValue((preState) => {
      return [...preState, ""];
    });
  };

  const removeFromInput = (index) => {
    const newFromIput = [...fromLoction];
    const NewInputLocation = [...fromlocationValue];

    newFromIput.splice(index, 1);
    NewInputLocation.splice(index, 1);

    setFromLoction(newFromIput);
    setFromLocatonValue(NewInputLocation);
  };

  const removeToInput = (index) => {
    const newFromIput = [...toLoction];

    const NewInputLocation = [...tolocationValue];

    newFromIput.splice(index, 1);
    NewInputLocation.splice(index, 1);

    setToLoction(newFromIput);
    setToLocatonValue(NewInputLocation);
  };

  const addToInput = () => {
    setToLoction([...toLoction, initiTo]);
    setToLocatonValue([...tolocationValue, ""]);
    addDiv();
    setListOfLr([...listOfLr, { list: [] }]);
    setSelectedLr([...selectedLr, { list: [] }]);
  };

  useEffect(() => {
    if (lrDetialsFrom) {
      const newFromvalue = [...fromlocationValue];
      const newFrom = [...fromLoction];
      const newTovalue = [...tolocationValue];
      const newTo = [...toLoction];
      newFromvalue[0] = {
        label: `${lrDetialsFrom.from_location}, ${lrDetialsFrom?.from_state}`,
        value: `${lrDetialsFrom.from_location}, ${lrDetialsFrom?.from_state}`,
      };

      newFrom[0] = {
        from_city: `${lrDetialsFrom.from_location}`,
        from_state: `${lrDetialsFrom.from_state}`,
      };

      newTovalue[0] = {
        label: `${lrDetialsFrom.to_location}, ${lrDetialsFrom?.to_state}`,
        value: `${lrDetialsFrom.to_location}, ${lrDetialsFrom?.to_state}`,
      };

      newTo[0] = {
        to_city: `${lrDetialsFrom.to_location}`,
        to_state: `${lrDetialsFrom.to_state}`,
      };
      setFromLocatonValue(newFromvalue);
      setFromLoction(newFrom);
      setToLocatonValue(newTovalue);
      setToLoction(newTo);

      addTable();
      const newList = [...listOfLr];
      const newSelect = [...selectedLr];
      newList[0]?.list.push(lrDetialsFrom);
      newSelect[0]?.list.push(lrDetialsFrom);

      setListOfLr(newList);
      setSelectedLr(newSelect);
      setLrDetialsFrom("");
    }
  }, [lrDetialsFrom]);

  let firstCreateRouteModal = {
    isOpen: showCreatRoute,
    closeModal: (e) => setShowCreatRoute(false),
    headerPart: true,
    footerPart: true,
    modalMainClass: "_dashCommon_Modal _smModal",
    modalTitle: "Create Route",
    submitTitle: "Create Route",
    callbackSelectRoute: callbackSelectRoute,
  };

  let selectLrModalProps = {
    isOpen: showSelectLr,
    closeModal: (e) => setShowSelectLr(false),
    onSubmit: (e) => setShowSelectLr(false),
    headerPart: false,
    footerPart: true,
    modalMainClass: " _dashCommon_Modal _xxlModal",
    modalTitle: "Select LR",
    submitTitle: "null",
    indexofTable: indexofTable,
    listOfLr: listOfLr,
    callbackSelectLr: callbackSelectLr,
    selectedLr: selectedLr,
    consignorList: consignorList,
    branchList: branchList,
    setSelectParty: setSelectParty,
  };

  const handleInputChange = async (value, index, label, tableIndex) => {
    setIndexofTable(tableIndex);

    if (label === "From City") {
      const stateDetails = State.getStateByCodeAndCountry(
        value?.stateCode,
        value?.countryCode
      );
      const NewFromLocation = [...fromLoction];
      const NewInputLocation = [...fromlocationValue];
      NewFromLocation[index].from_city = value.name;
      NewFromLocation[index].from_state = stateDetails.name;
      NewFromLocation[index].lat = value?.latitude;
      NewFromLocation[index].long = value?.longitude;
      NewInputLocation[index] = {
        label: `${value.name}, ${stateDetails.name}`,
        value: `${value.name}, ${stateDetails.name}`,
      };
      setFromLocatonValue(NewInputLocation);
      setFromLoction(NewFromLocation);

      if (!!formError) {
        const validationResult = await utils.checkFormError(
          { ...formValue, toLoction: toLoction, fromLoction: NewFromLocation },
          errorSchema.createTripSchima
        );

        if (validationResult === true) {
          setFormError("");
        } else {
          setFormError(validationResult);
        }
      }
    }

    if (label === "To City") {
      const stateDetails = State.getStateByCodeAndCountry(
        value?.stateCode,
        value?.countryCode
      );
      const NewToLocation = [...toLoction];
      const NewInputLocation = [...tolocationValue];
      NewToLocation[index].to_city = value.name;
      NewToLocation[index].to_state = stateDetails.name;
      NewToLocation[index].lat = value?.latitude;
      NewToLocation[index].long = value?.longitude;
      NewInputLocation[index] = {
        label: `${value.name}, ${stateDetails.name}`,
        value: `${value.name}, ${stateDetails.name}`,
      };

      setToLocatonValue(NewInputLocation);
      setToLoction(NewToLocation);

      if (!!formError) {
        const validationResult = await utils.checkFormError(
          { ...formValue, toLoction: NewToLocation, fromLoction: fromLoction },
          errorSchema.createTripSchima
        );

        if (validationResult === true) {
          setFormError("");
        } else {
          setFormError(validationResult);
        }
      }
    }
  };

  const removeDiv = (index) => {
    setDivCount((prevCount) => prevCount - 1);

    setFlag("delete");
    let newselectedLr = [...listOfLr];
    let newselected = [...selectedLr];
    // const newfromlr = [...fromLoction];
    // if(newfromlr?.length!==1){
    //   newfromlr.splice(index, 1);
    // }

    const newTolr = [...toLoction];
    newTolr[index].lr_list = [];
    newselectedLr.splice(index, 1);
    newselected.splice(index, 1);
    setListOfLr(newselectedLr);
    setSelectedLr(newselected);
    setToLoction(newTolr);
    // setFromLoction(newfromlr);
    removeToInput(index);
  };

  const handleOnChange = async (name, value) => {
    const stateObj = {
      ...formValue,
      [name]:
        name === "branch_id" ||
        name === "driver_id" ||
        // name === "vehicle_id" ||
        name === "supplier_id"
          ? value.value
          : name === "route_name"
          ? value.label
          : value,
    };

    if (name === "vehicle_id") {
      stateObj["lorry_type_id"] = value?.lorry_type_id?._id;
      stateObj["vehicle_type_id"] = value?.vehicle_type_id_1?._id;
      stateObj["supplier_id"] = value?.supplier_id?._id
        ? value?.supplier_id?._id
        : "";
      stateObj["driver_id"] = value?.driver_id?._id
        ? value?.driver_id?._id
        : "";
      stateObj["vehicle_id"] = value?._id;
    }

    setFormValue(stateObj);

    if (!!formError) {
      const validationResult = await utils.checkFormError(
        {
          ...stateObj,
          toLoction: toLoction,
          fromLoction: fromLoction,
          ownership: ownerShip,
        },
        errorSchema.createTripSchima
      );

      if (validationResult === true) {
        setFormError("");
      } else {
        setFormError(validationResult);
      }
    }
  };

  const handleChange = async (name, value) => {
    const stateObj = {
      ...ewayForm,
      [name]: value,
    };

    setEwayForm(stateObj);
    if (!!formError) {
      const validationResult = await utils.checkFormError(
        stateObj,
        errorSchema.ewayBillSchima
      );

      if (validationResult === true) {
        setFormError("");
      } else {
        setFormError(validationResult);
      }
    }
  };

  const addButtonAction = (data) => {
    setShowCreatRoute(true);
  };

  const vehicleStatusCheck = (data) => {
    // setLastTripDetails("");
    if (data?.vehicle_status == 1) {
      vehicleHandleChange(data);
      setVehicleStatus(data);
    } else if (data?.vehicle_status == 2) {
      setVehicleStatus(data);
      setShowEndTrip(true);
    } else {
      setShowAvailableTruck(true);
      setVehicleStatus(data);
    }
  };

  useEffect(() => {
    if (vehicleStatus) {
      getLastTripHandle(vehicleStatus);
    }
  }, [vehicleStatus]);

  useEffect(() => {
    if (formValue?.vehicle_id) {
      getLastTripHandle({ _id: formValue?.vehicle_id });
    }
  }, [formValue?.vehicle_id]);

  const vehicleHandleChange = (data) => {
    setOwnerShip(data?.vehicle_ownership);

    setFormLabelValue({
      ...formLabelValue,
      vehicle_id: {
        ...data,
        label: `${data.vehicle_no} | ${
          masterData?.vehicle_ownership[data?.vehicle_ownership]
        }`,
      },
      lorry_type_id: {
        label: data?.lorry_type_id?.lorry_type,
        value: data?.lorry_type_id?._id,
      },
      vehicle_type_id: {
        label: data?.vehicle_type_id_1?.name,
        value: data?.vehicle_type_id_1?._id,
      },
      supplier_id: data?.supplier_id?._id
        ? {
            label: data?.supplier_id?.name,
            value: data?.supplier_id?._id,
          }
        : { label: "", value: "" },
      driver_id: data?.driver_id?._id
        ? { label: data?.driver_id?.name, value: data?.driver_id?._id }
        : { label: "", value: "" },
    });

    // setFormValue({
    //   ...formValue,
    //   lorry_type_id: data?.lorry_type_id?._id,
    //   vehicle_type_id: data?.vehicle_type_id_1?._id,
    //   supplier_id: data?.supplier_id?._id ? data?.supplier_id?._id : "",
    //   driver_id: data?.driver_id?._id ? data?.driver_id?._id : "",
    //   vehicle_id: data?._id,
    // });

    handleOnChange("vehicle_id", data);

    setVehicleTypeLabel({
      label: `${data?.lorry_type_id?.lorry_type} > ${data?.vehicle_type_id_1?.name}`,
      value: "",
    });
  };

  const handleDelete = (item, index, indexTable) => {
    let newLr = [...listOfLr];
    let newselectedLr = [...selectedLr];
    const newTolr = [...toLoction];

    newTolr[LrDetele?.outerIndex]?.lr_list?.splice(LrDetele?.innerIndex, 1);
    newLr[LrDetele?.outerIndex]?.list?.splice(LrDetele?.innerIndex, 1);
    newselectedLr[LrDetele?.outerIndex]?.list?.splice(LrDetele?.innerIndex, 1);

    setListOfLr(newLr);
    setToLoction(newTolr);
    setSelectedLr(newselectedLr);
    toast.success("LR Deleted");
    setConfirmModalShow(false);
    setLrDetele("");
  };

  //////freight modal

  useEffect(() => {
    if (listOfLr) {
      const newarray = listOfLr.map((i) => [...i?.list]);
      const mergedArray1 = newarray.flat();
      setAllFrightLr(mergedArray1);
    }
  }, [listOfLr]);

  useEffect(() => {
    const updatedData = allFrightLr.map((item) => {
      return {
        ...item,
        consignor_Newid: item?.consignor_id?._id,
        freight_type: item?.lr_freight?.freight_type
          ? item?.lr_freight?.freight_type
          : 4,
        qty: item?.lr_freight?.qty ? item?.lr_freight?.qty : 0,
        rate: item?.lr_freight?.rate ? item?.lr_freight?.rate : 0,
        amount: item?.lr_freight?.total_freight
          ? item?.lr_freight?.total_freight
          : 0,
        lr_id: item?._id,
        selected: true,
      };
    });

    if (updatedData) setSeprateLr(separateSameValues(updatedData));
  }, [allFrightLr]);

  const separateSameValues = (arr) => {
    const grouped = arr?.reduce((acc, val) => {
      if (!acc[val?.consignor_id?._id]) {
        acc[val?.consignor_id?._id] = [];
      }
      acc[val?.consignor_id?._id].push(val);
      return acc;
    }, {});

    return Object.values(grouped);
  };

  const handleModalInputChange = (value, index, label, indexlr, field) => {
    setSeprateLr((prevData) =>
      prevData.map((item, index1) =>
        index1 === index
          ? item?.map((detail, index2) =>
              index2 === indexlr
                ? field === "freight_type" && value.value == 4
                  ? { ...detail, qty: 0, rate: 0, amount: 0, freight_type: 4 }
                  : { ...detail, [field]: value.value }
                : detail
            )
          : item
      )
    );
  };

  const onSubmit = async () => {
    let filterlist = [];
    setLoading(true);
    //   const filteredData = listOfFreight.map((consignor) => {
    //     const filteredLrList = consignor.lr_list.filter((lr) => lr.contract_rates.selected);

    //     return {
    //       ...consignor,
    //       lr_list: filteredLrList,
    //     };
    //   }).filter((consignor) => consignor.lr_list.length > 0);

    //   const transformedData = filteredData.map(consignor => {
    //     return consignor.lr_list.map(lr => ({
    //         consignor_id: consignor.consignor_id,
    //         freight_type: lr.contract_rates.rate_type,
    //         qty: lr.contract_rates.qty,
    //         rate: lr.contract_rates.rate,
    //         amount: lr.contract_rates.qty && lr.contract_rates.rate ?lr.contract_rates.qty * lr.contract_rates.rate: lr.contract_rates.amount,
    //         lr_id: lr._id
    //     }));
    // }).flat();

    const transformedData = listOfFreight
      .map((consignor) => ({
        ...consignor,
        lr_list: consignor.lr_list.filter((lr) =>
          consignor?.contract?.chargeable_on === 2
            ? lr.contract_rates.selected
            : lr.lr_freight.selected
        ),
      }))
      .filter((consignor) => consignor.lr_list.length > 0)
      .flatMap((consignor) =>
        consignor.lr_list.map((lr) => {
          const rateType =
            consignor?.contract?.chargeable_on === 2
              ? lr.contract_rates.freight_type
              : lr.lr_freight.freight_type;
          const qty =
            consignor?.contract?.chargeable_on === 2
              ? lr.contract_rates.qty == 0 || lr.contract_rates.qty == null
                ? 0
                : lr.contract_rates.qty
              : lr.lr_freight.qty == 0 || lr.lr_freight.qty == null
              ? 0
              : lr.lr_freight.qty;
          const rate =
            consignor?.contract?.chargeable_on === 2
              ? lr.contract_rates.rate == 0 || lr.contract_rates.rate == null
                ? 0
                : lr.contract_rates.rate
              : lr.lr_freight.rate == 0 || lr.lr_freight.rate == null
              ? 0
              : lr.lr_freight.rate;
          const amount =
            qty && rate
              ? qty * rate
              : consignor?.contract?.chargeable_on === 2
              ? lr.contract_rates.total_freight == 0 ||
                lr.contract_rates.total_freight == null
                ? 0
                : lr.contract_rates.total_freight
              : lr.lr_freight.total_freight == 0 ||
                lr.lr_freight.total_freight == null
              ? 0
              : lr.lr_freight.total_freight;

          return {
            consignor_id: consignor.consignor_id,
            freight_type: rateType,
            qty,
            rate,
            amount,
            lr_id: lr._id,
          };
        })
      );
    // const filteredArray = separateLr
    //   .flat()
    //   .filter((item) => item.selected)
    //   ?.map((i) => {
    //     return {
    //       consignor_id: i?.consignor_id?._id,
    //       freight_type: i?.freight_type,
    //       qty: i?.qty,
    //       rate: i?.rate,
    //       amount: i?.qty && i?.rate ? +i?.qty * +i?.rate : +i?.amount,
    //       lr_id: i?._id,
    //     };
    //   });

    const filteredArray1 = listOfFreight
      .map((consignor) => ({
        consignor_id: consignor.consignor_id,
        lr_list: consignor.lr_list
          // .filter(lr => lr.contract_rates.selected)
          .map((lr) => lr._id),
      }))
      .filter((consignor) => consignor.lr_list.length > 0);
    // const filteredArray1 = separateLr.map((item, index) => {
    //   return {
    //     consignor_id: item[0]?.consignor_Newid,
    //     lr_list: item?.filter((hobby) => hobby.selected).map((id) => id?._id),
    //   };
    // });
    const allFromLocation = fromLoction.map((i) => {
      return {
        city: i?.from_city,
        state: i?.from_state,
        lat: i?.lat,
        long: i?.long,
      };
    });

    const allToLocation = toLoction.map((i) => {
      return {
        city: i?.to_city,
        state: i?.to_state,
        lat: i?.lat,
        long: i?.long,
      };
    });

    // formValue.from_location = fromLoction;
    // formValue.to_location = toLoction;
    // formValue.lr_freight = transformedData;
    // formValue.consignor_list = filteredArray1;
    // formValue.company_id = StorageService?.getCompanyId();

    // formValue.dispatch_date = moment(startDate).format("DD/MM/YYYY hh:mm A");
    // formValue.dispatch_date = startDate;
    // const { lr_list, ...rest } = formValue;

    // let payload = rest;
    let payload = {
      branch_id: formValue?.branch_id,
      company_id: StorageService?.getCompanyId(),
      consignor_list: filteredArray1,
      dispatch_date: formValue?.dispatch_date,
      driver_id: formValue?.driver_id,
      end_kms: formValue?.end_kms,
      from_location: fromLoction,
      to_location: toLoction,
      lorry_type_id: formValue?.lorry_type_id,
      lr_freight: transformedData,
      route_name: formValue?.route_name,
      start_kms: formValue?.start_kms,
      supplier_id: formValue?.supplier_id,
      vehicle_id: formValue?.vehicle_id,
      vehicle_type_id: formValue?.vehicle_type_id,
      ...(dispatchIndent ? { plan_id: dispatchIndent._id } : {}),
    };

    try {
      const res = await dispatch(addTrip(payload));
      if (res?.payload?.responseCode === 200) {
        setLoading(false);
        toast.success(res?.payload?.responseMessage);

        setShow(false);
        setFormValue({ ...intialForm });
        setSelectedLr([]);
        navigate(`/trip/addtrip/dispatched/${res?.payload?.data?.trip?._id}`);
      } else {
        toast.error(res?.payload?.responseMessage);
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const callBackVehicleType = (value) => {
    setFormValue({
      ...formValue,
      lorry_type_id: value?.activeTab?._id,
      vehicle_type_id: value?.tyre?.value,
    });
    setVehicleTypeLabel({
      label: `${value?.activeTab?.lorry_type} > ${value?.tyre?.label}`,
      value: "",
    });
    setVehicleTypeShow(false);
  };

  const ewayBillHandle = async (i) => {
    try {
      let object = {
        company_id: StorageService?.getCompanyId(),
        lr_id: i?._id,
      };
      const res = await getDataLrDetails(object);

      if (res?.responseCode === 200) {
        setEwayBillId(res?.data?.lr);
        setEwayForm({
          ...ewayForm,
          start_date: res?.data?.lr?.eway_bill_date,
          end_date: res?.data?.lr?.eway_expiry_date,
          eway_bill_number: res?.data?.lr?.eway_bill_number,
        });
        setEwaybillShow(true);
      }
    } catch (error) {
      // throw error;
    }
  };

  const updateEwayBillNumber = (id, newEwayBillNumber) => {
    setListOfLr((prevState) =>
      prevState.map((lr) => {
        if (lr.list && lr.list.length) {
          return {
            ...lr,
            list: lr.list.map((item) => {
              if (item._id == id?._id) {
                return {
                  ...item,
                  eway_bill_number: newEwayBillNumber?.eway_bill_number,
                  eway_bill_date: newEwayBillNumber?.eway_bill_date,
                  eway_expiry_date: newEwayBillNumber?.eway_expiry_date,
                };
              }
              return item;
            }),
          };
        }
        return lr;
      })
    );
  };

  const handleEwayBillUpdate = async () => {
    const validationResult = await utils.checkFormError(
      { ...ewayForm },
      errorSchema.ewayBillSchima
    );
    const payload = {
      lr_id: ewayBillId?._id,
      eway_bill_number: ewayForm?.eway_bill_number,
      eway_bill_date: ewayForm?.start_date,
      eway_expiry_date: ewayForm?.end_date,
    };

    if (validationResult === true) {
      const res = await dispatch(addEwayWill(payload));

      if (res?.payload?.responseCode === 200) {
        setEwaybillShow(false);
        updateEwayBillNumber(ewayBillId, res?.payload?.data?.lr);
        setEwayForm({
          ...ewayForm,
          start_date: new Date(),
          end_date: new Date(),
          eway_bill_number: "",
        });
      } else {
        throw new Error(res?.payload?.responseMessage);
      }
    } else {
      setFormError(validationResult);
    }
  };

  const onValidationCheck = async () => {
    const validationResult = await utils.checkFormError(
      {
        ...formValue,
        manage_trip_kms: vehicleStatus?.manage_trip_kms,
        toLoction: toLoction,
        fromLoction: fromLoction,
        ownership: ownerShip,
      },
      errorSchema.createTripSchima
    );

    if (validationResult === true) {
      if (allFrightLr.length == 0) {
        toast.error("Please Select Atleast 1 LR");
      } else {
        setShow(!show);
      }
    } else {
      setFormError(validationResult);
    }
  };

  let firstAddLrModal = {
    isOpen: show,
    onSubmit: onSubmit,
    closeModal: (e) => setShow(false),
    headerPart: true,
    footerPart: true,
    modalMainClass: "_dashCommon_Modal _xlModal",
    modalTitle: "LR Freight",
    submitTitle: "Dispatch",
    separateLr: separateLr,
    isLoadding: loading,
    disabled: loading ? true : false,
    setListOfFreight: setListOfFreight,
    listOfFreight: listOfFreight,
    ownerShip: ownerShip,
    formValue: formValue,
  };

  let confirmModalProps = {
    show: confirmModalShow,
    closeModal: (e) => setConfirmModalShow(false),
    title: "Confirm",
    submitTitle: "Delete",
    onSubmit: handleDelete,
    confirmModal: "confirmModal",
    icon: Images.DELETEICON.default,
  };

  let ewaybillprops = {
    show: ewaybillShow,
    closeModal: (e) => setEwaybillShow(false),
    title: "Update",
    submitTitle: "Update",
    onSubmit: handleEwayBillUpdate,
    icon: images.EDIT,
    isLoadding: ewayLoading,
    disabled: ewayLoading,
  };

  let vehicleTypeProps = {
    isOpen: vehicleTypeshow,
    closeModal: (e) => setVehicleTypeShow(false),
    headerPart: true,
    footerPart: true,
    modalMainClass: "_dashCommon_Modal _smModal",
    modalTitle: "Vehicle Type",
    submitTitle: "Save",
    isLoadding: loading,
    disabled: loading ? true : false,
    lorryType: lorryType,
    callBackVehicleType: callBackVehicleType,
  };

  const handleDelteSubmit = (i, innerIndex, outerIndex) => {
    setLrDetele({
      ...LrDetele,
      item: i,
      innerIndex,
      outerIndex,
      title: `${i?.from_location} TO ${i?.to_location} Lr`,
    });
  };

  const addVehicleHandle = (data) => {
    setShowOffcanvas(true);
  };

  const addSupplierHandle = (data) => {
    setShowOffcanvasSupplier(true);
  };

  const addDriverHandle = (data) => {
    setShowOffcanvasDriver(true);
  };

  const callBackVehicle = async (data) => {
    await vehicleHandleChange(data);
    setTimeout(() => {
      fetchVehicleList("add");
      fetchInitialData()
    }, 2000);
  };

  const callBackSupplier = (data) => {
    setFormValue({ ...formValue, supplier_id: data?._id });
    const object = {
      ...data,
      label: data?.name,
      value: data?._id,
    };
    setFormLabelValue({ ...formLabelValue, supplier_id: object });
  };

  const callBackDriver = (data) => {
    setFormValue({ ...formValue, driver_id: data?._id });
    const object = {
      ...data,
      label: data?.name,
      value: data?._id,
    };
    setFormLabelValue({ ...formLabelValue, driver_id: object });
  };

  const handleDetailsAction = (data) => {
    setShowLrDetail(data);
  };

  const callBackEnd = (data) => {
    setOwnerShip(data?.vehicle_id?.vehicle_ownership);

    setFormLabelValue({
      ...formLabelValue,
      vehicle_id: {
        label: `${data?.vehicle_id?.vehicle_no} | ${
          masterData?.vehicle_ownership[data?.vehicle_id?.vehicle_ownership]
        }`,
      },
      lorry_type_id: {
        label: data?.lorry_type_id?.lorry_type,
        value: data?.lorry_type_id?._id,
      },
      vehicle_type_id: {
        label: data?.vehicle_type_id?.name,
        value: data?.vehicle_type_id?._id,
      },
      supplier_id: data?.supplier_id?._id
        ? {
            label: data?.supplier_id?.name,
            value: data?.supplier_id?._id,
          }
        : { label: "", value: "" },
      driver_id: data?.driver_id?._id
        ? { label: data?.driver_id?.name, value: data?.driver_id?._id }
        : { label: "", value: "" },
    });

    setFormValue({
      ...formValue,
      lorry_type_id: data?.lorry_type_id?._id,
      vehicle_type_id: data?.vehicle_type_id?._id,
      supplier_id: data?.supplier_id?._id ? data?.supplier_id?._id : "",
      driver_id: data?.driver_id?._id ? data?.driver_id?._id : "",
      vehicle_id: data?.vehicle_id?._id,
      start_kms: data?.end_kms,
    });

    setLastReading(data?.end_kms);
    setVehicleTypeLabel({
      label: `${data?.lorry_type_id?.lorry_type} > ${data?.vehicle_type_id?.name}`,
      value: "",
    });
    getVehicleList();
  };

  const handleNextModal = (data) => {
    setShowLrDetail(!showLrDetail);
    setShowAddLR(data);
  };

  let selectPartyModal = {
    isOpen: selectParty,
    closeModal: (e) => setSelectParty(false),
    addButtonAction: (e) => setIsAddConsignor(e),
    modalMainClass: "_dashCommon_Modal _smModal",
    modalTitle: "Add LR",
    submitTitle: "Continue",
    nextModal: (e) => setShowAddLR(e),
    showPrefield: showPrefield,
    showingPrefield: (e) => setShowingPrefield(e),
  };

  const callBackDataLr = (data) => {
    if (data?.branch_id) {
      // addTable is called when required
      if (!listOfLr.some((table) => table.branch_id === data.branch_id)) {
        addTable();
      }

      const tableIndex = listOfLr.findIndex((table) =>
        table.list.some((lrItem) => lrItem._id === data._id)
      );

      if (tableIndex !== -1) {
        const lrIndex = listOfLr[tableIndex].list.findIndex(
          (lrItem) => lrItem._id === data._id
        );

        //add new lr
        const newList = listOfLr.map((table, index) =>
          index === tableIndex
            ? {
                ...table,
                list: table.list.map((lrItem, i) =>
                  i === lrIndex ? data : lrItem
                ),
              }
            : table
        );

        const newSelect = selectedLr.map((table, index) =>
          index === tableIndex && table.list[lrIndex]
            ? {
                ...table,
                list: table.list.map((lrItem, i) =>
                  i === lrIndex ? data : lrItem
                ),
              }
            : table
        );

        setSelectedLr(newSelect);
        setListOfLr(newList);
      } else {
        //  LR is not already added
        const newList = [...listOfLr];
        if (
          !newList[indexofTable].list.some((lrItem) => lrItem._id === data._id)
        ) {
          newList[indexofTable].list.push(data);

          const newSelect = [...selectedLr];
          newSelect[indexofTable] = {
            ...newSelect[indexofTable],
            list: [...(newSelect[indexofTable]?.list || []), data],
          };

          setSelectedLr(newSelect);
          setListOfLr(newList);
        } else {
          console.log("LR already exists, skipping add");
        }
      }
    }
  };

  let endTripModal = {
    isOpen: showEndTrip,
    closeModal: (e) => {
      setShowEndTrip(false);
    },
    modalMainClass: "_dashCommon_Modal _smModal",
    modalTitle: "End Trip",
    submitTitle: "End Trip",
    callBackEnd: callBackEnd,
    lastTripDetails: lastTripDetails,
    endTripFrom: "Trip",
  };

  let makeTruckModal = {
    isOpen: showAvailableTruck,
    closeModal: (e) => setShowAvailableTruck(false),
    modalMainClass: "_dashCommon_Modal _smModal",
    modalTitle: "Make Truck Available",
    submitTitle: "Mark Available",
  };

  const handleAddNewSupplierButtonAction = () => {
    // setShowOffcanvasOfVehical(false);
    setIsSupplier(false);
    setShowOffcanvasOfSupplier(true);
  };

  const handleAddNewVehicleButtonAction = () => {
    // setShowOffcanvasOfVehical(false);
    setIsDriver(false);
    setShowOffcanvasOfDriver(true);
  };

  const handleSupplierNextModal = () => {
    setIsSupplier(!isSupplier);
    setShowOffcanvas(true);
  };

  const handleDriverNextModal = () => {
    setIsDriver(!isDriver);
    setShowOffcanvas(true);
  };

  return (
    <>
      {branchList.length > 0 ? (
        <>
          <Heading title={"Create Trip"} />

          <div className="_addContainer bg-[#FBF9FB]">
            <div className="_midCont_body">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-md-12 col-lg-12 col-xl-10">
                    <div className="_crtDisp_listBx">
                      <div className="">
                        <div className="_fleX_inds">
                          <div className="_divForth">
                            <CustomSelect
                              label="Branch"
                              placeholder="Select Branch"
                              options={branchList}
                              onSelect={(data) => {
                                handleOnChange("branch_id", data);
                                setFormLabelValue({
                                  ...formLabelValue,
                                  branch_id: data,
                                });
                              }}
                              error={formError.branch_id}
                              value={branchList?.find(
                                (item) => item?.value == formValue?.branch_id
                              )}
                            />
                          </div>

                          <div className="_divForth">
                            {/* <CommonInput
                          label="Date of Dispatch"
                          type="date"
                          value={formValue.dispatch_date}
                          name="dispatch_date"
                          onChange={({ target: { name, value } }) =>
                            handleOnChange(name, value)
                          }
                        /> */}
                            <CommonDatePicker
                              name="dispatch_date"
                              label="Date of Dispatch"
                              startDate={formValue?.dispatch_date}
                              setStartDate={(data) => {
                                handleOnChange("dispatch_date", data);
                                // setStartDate(data);
                              }}
                              error={formError.dispatch_date}
                              value={formLabelValue.dispatch_date}
                            />
                          </div>

                          <div className="_divForth">
                            <CustomSelect
                              label="Route"
                              placeholder="Select Route"
                              options={routeList}
                              isAddButton={true}
                              onSelect={(data) => {
                                handleOnChange("route_name", data);

                                setFormLabelValue({
                                  ...formLabelValue,
                                  route_name: data,
                                });
                                setcallbackDataRoute(data);
                                setLrDetialsFrom("");
                              }}
                              addButtonAction={addButtonAction}
                              error={formError.route_name}
                              value={formLabelValue.route_name}
                            />
                          </div>

                          {/* <div>
                        <button
                          onClick={() => setShowCreatRoute(!showCreatRoute)}
                        >
                          Add new route
                        </button>
                      </div> */}
                        </div>
                      </div>

                      <div className="">
                        <h4 className="_disPatch_heads">Dispatch Details</h4>
                        <div className="_fleX_inds">
                          <div className="_divForth">
                            <CustomSelect
                              label="Vehicle Number"
                              placeholder="Select Vehicle Number"
                              options={vehicleNumbList}
                              component_class="vehicle_number_trip"
                              onSelect={(data) => {
                                // handleOnChange("vehicle_id", data);
                                vehicleStatusCheck(data);
                                setOwnerShip(data?.vehicle_ownership);
                              }}
                              error={formError.vehicle_id}
                              value={formLabelValue.vehicle_id}
                              isAddButton={true}
                              addButtonAction={addVehicleHandle}
                              enableInfiniteScroll={true}
                              fetchMoreData={() => fetchVehicleList(page)}
                              hasMore={hasMoreVehicle}
                            />
                          </div>

                          <div
                            className="_divForth"
                            onClick={() => setVehicleTypeShow(true)}
                          >
                            <CustomSelect
                              label="Vehicle Type"
                              placeholder="Select Vehicle Type"
                              options={[
                                { label: "Vehicle Number", value: "123" },
                              ]}
                              value={vehicleTypeLabel}
                              openList={false}
                              error={formError.vehicle_type_id}
                            />
                          </div>

                          {ownerShip !== 1 && (
                            <div className="_divForth">
                              <CustomSelect
                                label="Supplier name"
                                placeholder="Select Supplier"
                                options={
                                  supplierList
                                    ? supplierList
                                    : [{ label: "Select ", value: "123" }]
                                }
                                onSelect={(data) => {
                                  handleOnChange("supplier_id", data);
                                  setFormLabelValue({
                                    ...formLabelValue,
                                    supplier_id: data,
                                  });
                                }}
                                value={formLabelValue?.supplier_id}
                                isAddButton={true}
                                addButtonAction={addSupplierHandle}
                                error={formError.supplier_id}
                              />
                            </div>
                          )}

                          {ownerShip === 1 && (
                            <div className="_divForth">
                              <CustomSelect
                                label="Driver"
                                placeholder="Select Driver"
                                options={
                                  driverList
                                    ? driverList
                                    : [{ label: "Select ", value: "123" }]
                                }
                                onSelect={(data) => {
                                  handleOnChange("driver_id", data);
                                  setFormLabelValue({
                                    ...formLabelValue,
                                    driver_id: data,
                                  });
                                }}
                                value={formLabelValue?.driver_id}
                                isAddButton={true}
                                addButtonAction={addDriverHandle}
                                error={formError.driver_id}
                              />
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="mb-3">
                        {vehicleStatus?.manage_trip_kms !== undefined &&
                          vehicleStatus?.manage_trip_kms != 0 && (
                            <h4 className="_disPatch_heads">
                              Truck Running Details
                            </h4>
                          )}
                        {vehicleStatus?.manage_trip_kms !== undefined &&
                          vehicleStatus?.manage_trip_kms != 0 && (
                            <div className="_fleX_inds">
                              <div className="_divForth">
                                <CommonInput
                                  type="text"
                                  label="Start Kms"
                                  name="start_kms"
                                  onChange={({ target: { name, value } }) =>
                                    handleOnChange(name, value)
                                  }
                                  value={formValue?.start_kms}
                                  onKeyDown={handleKeyDown}
                                  error={formError.start_kms}
                                />
                                <p className="_bottomPLine pt-1">
                                  Last Odometer Reading{" "}
                                  <span>
                                    {lastReading
                                      ? `${lastReading} km`
                                      : "Not Found"}
                                  </span>
                                </p>
                              </div>

                              {/* <div className="_divForth">
                            <CommonInput
                              type="text"
                              label="End Kms"
                              name="end_kms"
                              onChange={({ target: { name, value } }) =>
                                handleOnChange(name, value)
                              }
                              value={formValue?.end_kms}
                              onKeyDown={handleKeyDown}
                            />
                          </div> */}

                              {/* <div className="_divForth">
                            <CommonInput
                              label="Total Kms"
                              disabled
                              value={
                                formValue.start_kms && formValue.end_kms
                                  ? parseInt(formValue.end_kms) -
                                    parseInt(formValue.start_kms)
                                  : 0
                              }
                            />
                          </div> */}
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="container-fluid _verLine_cts">
                <div className="row _btmRow_cts">
                  {/* <h4 className="_disPatch_heads">
                         Locations And Lr Details
                        </h4> */}
                  {fromLoction?.map((data, index1) => {
                    return (
                      <div
                        className="col-md-12 col-lg-10 col-xl-10"
                        key={`fromLoction_${index1}`}
                      >
                        <div className="_addRouts_flex">
                          <div className="_cityInput_bx">
                            <CustomSelect
                              label="From City"
                              component_class="vehicle_number_trip"
                              options={cities}
                              dynamicIndex={index1}
                              onSelect={handleInputChange}
                              value={{
                                label: data?.from_city
                                  ? `${data?.from_city}, ${data?.from_state}`
                                  : "",
                                value: `${data?.from_city}`,
                              }}
                              error={
                                `${
                                  formError[`fromLoction[${index1}].from_city`]
                                }` !== "undefined"
                                  ? `${
                                      formError[
                                        `fromLoction[${index1}].from_city`
                                      ]
                                    }`
                                  : ""
                              }
                            />
                          </div>

                          <div className="_addDel_bx">
                            {!fromLoction[index1]?.from_city ? (
                              <button
                                className="_pickN_dropBtn"
                                // onClick={addFromInput}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="18"
                                  viewBox="0 0 18 18"
                                  fill="none"
                                >
                                  <path
                                    d="M8.61539 0C6.33902 0.0276205 4.16368 0.944178 2.55393 2.55393C0.944178 4.16368 0.0276205 6.33902 0 8.61539C0.0276205 10.8917 0.944178 13.0671 2.55393 14.6768C4.16368 16.2866 6.33902 17.2031 8.61539 17.2308C10.8917 17.2031 13.0671 16.2866 14.6768 14.6768C16.2866 13.0671 17.2031 10.8917 17.2308 8.61539C17.2031 6.33902 16.2866 4.16368 14.6768 2.55393C13.0671 0.944178 10.8917 0.0276205 8.61539 0ZM13.5385 9.23077H9.23077V13.5385H8V9.23077H3.69231V8H8V3.69231H9.23077V8H13.5385V9.23077Z"
                                    fill="#1956F6"
                                  />
                                </svg>{" "}
                                <span>Add Pickup</span>
                              </button>
                            ) : (
                              <button
                                className="_pickN_dropBtn"
                                onClick={addFromInput}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="18"
                                  height="18"
                                  viewBox="0 0 18 18"
                                  fill="none"
                                >
                                  <path
                                    d="M8.61539 0C6.33902 0.0276205 4.16368 0.944178 2.55393 2.55393C0.944178 4.16368 0.0276205 6.33902 0 8.61539C0.0276205 10.8917 0.944178 13.0671 2.55393 14.6768C4.16368 16.2866 6.33902 17.2031 8.61539 17.2308C10.8917 17.2031 13.0671 16.2866 14.6768 14.6768C16.2866 13.0671 17.2031 10.8917 17.2308 8.61539C17.2031 6.33902 16.2866 4.16368 14.6768 2.55393C13.0671 0.944178 10.8917 0.0276205 8.61539 0ZM13.5385 9.23077H9.23077V13.5385H8V9.23077H3.69231V8H8V3.69231H9.23077V8H13.5385V9.23077Z"
                                    fill="#1956F6"
                                  />
                                </svg>{" "}
                                <span>Add Pickup</span>
                              </button>
                            )}
                            {fromLoction?.length > 1 && (
                              <button
                                className="_deleteBtn"
                                onClick={() => removeFromInput(index1)}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="16"
                                  height="17"
                                  viewBox="0 0 16 17"
                                  fill="none"
                                >
                                  <path
                                    d="M2.83333 17C2.31389 17 1.86937 16.8152 1.49978 16.4456C1.13019 16.076 0.945074 15.6312 0.944444 15.1111V2.83333H0V0.944444H4.72222V0H10.3889V0.944444H15.1111V2.83333H14.1667V15.1111C14.1667 15.6306 13.9819 16.0754 13.6123 16.4456C13.2427 16.8158 12.7979 17.0006 12.2778 17H2.83333ZM12.2778 2.83333H2.83333V15.1111H12.2778V2.83333ZM4.72222 13.2222H6.61111V4.72222H4.72222V13.2222ZM8.5 13.2222H10.3889V4.72222H8.5V13.2222Z"
                                    fill="#C41F1F"
                                  />
                                </svg>
                                <span>Delete</span>
                              </button>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  {Array.from({ length: divCount }, (_, index) => (
                    <>
                      {toLoction?.map((data, index1) => {
                        if (index1 === index) {
                          return (
                            <div
                              className="col-md-12 col-lg-10 col-xl-10"
                              key={`table#${index}`}
                            >
                              <div className="_addRouts_flex">
                                <div className="_cityInput_bx">
                                  {/* <CommonInput value="Jabalpur" /> */}
                                  <CustomSelect
                                    label="To City"
                                    component_class="vehicle_number_trip"
                                    options={cities}
                                    dynamicIndex={index1}
                                    tableIndex={index}
                                    onSelect={handleInputChange}
                                    value={{
                                      label: data?.to_city
                                        ? `${data?.to_city}, ${data?.to_state}`
                                        : "",
                                      value: `${data?.to_city}`,
                                    }}
                                    // error={formError?.toLoction?.to_city}
                                    error={
                                      `${
                                        formError[
                                          `toLoction[${index1}].to_city`
                                        ]
                                      }` !== "undefined"
                                        ? `${
                                            formError[
                                              `toLoction[${index1}].to_city`
                                            ]
                                          }`
                                        : ""
                                    }
                                  />
                                </div>

                                <div className="_addDel_bx">
                                  {!toLoction[index]?.to_city ? (
                                    <button
                                      className="_pickN_dropBtn"
                                      // onClick={addToInput}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                      >
                                        <path
                                          d="M8.61539 0C6.33902 0.0276205 4.16368 0.944178 2.55393 2.55393C0.944178 4.16368 0.0276205 6.33902 0 8.61539C0.0276205 10.8917 0.944178 13.0671 2.55393 14.6768C4.16368 16.2866 6.33902 17.2031 8.61539 17.2308C10.8917 17.2031 13.0671 16.2866 14.6768 14.6768C16.2866 13.0671 17.2031 10.8917 17.2308 8.61539C17.2031 6.33902 16.2866 4.16368 14.6768 2.55393C13.0671 0.944178 10.8917 0.0276205 8.61539 0ZM13.5385 9.23077H9.23077V13.5385H8V9.23077H3.69231V8H8V3.69231H9.23077V8H13.5385V9.23077Z"
                                          fill="#1956F6"
                                        />
                                      </svg>{" "}
                                      <span>Add Drop</span>
                                    </button>
                                  ) : (
                                    <button
                                      className="_pickN_dropBtn"
                                      onClick={addToInput}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="18"
                                        height="18"
                                        viewBox="0 0 18 18"
                                        fill="none"
                                      >
                                        <path
                                          d="M8.61539 0C6.33902 0.0276205 4.16368 0.944178 2.55393 2.55393C0.944178 4.16368 0.0276205 6.33902 0 8.61539C0.0276205 10.8917 0.944178 13.0671 2.55393 14.6768C4.16368 16.2866 6.33902 17.2031 8.61539 17.2308C10.8917 17.2031 13.0671 16.2866 14.6768 14.6768C16.2866 13.0671 17.2031 10.8917 17.2308 8.61539C17.2031 6.33902 16.2866 4.16368 14.6768 2.55393C13.0671 0.944178 10.8917 0.0276205 8.61539 0ZM13.5385 9.23077H9.23077V13.5385H8V9.23077H3.69231V8H8V3.69231H9.23077V8H13.5385V9.23077Z"
                                          fill="#1956F6"
                                        />
                                      </svg>{" "}
                                      <span>Add Drop</span>
                                    </button>
                                  )}

                                  {toLoction?.length > 1 && (
                                    <button
                                      className="_deleteBtn"
                                      onClick={() => removeDiv(index)}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="16"
                                        height="17"
                                        viewBox="0 0 16 17"
                                        fill="none"
                                      >
                                        <path
                                          d="M2.83333 17C2.31389 17 1.86937 16.8152 1.49978 16.4456C1.13019 16.076 0.945074 15.6312 0.944444 15.1111V2.83333H0V0.944444H4.72222V0H10.3889V0.944444H15.1111V2.83333H14.1667V15.1111C14.1667 15.6306 13.9819 16.0754 13.6123 16.4456C13.2427 16.8158 12.7979 17.0006 12.2778 17H2.83333ZM12.2778 2.83333H2.83333V15.1111H12.2778V2.83333ZM4.72222 13.2222H6.61111V4.72222H4.72222V13.2222ZM8.5 13.2222H10.3889V4.72222H8.5V13.2222Z"
                                          fill="#C41F1F"
                                        />
                                      </svg>
                                      <span>Delete</span>
                                    </button>
                                  )}
                                </div>
                              </div>
                            </div>
                          );
                        }
                      })}
                      <div className="col-md-12 col-lg-10 col-xl-10">
                        <div className="_spaceTble">
                          {tripTable?.map((data, index3) => {
                            return (
                              <div
                                className="accordionTable_view"
                                key={`TripTable_${index3}`}
                              >
                                <div
                                  className="accordion"
                                  id="accordionExample2"
                                >
                                  {index === index3 &&
                                  listOfLr[index3]?.list?.length ? (
                                    <>
                                      {listOfLr[index3]?.list &&
                                      listOfLr[index3]?.list?.length
                                        ? listOfLr[index3]?.list?.map(
                                            (i, index1) => {
                                              return (
                                                <div
                                                  className="accordion-item"
                                                  key={`TripTable_${index3}${index1}`}
                                                >
                                                  <h2 className="accordion-header">
                                                    <div className="PickNdrop_placeBx">
                                                      <div
                                                        className="_inrPd"
                                                        bis_skin_checked="1"
                                                      >
                                                        <h4>
                                                          {i?.from_location}
                                                        </h4>
                                                        <img
                                                          src={
                                                            Images.ROUTTRUCKICON
                                                              .default
                                                          }
                                                          alt=""
                                                        />
                                                        <h4>
                                                          {i?.to_location}
                                                        </h4>
                                                        <button
                                                          className={`accordion-button collapsed`}
                                                          type="button"
                                                          data-bs-toggle="collapse"
                                                          data-bs-target={`#collapse${index1}${index3}`}
                                                          aria-expanded="true"
                                                          aria-controls={`collapse${index1}${index3}`}
                                                        >
                                                          <svg
                                                            className="cstCevs"
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            width="18"
                                                            height="18"
                                                            viewBox="0 0 18 18"
                                                            fill="none"
                                                          >
                                                            <path
                                                              d="M4.91862 6.69936C4.87178 6.6502 4.81668 6.61176 4.75647 6.58625C4.69625 6.56074 4.63211 6.54865 4.56769 6.55068C4.50327 6.5527 4.43984 6.5688 4.38102 6.59805C4.3222 6.6273 4.26915 6.66913 4.22488 6.72116C4.18062 6.77319 4.14602 6.83439 4.12305 6.90127C4.10008 6.96815 4.08919 7.0394 4.09102 7.11095C4.09284 7.18251 4.10733 7.25296 4.13367 7.31829C4.16 7.38362 4.19767 7.44255 4.24451 7.49172L8.6601 12.1238C8.75119 12.2194 8.87179 12.2727 8.99715 12.2727C9.12251 12.2727 9.24312 12.2194 9.33421 12.1238L13.7503 7.49172C13.7982 7.44288 13.8368 7.38396 13.864 7.31839C13.8912 7.25282 13.9064 7.1819 13.9087 7.10975C13.911 7.0376 13.9004 6.96566 13.8774 6.89811C13.8545 6.83056 13.8197 6.76875 13.7751 6.71626C13.7305 6.66377 13.6769 6.62165 13.6175 6.59235C13.5581 6.56305 13.494 6.54715 13.4291 6.54558C13.3641 6.54401 13.2995 6.5568 13.239 6.5832C13.1785 6.60959 13.1233 6.64908 13.0767 6.69936L8.99715 10.9783L4.91862 6.69936Z"
                                                              fill="#1450EC"
                                                            />
                                                            <circle
                                                              cx="9"
                                                              cy="9"
                                                              r="8.59091"
                                                              transform="rotate(-180 9 9)"
                                                              stroke="#0049D7"
                                                              strokeWidth="0.818182"
                                                            />
                                                          </svg>
                                                        </button>
                                                      </div>
                                                      <div className="_pdTransport_dl">
                                                        <h4>
                                                          {
                                                            i?.consignor_id
                                                              ?.name
                                                          }
                                                        </h4>
                                                        <span
                                                          className="_avPointer"
                                                          onClick={(e) =>
                                                            handleDetailsAction(
                                                              i
                                                            )
                                                          }
                                                        >
                                                          {i?.invoice_number
                                                            ? `INV- ${i?.invoice_number}`
                                                            : ""}
                                                        </span>
                                                        <span
                                                          className="_avPointer"
                                                          onClick={(e) =>
                                                            handleDetailsAction(
                                                              i
                                                            )
                                                          }
                                                        >
                                                          {i?.lr_number
                                                            ? `# ${i?.lr_number}`
                                                            : ""}
                                                        </span>
                                                      </div>
                                                    </div>
                                                    <button
                                                      className="closeTble_btn"
                                                      type="button"
                                                      onClick={(e) => {
                                                        handleDelteSubmit(
                                                          i,
                                                          index1,
                                                          index3
                                                        );
                                                      }}
                                                    >
                                                      <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="56"
                                                        height="29"
                                                        viewBox="0 0 56 29"
                                                        fill="none"
                                                      >
                                                        <rect
                                                          x="56"
                                                          y="29"
                                                          width="56"
                                                          height="29"
                                                          rx="14.5"
                                                          transform="rotate(180 56 29)"
                                                          fill="#434343"
                                                        />
                                                        <path
                                                          d="M41.9806 10.2104C41.9162 10.1458 41.8396 10.0946 41.7554 10.0596C41.6711 10.0247 41.5807 10.0067 41.4895 10.0067C41.3983 10.0067 41.3079 10.0247 41.2237 10.0596C41.1394 10.0946 41.0629 10.1458 40.9984 10.2104L37.592 13.6098L34.1856 10.2034C34.1211 10.1389 34.0446 10.0878 33.9603 10.0529C33.876 10.018 33.7857 10 33.6945 10C33.6033 10 33.513 10.018 33.4287 10.0529C33.3445 10.0878 33.2679 10.1389 33.2034 10.2034C33.1389 10.2679 33.0878 10.3445 33.0529 10.4287C33.018 10.513 33 10.6033 33 10.6945C33 10.7857 33.018 10.876 33.0529 10.9603C33.0878 11.0446 33.1389 11.1211 33.2034 11.1856L36.6098 14.592L33.2034 17.9984C33.1389 18.0629 33.0878 18.1395 33.0529 18.2237C33.018 18.308 33 18.3983 33 18.4895C33 18.5807 33.018 18.671 33.0529 18.7553C33.0878 18.8396 33.1389 18.9161 33.2034 18.9806C33.2679 19.0451 33.3445 19.0963 33.4287 19.1312C33.513 19.1661 33.6033 19.184 33.6945 19.184C33.7857 19.184 33.876 19.1661 33.9603 19.1312C34.0446 19.0963 34.1211 19.0451 34.1856 18.9806L37.592 15.5742L40.9984 18.9806C41.0629 19.0451 41.1395 19.0963 41.2237 19.1312C41.308 19.1661 41.3983 19.184 41.4895 19.184C41.5807 19.184 41.671 19.1661 41.7553 19.1312C41.8396 19.0963 41.9161 19.0451 41.9806 18.9806C42.0451 18.9161 42.0963 18.8396 42.1312 18.7553C42.1661 18.671 42.184 18.5807 42.184 18.4895C42.184 18.3983 42.1661 18.308 42.1312 18.2237C42.0963 18.1395 42.0451 18.0629 41.9806 17.9984L38.5742 14.592L41.9806 11.1856C42.2453 10.9209 42.2453 10.4751 41.9806 10.2104Z"
                                                          fill="white"
                                                        />
                                                      </svg>
                                                    </button>
                                                  </h2>

                                                  <div
                                                    id={`collapse${index1}${index3}`}
                                                    className="accordion-collapse collapse"
                                                    data-bs-parent="#accordionExample2"
                                                  >
                                                    <div className="accordion-body">
                                                      <table className="ctmTable">
                                                        <thead>
                                                          <tr className="accordiTr">
                                                            <th>Date</th>
                                                            <th>
                                                              From Station
                                                            </th>
                                                            <th>To Station</th>
                                                            <th>
                                                              Invoice Number
                                                            </th>
                                                            <th>Qty</th>
                                                            <th>Weight</th>
                                                            <th>KOT</th>
                                                            <th>Eway Bill</th>
                                                            <th>Expiry Date</th>
                                                          </tr>
                                                        </thead>

                                                        <tbody>
                                                          <tr>
                                                            <td>
                                                              <span>
                                                                {" "}
                                                                {moment(
                                                                  i?.indent_date
                                                                ).format(
                                                                  " D  MMM YYYY"
                                                                )}
                                                              </span>
                                                              <br />
                                                              <span className="sameSpan">
                                                                5:30 PM
                                                              </span>
                                                            </td>

                                                            <td>
                                                              <span className="sameSpan fw-500">
                                                                {
                                                                  i?.from_location
                                                                }
                                                              </span>
                                                              <br />
                                                              <span className="sameSpan">
                                                                {i?.unit_id
                                                                  ?.unit_code
                                                                  ? i?.unit_id
                                                                      ?.unit_code
                                                                  : " -"}{" "}
                                                                |{" "}
                                                                {i?.unit_id
                                                                  ?.name
                                                                  ? i?.unit_id
                                                                      ?.name
                                                                  : " -"}
                                                              </span>
                                                            </td>

                                                            <td>
                                                              <span className="sameSpan fw-500">
                                                                {i?.to_location}
                                                              </span>
                                                              <br />
                                                              <span className="sameSpan">
                                                                {i?.consignee_id
                                                                  ?.unit_code
                                                                  ? i
                                                                      ?.consignee_id
                                                                      ?.unit_code
                                                                  : " -"}{" "}
                                                                |{" "}
                                                                {i?.consignee_id
                                                                  ?.name
                                                                  ? i
                                                                      ?.consignee_id
                                                                      ?.name
                                                                  : "-"}
                                                              </span>
                                                            </td>

                                                            <td>
                                                              <span
                                                                className="_invoicSpan"
                                                                onClick={(e) =>
                                                                  i?.invoice_number
                                                                    ? handleDetailsAction(
                                                                        i
                                                                      )
                                                                    : ""
                                                                }
                                                              >
                                                                {i?.invoice_number
                                                                  ? i?.invoice_number
                                                                  : "-"}
                                                              </span>
                                                              <br />
                                                              <span className="sameSpan">
                                                                {i?.po_number
                                                                  ? i?.po_number
                                                                  : "-"}
                                                              </span>
                                                            </td>

                                                            <td>
                                                              <span className="sameSpan">
                                                                {i?.total_qty}
                                                              </span>
                                                            </td>

                                                            <td>
                                                              <span className="sameSpan">
                                                                {
                                                                  i?.total_weight
                                                                }
                                                              </span>
                                                            </td>

                                                            <td>
                                                              <span className="sameSpan">
                                                                {i?.total_kot}
                                                              </span>
                                                            </td>

                                                            <td>
                                                              <span
                                                                className="sameSpan"
                                                                onClick={() =>
                                                                  ewayBillHandle(
                                                                    i
                                                                  )
                                                                }
                                                              >
                                                                {i?.eway_bill_number ? (
                                                                  i?.eway_bill_number
                                                                ) : (
                                                                  <span
                                                                    onClick={() =>
                                                                      ewayBillHandle(
                                                                        i
                                                                      )
                                                                    }
                                                                  >
                                                                    + Add Eway
                                                                    Bill
                                                                  </span>
                                                                )}
                                                              </span>
                                                            </td>

                                                            <td>
                                                              <span className="sameSpan">
                                                                {i?.eway_bill_number
                                                                  ? moment(
                                                                      i?.indent_date
                                                                    ).format(
                                                                      " D  MMM YYYY"
                                                                    )
                                                                  : ""}
                                                              </span>
                                                            </td>
                                                          </tr>
                                                        </tbody>
                                                      </table>
                                                    </div>
                                                  </div>
                                                </div>
                                              );
                                            }
                                          )
                                        : ""}
                                    </>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            );
                          })}
                        </div>

                        <div className="lrDeatilsTrip _spaceTble">
                          <button
                            className="_addComm_btn"
                            type="button"
                            disabled={!toLoction[index]?.to_city}
                            onClick={() => {
                              setIndexofTable(index);
                              // setConfirmDialog1({
                              //   ...confirmDialog1,
                              //   isOpen: true,
                              // });
                              setSelectParty(true);
                            }}
                          >
                            Add New LR
                          </button>
                          <button
                            className="_addComm_btn"
                            type="button"
                            disabled={!toLoction[index]?.to_city}
                            onClick={() => {
                              addTable();
                              setIndexofTable(index);
                              setShowSelectLr(true);
                            }}
                          >
                            Add Existing LR
                          </button>
                        </div>
                      </div>
                    </>
                  ))}
                </div>
              </div>
            </div>
          </div>

          <div className="bottomFix">
            <button
              onClick={() => onValidationCheck()}
              className="_addComm_btn"
              type="button"
              // disabled={
              //   !formValue.branch_id ||
              //   !formValue.vehicle_type_id ||
              //   !formValue.vehicle_id ||
              //   !formValue.dispatch_date ||
              //   !fromLoction.every((location) => location.from_city) ||
              //   !toLoction.every((location) => location.to_city) ||
              //   allFrightLr.length === 0 ||
              //   !(ownerShip == 1
              //     ? formValue?.driver_id
              //     : formValue?.supplier_id)
              // }
            >
              Dispatch Trip{" "}
            </button>

            {/* <button className="_cancel_btn">Cancel</button> */}
          </div>

          {show && <LrFreightModal {...firstAddLrModal} />}

          {showCreatRoute && <AddRouteModal {...firstCreateRouteModal} />}
          {showSelectLr && <SelectLrModal {...selectLrModalProps} />}
          {confirmModalShow && (
            <ConfirmModalFor {...confirmModalProps}>
              <>
                <p className="_confirmPrx">
                  {" "}
                  Do you really want to delete " {LrDetele.title} "{" "}
                </p>
              </>
            </ConfirmModalFor>
          )}
          {ewaybillShow && (
            <ConfirmModalFor {...ewaybillprops}>
              <>
                <div className="colFlx">
                  <div className="_addRouts_flex">
                    <CommonDatePicker
                      name="dispatch_date"
                      label="Date of Dispatch"
                      startDate={ewayForm?.start_date}
                      setStartDate={(data) => {
                        handleChange("start_date", data);
                      }}
                      error={formError?.start_date}
                      focus={formError?.start_date}
                    />
                    <CommonDatePicker
                      name="dispatch_date"
                      label="Date of Dispatch"
                      startDate={ewayForm?.end_date}
                      setStartDate={(data) => {
                        handleChange("end_date", data);
                      }}
                      error={formError?.end_date}
                      focus={formError?.end_date}
                    />
                  </div>
                  <CommonInput
                    label="Eway bill number"
                    placeholder="Enter Number"
                    name="eway_bill_number"
                    onChange={({ target: { name, value } }) =>
                      handleChange(name, value)
                    }
                    maxLength={12}
                    value={ewayForm?.eway_bill_number}
                    onKeyDown={handleKeyDown}
                    error={formError?.eway_bill_number}
                    focus={formError?.eway_bill_number}
                  />
                </div>
              </>
            </ConfirmModalFor>
          )}
          {vehicleTypeshow && <VehicleTypeModal {...vehicleTypeProps} />}

          <div
            className={`offcanvas offcanvas-end common_offCanvas ${
              showOffcanvas ? "show" : ""
            }`}
            tabIndex={-1}
            style={{ visibility: showOffcanvas ? "visible" : "hidden" }}
          >
            {showOffcanvas && (
              <AddEditVehicleModal
                isOpen={showOffcanvas}
                onClose={() => setShowOffcanvas(false)}
                callBackVehicle={callBackVehicle}
                isSupplier={isSupplier}
                isDriver={isDriver}
                handleAddNewSupplierButtonAction={
                  handleAddNewSupplierButtonAction
                }
                handleAddNewVehicleButtonAction={
                  handleAddNewVehicleButtonAction
                }
              />
            )}
          </div>

          <div className={showOffcanvas ? "offBack_drop" : ""}></div>

          {/* Supplier Canvas Open and Vahicle canvas will close */}
          <div
            className={`offcanvas offcanvas-end common_offCanvas ${
              showOffcanvasOfSupplier ? "show" : ""
            }`}
            tabIndex={-1}
            style={{
              visibility: showOffcanvasOfSupplier ? "visible" : "hidden",
            }}
          >
            {showOffcanvasOfSupplier && (
              <AddEditSupplier
                isOpen={showOffcanvasOfSupplier}
                onClose={(e) => setShowOffcanvasOfSupplier(false)}
                callBackSupplier={(e) => handleSupplierNextModal(e)}
              />
            )}
          </div>
          <div className={showOffcanvasOfSupplier ? "offBack_drop" : ""}></div>

          {/* Driver Canvas Open and Vahicle canvas will close */}
          <div
            className={`offcanvas offcanvas-end common_offCanvas ${
              showOffcanvasOfDriver ? "show" : ""
            }`}
            tabIndex={-1}
            style={{ visibility: showOffcanvasOfDriver ? "visible" : "hidden" }}
          >
            {showOffcanvasOfDriver && (
              <AddEditOwnDriver
                isOpen={showOffcanvasOfDriver}
                onClose={() => setShowOffcanvasOfDriver(false)}
                callBackDriver={(e) => handleDriverNextModal(e)}
              />
            )}
          </div>
          <div className={showOffcanvasOfDriver ? "offBack_drop" : ""}></div>

          <div
            className={`offcanvas offcanvas-end common_offCanvas ${
              showOffcanvasSupplier ? "show" : ""
            }`}
            tabIndex={-1}
            style={{ visibility: showOffcanvasSupplier ? "visible" : "hidden" }}
          >
            {showOffcanvasSupplier && (
              <AddEditSupplier
                isOpen={showOffcanvasSupplier}
                onClose={() => setShowOffcanvasSupplier(false)}
                callBackSupplier={callBackSupplier}
              />
            )}
          </div>
          <div className={showOffcanvasSupplier ? "offBack_drop" : ""}></div>
          <div
            className={`offcanvas offcanvas-end common_offCanvas ${
              showOffcanvasDriver ? "show" : ""
            }`}
            tabIndex={-1}
            style={{ visibility: showOffcanvasDriver ? "visible" : "hidden" }}
          >
            {showOffcanvasDriver && (
              <AddEditOwnDriver
                isOpen={showOffcanvasDriver}
                onClose={(e) => setShowOffcanvasDriver(false)}
                callBackDriver={callBackDriver}
              />
            )}
          </div>

          <div className={showOffcanvasDriver ? "offBack_drop" : ""}></div>

          <div
            className={`offcanvas offcanvas-end common_offCanvas xxlOffcanvas ${
              showLrDetail ? "show" : ""
            }`}
            tabIndex={-1}
            style={{ visibility: showLrDetail ? "visible" : "hidden" }}
          >
            {showLrDetail && (
              <LrDetailModal
                isOpen={showLrDetail}
                onClose={(e) => setShowLrDetail(false)}
                nextModal={(e) => handleNextModal(e)}
              />
            )}
          </div>
          <div className={showLrDetail ? "offBack_drop" : ""}></div>

          {selectParty && <AddLrModal {...selectPartyModal} />}

          <div
            className={`offcanvas offcanvas-end common_offCanvas xxlOffcanvas ${
              showAddLR ? "show" : ""
            }`}
            tabIndex={-1}
            style={{ visibility: showAddLR ? "visible" : "hidden" }}
          >
            {showAddLR && (
              <AddEditLrModal
                isOpen={showAddLR}
                onClose={() => setShowAddLR(false)}
                callBackDataLr={callBackDataLr}
                type="from_trip"
                callGetData={() => {}}
              />
            )}
          </div>
          <div className={showAddLR ? "offBack_drop" : ""}></div>

          {showEndTrip && <EndTripModal {...endTripModal} />}

          {showAvailableTruck && (
            <MakeTruckAvailableModal {...makeTruckModal} />
          )}
        </>
      ) : (
        <Loading />
      )}

      <input ref={hiddenInputRef} type="hidden" />
      {showBackModal && (
        <ConfirmModalFor {...backModalProps}>
          <>
            <p className="_confirmPrx"> Do you really want leave this Page </p>
          </>
        </ConfirmModalFor>
      )}

      <div
        className={`offcanvas offcanvas-end common_offCanvas ${
          isAddConsignor ? "show" : ""
        }`}
        tabIndex={-1}
        style={{ visibility: isAddConsignor ? "visible" : "hidden" }}
      >
        {isAddConsignor && (
          <AddEditConsignor
            isOpen={isAddConsignor}
            onClose={() => setIsAddConsignor(false)}
            isPreviousModal={(e) => setSelectParty(true)}
            showingPrefield={(e) => setShowingPrefield(true)}
          />
        )}
      </div>

      <div className={isAddConsignor ? "offBack_drop" : ""}></div>
    </>
  );
};

export default CreatTrip;
