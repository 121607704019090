import { getLastTrip } from "_services";
import { CommonDatePicker, CommonInput, CustomSelect } from "components";
import { Images } from "constants";
import { UserContext } from "context";
import { utils } from "helper";
import {
  AddEditLrModal,
  AtPartyPointModal,
  EndTripModal,
  LrDetailModal,
  MakeTruckAvailableModal,
} from "modals";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import StorageService from "utils/StorageServices/StorageServices";

const DispatchDetails = ({
  freightId,
  tripDetais,
  callApi,
}) => {
  const intialForm = {
    company_id: StorageService.getCompanyId(),
    branch_id: "",
    dispatch_date: moment(new Date()).format("YYYY-MM-DD"),
    driver_id: "",
    supplier_id: "",
    vehicle_type_id: "",
    lorry_type_id: "",
    vehicle_id: "",
    start_kms: 0,
    end_kms: 0,
    route_name: "",
    lr_list: [],
    from_location: [],
    to_location: [],
    location: [],
  };

  const initiFrom = {
    from_city: "",
    from_state: "",
    lat: "",
    long: "",
    status: 1,
  };

  const initiTo = {
    to_city: "",
    to_state: "",
    lat: "",
    long: "",
    status: 1,
  };

  const arryOptions = [
    { label: "Arjun", value: "01" },
    { label: "Anvit", value: "02" },
  ];
  const { masterData, globTripDetails, cities } = useContext(UserContext);
  const navigate = useNavigate()
  const [data, setData] = useState(tripDetais);
  const [fromLoction, setFromLoction] = useState([initiFrom]);
  const [toLoction, setToLoction] = useState([initiTo]);
  const [form, setForm] = useState(intialForm);
  const [divCount, setDivCount] = useState(1);
  const [tripTable, setTripTable] = useState([]);
  const [listOfLr, setListOfLr] = useState([{ list: [] }]);
  const [isOpenSmallModal, setIsOpenSmallModal] = useState(false);
  const [textState, setTextState] = useState(0);
  const [showEndTrip, setShowEndTrip] = useState(false);
  const [showAvailableTruck, setShowAvailableTruck] = useState(false);
  const [showLrDetail, setShowLrDetail] = useState(false);
  const [showAddLR, setShowAddLR] = useState(false);

  useEffect(() => {
    setForm({
      ...form,
      branch_id: {
        label: tripDetais?.branch_id?.branch,
        value: tripDetais?.branch_id?._id,
      },
      dispatch_date: moment(tripDetais?.dispatch_date).format("YYYY-MM-DD"),
      route_name: { label: tripDetais?.route_name, value: "" },
      vehicle_id: {
        label: `${tripDetais?.vehicle_id?.vehicle_no} | ${
          masterData?.vehicle_ownership[tripDetais?.vehicle_ownership]
        }`,
        value: tripDetais?.vehicle_id?._id,
      },
      vehicle_type_id: {
        label: `${tripDetais?.lorry_type_id?.lorry_type} > ${tripDetais?.vehicle_type_id?.name}`,
        value: tripDetais?.vehicle_type_id?._id,
      },
      supplier_id: {
        label: tripDetais?.supplier_id?.name,
        value: tripDetais?.supplier_id?._id,
      },
      driver_id: {
        label: tripDetais?.driver_id?.name,
        value: tripDetais?.driver_id?._id,
      },
      start_kms: tripDetais?.start_kms ? tripDetais?.start_kms : 0,
      end_kms: tripDetais?.end_kms ? tripDetais?.end_kms : 0,
    });

    setFromLoction(tripDetais?.from_location);
  }, [tripDetais]);


  useEffect(() => {
    if (tripDetais) {
      tripDetais?.from_location?.map((user) => addFromInput());
      tripDetais?.to_location?.map((user) => {
        setToLoction([...toLoction, initiTo]);
        // setToLocatonValue([...tolocationValue, ""]);
        setListOfLr([...listOfLr, { list: [] }]);
        // setSelectedLr([...selectedLr, { list: [] }]);
        setDivCount(tripDetais?.to_location.length);
      });

      setFromLoction(
        tripDetais?.from_location?.map((user) => {
          return {
            ...user,
            label: `${user.from_city}, ${user?.from_state}`,
            value: `${user.from_city}, ${user?.from_state}`,
          };
        })
      );
      setToLoction(
        tripDetais?.to_location?.map((user) => {
          return {
            ...user,
            label: `${user.to_city}, ${user?.to_state}`,
            value: `${user.to_city}, ${user?.to_state}`,
          };
        })
      );
    }
  }, [tripDetais]);

  const addFromInput = (e) => {
    setFromLoction((preState) => {
      return [...preState, initiFrom];
    });
  };

  const handleButtonText = (status, type) => {
    switch (status) {
      case 1:
        return "Mark Document Ready";
      case 2:
        return "Intransit";
      case 3:
        return "At Party Point";
      case 4:
        return "Delivered";
      case 5:
        return "Bill Raised";
      case 6:
        return "Completed";

      default:
        break;
    }
  };

  const submitTestHandle = (status) => {
    switch (status) {
      case 1:
        return "Mark Document Ready";
      case 2:
        return "Intransit";
      case 3:
        return "At Party Point";
      case 4:
        return "Delivered";
      case 5:
        return "Bill Raised";

      default:
        return "Completed";
        break;
    }
  };

  const handleButtonClick = (status) => {
    switch (status?.lr_status) {
      case 1:
        setIsOpenSmallModal(true);
        setTextState(status);
        break;
      case 2:
        setIsOpenSmallModal(true);
        setTextState(status);
        break;
      case 3:
        setIsOpenSmallModal(true);
        setTextState(status);
        break;
      case 4:
        setIsOpenSmallModal(true);
        setTextState(status);
        break;
      case 5:
      navigate("/invoicing")
       
        // setIsOpenSmallModal(true);
        // setTextState(status);
        break;
      case 6:
        setIsOpenSmallModal(true);
        setTextState(status);
        break;

      default:
        break;
    }
  };


  const callBackApiCall = (status) => {
    if (status) {
      callApi(status);
    }
  };

  const handleDetailsAction = (data) => {
   
    setShowLrDetail(data);
  };

  const handleNextModal = (data) => {
    setShowLrDetail(!showLrDetail);
    setShowAddLR(data);
  
  };

  const callBackDataLr = (data) => {
    callApi();
    // if (data?.branch_id) {
    //   addTable();
    //   const newList = [...listOfLr];
    //   newList[indexofTable].list[listOfLr[indexofTable].list.length] = data;
    //   const newSelect = [...selectedLr];
    //   newSelect[indexofTable]?.list.push(data);
    //   setSelectedLr(newSelect);
    //   setListOfLr(newList);
    // }

//     const updatedLR = {...data}; // Copy the new LR data
// const listoflrCopy = [...toLoction]; // Copy the state to avoid direct mutation

// listoflrCopy.forEach((location) => {
//   location.lr_list = location.lr_list.map((existingLR) => 
//     existingLR._id === updatedLR._id ? updatedLR : existingLR
//   );
// });

// // Update the state with the modified list
// setToLoction(listoflrCopy);
  };
  let modalProps = {
    isOpen: isOpenSmallModal,
    closeModal: (e) => setIsOpenSmallModal(false),
    lrId: textState?._id,
    companyId: textState?.company_id,
    modalMainClass: "_dashCommon_Modal _smModal",
    modalTitle: submitTestHandle(textState?.lr_status),
    submitTitle: submitTestHandle(textState?.lr_status),
    callBackApiCall: callBackApiCall,
  };

  let makeTruckModal = {
    isOpen: showAvailableTruck,
    closeModal: (e) => setShowAvailableTruck(false),
    modalMainClass: "_dashCommon_Modal _smModal",
    modalTitle: "Make Truck Available",
    submitTitle: "Mark Available",
  };



  return (
    <>
      <div className=" bg-[#FBF9FB]">
        <div className="_midCont_body">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-12 col-lg-10 col-xl-10">
                <div className="_crtDisp_listBx">
                  <div className="">
                    <div className="_fleX_inds">
                      <div className="_divForth">
                        <CustomSelect
                          label="Branch"
                          placeholder="Select Branch"
                          options={arryOptions}
                          disabled={true}
                          value={form?.branch_id}
                        />
                      </div>

                      <div className="_divForth">
                        <CommonDatePicker
                          name="dispatch_date"
                          label="Date of Dispatch"
                          startDate={form?.dispatch_date}
                          disabled={true}
                        />
                      </div>

                      <div className="_divForth">
                        <CustomSelect
                          label="Route"
                          placeholder="Select Route"
                          options={arryOptions}
                          disabled={true}
                          value={form?.route_name}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="">
                    <h4 className="_disPatch_heads">Dispatch Details</h4>
                    <div className="_fleX_inds">
                      <div className="_divForth">
                        <CustomSelect
                          label="Vehicle Number"
                          placeholder="Select Vehicle Number"
                          options={arryOptions}
                          disabled={true}
                          value={form?.vehicle_id}
                        />
                      </div>

                      <div className="_divForth">
                        <CustomSelect
                          label="Vehicle Type"
                          placeholder="Select Vehicle Type"
                          options={[{ label: "Vehicle Number", value: "123" }]}
                          disabled={true}
                          value={form?.vehicle_type_id}
                        />
                      </div>

                      {tripDetais?.supplier_id && (
                        <div className="_divForth">
                          <CustomSelect
                            label="Supplier name"
                            placeholder="Select Supplier"
                            options={arryOptions}
                            disabled={true}
                            value={form?.supplier_id}
                          />
                        </div>
                      )}

                      {tripDetais?.driver_id && (
                        <div className="_divForth">
                          <CustomSelect
                            label="Driver"
                            placeholder="Select Driver"
                            options={arryOptions}
                            disabled={true}
                            value={form?.driver_id}
                          />
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="mb-4">
                    <h4 className="_disPatch_heads">Truck Running Details</h4>
                    <div className="_fleX_inds">
                      <div className="_divForth">
                        <CommonInput
                          type="text"
                          label="Start Kms"
                          name="start_kms"
                          disabled={true}
                          value={form?.start_kms}
                        />
                      </div>
                      {tripDetais?.trip_status == (4 || 5) && (
                        <div className="_divForth">
                          <CommonInput
                            type="text"
                            label="End Kms"
                            name="end_kms"
                            disabled={true}
                            value={form?.end_kms}
                          />
                        </div>
                      )}
                      {tripDetais?.trip_status == (4 || 5) && (
                        <div className="_divForth">
                          <CommonInput
                            type="text"
                            label="Total Kms"
                            name="total_kms"
                            disabled={true}
                            value={form?.end_kms - form?.start_kms}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="container-fluid _verLine_cts">
            <div className="row _btmRow_cts">
              {fromLoction?.map((data, index1) => {
                return (
                  <div
                    className="col-md-12 col-lg-10 col-xl-10"
                    key={`fromLoction_${index1}`}
                  >
                    <div className="_addRouts_flex">
                      <div className="_cityInput_bx">
                        <CustomSelect
                          label="From City"
                          options={cities}
                          dynamicIndex={index1}
                          disabled={true}
                          value={fromLoction[index1]}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
              {Array.from({ length: divCount }, (_, index) => (
                <>
                  {toLoction?.map((data, index1) => {
                    if (index1 === index) {
                      return (
                        <>
                          <div
                            className="col-md-12 col-lg-10 col-xl-10"
                            key={`table#${index}`}
                          >
                            <div className="_addRouts_flex">
                              <div className="_cityInput_bx">
                                <CustomSelect
                                  label="To City"
                                  options={cities}
                                  dynamicIndex={index1}
                                  tableIndex={index}
                                  disabled={true}
                                  value={toLoction[index1]}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12 col-lg-10 col-xl-10">
                            {data?.lr_list?.map((i, index3) => {
                           
                              return (
                                <div className="_spaceTble">
                                  <div
                                    className="accordionTable_view"
                                    key={`TripTable_${index3}`}
                                  >
                                    <div
                                      className="accordion"
                                      id="accordionExample2"
                                    >
                                      <>
                                        <div
                                          className="accordion-item"
                                          key={`TripTable_${index3}${index1}`}
                                        >
                                          <h2 className="accordion-header">
                                            <div className="PickNdrop_placeBx">
                                              <div className="_cnFr_listsBx">
                                                <div>
                                                  <div
                                                    className="_inrPd"
                                                    bis_skin_checked="1"
                                                  >
                                                    <h4>{i?.from_location}</h4>
                                                    <img
                                                      src={
                                                        Images.ROUTTRUCKICON
                                                          .default
                                                      }
                                                      alt=""
                                                    />
                                                    <h4>{i?.to_location}</h4>
                                                    <button
                                                      className={`accordion-button collapsed`}
                                                      type="button"
                                                      data-bs-toggle="collapse"
                                                      data-bs-target={`#collapse${index1}${index3}`}
                                                      aria-expanded="true"
                                                      aria-controls={`collapse${index1}${index3}`}
                                                    >
                                                      <svg
                                                        className="cstCevs"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        width="18"
                                                        height="18"
                                                        viewBox="0 0 18 18"
                                                        fill="none"
                                                      >
                                                        <path
                                                          d="M4.91862 6.69936C4.87178 6.6502 4.81668 6.61176 4.75647 6.58625C4.69625 6.56074 4.63211 6.54865 4.56769 6.55068C4.50327 6.5527 4.43984 6.5688 4.38102 6.59805C4.3222 6.6273 4.26915 6.66913 4.22488 6.72116C4.18062 6.77319 4.14602 6.83439 4.12305 6.90127C4.10008 6.96815 4.08919 7.0394 4.09102 7.11095C4.09284 7.18251 4.10733 7.25296 4.13367 7.31829C4.16 7.38362 4.19767 7.44255 4.24451 7.49172L8.6601 12.1238C8.75119 12.2194 8.87179 12.2727 8.99715 12.2727C9.12251 12.2727 9.24312 12.2194 9.33421 12.1238L13.7503 7.49172C13.7982 7.44288 13.8368 7.38396 13.864 7.31839C13.8912 7.25282 13.9064 7.1819 13.9087 7.10975C13.911 7.0376 13.9004 6.96566 13.8774 6.89811C13.8545 6.83056 13.8197 6.76875 13.7751 6.71626C13.7305 6.66377 13.6769 6.62165 13.6175 6.59235C13.5581 6.56305 13.494 6.54715 13.4291 6.54558C13.3641 6.54401 13.2995 6.5568 13.239 6.5832C13.1785 6.60959 13.1233 6.64908 13.0767 6.69936L8.99715 10.9783L4.91862 6.69936Z"
                                                          fill="#1450EC"
                                                        />
                                                        <circle
                                                          cx="9"
                                                          cy="9"
                                                          r="8.59091"
                                                          transform="rotate(-180 9 9)"
                                                          stroke="#0049D7"
                                                          strokeWidth="0.818182"
                                                        />
                                                      </svg>
                                                    </button>
                                                  </div>
                                                  <div className="_pdTransport_dl">
                                                    <h4>
                                                      {i?.consignor_id?.name}
                                                    </h4>
                                                    <span onClick={(e) => handleDetailsAction(i)
                                                    }>{`INV- `}</span>
                                                    <span onClick={(e) =>i?.invoice_number? handleDetailsAction(i):""
                                                    }>{i?.invoice_number?`# ${i?.invoice_number}`:""}</span>
                                                  </div>
                                                </div>

                                                <div className="_td_rightPt">
                                                  <div className="_td_lrsCommon">
                                                    {i?.lr_process?.length >
                                                      0 &&
                                                      i?.lr_process?.map(
                                                        (k, index) => {
                                                          return (
                                                            <div
                                                              className={`_td_stepsBx ${
                                                                k?.status === 1
                                                                  ? "_td_stepsBx_active"
                                                                  : ""
                                                              }`}
                                                              key={index}
                                                            >
                                                              <div className="_td_stepIcon">
                                                                <svg
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                  width="21"
                                                                  height="21"
                                                                  viewBox="0 0 17 17"
                                                                  fill="none"
                                                                >
                                                                  <circle
                                                                    cx="8.1076"
                                                                    cy="8.1076"
                                                                    r="8.1076"
                                                                    fill="#D9D9D9"
                                                                  ></circle>
                                                                  <path
                                                                    d="M3.47461 7.722L6.5637 10.8111L12.7404 4.6329"
                                                                    stroke="#828282"
                                                                    strokeWidth="1.45609"
                                                                    strokeLinecap="round"
                                                                    strokeLinejoin="round"
                                                                  ></path>
                                                                </svg>
                                                              </div>

                                                              <div className="_td_stepInfos">
                                                                <p>
                                                                  {" "}
                                                                  {k?.name}
                                                                </p>
                                                                <span>
                                                                  {k?.status ===
                                                                  1
                                                                    ? utils.formatDateMonth(
                                                                        k?.date
                                                                      )
                                                                    : ""}
                                                                </span>
                                                              </div>
                                                            </div>
                                                          );
                                                        }
                                                      )}
                                                  </div>

                                                  <button
                                                    disabled={i?.lr_status == 6}
                                                    className="_atPr_point"
                                                    onClick={() => {
                                                      handleButtonClick(i);
                                                    }}
                                                  >
                                                    {handleButtonText(
                                                      i?.lr_status
                                                    )}
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                            <button
                                              className="closeTble_btn"
                                              type="button"
                                            >
                                              <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width="56"
                                                height="29"
                                                viewBox="0 0 56 29"
                                                fill="none"
                                              >
                                                <rect
                                                  x="56"
                                                  y="29"
                                                  width="56"
                                                  height="29"
                                                  rx="14.5"
                                                  transform="rotate(180 56 29)"
                                                  fill="#434343"
                                                />
                                                <path
                                                  d="M41.9806 10.2104C41.9162 10.1458 41.8396 10.0946 41.7554 10.0596C41.6711 10.0247 41.5807 10.0067 41.4895 10.0067C41.3983 10.0067 41.3079 10.0247 41.2237 10.0596C41.1394 10.0946 41.0629 10.1458 40.9984 10.2104L37.592 13.6098L34.1856 10.2034C34.1211 10.1389 34.0446 10.0878 33.9603 10.0529C33.876 10.018 33.7857 10 33.6945 10C33.6033 10 33.513 10.018 33.4287 10.0529C33.3445 10.0878 33.2679 10.1389 33.2034 10.2034C33.1389 10.2679 33.0878 10.3445 33.0529 10.4287C33.018 10.513 33 10.6033 33 10.6945C33 10.7857 33.018 10.876 33.0529 10.9603C33.0878 11.0446 33.1389 11.1211 33.2034 11.1856L36.6098 14.592L33.2034 17.9984C33.1389 18.0629 33.0878 18.1395 33.0529 18.2237C33.018 18.308 33 18.3983 33 18.4895C33 18.5807 33.018 18.671 33.0529 18.7553C33.0878 18.8396 33.1389 18.9161 33.2034 18.9806C33.2679 19.0451 33.3445 19.0963 33.4287 19.1312C33.513 19.1661 33.6033 19.184 33.6945 19.184C33.7857 19.184 33.876 19.1661 33.9603 19.1312C34.0446 19.0963 34.1211 19.0451 34.1856 18.9806L37.592 15.5742L40.9984 18.9806C41.0629 19.0451 41.1395 19.0963 41.2237 19.1312C41.308 19.1661 41.3983 19.184 41.4895 19.184C41.5807 19.184 41.671 19.1661 41.7553 19.1312C41.8396 19.0963 41.9161 19.0451 41.9806 18.9806C42.0451 18.9161 42.0963 18.8396 42.1312 18.7553C42.1661 18.671 42.184 18.5807 42.184 18.4895C42.184 18.3983 42.1661 18.308 42.1312 18.2237C42.0963 18.1395 42.0451 18.0629 41.9806 17.9984L38.5742 14.592L41.9806 11.1856C42.2453 10.9209 42.2453 10.4751 41.9806 10.2104Z"
                                                  fill="white"
                                                />
                                              </svg>
                                            </button>
                                          </h2>

                                          <div
                                            id={`collapse${index1}${index3}`}
                                            className="accordion-collapse collapse"
                                            data-bs-parent="#accordionExample2"
                                          >
                                            <div className="accordion-body">
                                              <table className="ctmTable">
                                                <thead>
                                                  <tr className="accordiTr">
                                                    <th>Date</th>
                                                    <th>From Station</th>
                                                    <th>To Station</th>
                                                    <th>Invoice Number</th>
                                                    <th>Qty</th>
                                                    <th>Weight</th>
                                                    <th>KOT</th>
                                                    <th>Eway Bill</th>
                                                    <th>Expiry Date</th>
                                                  </tr>
                                                </thead>

                                                <tbody>
                                                  <tr>
                                                    <td>
                                                      <span>
                                                        {" "}
                                                        {moment(
                                                          i?.indent_date
                                                        ).format(
                                                          " D  MMM YYYY"
                                                        )}
                                                      </span>
                                                      <br />
                                                      <span className="sameSpan">
                                                        5:30 PM
                                                      </span>
                                                    </td>

                                                    <td>
                                                      <span className="sameSpan fw-500">
                                                        {i?.from_location}
                                                      </span>
                                                      <br />
                                                      <span className="sameSpan">
                                                        {i?.unit_id?.unit_code
                                                          ? i?.unit_id
                                                              ?.unit_code
                                                          : " -"}{" "}
                                                        |{" "}
                                                        {i?.unit_id?.name
                                                          ? i?.unit_id?.name
                                                          : " -"}
                                                      </span>
                                                    </td>

                                                    <td>
                                                      <span className="sameSpan fw-500">
                                                        {i?.to_location}
                                                      </span>
                                                      <br />
                                                      <span className="sameSpan">
                                                        {i?.consignee_id
                                                          ?.unit_code
                                                          ? i?.consignee_id
                                                              ?.unit_code
                                                          : " -"}{" "}
                                                        |{" "}
                                                        {i?.consignee_id?.name
                                                          ? i?.consignee_id
                                                              ?.name
                                                          : "-"}
                                                      </span>
                                                    </td>

                                                    <td>
                                                      <span className="_invoicSpan" onClick={(e) =>i?.invoice_number? handleDetailsAction(i):""
                                                    }>
                                                        {i?.invoice_number
                                                          ? i?.invoice_number
                                                          : "-"}
                                                      </span>
                                                      <br />
                                                      <span className="sameSpan">
                                                        {i?.po_number
                                                          ? i?.po_number
                                                          : "-"}
                                                      </span>
                                                    </td>

                                                    <td>
                                                      <span className="sameSpan">
                                                        {i?.total_qty}
                                                      </span>
                                                    </td>

                                                    <td>
                                                      <span className="sameSpan">
                                                        {i?.total_weight}
                                                      </span>
                                                    </td>

                                                    <td>
                                                      <span className="sameSpan">
                                                        {i?.total_kot}
                                                      </span>
                                                    </td>

                                                    <td>
                                                      <span className="sameSpan">
                                                        {i?.eway_bill_number
                                                          ? i?.eway_bill_number
                                                          : ""}
                                                      </span>
                                                    </td>

                                                    <td>
                                                      <span className="sameSpan">
                                                        {moment(
                                                          i?.indent_date
                                                        ).format(
                                                          " D  MMM YYYY"
                                                        )}
                                                      </span>
                                                    </td>
                                                  </tr>
                                                </tbody>
                                              </table>
                                            </div>
                                          </div>
                                        </div>
                                      </>
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>{" "}
                        </>
                      );
                    }
                  })}
                  {toLoction?.map((data, index1) => {
                    if (index1 === index) {
                      data?.lr_list?.map((i, innerIndex) => {
                        
                        return (
                          <div className="col-md-12 col-lg-10 col-xl-10">
                            <div className="_spaceTble">
                              {/* {tripTable?.map((data, index3) => {
                          return ( */}
                              <div
                                className="accordionTable_view"
                                // key={`TripTable_${index3}`}
                              >
                                <div
                                  className="accordion"
                                  id="accordionExample2"
                                >
                                  <>
                                    <div
                                      className="accordion-item"
                                      // key={`TripTable_${index3}${index1}`}
                                    >
                                      <h2 className="accordion-header">
                                        <div className="PickNdrop_placeBx">
                                          <div className="_cnFr_listsBx">
                                            <div>
                                              <div
                                                className="_inrPd"
                                                bis_skin_checked="1"
                                              >
                                                {/* <h4>{i?.from_location}</h4> */}
                                                <img
                                                  src={
                                                    Images.ROUTTRUCKICON.default
                                                  }
                                                  alt=""
                                                />
                                                {/* <h4>{i?.to_location}</h4> */}
                                                <button
                                                  className={`accordion-button collapsed`}
                                                  type="button"
                                                  data-bs-toggle="collapse"
                                                  data-bs-target={`#collapse`}
                                                  aria-expanded="true"
                                                  aria-controls={`collapse`}
                                                >
                                                  <svg
                                                    className="cstCevs"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    width="18"
                                                    height="18"
                                                    viewBox="0 0 18 18"
                                                    fill="none"
                                                  >
                                                    <path
                                                      d="M4.91862 6.69936C4.87178 6.6502 4.81668 6.61176 4.75647 6.58625C4.69625 6.56074 4.63211 6.54865 4.56769 6.55068C4.50327 6.5527 4.43984 6.5688 4.38102 6.59805C4.3222 6.6273 4.26915 6.66913 4.22488 6.72116C4.18062 6.77319 4.14602 6.83439 4.12305 6.90127C4.10008 6.96815 4.08919 7.0394 4.09102 7.11095C4.09284 7.18251 4.10733 7.25296 4.13367 7.31829C4.16 7.38362 4.19767 7.44255 4.24451 7.49172L8.6601 12.1238C8.75119 12.2194 8.87179 12.2727 8.99715 12.2727C9.12251 12.2727 9.24312 12.2194 9.33421 12.1238L13.7503 7.49172C13.7982 7.44288 13.8368 7.38396 13.864 7.31839C13.8912 7.25282 13.9064 7.1819 13.9087 7.10975C13.911 7.0376 13.9004 6.96566 13.8774 6.89811C13.8545 6.83056 13.8197 6.76875 13.7751 6.71626C13.7305 6.66377 13.6769 6.62165 13.6175 6.59235C13.5581 6.56305 13.494 6.54715 13.4291 6.54558C13.3641 6.54401 13.2995 6.5568 13.239 6.5832C13.1785 6.60959 13.1233 6.64908 13.0767 6.69936L8.99715 10.9783L4.91862 6.69936Z"
                                                      fill="#1450EC"
                                                    />
                                                    <circle
                                                      cx="9"
                                                      cy="9"
                                                      r="8.59091"
                                                      transform="rotate(-180 9 9)"
                                                      stroke="#0049D7"
                                                      strokeWidth="0.818182"
                                                    />
                                                  </svg>
                                                </button>
                                              </div>
                                              <div className="_pdTransport_dl">
                                                <h4></h4>
                                                <span>{`INV- `}</span>
                                                <span># </span>
                                              </div>
                                            </div>

                                            <div className="_td_rightPt">
                                              <div className="_td_lrsCommon">
                                                <div className="_td_stepsBx _td_stepsBx_active">
                                                  <div className="_td_stepIcon">
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      width="21"
                                                      height="21"
                                                      viewBox="0 0 17 17"
                                                      fill="none"
                                                    >
                                                      <circle
                                                        cx="8.1076"
                                                        cy="8.1076"
                                                        r="8.1076"
                                                        fill="#D9D9D9"
                                                      ></circle>
                                                      <path
                                                        d="M3.47461 7.722L6.5637 10.8111L12.7404 4.6329"
                                                        stroke="#828282"
                                                        strokeWidth="1.45609"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                      ></path>
                                                    </svg>
                                                  </div>

                                                  <div className="_td_stepInfos">
                                                    <p>Intransit</p>
                                                    <span>12 Jan 24</span>
                                                  </div>
                                                </div>

                                                <div className="_td_stepsBx">
                                                  <div className="_td_stepIcon">
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      width="21"
                                                      height="21"
                                                      viewBox="0 0 17 17"
                                                      fill="none"
                                                    >
                                                      <circle
                                                        cx="8.1076"
                                                        cy="8.1076"
                                                        r="8.1076"
                                                        fill="#D9D9D9"
                                                      ></circle>
                                                      <path
                                                        d="M3.47461 7.722L6.5637 10.8111L12.7404 4.6329"
                                                        stroke="#828282"
                                                        strokeWidth="1.45609"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                      ></path>
                                                    </svg>
                                                  </div>

                                                  <div className="_td_stepInfos">
                                                    <p>Party Point</p>
                                                    <span>12 Jan 24</span>
                                                  </div>
                                                </div>

                                                <div className="_td_stepsBx">
                                                  <div className="_td_stepIcon">
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      width="21"
                                                      height="21"
                                                      viewBox="0 0 17 17"
                                                      fill="none"
                                                    >
                                                      <circle
                                                        cx="8.1076"
                                                        cy="8.1076"
                                                        r="8.1076"
                                                        fill="#D9D9D9"
                                                      ></circle>
                                                      <path
                                                        d="M3.47461 7.722L6.5637 10.8111L12.7404 4.6329"
                                                        stroke="#828282"
                                                        strokeWidth="1.45609"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                      ></path>
                                                    </svg>
                                                  </div>

                                                  <div className="_td_stepInfos">
                                                    <p>Delivered</p>
                                                    <span></span>
                                                  </div>
                                                </div>

                                                <div className="_td_stepsBx">
                                                  <div className="_td_stepIcon">
                                                    <svg
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      width="21"
                                                      height="21"
                                                      viewBox="0 0 17 17"
                                                      fill="none"
                                                    >
                                                      <circle
                                                        cx="8.1076"
                                                        cy="8.1076"
                                                        r="8.1076"
                                                        fill="#D9D9D9"
                                                      ></circle>
                                                      <path
                                                        d="M3.47461 7.722L6.5637 10.8111L12.7404 4.6329"
                                                        stroke="#828282"
                                                        strokeWidth="1.45609"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                      ></path>
                                                    </svg>
                                                  </div>

                                                  <div className="_td_stepInfos">
                                                    <p>Bill Raised</p>
                                                    <span></span>
                                                  </div>
                                                </div>
                                              </div>

                                              <button className="_atPr_point">
                                                At Party Point
                                              </button>
                                            </div>
                                          </div>
                                        </div>
                                        <button
                                          className="closeTble_btn"
                                          type="button"
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="56"
                                            height="29"
                                            viewBox="0 0 56 29"
                                            fill="none"
                                          >
                                            <rect
                                              x="56"
                                              y="29"
                                              width="56"
                                              height="29"
                                              rx="14.5"
                                              transform="rotate(180 56 29)"
                                              fill="#434343"
                                            />
                                            <path
                                              d="M41.9806 10.2104C41.9162 10.1458 41.8396 10.0946 41.7554 10.0596C41.6711 10.0247 41.5807 10.0067 41.4895 10.0067C41.3983 10.0067 41.3079 10.0247 41.2237 10.0596C41.1394 10.0946 41.0629 10.1458 40.9984 10.2104L37.592 13.6098L34.1856 10.2034C34.1211 10.1389 34.0446 10.0878 33.9603 10.0529C33.876 10.018 33.7857 10 33.6945 10C33.6033 10 33.513 10.018 33.4287 10.0529C33.3445 10.0878 33.2679 10.1389 33.2034 10.2034C33.1389 10.2679 33.0878 10.3445 33.0529 10.4287C33.018 10.513 33 10.6033 33 10.6945C33 10.7857 33.018 10.876 33.0529 10.9603C33.0878 11.0446 33.1389 11.1211 33.2034 11.1856L36.6098 14.592L33.2034 17.9984C33.1389 18.0629 33.0878 18.1395 33.0529 18.2237C33.018 18.308 33 18.3983 33 18.4895C33 18.5807 33.018 18.671 33.0529 18.7553C33.0878 18.8396 33.1389 18.9161 33.2034 18.9806C33.2679 19.0451 33.3445 19.0963 33.4287 19.1312C33.513 19.1661 33.6033 19.184 33.6945 19.184C33.7857 19.184 33.876 19.1661 33.9603 19.1312C34.0446 19.0963 34.1211 19.0451 34.1856 18.9806L37.592 15.5742L40.9984 18.9806C41.0629 19.0451 41.1395 19.0963 41.2237 19.1312C41.308 19.1661 41.3983 19.184 41.4895 19.184C41.5807 19.184 41.671 19.1661 41.7553 19.1312C41.8396 19.0963 41.9161 19.0451 41.9806 18.9806C42.0451 18.9161 42.0963 18.8396 42.1312 18.7553C42.1661 18.671 42.184 18.5807 42.184 18.4895C42.184 18.3983 42.1661 18.308 42.1312 18.2237C42.0963 18.1395 42.0451 18.0629 41.9806 17.9984L38.5742 14.592L41.9806 11.1856C42.2453 10.9209 42.2453 10.4751 41.9806 10.2104Z"
                                              fill="white"
                                            />
                                          </svg>
                                        </button>
                                      </h2>

                                      <div
                                        id={`collapse`}
                                        className="accordion-collapse collapse"
                                        data-bs-parent="#accordionExample2"
                                      >
                                        <div className="accordion-body">
                                          <table className="ctmTable">
                                            <thead>
                                              <tr className="accordiTr">
                                                <th>Date</th>
                                                <th>From Station</th>
                                                <th>To Station</th>
                                                <th>Invoice Number</th>
                                                <th>Qty</th>
                                                <th>Weight</th>
                                                <th>KOT</th>
                                                <th>Eway Bill</th>
                                                <th>Expiry Date</th>
                                              </tr>
                                            </thead>

                                            <tbody>
                                              <tr>
                                                <td>
                                                  <span></span>
                                                  <br />
                                                  <span
                                                    className="sameSpan"
                                                    onClick={(e) => {
                                                     
                                                      handleDetailsAction(i);
                                                    }}
                                                  >
                                                    5:30 PM
                                                  </span>
                                                </td>

                                                <td>
                                                  <span className="sameSpan fw-500">
                                                    {/* {i?.from_location} */}
                                                  </span>
                                                  <br />
                                                  <span className="sameSpan">
                                                    {/* {i?.unit_id
                                                                ?.unit_code
                                                                ? i?.unit_id
                                                                    ?.unit_code
                                                                : " -"}{" "}
                                                              |{" "}
                                                              {i?.unit_id?.name
                                                                ? i?.unit_id?.name
                                                                : " -"} */}
                                                  </span>
                                                </td>

                                                <td>
                                                  <span className="sameSpan fw-500">
                                                    {/* {i?.to_location} */}
                                                  </span>
                                                  <br />
                                                  <span className="sameSpan">
                                                    {/* {i?.consignee_id
                                                                ?.unit_code
                                                                ? i?.consignee_id
                                                                    ?.unit_code
                                                                : " -"}{" "}
                                                              |{" "}
                                                              {i?.consignee_id
                                                                ?.name
                                                                ? i?.consignee_id
                                                                    ?.name
                                                                : "-"} */}
                                                  </span>
                                                </td>

                                                <td>
                                                  <span className="_invoicSpan">
                                                    {/* {i?.invoice_number
                                                                ? i?.invoice_number
                                                                : "-"} */}
                                                  </span>
                                                  <br />
                                                  <span className="sameSpan">
                                                    {/* {i?.po_number
                                                                ? i?.po_number
                                                                : "-"} */}
                                                  </span>
                                                </td>

                                                <td>
                                                  <span className="sameSpan">
                                                    {/* {i?.total_qty} */}
                                                  </span>
                                                </td>

                                                <td>
                                                  <span className="sameSpan">
                                                    {/* {i?.total_weight} */}
                                                  </span>
                                                </td>

                                                <td>
                                                  <span className="sameSpan">
                                                    {/* {i?.total_kot} */}
                                                  </span>
                                                </td>

                                                <td>
                                                  <span className="sameSpan"></span>
                                                </td>

                                                <td>
                                                  <span className="sameSpan"></span>
                                                </td>
                                              </tr>
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      });
                    }
                  })}
                </>
              ))}
            </div>
          </div>
        </div>
      </div>

      {isOpenSmallModal && <AtPartyPointModal {...modalProps} />}
      {showAvailableTruck && <MakeTruckAvailableModal {...makeTruckModal} />}
      <div
        className={`offcanvas offcanvas-end common_offCanvas xxlOffcanvas ${
          showLrDetail ? "show" : ""
        }`}
        tabIndex={-1}
        style={{ visibility: showLrDetail ? "visible" : "hidden" }}
      >
        {showLrDetail && (
          <LrDetailModal
            isOpen={showLrDetail}
            onClose={(e) => setShowLrDetail(false)}
            nextModal={(e) => handleNextModal(e)}
          />
        )}
      </div>
      <div className={showLrDetail ? "offBack_drop" : ""}></div>

      <div
        className={`offcanvas offcanvas-end common_offCanvas xxlOffcanvas ${
          showAddLR ? "show" : ""
        }`}
        tabIndex={-1}
        style={{ visibility: showAddLR ? "visible" : "hidden" }}
      >
        {showAddLR && (
          <AddEditLrModal
            isOpen={showAddLR}
            onClose={() => setShowAddLR(false)}
            callBackDataLr={callBackDataLr}
            type="from_trip"
            callGetData={()=>{}}
          />
        )}
      </div>
      <div className={showAddLR ? "offBack_drop" : ""}></div>
    </>
  );
};

export default DispatchDetails;
