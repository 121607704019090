import { planImport } from "_services";
import {
  getConsignorDropdownListData,
  getContractDropdownListData,
} from "_services/dropdownServices/dropdownService";
import { CommonInput, CustomSelect, ExcelDownload, Heading } from "components";
import { errorSchema, utils } from "helper";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import StorageService from "utils/StorageServices";

const ParleBill = () => {
  const tableData = [
    { name: "John", age: 28, city: "New York" },
    { name: "Jane", age: 22, city: "San Francisco" },
    // more data
  ];

  const initial = {
    company_id: StorageService?.getCompanyId(),
    consignor_id: "",
    contract_id: "",
    branch_id: "",
    file: "",
  };
  const navigate = useNavigate();
  const [form, setForm] = useState(initial);
  const [formError, setFormError] = useState("");
  const [loading, setLoading] = useState(false);
  const [consignorList, setConsignorList] = useState([]);
  const [listData, setListData] = useState({
    consignorList: [],
    contractList: [],
    branchList: [],
  });
  const [hasMoreVehicle, setHasMoreVehicle] = useState(true);
  const [page, setPage] = useState(1);
  const [planInportList,setPlanInportList] = useState([])
  useEffect(() => {
    fetchPartyList();
  }, []);

  useEffect(() => {
    if (form?.consignor_id) {
      getContractList(form?.consignor_id);
    }
  }, [form?.consignor_id]);

  const handleOnChange = async (name, value) => {
    setForm((prevForm) => {
      const updatedForm = {
        ...prevForm,
        [name]: value,
      };

      if (formError) {
        utils
          .checkFormError(
            {
              ...updatedForm,
            },
            errorSchema.addPostIndentSchima
          )
          .then((validationResult) => {
            if (validationResult === true) {
              setFormError("");
            } else {
              setFormError(validationResult);
            }
          });
      }

      return updatedForm;
    });
  };

  //file

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const validTypes = [
        "text/csv",
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      ];
      if (validTypes.includes(file.type)) {
        handleOnChange("file", file);
        setFormError({
          ...formError,
          file: "",
        });
      } else {
        setFormError({
          ...formError,
          file: "Please upload only CSV or XLSX files.",
        });
      }
    }
  };

  //common Api function
  const commonFetchFuction = async (
    apiMethod,
    payload = {},
    transformer = (res) => res
  ) => {
    try {
      const res = await apiMethod(payload);
      if (res?.responseCode === 200) {
        return transformer(res);
      } else {
        toast.error(res?.responseMessage);
      }
    } catch (error) {
      toast.error(error?.message || "Something went wrong");
    }
  };

  //common formate for dropdown

  const formatList = (list, labelKey, valueKey, defaultOption = null) => {
    const formattedList = list?.map((item) => ({
      ...item,
      label: item[labelKey],
      value: item[valueKey],
    }));
    return defaultOption
      ? [{ label: defaultOption, value: "" }, ...formattedList]
      : formattedList;
  };

  //set initial value

  const setInitialFormValues = (name, value) => {
    handleOnChange(name, value || "");
  };

  const getConsignorList = async (page = 1, limit = 10) => {
    let consignorRes = await commonFetchFuction(getConsignorDropdownListData, {
      company_id: StorageService.getCompanyId(),
      offset: page,
      limit,
    });

    return consignorRes;
  };

  const fetchPartyList = async (nextPage = 1) => {
    try {
      const res = await getConsignorList(nextPage, 10);

      if (res?.responseCode === 200) {
        const newVehicles = res?.data?.consignor_list.map((i) => ({
          ...i,
          label: i.name,
          value: i._id,
        }));

        if (nextPage == 1) {
          setConsignorList(newVehicles);
          setInitialFormValues(
                  "consignor_id",
                  newVehicles[0]?._id
                );
        } else {
          setConsignorList((prevList) => [...prevList, ...newVehicles]);
        }

        if (newVehicles.length < 10) {
          setHasMoreVehicle(false);
        } else {
          setHasMoreVehicle(true);
          setPage(nextPage + 1);
        }
      } else {
        toast.error(res?.responseMessage);
      }
    } catch (error) {
      console.error("Error fetching vehicle list:", error);
      toast.error("Error fetching vehicle list.");
    }
  };

 
  const getContractList = async (contarct) => {
    const payload = {
      company_id: StorageService.getCompanyId(),
      consignor_id: contarct,
      limit: 100,
      offset: 1,
      search: "",
    };
    try {
      const contractRes = await commonFetchFuction(
        getContractDropdownListData,
        payload
      );

      setListData({
        ...listData,
        contractList: formatList(
          contractRes?.data?.contract_list,
          "name",
          "_id"
        ),
      });

      setInitialFormValues(
        "contract_id",
        contractRes?.data?.contract_list[0]?._id
      );
    } catch (error) {
      toast.error("Error fetching data", error);
    }
  };

  const handleImportFile = async () => {
    const formData = new FormData();
    Object.keys(form).forEach((key) => {
      if (key === "file" && form[key]) {
        formData.append(key, form[key]);
      } else {
        formData.append(key, form[key]);
      }
    });

    const validationResult = await utils.checkFormError(
      { ...form },
      errorSchema.planImportSchema
    );
    if (validationResult == true) {
      setLoading(true);
      try {
        const res = await planImport(formData);

        if (res?.responseCode == 200) {
          setLoading(false);
          setPlanInportList(res?.data?.results)
          toast?.success(res?.responseMessage);
          // toast(
          //   `
          //         New plan count:${res?.data?.new_plan_count}
    
          //         New po count:${res?.data?.new_po_count}
    
          //          Re-Direct to planList ...
          //         `,
          //   {
          //     duration: 3000,
          //   }
          // );
          const execleDat = res?.data?.results?.map((i)=>{
            return {
              "Indent Number":i?.name,
              "status":i?.status,
              "remark":i?.remark

            }
          })
        } else {
          setLoading(false);
        }
      } catch (error) {
        toast.error(error);
      }
    } else {
      setFormError(validationResult);
    }
  };

  return (
    <>
      <Heading title="Parle Indent Update" />

      <div className="_Qwk_inr">
        <div className="_tierPart">
          <CustomSelect
            label="Select Party"
            placeholder="Party"
            options={consignorList}
            value={consignorList?.find(
              (i) => i?.value == form?.consignor_id
            )}
            onSelect={(data) => {
              handleOnChange("consignor_id", data?.value);
            }}
            enableInfiniteScroll={true}
            fetchMoreData={() => fetchPartyList(page)}
            hasMore={hasMoreVehicle}
          />
        </div>

        <div className="_tierPart">
          <CustomSelect
            label="Contract"
            placeholder={"Select Contract"}
            options={listData?.contractList}
            value={listData?.contractList?.find(
              (i) => i?.value == form?.contract_id
            )}
            onSelect={(data) => {
              handleOnChange("contract_id", data?.value);
            }}
          />
        </div>

        <div className="_exceluploadBx">
          <CommonInput
            type="file"
            onChange={handleFileChange}
            accept=".csv, .xlsx"
            error={formError?.file}
          />
        </div>

        <button
          className="_flUpload_btn"
          disabled={loading}
          onClick={() => handleImportFile()}
        >
          {loading ? "Uploading.." : "Upload File"}
        </button>
      </div>

      {planInportList?.length > 0 && <div className="tbdFilts_bx">
        <div className="_hedFilts">
          <h4>Status</h4> <ExcelDownload tableData={tableData} />
        </div>

        <div className="_tbdOx_bx">
          <div className="_fourTbx">
            <h4>100</h4>
            <p className="__successTxt">All</p>
          </div>

          <div className="_fourTbx">
            <h4>100</h4>
            <p className="__successTxt">Added</p>
          </div>

          <div className="_fourTbx">
            <h4>100</h4>
            <p className="__successTxt">Updated</p>
          </div>

          <div className="_fourTbx">
            <h4>100</h4>
            <p className="__rejectTxe">Not Updated</p>
          </div>
        </div>
      </div>}

      {planInportList?.length > 0 && <div className="_qwkTable_dix">
        <div className="_indentTable _disPatchView _fuelCrd__table">
          <table>
            <thead>
              <tr>
                <th>Indent Number</th>
                <th>Status</th>
                <th>Remarks</th>
              </tr>
            </thead>

            <tbody>
            {planInportList?.length && planInportList?.map((i)=>{
          return <tr>
                <td>
                  <div className="_dateNtime _pDate">
                    <p className="_tblText_inDent">{i?.value?.name}</p>
                  </div>
                </td>

                <td>
                  <div className="_dateNtime _pDate">
                    <p className="_tblText_inDent __successTxt">{i?.status}</p>
                  </div>
                </td>

                <td>
                  <div className="_dateNtime _pDate">
                    <p className="_tblText_inDent">{i?.remarks}</p>
                  </div>
                </td>
              </tr>        })}

            </tbody>
          </table>
        </div>
      </div>}
    </>
  );
};

export default ParleBill;
