

import React, { useEffect } from 'react';
import { usePlacesWidget } from "react-google-autocomplete";
import CommonInput from '../CommonInput';

const MapInput = ({ label, handleInputChange,placeholder="Location" }) => {
  const { ref } = usePlacesWidget({
    apiKey: "AIzaSyAkWxbuO-maU16USeyELd3UP0hPLITP3Ec",
    onPlaceSelected: (place) => {
      handleInputChange(place); 
    },
    options: {
      types: ['geocode'], 
      componentRestrictions: { country: 'in' },
    },
  });

  return (
    <div className="anvCustom_floatInput form-floating">
      <input
        id={label}
        className="form-control"
        placeholder={placeholder}
        autoComplete='off'
        ref={ref} 
      />
      <label htmlFor={label}>
        <span>{label}</span>
      </label>
    </div>
  );
};

export default MapInput;
