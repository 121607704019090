
import { IoChevronBack, IoChevronForward } from 'react-icons/io5';

const CustomPagination = ({ 
  totalItems, 
  itemsPerPage, 
  currentPage, 
  onPageChange 
}) => {
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  let pages = [];

  const createPageItem = (i) => (
    <li
      key={i}
      className={`page-item ${i === currentPage ? 'active' : ''}`}
    >
      <a
        className="page-link _avPointer"
        onClick={() => onPageChange(i)}
      >
        {i}
      </a>
    </li>
  );


  pages.push(createPageItem(1));

 
  const rangeStart = Math.max(2, currentPage - 2);
  if (rangeStart > 2) {
    pages.push(
      <li key="dots-before" className="page-item disabled">
        <span className="page-link">...</span>
      </li>
    );
  }

 
  const rangeEnd = Math.min(totalPages - 1, currentPage + 2);
  for (let i = rangeStart; i <= rangeEnd; i++) {
    pages.push(createPageItem(i));
  }

  
  if (rangeEnd < totalPages - 1) {
    pages.push(
      <li key="dots-after" className="page-item disabled">
        <span className="page-link">...</span>
      </li>
    );
  }


  if (totalPages > 1) {
    pages.push(createPageItem(totalPages));
  }

  return (
    <ul className="pagination">
      {/* Previous Button */}
      <li
       className={`page-item ${currentPage == 1 || totalPages == 1 ? 'disabled' : ''}`}
      >
        <a
          className="page-link _avPointer"
          onClick={() => onPageChange(currentPage - 1)}
        >
          <IoChevronBack />
        </a>
      </li>

      {pages}

      {/* Next Button */}
      <li
        className={`page-item ${currentPage == totalPages || totalPages == 1 ? 'disabled' : ''}`}
      >
        <a
          className="page-link _avPointer"
          onClick={() => onPageChange(currentPage + 1)}
        >
          <IoChevronForward />
        </a>
      </li>
    </ul>
  );
};



export default CustomPagination;
