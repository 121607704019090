import React, { useContext, useEffect, useState } from "react";
import { fetchBranchDetailsData } from "_services/accountsServices/branchServices";
import { CommonInput, CustomSelect } from "components";
import { UserContext } from "context";
import { utils } from "helper";
import { useNavigate, useParams } from "react-router-dom";
import StorageService from "utils/StorageServices/StorageServices";
import toast from "react-hot-toast";
import { AddButton } from "components/common/CommonButton";
import { FiEdit } from "react-icons/fi";
import { updateBranchOtherSetting } from "_services/accountsServices/consignorServices";

const BranchOtherDetailsTab = ({ onDataFetch }) => {
  let { id } = useParams();

  const initialForm = {
    company_id: StorageService.getCompanyId(),
    branch_id: id,
    fssai_licence: "",
    terms_conditions: "",
    lr_bilty_format: "",
  };

  const navigate = useNavigate();
  const { masterData } = useContext(UserContext);
  const [form, setForm] = useState(initialForm);
  const [detailsData, setDetailsData] = useState({});
  const [loading, setLoading] = useState(false);
  const companyId = StorageService.getCompanyId();
  const [builtyFormat, setBuiltyFormat] = useState([]);
  const [isEdit, setIsEdit] = useState(false);

  const getDataBranchDetails = async (id_1, id_2) => {
    try {
      let object = {
        branch_id: id_1,
        company_id: id_2,
      };
      const res = await fetchBranchDetailsData(object);
      if (res?.responseCode == 200) {
        setDetailsData(res?.data?.branch);
        setForm({...form,
            fssai_licence:res?.data?.branch?.fssai_licence,
            terms_conditions:res?.data?.branch?.terms_conditions,
            lr_bilty_format:res?.data?.branch?.lr_bilty_format,
        })
        onDataFetch(res?.data?.branch);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      // throw error;
    }
  };

  useEffect(() => {
    if (id) {
      setLoading(true);
      getDataBranchDetails(id, companyId);
    }
  }, [id]);

  useEffect(() => {
    if (detailsData && masterData) {
      const biltyObject = masterData?.lr_bilty_format_list?.map((item) => {
        return {
          label: item?.name,
          value: item?.id,
        };
      });
      setBuiltyFormat(biltyObject);
    }
  }, [detailsData, masterData]);

  const handleChange = async (name, value) => {
    const stateObj = {
      ...form,
      [name]: value,
    };

    setForm(stateObj);
  };

  const submitSettings = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
        const res = await updateBranchOtherSetting(form);
        if (res?.responseCode == 200) {
          toast.success(`Settings Update`);
          setLoading(false);
          getDataBranchDetails(id, StorageService.getCompanyId());
        } else {
          toast.error("Error , Settings Not Updated");
          setLoading(false);
        }
    } catch (error) {
      setLoading(false);
      toast.error("Error , Settings Not Updated");
      // throw error;
    }
  };

  return (
    <div>
     
        <div className="_addContainer2 ">
          <div className="_ptyDetail_bx">
            <div className="inrPrt_bxs">
              <div className="_inrPrt_head">Basic Details</div>
              <div className="_inrPrt_flx">
                <div className="_divForth">
                  <CommonInput
                    type="text"
                    placeholder="FSSAI LICENCE"
                    label="FSSAI LICENCE"
                    name="fssai_licence"
                    value={form?.fssai_licence}
                    onChange={({ target: { name, value } }) =>
                      handleChange(name, value)
                    }
                    disabled={!isEdit}
                  />
                </div>

                <div className="_divForth">
                  <CommonInput
                    type="text"
                    label="Terms & Conditions"
                    placeholder="Terms & Conditions"
                    name="terms_conditions"
                    value={form?.terms_conditions}
                    onChange={({ target: { name, value } }) =>
                      handleChange(name, value)
                    }
                    disabled={!isEdit}
                  />
                </div>

                <div className="_divForth">
                  <CustomSelect
                    label="LR Bilty Format"
                    placeholder="LR Bilty Format"
                    name="lr_bilty_format"
                    options={builtyFormat}
                    onSelect={(data) => {
                      handleChange("lr_bilty_format", data?.value);
                    }}
                    value={builtyFormat?.find(
                      (item) => item?.value == form?.lr_bilty_format
                    )}
                    disabled={!isEdit}
                  />
                </div>
                <div className="_divForth">
                  <div onClick={(e) => setIsEdit(true)} className="_avPointer">
                    <span className="_samAnchor">
                      <span className="editIcon_avt">
                        <FiEdit />
                      </span>
                    </span>
                  </div>
                </div>
              </div>
              <div className="bottomFix">
                <AddButton
                  onClick={(e) => submitSettings(e)}
                  name={loading ? "Loading..." : "Save Changes"}
                  type="button"
                  disabled={
                    loading ||
                    !form?.fssai_licence ||
                    !form?.terms_conditions ||
                    !form?.lr_bilty_format || !isEdit
                  }
                />
                <button className="_cancel_btn" onClick={(e) => navigate(-1)}>
                  {" "}
                  Cancel{" "}
                </button>
              </div>
            </div>
          </div>
        </div>
    
    </div>
  );
};

export default BranchOtherDetailsTab;
