import React, { useEffect, useState, useRef } from 'react'
import { ButtonHeading, CommonInput, CommonModal, CustomPagination } from 'components'
import StorageService from "utils/StorageServices/StorageServices";
import toast, { Toaster } from 'react-hot-toast';
import { Link } from 'react-router-dom';
import { AddEditBranch } from 'modals';
import { deleteBranchData, getBranchListData, changeBranchStatusData } from '_services/accountsServices/branchServices';
import BootstrapTable from 'components/BootstrapTable';
import { BsThreeDotsVertical } from "react-icons/bs";
import { AiOutlineEye } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import { FaRegTrashCan } from "react-icons/fa6";
import { IoChevronBack, IoChevronForward } from "react-icons/io5";
import MastersFilterModal from 'modals/MastersFilterModal';
import LoadingBar from 'react-top-loading-bar';

const Branch = () => {

    const loadingBarRef = useRef(null);
    const [showOffcanvas, setShowOffcanvas] = useState(false);
    const [showFilterModal, setShowFilterModal] = useState(false);

    const [branchList, setBranchList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [isDelete, setIsDelete] = useState(false);

    const itemsPerPage = 10;
    const [totalItems, setTotalItems] = useState(0);
    const [filter, setFilter] = useState({
        limit: 10,
        offset: 1,
        search: '',
        status: 1
    });

    const [editBranchData, setEditBranchData] = useState(null)
    const [checkboxStates, setCheckboxStates] = useState({});

    const toggleOffcanvas = () => {
        setShowOffcanvas(!showOffcanvas);
        setEditBranchData(null)
    };

    const getDatabranchList = async () => {
        try {
            if (loadingBarRef.current) {
                loadingBarRef.current.continuousStart(); // Start the loading bar
            }
            let object = {
                ...filter,
                company_id: StorageService.getCompanyId(),
            }
            const res = await getBranchListData(object);
            if (res?.responseCode == 200) {
                setBranchList(res?.data?.branch_list)
                setTotalItems(res?.data?.total_record);
            }
        } catch (error) {
            throw error;
        } finally {
            if (loadingBarRef.current) {
                loadingBarRef.current.complete(); // Complete the loading bar
            }
        }
    }

    useEffect(() => {
        getDatabranchList();
    }, [filter, showOffcanvas, StorageService.getCompanyId()])

    const deleteHandler = (item) => {
        setIsDelete(item)
    }

    const submitDeleteBranch = async () => {
        setLoading(true);
        try {
            let object = {
                company_id: StorageService.getCompanyId(),
                branch_id: isDelete?._id,
            }
            const res = await deleteBranchData(object);
            if (res?.responseCode == 200) {
                toast.success(`${res?.responseMessage}`);
                getDatabranchList();
                setLoading(false);
                setIsDelete(false);
            } else {
                toast.success('Error !! ');
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
            throw error;
        }
    }

    let propsObject = {
        isOpen: isDelete,
        closeModal: setIsDelete,
        modalTitle: "Confirmation",
        submitTitle: "Confirm",
        cancleTitle: "Close",
        onSubmit: submitDeleteBranch,
        isLoadding: loading,
    }

    const columns = [
        { header: "", accessor: "serial" },
        { header: "Branch Name", accessor: "branch_name" },
        { header: "Branch Code", accessor: "branch_code" },
        { header: "City", accessor: "city" },
        { header: "Contact", accessor: "contact" },
        { header: "Email ID", accessor: "email_id" },
        { header: "Status", accessor: "status" },
        { header: "Actions", accessor: "action" },
    ];

    useEffect(() => {
        if (branchList && branchList.length > 0) {
            const initialStates = branchList.reduce((acc, item) => {
                acc[item._id] = Boolean(item?.active);
                return acc;
            }, {});
            setCheckboxStates(initialStates);
        }
    }, [branchList]);

    const handleCheckboxChange = (id) => {
        const newStatus = !checkboxStates[id];
        setCheckboxStates(prevStates => ({ ...prevStates, [id]: newStatus }));

        const object = {
            company_id: StorageService.getCompanyId(),
            branch_id: id,
            active: Number(newStatus)
        };
        statusChangeofBranch(object);
    };

    const statusChangeofBranch = async (obj) => {
        try {
            if (obj) {
                const res = await changeBranchStatusData(obj);
                if (res?.responseCode === 200) {
                    toast.success(`${res?.responseMessage}`);
                } else {
                    toast.error('Error !!');
                    await getDatabranchList();
                }
            }
        } catch (error) {
            toast.error(error.message);
            await getDatabranchList();
        }
    };

    const editHandler = (supplier) => {
        setShowOffcanvas(true);
        setEditBranchData(supplier);
    };

    const RowListData = (data) => {

        return data?.map((item, i) => ({
            ...item,
            // serial: <>{i + 1 + filter?.limit * (filter?.page - 1)}</>,
            serial:
                <React.Fragment>
                    <div className="signDropdown">
                        <button className="dropbtn11">
                            <BsThreeDotsVertical />
                        </button>
                        <div className="dropdownContent">

                            <Link to={`/setting/branch/details/${item?._id}`}
                                className='_avPointer'
                                style={{ textDecoration: 'none' }}
                            >
                                <span className='_samAnchor'>
                                    <span className='viewIcon_avt'>
                                        <AiOutlineEye />
                                    </span>View
                                </span>
                            </Link>

                            <div
                                onClick={(e) => editHandler(item)}
                                className='_avPointer'
                            >
                                <span className='_samAnchor'>
                                    <span className='editIcon_avt'>
                                        <FiEdit />
                                    </span>
                                    Edit
                                </span>
                            </div>

                            <div
                                onClick={(e) => deleteHandler(item)}
                                className='_avPointer'
                            >
                                <span className='_samAnchor'>
                                    <span className='deleteIcon_avt'>
                                        <FaRegTrashCan color='red' />
                                    </span>
                                    Delete
                                </span>
                            </div>
                        </div>
                    </div>
                </React.Fragment>,
            branch_code:
                <React.Fragment>
                    {item?.branch_code ? item?.branch_code : '-'}
                </React.Fragment>,
            branch_name:
                <Link to={`/setting/branch/details/${item?._id}`} style={{ textDecoration: 'none' }}>
                    {item?.branch ? item?.branch : '-'}
                </Link>,
            city:
                <React.Fragment>
                    {item?.city?.label ? item?.city?.label : '-'}
                </React.Fragment>,
            contact:
                <React.Fragment>
                    {item?.contact_nunber ? item?.contact_nunber : '-'}
                </React.Fragment>,
            email_id:
                <React.Fragment>
                    {item?.email_id ? item?.email_id : '-'}
                </React.Fragment>,
            status: (
                <div className="_anvSwitcherNew form-check form-switch" style={{ width: "125px" }}>
                    <input
                        className="form-check-input"
                        type="checkbox"
                        name="actStatus"
                        id={`actStatus-${item._id}`}
                        autoComplete="off"
                        checked={checkboxStates[item._id]}
                        onChange={() => handleCheckboxChange(item._id)}
                    />
                    <span className={checkboxStates[item._id] ? "active_label_masters_status" : "disabled_label_masters_status"}>
                        {checkboxStates[item._id] ? "Active" : "Disabled"}
                    </span>
                </div>
            ),
            action: (
                <React.Fragment>
                    -
                </React.Fragment>
            ),
        }));
    };

    const handlePageChange = (page) => {
        setFilter((s) => ({ ...s, offset: page }))
    };


    return (
        <div>
            <LoadingBar
                color="#ffa800"
                ref={loadingBarRef}
                height={4}
                shadow={true}
                loaderSpeed={1000}
            />
            <ButtonHeading
                title={"Branch"}
                onClick={toggleOffcanvas}
                buttonTitle="Add Branch"
                showSearchFilter={{ search: true, filterOption: true, filterCount: true }}
                onClickFilterModal={(e) => setShowFilterModal(!showFilterModal)}
                filter={filter}
                setFilter={setFilter}
            />

            {/* <div className='p-5'>
                <table className="table table-hover">
                    <thead>
                        <tr>
                            <th scope="col">S No.</th>
                            <th scope="col">Branch Name</th>
                            <th scope="col">Location</th>
                            <th scope="col">Created On</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            branchList?.length > 0 ? (
                                branchList?.map((el, ind) => {
                                    return (
                                        <tr key={ind + 'abc'}>
                                            <th scope="row">{ind + 1}</th>
                                            <td>
                                                <Link to={`/setting/branch/details/${el?._id}`}> {el?.branch} </Link>
                                            </td>
                                            <td>{el?.branch}</td>
                                            <td>{el?.city?.label ? el?.city?.label : '-'}</td>
                                            <td>
                                                <span disabled={loading}>Edit</span> &nbsp; &nbsp;
                                                <span onClick={(e) => deleteHandler(el)}>Delete</span>
                                            </td>
                                        </tr>
                                    )
                                })
                            ) : (
                                <tr > <td> No data available </td> </tr>
                            )
                        }
                    </tbody>
                </table>
            </div> */}

            <div className='_tabWith_outTab'>
                <BootstrapTable
                    columns={columns}
                    data={RowListData(branchList?.length > 0 ? branchList : [])}
                    isLoading={loading}
                />

                {branchList?.length && <div className='paginationFt_avt'>
                <CustomPagination
          totalItems={totalItems}
          itemsPerPage={itemsPerPage}
          currentPage={filter.offset}
          onPageChange={handlePageChange}
        />
                </div>}
            </div>

            <div
                className={`offcanvas offcanvas-end common_offCanvas ${showOffcanvas ? 'show' : ''}`}
                tabIndex={-1}
                style={{ visibility: showOffcanvas ? 'visible' : 'hidden' }}
            >
                {showOffcanvas &&
                    <AddEditBranch
                        isOpen={showOffcanvas}
                        onClose={(e) => setShowOffcanvas(false)}
                        updateBranchData={editBranchData}
                    />
                }
            </div>
            <div className={showOffcanvas ? 'offBack_drop' : ''}></div>

            <div
                className={`offcanvas offcanvas-end common_offCanvas ${showFilterModal ? 'show' : ''}`}
                tabIndex={-1}
                style={{ visibility: showFilterModal ? 'visible' : 'hidden' }}
            >
                {showFilterModal &&
                    <MastersFilterModal
                        isOpen={showFilterModal}
                        onClose={() => setShowFilterModal(false)}
                        filter={filter}
                        setFilter={setFilter}
                    />
                }
            </div>
            <div className={showFilterModal ? 'offBack_drop' : ''}></div>

            {
                isDelete?._id && (
                    <CommonModal {...propsObject}>
                        <p className='p-3'> Do you really want to delete branch {isDelete?.name} </p>
                    </CommonModal>
                )
            }
        </div>
    )
}

export default Branch