import { getAccountingTransaction, getExpense } from "_services";
import { CommonDateRange, CommonInput, CustomPagination, CustomSelect } from "components";
import { constant } from "constants/constant";
import { utils } from "helper";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { FaEnvelope } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import StorageService from "utils/StorageServices/StorageServices";
import Tab from 'components/common/Tab';
import { IoChevronBack, IoChevronForward } from "react-icons/io5";

const TransactionsDetails = () => {

  let runing_balance = 0;
  const [activeTab, setActiveTab] = useState(1);
  const location = useLocation();
  const { data } = location.state;
  const [transactionList, setTransactionList] = useState([]);
  const [expenseList, setExpenseList] = useState([]);
  const [selectedRange, setSelectedRange] = useState({
    startDate: moment().subtract(32, "days"),
    endDate: moment(),
  });

  const itemsPerPage = 100;
  const [totalItems, setTotalItems] = useState(0);
  const [filter, setFilter] = useState({
    limit: 100,
    offset: 1,
    // status: 1,
    // start_date: new Date(),
    // end_date: new Date()
  });

  const columns = [
    { header: "DATE", accessor: "tADate" },
    { header: "VOUCHER NO", accessor: "voucher_no" },
    { header: "TRANSACTION NO", accessor: "transaction_no" },
    { header: "ACCOUNT", accessor: "account_name" },
    { header: "Category", accessor: "category" },
    { header: "TRANSACTION TYPE", accessor: "transactionType" },
    { header: "DESCRIPTION", accessor: "description" },
    { header: "RECEIPT", accessor: "credit_amount" },
    { header: "PAYMENT", accessor: "debit_amount" },
    { header: "RUNNING BALANCE", accessor: "running_balance" },
  ];

  const accessorList = (data) => {
    const listArray = data?.map((item, i) => {
      runing_balance += item?.credit_amount - item?.debit_amount
      return {
        ...item,
        serial: i + 1,
        tADate: (
          <>
            {" "}
            <div className="_dateTbs">{item?.date ? utils.formatDateMonth(item?.date) : '-'}</div>
          </>
        ),
        description: item?.description ? item?.description : '-',
        debit_amount: item?.debit_amount ? item?.debit_amount : '-',
        credit_amount: item?.credit_amount ? item?.credit_amount : '-',
        running_balance: runing_balance ? runing_balance : 0,
        transactionType: (
          <>
            <div>
            {item?.sub_type_name ? `${item?.sub_type_name} | ${item?.type_name}` : "-"}
            </div>
          </>
        ),
        category:(
          <>
            <div>
            {item?.expense_category?.name ? `${item?.expense_category?.name} ` : "-"}
            
            </div>
          </>
        ),
        accountName: (
          <>
            <div>
              {item?.account_name == "Opening Balance" ? <h6>{item?.account_name}</h6> : item?.account_name}
            </div>
          </>
        ),
      }
    });
    return listArray;
  };

  useEffect(() => {
    getExpenseHandler();
  }, [data]);

  useEffect(() => {
    getAccountTransactionListHandler(data);
  }, [selectedRange, activeTab, filter]);

  const handleApply = (newRange) => {
    setSelectedRange(newRange);
    setFilter((s) => ({ ...s, offset: 1 }))
  };

  const getExpenseHandler = async () => {
    try {
      const res = await getExpense({
        type: 0,
      });

      if (res?.responseCode === 200) {
        const details = res?.data?.categories?.map((item) => {
          return {
            label: item?.name,
            value: item?._id,
          };
        });

        setExpenseList(details);
      } else {
        console.log(res?.responseMessage);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAccountTransactionListHandler = async (data) => {
    try {
      const res = await getAccountingTransaction({
        ...filter,
        company_id: StorageService?.getCompanyId(),
        category_id: data?.category_id,
        sub_category_id: data?.sub_category_id,
        account_id: data?.account_id,
        start_date: moment(selectedRange.startDate).format("YYYY-MM-DD"),
        end_date: moment(selectedRange.endDate).format("YYYY-MM-DD"),
        approval_status: activeTab,
      });

      if (res?.responseCode === 200) {
        setTransactionList(res?.data);
        setTotalItems(res?.data?.total_record);
      } else {
        console.log(res?.responseMessage);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const tabProps = {
    tabList: constant.transactionsDetailsTabs,
    callBackTab: (e) => { setActiveTab(e); setFilter((s) => ({ ...s, offset: 1 })) },
    activeTab: activeTab,
  };

  const handlePageChange = (page) => {
    setFilter((s) => ({ ...s, offset: page }))
  };

  const getNumberSign = (number) => {
    const parsedNumber = parseFloat(number);
    if (isNaN(parsedNumber)) return 0;

    return parsedNumber < 0 ? `${number}` : number;
  };

  return (
    <>
      <div className="_trnsBr_flex">
        <div className="_leftBrch_filts">
          <h4>{transactionList?.account_detail?.name}</h4>
          <div className="_brchsBalance">
            <span className={`${transactionList?.available_balance >= 0?"_plusRs":"_minusRs"}`}>{`Balance - ₹ ${getNumberSign(transactionList?.available_balance)}`} </span>
            <span className={`${transactionList?.account_detail?.under_approval_balance >= 0?"_plusRs":"_minusRs"}`}>{`Under Approval - ₹ ${getNumberSign(transactionList?.account_detail?.under_approval_balance)}`} </span>
          </div>
        </div>

        <div className="_rightBrch_filts">
          <div>
            <CommonInput label="Search" />
          </div>
          <div>
            <CustomSelect label="Category" options={expenseList} />
          </div>
          <div>
            <CommonDateRange
              initialStartDate={selectedRange.startDate}
              initialEndDate={selectedRange.endDate}
              onApply={handleApply}
            />
          </div>
          <a href="#" className="_addComm_btn _envlBtn">
            <FaEnvelope />
          </a>
          <button className="_addComm_btn">Export</button>
        </div>
      </div>

      <div className="addPr_flBx">
        <Tab {...tabProps} />
        <div className="_transactionTable">
          <CustomeTable
            columns={columns}
            data={accessorList(transactionList?.list?.length ? transactionList?.list : [])}
          />
          <div className='paginationFt_avt '>
          <CustomPagination
          totalItems={totalItems}
          itemsPerPage={itemsPerPage}
          currentPage={filter.offset}
          onPageChange={handlePageChange}
        />
          </div>
        </div>

      </div>

      {/* <div className="_transactionTabs_ui">
        <ul className="nav nav-tabs" id="myTab" role="tablist">
          <li className="nav-item" role="presentation">
            <button
              className="nav-link active"
              id="home-tab"
              data-bs-toggle="tab"
              data-bs-target="#home-tab-pane"
              type="button"
              role="tab"
              aria-controls="home-tab-pane"
              aria-selected="true"
            >
              <span>Transactions</span>
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="profile-tab"
              data-bs-toggle="tab"
              data-bs-target="#profile-tab-pane"
              type="button"
              role="tab"
              aria-controls="profile-tab-pane"
              aria-selected="false"
            >
              <span>Cancelled Transactions</span>
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className="nav-link"
              id="under-tab"
              data-bs-toggle="tab"
              data-bs-target="#under-tab-pane"
              type="button"
              role="tab"
              aria-controls="under-tab-pane"
              aria-selected="false"
            >
              <span>Under Approval</span>
            </button>
          </li>
        </ul>
        <div className="tab-content" id="myTabContent">
          <div
            className="tab-pane fade show active"
            id="home-tab-pane"
            role="tabpanel"
            aria-labelledby="home-tab"
            tabIndex="0"
          >
            <div className="_transactionTable">
              <CustomeTable
                columns={columns}
                data={accessorList(
                  transactionList?.length
                    ? transactionList?.filter(
                      (item) => (item?.approval_status == 5 || item?.approval_status == 0 || (item?.approval_status !== 4 && item?.approval_status !== 5))
                    )
                    : []
                )}
              />
            </div>
          </div>

          <div
            className="tab-pane fade"
            id="profile-tab-pane"
            role="tabpanel"
            aria-labelledby="profile-tab"
            tabIndex="0"
          >
            <div className="_transactionTable">
              <CustomeTable
                columns={columns}
                data={accessorList(
                  transactionList?.length
                    ? transactionList?.filter(
                      (item) => item?.approval_status == 4 || item?.approval_status == 0
                    )
                    : []
                )}
              />
            </div>
          </div>
          <div
            className="tab-pane fade"
            id="under-tab-pane"
            role="tabpanel"
            aria-labelledby="under-tab"
            tabIndex="0"
          >
            <div className="_transactionTable">
              <CustomeTable
                columns={columns}
                data={accessorList(
                  transactionList?.length
                    ? transactionList?.filter(
                      (item) =>
                        item?.approval_status == 1 && item?.approval_status == 2 && item?.approval_status == 3
                    )
                    : []
                )}
              />
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default TransactionsDetails;

const CustomeTable = ({ columns, data }) => {
  return (
    <table className="">
      <thead>
        <tr>
          {columns.map((column, index) => (
            <th key={index}>{column.header}</th>
          ))}

          <th></th>
        </tr>
      </thead>

      <tbody>
        {data?.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {columns.map((column, colIndex) => (
              <td key={colIndex}>{row[column.accessor]}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
};
