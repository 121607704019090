import {
  addExpenseAccount,
  getChartExpense,
  getUserAccountList,
  handleKeyDown,
  uploadFiles,
} from "_services";
import {
  CommonDatePicker,
  CommonInput,
  CommonModal,
  CommonSwitch,
  CustomSelect,
} from "components";
import { Images } from "constants";
import { UserContext } from "context";
import { errorSchema, utils } from "helper";
import React, { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { IoCloseCircle } from "react-icons/io5";
import StorageService from "utils/StorageServices";

const AddExpensesModal = (props) => {
  const arryOptions = [
    { label: "Arjun", value: "01" },
    { label: "Anvit", value: "02" },
  ];

  const initial = {
    type: 8,
    sub_type: null,
    date: new Date(),
    is_gst: 0,
    amount: "",
    account_category: "",
    account_sub_category: "",
    account_id: "",
    // vendor_type: 1,
    // vendor_id: "",
    invoice_number: "",
    invoice_date: new Date(),
    place_of_supply: {},
    lr_id: "",
    trip_id: "",
    vehicle_id: "",
    branch_id: "",
    company_id: StorageService.getCompanyId(),
    is_approval: 0,
    item_expenses: [],
    file: [],
  };

  const expenseInit = {
    category_id: "",
    sub_category_id: "",
    expense_category: "",
    expense_type: "",
    hsn_code: "",
    description: "",
    // gst_type: 1,//1-IGST, 2-CGST/SGST
    gst_percentage: "",
    gst_amount: "",
    amount: "",
    rate: "",
  };

  const { masterData, states } = useContext(UserContext);
  const [form, setForm] = useState(initial);
  const [expenseTypeList, setExpenseTypeList] = useState([]);
  const [expenseList, setExpenseList] = useState([]);
  const [expenseArray, setExpenseArray] = useState([expenseInit]);
  const [formError, setFormError] = useState("");
  const [gstTaxList, setGstTaxList] = useState([]);
  const [accountLoading, setAccountLoading] = useState(false);
  const [accountList, setAccountList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [accountType, setAccountType] = useState();



  useEffect(() => {
    getMasterData();
    getExpenseHandler();
  }, [masterData]);

  useEffect(() => {
    if (
      (form?.account_sub_category && form?.account_category,
        expenseTypeList.length)
    ) {
   
      if (form?.account_id == "") {
        
        getAccountListHandler();
      }
    }
  }, [
    form?.account_sub_category,
    form?.account_category,
    expenseTypeList,
    form?.account_id,
  ]);

  useEffect(() => {
    if (accountType) {
      setForm({ ...form, account_id: "" });
    }
  }, [accountType]);

  const handleOnChange = async (name, value) => {
    const stateObj = {
      ...form,
      [name]: value,
    };
    setForm(stateObj);

    if (!!formError) {
      const validationResult = await utils.checkFormError(
        { ...stateObj },
        errorSchema.addExpenseAccountSchema
      );
      if (validationResult === true) {
        setFormError("");
      } else {
        setFormError(validationResult);
      }
    }
  };

  const getMasterData = async () => {
    try {
      const detailsAccount = masterData?.account_category?.sub_category?.map(
        (item) => {
          return {
            label: item?.name,
            value: item?.id,
            category_id: masterData?.account_category?.id,
          };
        }
      );

      const gstTaxList = masterData?.gst_percentage_list?.map((item) => {
        return {
          ...item,
          label: item?.name,
          value: item?.id,
        };
      });

      if (detailsAccount && gstTaxList) {
        setForm({
          ...form,
          type: masterData?.general_expense?.id,
          sub_type: null,
          account_category: detailsAccount[0]?.category_id,
          account_sub_category: detailsAccount[0]?.value,
        });

        setExpenseTypeList(detailsAccount);
        setGstTaxList(gstTaxList);
        setAccountType(detailsAccount[0]?.value);
      }

      // }
    } catch {}
  };

  const getExpenseHandler = async () => {
    try {
      const res = await getChartExpense({
        company_id: StorageService.getCompanyId(),
      });

      if (res?.responseCode === 200) {
        const { category_id, sub_category_id } = res?.data;
        const details = res?.data?.categories?.map((item) => {
          return {
            label: item?.name,
            value: item?.id,
          };
        });

        const updatedList = expenseArray.map((item) => ({
          ...item,
          category_id: category_id,
          sub_category_id: sub_category_id,
        }));

        setExpenseArray(updatedList);
        setExpenseList(details);
      } else {
        console.log(res?.responseMessage);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getAccountListHandler = async () => {
    setAccountLoading(true);
    try {
      const res = await getUserAccountList({
        company_id: StorageService?.getCompanyId(),
        category_id: parseInt(form?.account_category),
        sub_category_id: parseInt(form?.account_sub_category),
        // account_id: "",
      });

      if (res?.responseCode === 200) {
        setAccountLoading(false);
        const details = res?.data?.account_list?.map((item) => {
          return {
            label: item?.name,
            value: item?.id,
          };
        });
       
        setAccountList(details);
       
        handleOnChange("account_id", details[0].value);
        
      } else {
        setAccountLoading(false);
        console.log(res?.responseMessage);
      }
    } catch (error) {
      console.log(error);
      setAccountLoading(false);
    }
  };

  const addExpenseInput = (e) => {
    setExpenseArray((preState) => {
      return [...preState, expenseInit];
    });
  };

  const removeExpenseInput = (index) => {
    const newExpense = [...expenseArray];
    newExpense.splice(index, 1);
    setExpenseArray(newExpense);
  };

  const formatToTwoDecimal = (value) => {
    if (value === 0) return "";
    return value % 1 === 0 ? value.toString() : parseFloat(value.toFixed(2));
  };

  const handleExpenseChange = async (value, index, name) => {
    const newExpense = [...expenseArray];
    newExpense[index][name] = value;

    if (name === "amount" || name === "gst_percentage") {
      const isGstEnabled = form.is_gst === 1;
      const amount = parseFloat(newExpense[index].amount) || 0;
      const gstPercentage = parseFloat(newExpense[index].gst_percentage) || 0;

      if (isGstEnabled && gstPercentage > 0) {
        const gstAmount = (amount / (100 + gstPercentage)) * gstPercentage;
        newExpense[index].gst_amount = formatToTwoDecimal(gstAmount);
        newExpense[index].rate = formatToTwoDecimal(amount - gstAmount);
      } else {
        newExpense[index].gst_amount = "";
        newExpense[index].rate = formatToTwoDecimal(amount);
      }

      newExpense[index].amount = formatToTwoDecimal(amount);
    }

    setExpenseArray(newExpense);

    const totalAmount = newExpense.reduce(
      (acc, expense) => acc + parseFloat(expense.amount || 0),
      0
    );
    setForm((prevForm) => ({
      ...prevForm,
      amount: formatToTwoDecimal(totalAmount),
    }));

    if (formError) {
      if (`${formError}${[index]}${[name]}`) {
        const validationResult = await utils.checkFormError(
          { ...form, expenseArray: newExpense },
          errorSchema.addExpenseAccountSchema
        );

        if (validationResult === true) {
          const newFormError = { ...formError };
          if (newFormError.expenseArray && newFormError.expenseArray[index]) {
            delete newFormError.expenseArray[index][name];
            if (Object.keys(newFormError.expenseArray[index]).length === 0) {
              delete newFormError.expenseArray[index];
            }
          }
          setFormError(newFormError);
        } else {
          setFormError(validationResult);
        }
      }
    }
  };

  const calculateTotalAmount = () => {
    const exTotal = expenseArray.reduce((total, item) => {
      const amount = parseFloat(item.amount) || 0;
      return total + amount;
    }, 0);

    return exTotal.toFixed(2);
  };

  const getStateValue = (value) => {
    const stateList = states?.map((i) => {
      return {
        ...i,
        label: i?.name,
        value: i?.name,
      };
    });
    const stateValue = stateList?.find((i) => i?.value === value);
    return stateValue;
  };

  const handleFileChange = async (e) => {
    if (
      e?.target?.files[0]?.type === "image/gif" ||
      e?.target?.files[0]?.type === "image/jpeg" ||
      e?.target?.files[0]?.type === "image/png"
    ) {
      setLoading(true);
      try {
        const formData = new FormData();
        formData.append("file", e?.target?.files[0]);
        const res = await uploadFiles(formData);
        if (res?.responseCode === 200) {
          var fileArray = [];
          fileArray.push(...form?.file, {
            filename: res?.data?.filename,
            path: res?.data?.path,
          });
          handleOnChange("file", fileArray);
          setLoading(false);
          toast.success("Document added ");
        }
      } catch (error) {
        setLoading(false);
      }
    } else {
      toast.error("Invalid file, choose jpeg/png images");
    }
  };

  const documentRemove = (index) => {
    const newFromIput = [...form?.file];

    newFromIput.splice(index, 1);

    setForm({ ...form, file: newFromIput });
  };

  const onHandleSubmit = async () => {
    const validationResult = await utils.checkFormError(
      { ...form, expenseArray: expenseArray },
      errorSchema.addExpenseAccountSchema
    );

    const formattedExpenseArray = expenseArray.map((expense) => ({
      ...expense,
      amount: parseFloat(parseFloat(expense.amount || 0).toFixed(2)),
    }));

    const payload = {
      ...form,
      amount: parseFloat(parseFloat(form.amount || 0).toFixed(2)),
      item_expenses: formattedExpenseArray,
    };

    if (validationResult == true) {
      try {
        // const res = await addExpenseAccount(payload);
      } catch (error) {}
    } else {
      setFormError(validationResult);
    }
  };

  const propsExpenses = {
    ...props,
    onSubmit: onHandleSubmit,
  };

  return (
    <>
      <CommonModal {...propsExpenses}>
        <div className="__addExp_body">
          <div className="flxInr">
            {/* <div className="halfDiv ps-3">
              <CommonInput
                type="text"
                label="Voucher Number"
                placeholder="Voucher Number"
                name="voucher_no"
                disabled={true}
                errorShow={false}
              />
            </div> */}

            <div className="halfDiv ps-3">
              <CommonDatePicker
                name="date"
                label="Expense Date"
                placeholderText="Expense Date"
                startDate={form?.date}
                setStartDate={(data) => {
                  handleOnChange("date", data);
                }}
                error={formError?.date}
                focus={!!formError?.date}
                errorShow={false}
              />
            </div>

            <div className="singleDiv ">
              <div className="__gstToggle_bx">
                <CommonSwitch
                  id="Exp_with_GST"
                  name="is_gst"
                  label="Expense with GST"
                  value={form?.is_gst}
                  checked={form?.is_gst}
                  onChange={({ target: { name, checked } }) => {
                    handleOnChange(name, checked);
                  }}
                />
              </div>
              <div className="_catGry_tableBx _withGst_catGry">
                {form.is_gst ? (
                  <div className="__catrTb_row __catrTb_head">
                    <div className="__cat_childs">
                      <h5>Expense Category</h5>
                    </div>
                    <div className="__cat_childs">
                      <h5>Tax</h5>
                    </div>
                    <div className="__cat_childs">
                      <h5>Amount</h5>
                    </div>
                  </div>
                ) : (
                  <div className="__catrTb_row __catrTb_head">
                    <div className="__cat_childs">
                      <h5>Expense Category</h5>
                    </div>
                    <div className="__cat_childs">
                      <h5>Amount</h5>
                    </div>
                  </div>
                )}

                {expenseArray?.map((exp, index) => {
                  return (
                    <>
                      {form?.is_gst ? (
                        <div className="__catrTb_row __catrTb_body">
                          <div className="__cat_childs">
                            <div>
                              <CustomSelect
                                name="expense_category"
                                options={expenseList}
                                onSelect={(data) => {
                                  handleExpenseChange(
                                    data?.value,
                                    index,
                                    "expense_category"
                                  );
                                }}
                                placeholder="Expense Category"
                                error={
                                  formError[
                                    `expenseArray[${index}].expense_category`
                                  ]
                                }
                                value={expenseList?.find(
                                  (i) => i.value == exp?.expense_category
                                )}
                                errorShow={false}
                              />
                            </div>
                            <div className="mt-1">
                              <CommonInput
                                type="text"
                                placeholder="Item Description"
                                name="description"
                                value={exp?.description}
                                onChange={({ target: { name, value } }) => {
                                  handleExpenseChange(
                                    value,
                                    index,
                                    "description"
                                  );
                                }}
                                focus={
                                  !!formError[
                                    `expenseArray[${index}].description`
                                  ]
                                }
                                error={
                                  formError[
                                    `expenseArray[${index}].description`
                                  ]
                                }
                                errorShow={false}
                              />
                            </div>
                          </div>

                          <div className="__cat_childs">
                            <div>
                              <CustomSelect
                                name="gst_percentage"
                                options={gstTaxList}
                                onSelect={(data) => {
                                  handleExpenseChange(
                                    data?.value,
                                    index,
                                    "gst_percentage"
                                  );
                                }}
                                placeholder="Tax"
                                focus={
                                  !!formError[
                                    `expenseArray[${index}].gst_percentage`
                                  ]
                                }
                                error={
                                  formError[
                                    `expenseArray[${index}].gst_percentage`
                                  ]
                                }
                                value={gstTaxList?.find(
                                  (i) => i.value == exp?.gst_percentage
                                )}
                                errorShow={false}
                              />
                            </div>
                            <div className="mt-1">
                              <CustomSelect
                                options={arryOptions}
                                placeholder="Type"
                              />
                            </div>
                          </div>

                          <div className="__cat_childs">
                            <div>
                              <CommonInput
                                type="text"
                                placeholder="Amount"
                                name="amount"
                                value={exp?.amount}
                                onChange={({ target: { name, value } }) => {
                                  handleExpenseChange(value, index, "amount");
                                }}
                                focus={
                                  !!formError[`expenseArray[${index}].amount`]
                                }
                                error={
                                  formError[`expenseArray[${index}].amount`]
                                }
                                onKeyDown={handleKeyDown}
                                errorShow={false}
                              />
                            </div>
                            <div className="mt-1">
                              <CommonInput
                                type="text"
                                placeholder="HSN Code"
                                name="hsn_code"
                                value={exp?.hsn_code}
                                onChange={({ target: { name, value } }) => {
                                  handleExpenseChange(value, index, "hsn_code");
                                }}
                                focus={
                                  !!formError[`expenseArray[${index}].hsn_code`]
                                }
                                error={
                                  formError[`expenseArray[${index}].hsn_code`]
                                }
                                onKeyDown={handleKeyDown}
                                errorShow={false}
                                maxLength={6}
                              />
                            </div>
                          </div>

                          {expenseArray.length > 1 && (
                            <div>
                              <img
                                className="_delExp"
                                src={Images.DELETEICON.default}
                                alt=""
                                onClick={() => removeExpenseInput(index)}
                              />
                            </div>
                          )}
                        </div>
                      ) : (
                        <div className="__catrTb_row __catrTb_body">
                          <div className="__cat_childs">
                            <CustomSelect
                              name="expense_category"
                              options={expenseList}
                              onSelect={(data) => {
                                handleExpenseChange(
                                  data?.value,
                                  index,
                                  "expense_category"
                                );
                              }}
                              placeholder="Expense Category"
                              error={
                                formError[
                                  `expenseArray[${index}].expense_category`
                                ]
                              }
                              value={expenseList?.find(
                                (i) => i.value == exp?.expense_category
                              )}
                              errorShow={false}
                            />
                          </div>

                          <div className="__cat_childs">
                            <CommonInput
                              type="text"
                              placeholder="Amount"
                              name="amount"
                              value={exp?.amount}
                              onChange={({ target: { name, value } }) => {
                                handleExpenseChange(value, index, "amount");
                              }}
                              focus={
                                !!formError[`expenseArray[${index}].amount`]
                              }
                              error={formError[`expenseArray[${index}].amount`]}
                              onKeyDown={handleKeyDown}
                              errorShow={false}
                            />
                          </div>

                          {expenseArray.length > 1 && (
                            <div>
                              <img
                                className="_delExp"
                                src={Images.DELETEICON.default}
                                alt=""
                                onClick={() => removeExpenseInput(index)}
                              />
                            </div>
                          )}
                        </div>
                      )}
                    </>
                  );
                })}
              </div>
              <div className=" totalExp_bal">
                <button
                  className="__adx_more "
                  onClick={() => addExpenseInput()}
                >
                  + Add More
                </button>
                <h4>{`Total- Rs. ${calculateTotalAmount()}`}</h4>
              </div>
            </div>

            <div className="px-3 w-100">
              <div className="_pMode_exbx">
                <p>Payment Mode</p>
                <div className="pFlex_bd">
                 
                  {expenseTypeList.map((i) => {
                
                    return (
                      <button
                        className={`_baseSpan ${
                          i?.value == form?.account_sub_category
                            ? "active"
                            : "_bspDisable"
                        }`}
                        name="account_sub_category"
                        disabled={accountLoading}
                        value={i.value}
                        onClick={({ target: { name, value } }) => {
                        
                          setAccountType(value);
                          handleOnChange(name, value);
                        }}
                      >
                        {i.label}
                      </button>
                    );
                  })}
                </div>
                {/* {form?.account_sub_category != 14 && ( */}
                <div className="mt-4">
                  <CustomSelect
                    label="Select Account"
                    options={accountList}
                    placeholder="Select Account"
                    name="account_id"
                    onSelect={(data) => {
                      handleOnChange("account_id", data?.value);
                    }}
                    error={formError["account_id"]}
                    value={
                      form?.account_id
                        ? accountList?.find(
                            (i) => i.value == form?.account_id
                          )
                        : { label: "", value: "" }
                    }
                    errorShow={false}
                  />
                </div>
                {/* )} */}
              </div>
            </div>

            <div className="singleDiv _pMode_exbx px-3">
              <p>Add Vendor Details</p>
            </div>

            <div className="halfDiv ps-3">
              <CustomSelect
                label="Select Vendor"
                options={arryOptions}
                // onSelect={(data) => {
                //   handleOnChange("entity_type", data?.value)
                // }}
                placeholder="Select Vendor"
                // error={formError["entity_type"]}
                // value={entityList?.find((i) => i.value == formValue?.entity_type)}
              />
            </div>

            <div className="halfDiv pe-3">
              <CustomSelect
                label="Place of Supply"
                options={states?.map((st) => {
                  return {
                    ...st,
                    label: st?.name,
                    value: st?.name,
                  };
                })}
                onSelect={(data) => {
                  handleOnChange("place_of_supply", data);
                }}
                placeholder="Place of Supply"
                value={getStateValue(form?.place_of_supply?.value)}
                error={formError["place_of_supply.label"]}
                errorShow={false}
              />
            </div>

            <div className="halfDiv ps-3">
              <CommonInput
                type="text"
                label="Invoice Number"
                placeholder="Invoice Number"
                name="invoice_number"
                value={form?.invoice_number}
                onChange={({ target: { name, value } }) => {
                  handleOnChange(name, value);
                }}
                focus={!!formError?.invoice_number}
                error={formError.invoice_number}
                // onKeyDown={handleKeyDown}
                errorShow={false}
              />
            </div>

            <div className="halfDiv pe-3">
              <CommonDatePicker
                name="invoice_date"
                label="Invoice Date"
                placeholderText="Invoice Date"
                startDate={form?.invoice_date}
                setStartDate={(data) => {
                  handleOnChange("invoice_date", data);
                }}
                error={formError?.invoice_date}
                focus={!!formError?.invoice_date}
                errorShow={false}
              />
            </div>

            <div className="singleDiv _pMode_exbx px-3">
              <div className="_attatchmentBxs">
                <p>Attachment</p>

                <div className="__addExp_fileBx">
                  <input
                    type="file"
                    name=""
                    id="attachExp_file"
                    onChange={(e) => handleFileChange(e)}
                    disabled={loading}
                  />
                  <label htmlFor="attachExp_file">
                    {loading ? "Uploadding" : "+ Attach File"}
                  </label>
                  {formError?.file && (
                    <span className="input_drop_error">{formError?.file}</span>
                  )}
                </div>
                <div className="img_fnex_src">
                  {form?.file?.length > 0 &&
                    form?.file?.map((el, index) => {
                      return (
                        <>
                          <div className="pos-relative">
                            <img
                              className="_pdfIcon_sigxe"
                              src={`${masterData?.s3_url}${el?.path}`}
                              alt=""
                            />
                            <span className="_clDel_icon">
                              <IoCloseCircle
                                onClick={() => documentRemove(index)}
                              />
                            </span>
                          </div>
                        </>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </CommonModal>
    </>
  );
};

export default AddExpensesModal;
