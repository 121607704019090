import { API, axiosInstance } from "lib";

export const getConnectionCounts = (data = {}) => {
  return axiosInstance
    .post(API.GET_CONNECTION_COUNT, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};

export const getSuggestionListData = (data = {}) => {
  return axiosInstance
    .post(API.GET_SUGGESTION_LIST, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};

export const getConnectionListData = (data = {}) => {
  return axiosInstance
    .post(API.GET_CONNECTION_LIST, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};

export const sendConnectionRequest = (data = {}) => {
  return axiosInstance
    .post(API.SEND_REQUEST_CONNECTION, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};

export const withDrawalConnectionRequest = (data = {}) => {
  return axiosInstance
    .post(API.WITHDRAWAL_CONNECTION_REQUEST, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};

export const rejectConnectionRequest = (data = {}) => {
  return axiosInstance
    .post(API.REJECT_CONNECTION_REQUEST, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};

export const removeConnectionRequest = (data = {}) => {
  return axiosInstance
    .post(API.REMOVE_CONNECTION_REQUEST, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};

export const acceptConnectionRequest = (data = {}) => {
  return axiosInstance
    .post(API.ACCEPT_CONNECTION_REQUEST, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};

export const addNewConnections = (data = {}) => {
  return axiosInstance
    .post(API.ADD_CONNECTION_LISTS, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};

export const getAllConnections = (data = {}) => {
  return axiosInstance
    .post(API.GET_CONNECTION_LISTS, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};


// Rating & Review

export const addRatingReview = (data = {}) => {
  return axiosInstance
    .post(API.ADD_RATINGS_REVIEW, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};

export const getRatingsReviewData = (data = {}) => {
  return axiosInstance
    .post(API.GET_RATINGS_REVIEW_LISTS, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};