
import { lazy } from "react";

//lazy-loaded Layouts
const DashboardLayout = lazy(() =>
  import("layouts").then((module) => ({ default: module.DashboardLayout }))
);
const AuthLayout = lazy(() =>
  import("layouts").then((module) => ({ default: module.AuthLayout }))
);
const ProfileLayout = lazy(() =>
  import("layouts").then((module) => ({ default: module.ProfileLayout }))
);

// lazy-loaded Pages
const lazyLoad = (moduleName) =>
  lazy(() =>
    import("pages").then((module) => ({ default: module[moduleName] }))
  );

const Login = lazyLoad("Login");
const Register = lazyLoad("Register");
const Verify = lazyLoad("Verify");
const Dashboard = lazyLoad("Dashboard");
const AddRole = lazyLoad("AddRole");
const AddUser = lazyLoad("AddUser");
const DataTable = lazyLoad("DataTable");
const SelectCompany = lazyLoad("SelectCompany");
const Consignor = lazyLoad("Consignor");
const Testing = lazyLoad("Testing");
const DetailsConsignor = lazyLoad("ConsignorDetails");
const Contact = lazyLoad("Contact");
const Unit = lazyLoad("Unit");
const Partydetail = lazyLoad("Partydetail");
const Lorryreceipt = lazyLoad("Lorryreceipt");
const Rate = lazyLoad("Rate");
const Ledger = lazyLoad("Ledger");
const Settings = lazyLoad("Settings");
const Role = lazyLoad("Role");
const User = lazyLoad("User");
const Supplier = lazyLoad("Supplier");
const Branch = lazyLoad("Branch");
const Consignee = lazyLoad("Consignee");
const MarketDriver = lazyLoad("MarketDriver");
const OwnDriver = lazyLoad("OwnDriver");
const MarketLoad = lazyLoad("MarketLoad");
const MyBids = lazyLoad("MyBids");
const BidsRecieved = lazyLoad("BidsRecieved");
const Plan = lazyLoad("Plan");
const Invitations = lazyLoad("Invitations");
const Lr = lazyLoad("Lr");
const Trip = lazyLoad("Trip");
const Company = lazyLoad("Company");
const ConsignorGroup = lazyLoad("ConsignorGroup");
const Material = lazyLoad("Material");
const MaterialCategory = lazyLoad("MaterialCategory");
const SupplierGroup = lazyLoad("SupplierGroup");
const VehicleGroup = lazyLoad("VehicleGroup");
const VehicleType = lazyLoad("VehicleType");
const Zone = lazyLoad("Zone");
const ChartOfAccount = lazyLoad("ChartOfAccount");
const Transactions = lazyLoad("Transactions");
const QuickUpload = lazyLoad("QuickUpload");
const ParleBill = lazyLoad("ParleBill");
const PartyAddress = lazyLoad("PartyAddress");
const DriverReports = lazyLoad("DriverReports");
const TripReports = lazyLoad("TripReports");
const VehicleReports = lazyLoad("VehicleReports");
const FuelReports = lazyLoad("FuelReports");
const EditRole = lazyLoad("EditRole");
const Vehicle = lazyLoad("Vehicle");
const AddTrip = lazyLoad("AddTrip");
const CreatTrip = lazyLoad("CreatTrip");
const TripDetail = lazyLoad("TripDetail");
const Custom = lazyLoad("Custom");
const FuelStation = lazyLoad("FuelStation");
const FuelCard = lazyLoad("FuelCard");
const FastTag = lazyLoad("FastTag");
const Vendors = lazyLoad("Vendors");
const TransactionsDetails = lazyLoad("TransactionsDetails");
const Item = lazyLoad("Item");
const AddressBook = lazyLoad("AddressBook");
// const TransactionsDetails = lazyLoad("TransactionsDetails")
const Driver = lazyLoad("Driver");
const DetailsSupplier = lazyLoad("SupplierDetails");
const DetailsUnit = lazyLoad("UnitDetails");
const DetailsDriver = lazyLoad("DriverDetails");
const DetailsConsignee = lazyLoad("ConsigneeDetails");
const DetailsVendors = lazyLoad("VendorsDetails");
const DetailsBranch = lazyLoad("BranchDetails");
const DetailsVehicle = lazyLoad("VehicleDetails");
const DetailsFuelStation = lazyLoad("FuelStationDetails");
const PaymentApproval = lazyLoad("PaymentApproval");
const Indent = lazyLoad("Indent");
const Invoicing = lazyLoad("Invoicing");
const Support = lazyLoad("Support");
const CreateBillRaise = lazyLoad("CreateBillRaise");
const BillRaiseSelectLR = lazyLoad("BillRaiseSelectLR");
const SelecedtBillRaiseLr = lazyLoad("SelecedtBillRaiseLr");
const BillSummary = lazyLoad("BillSummary");
const BillRaiseDetails = lazyLoad("BillRaiseDetails");
const ViewRole = lazyLoad("ViewRole");

// creating route objects
const createRoute = (path, element, isPrivate = false) => ({
  path,
  element,
  isPrivate,
});

export const routeConfigs = [
  {
    layout: AuthLayout,
    children: [
      createRoute("/", Login, false),
      createRoute("other", Login, false),
      createRoute("verify", Verify, false),
    ],
  },
  {
    layout: DashboardLayout,
    children: [
      createRoute("dashboard", Dashboard, true),
      createRoute("setting/role/addrole", AddRole, true),
      createRoute("setting/user/adduser", AddUser, true),
      createRoute("datatable", DataTable, true),
      createRoute("language", Verify, true),
      createRoute("testing", Testing, true),
      createRoute("details/contact/:id", Contact, true),
      createRoute("details/units/:id", Unit, true),
      createRoute("details/partydetail/:id", Partydetail, true),
      createRoute("details/ledger/:id", Ledger, true),
      createRoute("details/rate/:id", Rate, true),
      createRoute("details/settings/:id", Settings, true),
      createRoute("setting/role", Role, true),
      createRoute("setting/role/edit/:id", EditRole, true),
      createRoute("setting/user", User, true),
      createRoute("details/lr/:id", Lorryreceipt, true),
      createRoute("account/supplier", Supplier, true),
      createRoute("setting/branch", Branch, true),
      createRoute("account/consignor", Consignor, true),
      createRoute("account/unit", Unit, true),
      createRoute("account/consignee", Consignee, true),
      createRoute("account/vehicle", Vehicle, true),
      createRoute("account/marketdriver", MarketDriver, true),
      createRoute("account/owndriver", OwnDriver, true),
      createRoute("account/driver", Driver, true),
      createRoute("account/fuel_station", FuelStation, true),
      createRoute("account/fuel_card", FuelCard, true),
      createRoute("account/fast_tag", FastTag, true),
      createRoute("account/vendors", Vendors, true),
      createRoute("market_load", MarketLoad, true),
      createRoute("my_bids", MyBids, true),
      createRoute("bids_received", BidsRecieved, true),
      createRoute("plan/planlist", Plan, true),
      createRoute("invitations", Invitations, true),
      createRoute("trip/addtrip", Trip, true),
      createRoute("trip/addtrip/createtrip", CreatTrip, true),
      createRoute("trip/addtrip/dispatched/:id", TripDetail, true),
      createRoute("lr/addlr", Lr, true),
      createRoute("setting/company", Company, true),
      createRoute("setting/ConsignorGroup", ConsignorGroup, true),
      createRoute("setting/material", Material, true),
      createRoute("setting/material_category", MaterialCategory, true),
      createRoute("setting/supplier_group", SupplierGroup, true),
      createRoute("setting/vehicle_group", VehicleGroup, true),
      createRoute("setting/vehicle_type", VehicleType, true),
      createRoute("setting/zone", Zone, true),
      createRoute("accounting/transactions", Transactions, true),
      createRoute("accounting/chart_of_account", ChartOfAccount, true),
      createRoute("quick_uploads", QuickUpload, true),
      createRoute("quick_uploads/parle_bill", ParleBill, true),
      createRoute("quick_uploads/party_address", PartyAddress, true),
      createRoute("reports/driver_reports", DriverReports, true),
      createRoute("report", TripReports, true),
      createRoute("reports/vehicle_reports", VehicleReports, true),
      createRoute("reports/fuel_reports", FuelReports, true),
      createRoute(
        "accounting/transactions/bank_transaction_list",
        TransactionsDetails,
        true
      ),
      createRoute("account/consignor/details/:id", DetailsConsignor, true),
      createRoute("account/supplier/details/:id", DetailsSupplier, true),
      createRoute("account/unit/details/:id", DetailsUnit, true),
      createRoute("account/driver/details/:id", DetailsDriver, true),
      createRoute("account/consignee/details/:id", DetailsConsignee, true),
      createRoute("account/vendors/details/:id", DetailsVendors, true),
      createRoute("setting/branch/details/:id", DetailsBranch, true),
      createRoute("account/vehicle/details/:id", DetailsVehicle, true),
      createRoute("account/fuel_station/details/:id", DetailsFuelStation, true),
      createRoute("payment_approval", PaymentApproval, true),
      createRoute("indent", Indent, true),
      createRoute("/invoicing", Invoicing, true),
      createRoute("invoicing/createbillraise", CreateBillRaise, true),
      createRoute("masters/item", Item, true),
      createRoute("address_book", AddressBook, true),
      createRoute("invoicing/selectlr", BillRaiseSelectLR, true),
      createRoute("invoicing/selectedlr", SelecedtBillRaiseLr, true),
      createRoute("invoicing/summary", BillSummary, true),
      createRoute("invoicing/details/:id", BillRaiseDetails, true),
      createRoute("/support", Support, true),
      createRoute("setting/user/adduser/:id", AddUser, true),
      createRoute("setting/role/view/:id", ViewRole, true),
    ],
  },
  // {
  //   layout: ProfileLayout,
  //   children: [
  //     createRoute('register', Register, true),
  //     createRoute('profile', SelectCompany, true),
  //     createRoute('custom-table', Custom, true),
  //   ],
  // },
];
