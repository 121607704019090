import { API, axiosInstance } from "lib";

export const getConsignorDropdownListData = (data = {}) => {
  return axiosInstance
    .post(API.COMMON_DROPDOWN + `get_consignor_for_dropdown`, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};

export const getUnitDropdownListData = (data = {}) => {
  return axiosInstance
    .post(API.COMMON_DROPDOWN + `get_unit_for_dropdown`, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};

export const getConsigneeDropdownListData = (data = {}) => {
  return axiosInstance
    .post(API.COMMON_DROPDOWN + `get_consignee_for_dropdown`, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};

export const getBranchDropdownListData = (data = {}) => {
  return axiosInstance
    .post(API.COMMON_DROPDOWN + `get_branch_for_dropdown`, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};

export const getSupplierDropdownListData = (data = {}) => {
  return axiosInstance
    .post(API.COMMON_DROPDOWN + `get_supplier_for_dropdown`, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};

export const getContractDropdownListData = (data = {}) => {
  return axiosInstance
    .post(API.COMMON_DROPDOWN + `get_contract_for_dropdown`, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};

export const getZoneDropdownListData = (data = {}) => {
  return axiosInstance
    .post(API.COMMON_DROPDOWN + `get_zone_for_dropdown`, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};

export const getLrTemplateDropdownListData = (data = {}) => {
  return axiosInstance
    .post(API.COMMON_DROPDOWN + `get_LRtemplate_for_dropdown`, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};


export const getPlanResponseApi = (data = {}) => {
  return axiosInstance
    .post(API.PLAN + `plan_responses_list`, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};

export const getFuelApi = (data = {}) => {
  return axiosInstance
    .post(API.COMMON_DROPDOWN + `get_fuelstation_for_dropdown`, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};


export const getComapnyUser = (data = {}) => {
  return axiosInstance
    .post(API.COMMON_DROPDOWN + `get_company_users_for_dropdown`, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};

export const getAddressBookDropdownListData = (data = {}) => {
  return axiosInstance
    .post(API.COMMON_DROPDOWN + `get_addressbook_for_dropdown`, data)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      if (err) {
        console.log(err);
      }
      throw err;
    });
};
