import React, { useContext, useEffect, useState } from "react";
import { errorSchema, utils } from "helper";
import {
  CommonDatePicker,
  CommonInput,
  CommonModal,
  CommonTextarea,
  CustomSelect,
} from "components";
import StorageService from "utils/StorageServices/StorageServices";
import toast, { Toaster } from "react-hot-toast";
import { createMaterialCategory } from "_services/materialServices/materialCategoryServices";
import { updateLrProcess } from "_services";
import { updateApproveStatus } from "_services/accountingServices/accountingServices";

const formObj = {
  company_id: "",
  lr_id: "",
  date: new Date(),
  notes: "",
};

const AtPartyPointModal = (props) => {
  const {
    isOpen,
    closeModal,
    lrId,
    companyId,
    callBackApiCall,
    aprroveStatus,
  } = props;
  const [formValue, setFormValue] = useState(formObj);
  const [formError, setFormError] = useState({});
  const [loading, setLoading] = useState(false);

  const handleOnChange = async (name, value) => {
    const stateObj = { ...formValue, [name]: value };
    setFormValue(stateObj);

    if (!utils.isObjectKeyEmpty(formError)) {
      const validationResult = await utils.checkFormError(
        stateObj,
        errorSchema.addPartyPointSchema
      );
      if (validationResult === true) {
        setFormError("");
      } else {
        setFormError(validationResult);
      }
    }
  };

  const apiCallingFunction = async () => {
    try {
      if (aprroveStatus) {
        const res = await updateApproveStatus({
          company_id: StorageService?.getCompanyId(),
          transaction_id: lrId?.item?._id,
          approval_status: lrId?.i?.id,
          date:formValue?.date,
          reason: formValue?.notes,
        });
        if (res?.responseCode === 200) {
          setFormValue({});
          closeModal(false);
          setLoading(false);
          callBackApiCall(true);
          toast.success(`Status Changed`);
        } else {
          setLoading(false);
        }
      } else {
        const res = await updateLrProcess({
          company_id: formValue?.company_id,
          lr_id: formValue?.lr_id,
          date: formValue?.date,
          notes: formValue?.notes,
        });

        if (!!res) {
          setFormValue({});
          closeModal(false);
          setLoading(false);
          callBackApiCall(true);
          toast.success(`${res?.responseMessage}`);
        } else {
          setLoading(false);
        }
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const handleClick = async (e) => {
    e.preventDefault();
    const validationResult = await utils?.checkFormError(
      formValue,
      errorSchema.addPartyPointSchema
    );

    if (!aprroveStatus) {
      if (utils?.isObjectKeyEmpty(validationResult)) {
        setLoading(true);
        apiCallingFunction();
        setFormError("");
      } else {
        setFormError(validationResult);
        setLoading(false);
      }
    } else {
      setLoading(true);
      apiCallingFunction();
    }
  };

  let inhanceProps = {
    ...props,
    submitTitle: loading ? "Loading..." : props.submitTitle,
    disabled: loading,
    onSubmit: handleClick,
  };

  useEffect(() => {
    if (companyId, lrId) {
      setFormValue((s) => ({
        ...s,
        lr_id: lrId,
        company_id: companyId,
        
      }));
    }
  }, [props]);


  return (
    <div>
      <CommonModal {...inhanceProps}>
        <div className="addItem_body">
          <div className="colFlx">
            {
              <div className="singleDiv">
                <CommonDatePicker
                  name="invoice_date"
                  label={
                    isOpen?.name === "party_point"
                      ? "Select Party Point Date & Time * "
                      : !aprroveStatus ? "Select Delivered Date & Time * " : " Date & Time "
                  }
                  startDate={formValue?.date}
                  setStartDate={(data) => {
                    handleOnChange("date", data);
                  }}
                  error={formError?.date}
                  focus={!!formError?.date}
                />
                {/* <CommonInput
                  type="date"
                  label={
                    isOpen?.name === "party_point"
                      ? "Select Party Point Date & Time * "
                      : !aprroveStatus ? "Select Delivered Date & Time * " : " Date & Time "
                  }
                  placeholder={
                    isOpen?.name === "party_point"
                      ? "Select Party Point Date & Time *"
                      : !aprroveStatus ? "Select Delivered Date & Time *" : "Select Date & Time"
                  }
                  name="date"
                  value={formValue.date}
                  focus={!!(typeof formError === "object" && formError?.date)}
                  error={
                    !!(typeof formError === "object") ? formError?.date : ""
                  }
                  onChange={({ target: { name, value } }) =>
                    handleOnChange(name, value)
                  }
                /> */}
              </div>
            }

            <div className="singleDiv">
              <CommonTextarea
                type="textarea"
                label="Notes "
                placeholder="Notes "
                name="notes"
                value={formValue.notes}
                focus={!!(typeof formError === "object" && formError?.notes)}
                error={
                  !!(typeof formError === "object") ? formError?.notes : ""
                }
                onChange={({ target: { name, value } }) =>
                  handleOnChange(name, value)
                }
              />
            </div>
          </div>
        </div>
      </CommonModal>
      <div className="offBack_drop"></div>
    </div>
  );
};

export default AtPartyPointModal;
