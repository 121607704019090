import {
  createBankAccountApi,
  createCashAccountApi,
  createFastagAccountApi,
  createFuelAccountApi,
  createOtherAccountApi,
  getAllVehicleNumber,
  handleKeyDown,
} from "_services";
import {
  CommonInput,
  CommonModal,
  Customdropdown,
  CustomSelect,
} from "components";
import images from "constants/images";
import { UserContext } from "context";
import { errorSchema, utils } from "helper";
import React, { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { StorageServices } from "utils";
import StorageService from "utils/StorageServices";
// import {addTransactionsTabs} from 'constants/constant'

const AddTransctionsModal = (props) => {
  const addTransactionsTabs = [
    { id: 1, title: "Cash", value: "cash", img: images.RP_WALLET.default },
    { id: 2, title: "Bank", value: "bank", img: images.BANK_ICON.default },
    {
      id: 3,
      title: "Fuel Card",
      value: "fuel_card",
      img: images.FUELCARD_ICON.default,
    },
    {
      id: 4,
      title: "Other",
      value: "other",
      img: images.OTHER_TRANS_ICON.default,
    },
    {
      id: 5,
      title: "Fastag",
      value: "fastag",
      img: images.FASTAG_ICON.default,
    },
  ];

  const initialFrom = {
    company_id: StorageServices.getCompanyId(),
    cash_name: "",
    cash_opting_bal: "",
    cash_credit_or_debit: 1,
    cash_prefix: "",
    show_cash_prefix: false,

    holder_name: "",
    number: "",
    ifsc: "",
    bank: "",
    bank_opening_balance: "",
    bank_credit_or_debit: 1,
    bank_prefix: "",
    show_bank_prefix: false,

    other_name: "",
    other_opening_balance: "",
    other_credit_or_debit: 1,
    other_prefix: "",
    show_other_prefix: false,

    fuel_card_number: "",
    associate_vehicle_id: "",
    fuel_opening_balance: "",
    fuel_credit_or_debit: 1,
    fuel_card_master_id: "",

    fastag_id_number: "",
    fastag_associate_vehicle_id: "",
    fastag_opening_balance: "",
    fastag_credit_or_debit: 1,
    fastag_parent_account_id: "",
  };

  const { masterData } = useContext(UserContext);
  const [activeTab, setActiveTab] = useState(1);
  const [form, setForm] = useState(initialFrom);
  const [formError, setFormError] = useState("");
  const [bankList, setBankList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [vehicleList, setVehicleList] = useState([]);
  // Pass closeModal as a prop to CommonModal

  const changeActiveTab = async (event, value) => {
    event.preventDefault();
    setActiveTab(value);
  };

  const arryOptions = [
    { label: "Arjun", value: "01" },
    { label: "Anvit", value: "02" },
  ];

  useEffect(() => {
    const list = masterData?.bank_list?.map((i) => {
      return {
        label: i?.name,
        value: i?.code,
      };
    });
    setForm({ ...form, bank: list[0].value });
    setBankList(list);
    fetchInitialData();
  }, [masterData]);

  const fetchInitialData = async () => {
    try {
      const [vehicleRes] = await Promise.all([getVehicleList()]);

      if (vehicleRes?.responseCode === 200) {
        const list = vehicleRes.data?.vehicle_list.map((i) => ({
          ...i,
          label: i.vehicle_no,
          value: i._id,
        }));
        setVehicleList(list);

        handleListResponse(list, "associate_vehicle_id");
        handleListResponse(list, "fastag_associate_vehicle_id");
      } else {
        toast.error(vehicleRes?.responseMessage);
      }
    } catch (error) {
      console.error("Error fetching initial data:", error);
      toast.error("Error fetching initial data.");
    }
  };

  const handleListResponse = (list, labelKey) => {
    if (list.length > 0) {
      setForm((prev) => ({ ...prev, [labelKey]: list[0].value }));
    }
  };

  const getVehicleList = () => {
    return getAllVehicleNumber({
      company_id: StorageService.getCompanyId(),
    });
  };

  const handleOnChange = async (name, value) => {
    const stateObj = { ...form, [name]: value };
    setForm(stateObj);

    if (!!formError) {
      const validationSchemas = {
        1: errorSchema.cashSchema,
        2: errorSchema.bankSchema,
        3: errorSchema.fuelSchema,
        4: errorSchema.otherSchema,
        5: errorSchema.fastagSchema,
      };
      const validationResult = await utils.checkFormError(
        stateObj,
        validationSchemas[activeTab]
      );

      if (validationResult === true) {
        setFormError("");
      } else {
        setFormError(validationResult);
      }
    }
  };

  const handleOnSubmit = async () => {
    const validationSchemas = {
      1: errorSchema.cashSchema,
      2: errorSchema.bankSchema,
      3: errorSchema.fuelSchema,
      4: errorSchema.otherSchema,
      5: errorSchema.fastagSchema,
    };

    const validationResult = await utils.checkFormError(
      { ...form },
      validationSchemas[activeTab]
    );

    const payloads = {
      1: {
        company_id: form?.company_id,
        name: form?.cash_name,
        opening_balance: form?.cash_opting_bal,
        credit_or_debit: form?.cash_credit_or_debit,
        prefix: form?.cash_prefix,
      },
      2: {
        company_id: form?.company_id,
        name: form?.holder_name,
        number: form?.number,
        ifsc: form?.ifsc,
        bank: form?.bank,
        opening_balance: form?.bank_opening_balance,
        credit_or_debit: form?.bank_credit_or_debit,
        prefix: form?.bank_prefix,
      },
      3: {
        company_id: form?.company_id,
        fuel_card_number: form?.fuel_card_number,
        associate_vehicle_id: form?.associate_vehicle_id,
        opening_balance: form?.fuel_opening_balance,
        credit_or_debit: form?.fuel_credit_or_debit,
        fuel_card_master_id: form?.fuel_card_master_id,
      },
      4: {
        company_id: form?.company_id,
        name: form?.other_name,
        opening_balance: form?.other_opening_balance,
        credit_or_debit: form?.other_credit_or_debit,
        prefix: form?.other_prefix,
      },

      5: {
        company_id: form?.company_id,
        fastag_id_number: form?.fastag_id_number,
        associate_vehicle_id: form?.fastag_associate_vehicle_id,
        opening_balance: form?.fastag_opening_balance,
        credit_or_debit: form?.fastag_credit_or_debit,
        fastag_parent_account_id: form?.fastag_parent_account_id,
      },
    };

    const apiCalls = {
      1: createCashAccountApi,
      2: createBankAccountApi,
      3: createFuelAccountApi,
      4: createOtherAccountApi,
      5: createFastagAccountApi,
    };

    if (validationResult == true) {
      setLoading(true);
      try {
        const payload = payloads[activeTab];
        const apiCall = apiCalls[activeTab];

        if (!payload || !apiCall) {
          toast?.error("Invalid tab or missing payload");
          return;
        }

        const res = await apiCall(payload);

        if (res?.responseCode === 200) {
          setLoading(false);
          props?.closeModal();
          props?.getAccountListHandler();
          toast?.success(res?.responseMessage);
        } else {
          setLoading(false);
          toast?.error(res?.responseMessage);
        }
      } catch (error) {
        setLoading(false);
        console.error("Error:", error);
        toast?.error(error?.message || "An unexpected error occurred");
      }
    } else {
      setLoading(false);
      setFormError(validationResult);
    }
  };

  const propsTransAccount = {
    ...props,
    onSubmit: handleOnSubmit,
    isLoadding: loading,
    disabled: loading,
  };
  return (
    <>
      <CommonModal {...propsTransAccount}>
        <div className="addLr_body">
          <div className="addPr_flBx _addTrns_flx">
            <ul
              className="nav nav-tabs cstNew_tabs _truckSelection"
              id="addParty"
              role="tablist"
            >
              {addTransactionsTabs?.length > 0 ? (
                addTransactionsTabs?.map((item, indx) => (
                  <li className="nav-item" role="presentation" key={indx}>
                    <button
                      id={item?.id}
                      className={
                        "nav-link " + (item?.id === activeTab ? "active" : "")
                      }
                      type="button"
                      aria-selected={item?.id === activeTab}
                      onClick={(e, val) => changeActiveTab(e, item?.id)}
                    >
                      <img
                        src={item.img}
                        className=""
                        //   onClick={(e) => {
                        //     e.stopPropagation();
                        //     setActiveTab({});
                        //     setFilters((s) => ({ ...s, lorry_type_id: '' }))
                        //   }}
                      />

                      {item?.title}
                    </button>
                  </li>
                ))
              ) : (
                <p className="p-2 mt-3"> Loading... </p>
              )}
            </ul>
          </div>

          {activeTab === 1 && (
            <>
              <div className="_allDflx mt-4">
                <div>
                  <CommonInput
                    type="text"
                    placeholder="Cash Account Name"
                    label="Cash Account Name"
                    name="cash_name"
                    focus={!!formError?.cash_name}
                    error={formError.cash_name}
                    onChange={({ target: { name, value } }) =>
                      handleOnChange(name, value)
                    }
                    value={form?.cash_name}
                  />
                </div>

                <div>
                  <CommonInput
                    type="text"
                    placeholder="Opening Balance"
                    label="Opening Balance"
                    name="cash_opting_bal"
                    focus={!!formError?.cash_opting_bal}
                    error={formError.cash_opting_bal}
                    onChange={({ target: { name, value } }) => {
                      const validValue = value.replace(/[^0-9.]/g, "");
                      const formattedValue = validValue.includes(".")
                        ? validValue.split(".")[0] +
                          "." +
                          validValue.split(".")[1].slice(0, 2)
                        : validValue;

                      handleOnChange(name, formattedValue);
                    }}
                    value={form?.cash_opting_bal}
                    onKeyDown={handleKeyDown}
                  />
                </div>

                <div className="d-flex gap-4">
                  {[
                    { label: "(+) Balance", value: 1 },
                    { label: "(-) Balance", value: 2 },
                  ].map((i) => (
                    <div className="_dualCheckr_bx">
                      <input
                        className="form-check-input"
                        id={i?.label}
                        type="radio"
                        name="cash_credit_or_debit"
                        checked={i.value == form.cash_credit_or_debit}
                        value={i.value}
                        onChange={({ target: { name, value } }) =>
                          handleOnChange(name, Number(value))
                        }
                      />
                      <label htmlFor={i?.label} className="form-check-label">
                        {i?.label}
                      </label>
                    </div>
                  ))}
                </div>

                {!form?.show_cash_prefix && (
                  <div className="d-flex justify-content-end">
                    <button
                      className="_addMo_deBtn"
                      onClick={() => handleOnChange("show_cash_prefix", true)}
                    >
                      Add More Detail
                    </button>
                  </div>
                )}

                {form?.show_cash_prefix && (
                  <div>
                    <CommonInput
                      type="text"
                      placeholder="Prefix"
                      label="Prefix"
                      name="cash_prefix"
                      focus={!!formError?.cash_prefix}
                      error={formError.cash_prefix}
                      onChange={({ target: { name, value } }) =>
                        handleOnChange(name, value)
                      }
                      value={form?.cash_prefix}
                    />
                    <p class="_bottomPLine">Leave Blank to Auto Generate</p>
                  </div>
                )}
              </div>
            </>
          )}
          {activeTab === 2 && (
            <>
              <div className="_allDflx mt-4">
                <div>
                  <CommonInput
                    type="text"
                    placeholder="Bank Account Name"
                    label="Bank Account Name"
                    name="holder_name"
                    focus={!!formError?.holder_name}
                    error={formError.holder_name}
                    onChange={({ target: { name, value } }) =>
                      handleOnChange(name, value)
                    }
                    value={form?.holder_name}
                  />
                </div>

                <div>
                  <CommonInput
                    type="text"
                    placeholder="Account Number"
                    label="Account Number"
                    name="number"
                    focus={!!formError?.number}
                    error={formError.number}
                    onChange={({ target: { name, value } }) =>
                      handleOnChange(name, value)
                    }
                    value={form?.number}
                    onKeyDown={handleKeyDown}
                  />
                </div>

                <div>
                  <CommonInput
                    type="text"
                    placeholder="IFSC Code"
                    label="IFSC Code"
                    name="ifsc"
                    focus={!!formError?.ifsc}
                    error={formError.ifsc}
                    onChange={({ target: { name, value } }) =>
                      handleOnChange(name, value)
                    }
                    value={form?.ifsc}
                  />
                </div>

                <div>
                  <CustomSelect
                    options={bankList}
                    label="Bank Name"
                    placeholder="Bank Name"
                    name="bank"
                    focus={!!formError?.bank}
                    error={formError.bank}
                    onSelect={(data) => handleOnChange("bank", data?.value)}
                    value={bankList?.find((i) => i?.value == form?.bank)}
                  />
                </div>

                <div>
                  <CommonInput
                    type="text"
                    placeholder="Opening Balance"
                    label="Opening Balance"
                    name="bank_opening_balance"
                    focus={!!formError?.bank_opening_balance}
                    error={formError.bank_opening_balance}
                    onChange={({ target: { name, value } }) => {
                      const validValue = value.replace(/[^0-9.]/g, "");
                      const formattedValue = validValue.includes(".")
                        ? validValue.split(".")[0] +
                          "." +
                          validValue.split(".")[1].slice(0, 2)
                        : validValue;

                      handleOnChange(name, formattedValue);
                    }}
                    value={form?.bank_opening_balance}
                    onKeyDown={handleKeyDown}
                  />
                </div>

                <div className="d-flex gap-4">
                  {[
                    { label: "(+) Balance", value: 1 },
                    { label: "(-) Balance", value: 2 },
                  ].map((i) => (
                    <div className="_dualCheckr_bx">
                      <input
                        className="form-check-input"
                        id={i?.label}
                        type="radio"
                        name="bank_credit_or_debit"
                        checked={i.value == form.bank_credit_or_debit}
                        value={i.value}
                        onChange={({ target: { name, value } }) =>
                          handleOnChange(name, Number(value))
                        }
                      />
                      <label htmlFor={i?.label} className="form-check-label">
                        {i?.label}
                      </label>
                    </div>
                  ))}
                </div>

                {!form?.show_bank_prefix && (
                  <div className="d-flex justify-content-end">
                    <button
                      className="_addMo_deBtn"
                      onClick={() => handleOnChange("show_bank_prefix", true)}
                    >
                      Add More Detail
                    </button>
                  </div>
                )}

                {form?.show_bank_prefix && (
                  <div>
                    <CommonInput
                      type="text"
                      placeholder="Prefix"
                      label="Prefix"
                      name="bank_prefix"
                      focus={!!formError?.bank_prefix}
                      error={formError.bank_prefix}
                      onChange={({ target: { name, value } }) =>
                        handleOnChange(name, value)
                      }
                      value={form?.bank_prefix}
                    />
                    <p class="_bottomPLine">Leave Blank to Auto Generate</p>
                  </div>
                )}
              </div>
            </>
          )}

          {activeTab === 3 && (
            <>
              <div className="_allDflx mt-4">
                <div>
                  <CommonInput
                    type="text"
                    placeholder="Fuel Card Number"
                    label="Fuel Card Number"
                    name="fuel_card_number"
                    focus={!!formError?.fuel_card_number}
                    error={formError.fuel_card_number}
                    onChange={({ target: { name, value } }) =>
                      handleOnChange(name, value)
                    }
                    value={form?.fuel_card_number}
                  />
                </div>

                <div>
                  <CustomSelect
                    options={vehicleList}
                    label="Associate Vehicle"
                    placeholder="Associate Vehicle"
                    name="associate_vehicle_id"
                    focus={!!formError?.associate_vehicle_id}
                    error={formError.associate_vehicle_id}
                    onSelect={(data) =>
                      handleOnChange("associate_vehicle_id", data?.value)
                    }
                    value={vehicleList?.find(
                      (i) => i?.value == form?.associate_vehicle_id
                    )}
                  />
                </div>

                <div>
                  <CommonInput
                    type="text"
                    placeholder="Opening Balance"
                    label="Opening Balance"
                    name="fuel_opening_balance"
                    focus={!!formError?.fuel_opening_balance}
                    error={formError.fuel_opening_balance}
                    onChange={({ target: { name, value } }) => {
                      const validValue = value.replace(/[^0-9.]/g, "");
                      const formattedValue = validValue.includes(".")
                        ? validValue.split(".")[0] +
                          "." +
                          validValue.split(".")[1].slice(0, 2)
                        : validValue;

                      handleOnChange(name, formattedValue);
                    }}
                    value={form?.fuel_opening_balance}
                    onKeyDown={handleKeyDown}
                  />
                </div>

                <div className="d-flex gap-4">
                  {[
                    { label: "(+) Balance", value: 1 },
                    { label: "(-) Balance", value: 2 },
                  ].map((i) => (
                    <div className="_dualCheckr_bx">
                      <input
                        className="form-check-input"
                        id={i?.label}
                        type="radio"
                        name="fuel_credit_or_debit"
                        checked={i.value == form.fuel_credit_or_debit}
                        value={i.value}
                        onChange={({ target: { name, value } }) =>
                          handleOnChange(name, Number(value))
                        }
                      />
                      <label htmlFor={i?.label} className="form-check-label">
                        {i?.label}
                      </label>
                    </div>
                  ))}
                </div>

                <div>
                  <CustomSelect
                    options={arryOptions}
                    label="Fuel Card Master"
                    placeholder="Fuel Card Master"
                    name="fuel_card_master_id"
                    focus={!!formError?.fuel_card_master_id}
                    error={formError.fuel_card_master_id}
                    onSelect={(data) =>
                      handleOnChange("bank_name", data?.value)
                    }
                    // value={form?.bank_name}
                  />
                </div>
              </div>
            </>
          )}

          {activeTab === 4 && (
            <>
              <div className="_allDflx mt-4">
                <div>
                  <CommonInput
                    type="text"
                    placeholder="Account Name"
                    label="Account Name"
                    name="other_name"
                    focus={!!formError?.other_name}
                    error={formError.other_name}
                    onChange={({ target: { name, value } }) =>
                      handleOnChange(name, value)
                    }
                    value={form?.other_name}
                  />
                </div>

                <div>
                  <CommonInput
                    type="text"
                    placeholder="Opening Balance"
                    label="Opening Balance"
                    name="other_opening_balance"
                    focus={!!formError?.other_opening_balance}
                    error={formError.other_opening_balance}
                    onChange={({ target: { name, value } }) => {
                      const validValue = value.replace(/[^0-9.]/g, "");
                      const formattedValue = validValue.includes(".")
                        ? validValue.split(".")[0] +
                          "." +
                          validValue.split(".")[1].slice(0, 2)
                        : validValue;

                      handleOnChange(name, formattedValue);
                    }}
                    value={form?.other_opening_balance}
                    onKeyDown={handleKeyDown}
                  />
                </div>

                <div className="d-flex gap-4">
                  {[
                    { label: "(+) Balance", value: 1 },
                    { label: "(-) Balance", value: 2 },
                  ].map((i) => (
                    <div className="_dualCheckr_bx">
                      <input
                        className="form-check-input"
                        id={i?.label}
                        type="radio"
                        name="other_credit_or_debit"
                        checked={i.value == form.other_credit_or_debit}
                        value={i.value}
                        onChange={({ target: { name, value } }) =>
                          handleOnChange(name, Number(value))
                        }
                      />
                      <label htmlFor={i?.label} className="form-check-label">
                        {i?.label}
                      </label>
                    </div>
                  ))}
                </div>

                {!form?.show_other_prefix && (
                  <div className="d-flex justify-content-end">
                    <button
                      className="_addMo_deBtn"
                      onClick={() => handleOnChange("show_other_prefix", true)}
                    >
                      Add More Detail
                    </button>
                  </div>
                )}

                {form?.show_other_prefix && (
                  <div>
                    <CommonInput
                      type="text"
                      placeholder="Prefix"
                      label="Prefix"
                      name="other_prefix"
                      focus={!!formError?.other_prefix}
                      error={formError.other_prefix}
                      onChange={({ target: { name, value } }) =>
                        handleOnChange(name, value)
                      }
                      value={form?.other_prefix}
                    />
                    <p class="_bottomPLine">Leave Blank to Auto Generate</p>
                  </div>
                )}
              </div>
            </>
          )}

          {activeTab === 5 && (
            <>
              <div className="_allDflx mt-4">
                <div>
                  <CommonInput
                    type="text"
                    placeholder="Fastag ID Number"
                    label="Fastag ID Number"
                    name="fastag_id_number"
                    focus={!!formError?.fastag_id_number}
                    error={formError.fastag_id_number}
                    onChange={({ target: { name, value } }) =>
                      handleOnChange(name, value)
                    }
                    value={form?.fastag_id_number}
                  />
                </div>

                <div>
                  <CustomSelect
                    options={vehicleList}
                    label="Associate Vehicle"
                    placeholder="Associate Vehicle"
                    name="fastag_associate_vehicle_id"
                    focus={!!formError?.fastag_associate_vehicle_id}
                    error={formError.fastag_associate_vehicle_id}
                    onSelect={(data) =>
                      handleOnChange("fastag_associate_vehicle_id", data?.value)
                    }
                    // value={form?.associate_vehicle_id}
                    value={vehicleList?.find(
                      (i) => i?.value == form?.fastag_associate_vehicle_id
                    )}
                  />
                </div>

                <div>
                  <CommonInput
                    type="text"
                    placeholder="Opening Balance"
                    label="Opening Balance"
                    name="fastag_opening_balance"
                    focus={!!formError?.fastag_opening_balance}
                    error={formError.fastag_opening_balance}
                    onChange={({ target: { name, value } }) => {
                      const validValue = value.replace(/[^0-9.]/g, "");
                      const formattedValue = validValue.includes(".")
                        ? validValue.split(".")[0] +
                          "." +
                          validValue.split(".")[1].slice(0, 2)
                        : validValue;

                      handleOnChange(name, formattedValue);
                    }}
                    value={form?.fastag_opening_balance}
                    onKeyDown={handleKeyDown}
                  />
                </div>

                <div className="d-flex gap-4">
                  {[
                    { label: "(+) Balance", value: 1 },
                    { label: "(-) Balance", value: 2 },
                  ].map((i) => (
                    <div className="_dualCheckr_bx">
                      <input
                        className="form-check-input"
                        id={i?.label}
                        type="radio"
                        name="fastag_credit_or_debit"
                        checked={i.value == form.fastag_credit_or_debit}
                        value={i.value}
                        onChange={({ target: { name, value } }) =>
                          handleOnChange(name, Number(value))
                        }
                      />
                      <label htmlFor={i?.label} className="form-check-label">
                        {i?.label}
                      </label>
                    </div>
                  ))}
                </div>

                {/* <div className="d-flex justify-content-end">
                  <button className="_addMo_deBtn">Add More Detail</button>
                </div> */}

                <div>
                  <CustomSelect
                    options={arryOptions}
                    label="Fastag Parent Account"
                    placeholder="Fastag Parent Account"
                    name="consignor_id"
                    // value={isDropDownFilter?.consignor_id}
                    // onSelect={(data) => setIsDropDownFilter((s) => ({ ...s, consignor_id: data }))}
                  />
                </div>
              </div>
            </>
          )}
        </div>
      </CommonModal>
    </>
  );
};

export default AddTransctionsModal;
