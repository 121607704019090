import { CommonInput, CommonModal, CustomSelect } from 'components'
import React from 'react'

const AddFuelCardModal = (props) => {

    const propsFuelCard = {
        ...props
    };

    const testOption = [
        {  label: 'Arjun', value: '01'},
        {  label: 'Anvit', value: '02'},
        ]

    return (
        <>
            <CommonModal {...propsFuelCard}>
                <div className="addLr_body">
                    <div className="addPr_flBx _addTrns_flx">
                        <div className="_allDflx ">
                            <div>
                                <CommonInput
                                    type="text"
                                    placeholder="Fuel Card Number"
                                    label="Fuel Card Number"
                                    name="fuel_card_number"
                                    // focus={!!formError?.fuel_card_number}
                                    // error={formError.fuel_card_number}
                                    // onChange={({ target: { name, value } }) =>
                                    //     handleOnChange(name, value)
                                    // }
                                    // value={form?.fuel_card_number}
                                />
                            </div>

                            <div>
                                <CustomSelect
                                    options={testOption}
                                    label="Associate Vehicle"
                                    placeholder="Associate Vehicle"
                                    name="associate_vehicle_id"
                                    // focus={!!formError?.associate_vehicle_id}
                                    // error={formError.associate_vehicle_id}
                                    // onSelect={(data) =>
                                    //     handleOnChange("associate_vehicle_id", data?.value)
                                    // }
                                    // value={vehicleList?.find(
                                    //     (i) => i?.value == form?.associate_vehicle_id
                                    // )}
                                />
                            </div>

                            <div>
                                <CommonInput
                                    type="text"
                                    placeholder="Opening Balance"
                                    label="Opening Balance"
                                    name="fuel_opening_balance"
                                    // focus={!!formError?.fuel_opening_balance}
                                    // error={formError.fuel_opening_balance}
                                    // onChange={({ target: { name, value } }) => {
                                    //     const validValue = value.replace(/[^0-9.]/g, "");
                                    //     const formattedValue = validValue.includes(".")
                                    //         ? validValue.split(".")[0] +
                                    //         "." +
                                    //         validValue.split(".")[1].slice(0, 2)
                                    //         : validValue;

                                    //     handleOnChange(name, formattedValue);
                                    // }}
                                    // value={form?.fuel_opening_balance}
                                    // onKeyDown={handleKeyDown}
                                />
                            </div>

                            <div className="d-flex gap-4">
                                {[
                                    { label: "(+) Balance", value: 1 },
                                    { label: "(-) Balance", value: 2 },
                                ].map((i) => (
                                    <div className="_dualCheckr_bx">
                                        <input
                                            className="form-check-input"
                                            id={i?.label}
                                            // id='(+) Balance'
                                            type="radio"
                                            name="fuel_credit_or_debit"
                                            checked
                                            // checked={i.value == form.fuel_credit_or_debit}
                                            value={i.value}
                                            // onChange={({ target: { name, value } }) =>
                                            //     handleOnChange(name, Number(value))
                                            // }
                                        />
                                        <label 
                                        htmlFor={i?.label} 
                                        // label="(+) Balance"
                                        className="form-check-label">
                                            {i?.label}
                                            {/* (+) Balance */}
                                        </label>
                                    </div>
                                ))} 
                            </div>

                            <div>
                                <CustomSelect
                                    options={testOption}
                                    label="Fuel Card Master"
                                    placeholder="Fuel Card Master"
                                    name="fuel_card_master_id"
                                    // focus={!!formError?.fuel_card_master_id}
                                    // error={formError.fuel_card_master_id}
                                    // onSelect={(data) =>
                                    //     handleOnChange("bank_name", data?.value)
                                    // }
                                // value={form?.bank_name}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </CommonModal>
        </>
    )
}

export default AddFuelCardModal