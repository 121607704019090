import React from "react";
import ReactStarsRating from 'react-awesome-stars-rating';

export function StartRatings(props) {

    let { onChangeFn, defaultValue, size, isEdit = true } = props;

    return (
        <div>
            <ReactStarsRating
                value={defaultValue}
                onChange={onChangeFn}
                activeColor="#FDCC01"
                className="size_star"
                size={size}
                isEdit={isEdit}
                isHalf={false}
            />
        </div>
    )
}