import { CommonInput, CommonModal, MapInput } from "components";
import React, { useEffect, useMemo, useState } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  InfoWindow,
  Marker,
  MarkerClusterer,
  InfoBox,
  Circle,
} from "@react-google-maps/api";
import { useGoogleMaps } from "components/GoogleMapsProvider/GoogleMapsProvider";

const ToAndFromLocation = (props) => {
  const initialCenter = { lat: 28.7041, lng: 77.1025 }; // Default center location
  // const isLoaded = useGoogleMaps(); 

  const [fromLocation, setFromLocation] = useState({
    address: "",
    coords: initialCenter,
  });
  const [toLocation, setToLocation] = useState({
    address: "",
    coords: initialCenter,
  });

  const [fromDetails, setFromDetails] = useState('');
  const [toDetails, setToDetails] = useState('');



  const handleLocationChange = (locationSetter) => (value) => {
    const address = value?.formatted_address;
    const coords = {
      lat: value.geometry?.location?.lat(),
      lng: value.geometry?.location?.lng(),
    };
    locationSetter({ address, coords });
  };

  const handleLocation = (locationSetter) => (value) => {

    const { address_components, formatted_address, geometry } = value;

    const lt = geometry?.location?.lat()
    const lg = geometry?.location?.lng()

    let formattedAddress = {
        name: '',
        countryCode: '',
        stateCode: '',
        latitude:lt,
        longitude: lg,
        label: '',
        value: '',
      };

      address_components?.forEach((component) => {
        if (component.types.includes('locality')) {
          formattedAddress.name = component.long_name;
        }
        if (component.types.includes('administrative_area_level_1')) {
          formattedAddress.stateCode = component.short_name;
        }
        if (component.types.includes('country')) {
          formattedAddress.countryCode = component.short_name;
        }
      });

      formattedAddress.label = `${formattedAddress.name},${formattedAddress.stateCode}`;
      formattedAddress.value = formattedAddress.name;

    locationSetter(formattedAddress);
   
  };

  const handleSubmit = () => {

    props.callBackDataMap({ fromDetails,toDetails });
    props.closeModal();
    
  };

  const objectBid = {
    ...props,
    onSubmit: handleSubmit,
    disabled:!toDetails?.label
  };

  return (
    <>
      <CommonModal {...objectBid}>
        <div className="_plcMod_body" style={{"padding":"20px"}}>
          <div className="flxInr">
          
            <div className="singleDiv">
              {/* <MapInput
                label="From Location"
                handleInputChange={handleLocation(setFromDetails)}
              /> */}
            </div>
            <div className="singleDiv">
            <MapInput
              label="To Location"
              placeholder="To Location"
                handleInputChange={handleLocation(setToDetails)}
              />
              
            </div>
            
          </div>
        </div>
        
      </CommonModal>
      <div className="offBack_drop"></div>
    </>
  );
};

export default ToAndFromLocation;
