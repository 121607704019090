import React, { useState, useEffect } from 'react';
import './SkeletonInput.css'; // Import the CSS for styling

const SkeletonInput = ({ isLoading }) => {
  return (
    <div className="input-wrapper">
      {isLoading ? (
        <div className="skeleton-input"></div>
      ) : (
        <input type="text" className="input-field" placeholder="Enter text..." />
      )}
    </div>
  );
};

export default SkeletonInput;
