import React, { useCallback, useContext, useEffect, useState } from 'react'
import { Attachement, CommonInput, CustomSelect } from 'components'
import { errorSchema, utils } from 'helper';
import { State } from "country-state-city";
import StorageService from "utils/StorageServices/StorageServices";
import { UserContext } from "context";
import toast from 'react-hot-toast';
import { createConsignor, getAllConsignorgGroup, getConsignorListData } from '_services/accountsServices/consignorServices';
import { AddButton } from 'components/common/CommonButton';
import { constant } from 'constants';
import { createSupplier } from '_services/accountsServices/supplierServices';
import { Images } from 'constants';

const formObj = {
    company_id: StorageService.getCompanyId(),
    name: '',
    contact_no: '',
    opening_balance: '',
    pay_type: '2',
    as_of_date: '',
    address_line1: '',
    state: {},
    city: {},
    pincode: '',
    email_id: '',
    alt_contact_no: '',
    gst_no: '',
    gst_treatment_id: {},
    gst_state: {},
    tds_declaration_form: [],
    tds_deduction: {},
}

const AddEditSupplier = ({ isOpen, onClose, callBackSupplier, supplierData }) => {

    const { masterData, cities, states } = useContext(UserContext)

    const [formValue, setFormValue] = useState(formObj);
    const [formError, setFormError] = useState({});
    const [loading, setLoading] = useState(false);
    const [stateValue, setStateValue] = useState({});

    const [attachments, setAttachments] = useState([]);
    const [isAttachement, setIsAttachement] = useState(false);
    const [isTds, setIsTds] = useState(false);

    const [activeTab, setActiveTab] = useState(1);
    // Add and New Button functionality
    const [isButtonDisabled, setIsButtonDisabled] = useState(false);

    const handleOnChange = async (name, value) => {
        const stateObj = { ...formValue, [name]: value };
        setFormValue(stateObj);

        if (!utils.isObjectKeyEmpty(formError)) {
            const validationResult = await utils.checkFormError(stateObj, errorSchema.addSupplierSchema);
            if (validationResult === true) {
                setFormError("");
            } else {
                setFormError(validationResult);
            }
        }
    };

    const apiCallingFunction = async (shouldCloseModal) => {
        try {
            let object = {
                ...formValue,
                tds_deduction: formValue?.tds_deduction?.value,
                gst_treatment_id: formValue?.gst_treatment_id?.value,
                gst_state: {
                    value: formValue?.gst_state?.value,
                    label: formValue?.gst_state?.label,
                },
                tds_declaration_form: attachments?.length > 0 ? attachments?.map((el) => {
                    return {
                        ...el,
                        filename: el?.name,
                        path: URL.createObjectURL(el),
                    }
                }) : [],
            };

            const res = await createSupplier(object);
            if (!!res) {
                setFormValue({});
                setLoading(false);
                toast.success('Supplier Created Successfully');
                if (shouldCloseModal) {
                    onClose();
                } else {
                    setActiveTab(1);
                }
                callBackSupplier(res?.data?.supplier);
            } else {
                setLoading(false);
            }
        } catch (error) {
            setLoading(false);
        } finally {
            setIsButtonDisabled(false);
        }
    }


    const handleClick = async (e) => {
        e.preventDefault();
        const validationResult = await utils?.checkFormError(formValue, errorSchema.addSupplierSchema);
        if (activeTab === 3) {
            if (utils?.isObjectKeyEmpty(validationResult)) {
                setLoading(true);
                await apiCallingFunction(true);
                setFormError("");

            } else {
                setFormError(validationResult);
                setLoading(false);
            }
        } else {
            setActiveTab(activeTab + 1);
            setFormError(validationResult);
        }
    };

    const maxLengthCheck = (object) => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(0, object.target.maxLength)
        }
    }

    const onhandleSelect = async (item, name) => {

        const stateObj = { ...formValue, [name]: item };
        setFormValue(stateObj);

        if (!utils.isObjectKeyEmpty(formError)) {
            const validationResult = await utils.checkFormError(stateObj, errorSchema.addSupplierSchema);
            if (validationResult === true) {
                setFormError({});
            } else {
                setFormError(validationResult);
            }
        }

    };

    useEffect(() => {
        if (masterData?.gst_type_list?.length > 0 && states?.length > 0) {
            setFormValue((s) => ({
                ...s,
                as_of_date: utils?.getTodayDate(),
                gst_state: states?.map((el) => {
                    return {
                        label: el?.name,
                        value: el?.isoCode
                    }
                })[0],
                gst_treatment_id: masterData?.gst_type_list?.map((el) => {
                    return {
                        label: el?.name,
                        value: el?.id
                    }
                })[0],
                tds_deduction: masterData?.tds_section_list?.map((el) => {
                    return {
                        label: el?.name,
                        value: el?.id
                    }
                })[0],
                company_id: StorageService.getCompanyId()
            }));
        }
    }, [masterData?.gst_type_list?.length > 0, states?.length > 0, StorageService.getCompanyId()]);

    const changeActiveTab = async (event, value) => {
        event.preventDefault();
        if (value > activeTab) {
            const validationResult = await utils.checkFormError(formValue, errorSchema.addSupplierSchema);
            if (validationResult === true) {
                setActiveTab(value);
            } else {
                setFormError(validationResult);
            }
        } else {
            setActiveTab(value);
        }
    };

    const handleCityChange = (data) => {
        handleOnChange("city", {
            label: data?.label,
            value: data?.name,
        })
        const stateDetails = State.getStateByCodeAndCountry(
            data?.stateCode,
            data?.countryCode
        );
        if (stateDetails?.name && stateDetails?.isoCode) {
            setStateValue(stateDetails)
        }
    }

    useEffect(() => {
        if (stateValue?.name && stateValue?.isoCode) {
            handleOnChange("state", { label: stateValue?.name, value: stateValue?.isoCode })
        }
    }, [stateValue])

    const handleFileChange = async (event) => {
        let data = event?.target?.files[0];
        setAttachments(data)
    }


    const handleResetFormAndSubmit = async (e) => {
        e.preventDefault();
        setIsButtonDisabled(true); // Disable the button immediately
        const validationResult = await utils?.checkFormError(formValue, errorSchema.addSupplierSchema);
        if (activeTab === 3) {
            if (utils?.isObjectKeyEmpty(validationResult)) {
                setLoading(true);
                await apiCallingFunction(false); // Pass false to keep the modal open and reset the form
                setFormValue((prevData) => ({
                    ...prevData,
                    company_id: StorageService.getCompanyId(),
                    name: '',
                    contact_no: '',
                    opening_balance: '',
                    pay_type: '1',
                    // as_of_date: '',
                    address_line1: '',
                    // state: {},
                    // city: {},
                    pincode: '',
                    email_id: '',
                    alt_contact_no: '',
                    gst_no: '',
                    // gst_treatment_id: {},
                    // gst_state: {},
                    tds_declaration_form: [],
                    // tds_deduction: {},
                }))
                setFormError("");
            } else {
                setFormError(validationResult);
                setLoading(false);
                setIsButtonDisabled(false); // Re-enable the button if validation fails
            }
        } else {
            setActiveTab(activeTab + 1);
            setFormError(validationResult);
            setIsButtonDisabled(false); // Re-enable the button if validation fails
        }
    };

    console.log(supplierData)

    return (
        <div>
            <div
                className={`offcanvas offcanvas-end common_offCanvas ${isOpen ? 'show' : ''}`}
                tabIndex={-1}
                style={{ visibility: isOpen ? 'visible' : 'hidden' }}
            >
                <div className="offcanvas-header">
                    <h5 className="offcanvas-title">
                        {supplierData ? 'Edit Supplier' : 'Add Supplier'}
                    </h5>
                    <button type="button" className="btn-close" onClick={() => onClose(!isOpen)}></button>
                </div>

                <div className="offcanvas-body">

                    <div className='pr_procssBx'>
                        <div className='pr_procssBx_grid'>

                            <div className='singleDiv'>
                                <CommonInput
                                    type="text"
                                    placeholder="Supplier Name"
                                    label="Supplier Name *"
                                    name='name'
                                    value={formValue.name}
                                    focus={!!(typeof formError === "object" && formError?.name)}
                                    error={!!(typeof formError === "object") ? formError?.name : ""}
                                    onChange={({ target: { name, value } }) =>
                                        handleOnChange(name, value)
                                    }
                                />
                            </div>

                            <div className='singleDiv'>
                                <CommonInput
                                    type="number"
                                    placeholder="Mobile Number"
                                    label='Mobile Number'
                                    name='contact_no'
                                    maxLength="10"
                                    onInput={maxLengthCheck}
                                    value={formValue.contact_no}
                                    focus={!!(typeof formError === "object" && formError?.contact_no)}
                                    error={!!(typeof formError === "object") ? formError?.contact_no : ""}
                                    onChange={({ target: { name, value } }) =>
                                        handleOnChange(name, value)
                                    }
                                />
                            </div>

                            <div className='halfDiv'>
                                <CommonInput
                                    type="number"
                                    placeholder="Opening Balance"
                                    label="Opening Balance"
                                    name='opening_balance'
                                    value={formValue.opening_balance}
                                    focus={!!(typeof formError === "object" && formError?.opening_balance)}
                                    error={!!(typeof formError === "object") ? formError?.opening_balance : ""}
                                    onChange={({ target: { name, value } }) =>
                                        handleOnChange(name, value)
                                    }
                                />
                            </div>

                            <div className='halfDiv'>
                                <CommonInput
                                    type="date"
                                    placeholder="As of Date * "
                                    label="As of Date * "
                                    name='as_of_date'
                                    value={formValue.as_of_date}
                                    focus={!!(typeof formError === "object" && formError?.as_of_date)}
                                    error={!!(typeof formError === "object") ? formError?.as_of_date : ""}
                                    onChange={({ target: { name, value } }) =>
                                        handleOnChange(name, value)
                                    }
                                />
                            </div>
                        </div>

                        <div className='checkBx_lv mt-2'>
                            {
                                constant.Receive_Pay?.map((el, ind) => {
                                    return (
                                        <React.Fragment>
                                            <input
                                                className="form-check-input"
                                                id={+ind + "pay_type" + el?.value}
                                                type="radio"
                                                name="pay_type"
                                                checked={formValue?.pay_type === el?.value}
                                                value={formValue?.pay_type ? formValue?.pay_type : ""}
                                                onChange={({ target: { name, value } }) =>
                                                    handleOnChange(name, el?.value)
                                                }
                                            />
                                            <label htmlFor={+ind + "pay_type" + el.value} className="form-check-label" >
                                                {el.label}
                                            </label> &nbsp;
                                        </React.Fragment>
                                    )
                                })
                            }
                        </div>
                    </div>

                    <div className='addPr_flBx'>
                        <ul className="nav nav-tabs cstNew_tabs" id="addParty" role="tablist">
                            {constant.addPartyTabs?.map((item, indx) => (
                                <li
                                    className="nav-item"
                                    role="presentation"
                                    key={indx + item?.value}
                                >
                                    <button
                                        className={
                                            "nav-link " + (activeTab === item?.id ? "active" : "")
                                        }
                                        type="button"
                                        aria-selected={activeTab === item?.id}
                                        onClick={(e, val) => changeActiveTab(e, item.id)}
                                    >
                                        {item?.title}
                                    </button>
                                </li>
                            ))}
                        </ul>

                        {activeTab === 1 && (
                            <div className='pr_procssBx' id="addPartyContent">
                                <div className='pr_procssBx_grid'>

                                    <div className='singleDiv'>
                                        <div className="h4Head">Add Address</div>
                                    </div>

                                    <div className='singleDiv'>
                                        <CommonInput
                                            type="text"
                                            placeholder="Address Line 1"
                                            label="Address Line 1"
                                            name='address_line1'
                                            value={formValue.address_line1}
                                            focus={!!(typeof formError === "object" && formError?.address_line1)}
                                            error={!!(typeof formError === "object") ? formError?.address_line1 : ""}
                                            onChange={({ target: { name, value } }) =>
                                                handleOnChange(name, value)
                                            }
                                        />
                                    </div>

                                    <div className='singleDiv'>
                                        <CustomSelect
                                            options={cities}
                                            onSelect={(data) => handleCityChange(data, 'city')}
                                            value={formValue?.city}
                                            placeholder="Select a city"
                                            error={formError["city.label"]}
                                            label="City"
                                        />
                                    </div>

                                    <div className='halfDiv'>
                                        <CommonInput
                                            type="text"
                                            placeholder="State"
                                            label="State"
                                            name='state'
                                            disabled={true}
                                            value={formValue?.state?.label}
                                            focus={!!(typeof formError === "object" && formError?.state)}
                                            error={!!(typeof formError === "object") ? formError?.state : ""}
                                            onChange={({ target: { name, value } }) =>
                                                handleOnChange(name, value)
                                            }
                                        />
                                    </div>

                                    <div className='halfDiv'>
                                        <CommonInput
                                            type="number"
                                            placeholder="PIN Code"
                                            label="PIN Code"
                                            name='pincode'
                                            value={formValue.pincode}
                                            focus={!!(typeof formError === "object" && formError?.pincode)}
                                            error={!!(typeof formError === "object") ? formError?.pincode : ""}
                                            onChange={({ target: { name, value } }) =>
                                                handleOnChange(name, value)
                                            }
                                        />
                                    </div>

                                    <div className='singleDiv'>
                                        <div className="h4Head">Other Contact</div>
                                    </div>

                                    <div className='singleDiv'>
                                        <CommonInput
                                            type="number"
                                            placeholder="Alternate Number"
                                            label="Alternate Number"
                                            name='alt_contact_no'
                                            maxLength="10"
                                            onInput={maxLengthCheck}
                                            value={formValue.alt_contact_no}
                                            focus={!!(typeof formError === "object" && formError?.alt_contact_no)}
                                            error={!!(typeof formError === "object") ? formError?.alt_contact_no : ""}
                                            onChange={({ target: { name, value } }) =>
                                                handleOnChange(name, value)
                                            }
                                        />
                                    </div>

                                    <div className='singleDiv'>
                                        <CommonInput
                                            type="Email"
                                            placeholder="Email ID"
                                            label='Email ID'
                                            name='email_id'
                                            value={formValue.email_id}
                                            focus={!!(typeof formError === "object" && formError?.email_id)}
                                            error={!!(typeof formError === "object") ? formError?.email_id : ""}
                                            onChange={({ target: { name, value } }) =>
                                                handleOnChange(name, value)
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        )}

                        {activeTab === 2 && (
                            <div className='pr_procssBx' id="addPartyContent">
                                <div className='pr_procssBx_grid'>
                                    <div className='singleDiv'>
                                        <CustomSelect
                                            options={masterData?.gst_type_list?.map((el) => {
                                                return {
                                                    label: el?.name,
                                                    value: el?.id
                                                }
                                            })}
                                            name="gst_treatment_id"
                                            label="GST Type"
                                            value={formValue?.gst_treatment_id}
                                            error={formError["gst_treatment_id.label"]}
                                            onSelect={(data) => onhandleSelect(data, 'gst_treatment_id')}
                                            placeholder="Select GST Type"
                                        />
                                    </div>

                                    {
                                        (formValue?.gst_treatment_id?.value == '2' || formValue?.gst_treatment_id?.value == '3') && (
                                            <div className='singleDiv'>
                                                <CommonInput
                                                    type="number"
                                                    placeholder="GST Number"
                                                    label='GST Number'
                                                    name='gst_no'
                                                    value={formValue.gst_no}
                                                    onChange={({ target: { name, value } }) =>
                                                        handleOnChange(name, value)
                                                    }
                                                />
                                            </div>
                                        )
                                    }

                                    <div className='singleDiv'>
                                        <CustomSelect
                                            options={states?.map((el) => {
                                                return {
                                                    label: el?.name,
                                                    value: el?.isoCode
                                                }
                                            })}
                                            name="gst_state"
                                            label="GST State"
                                            value={formValue?.gst_state}
                                            error={formError["gst_state.label"]}
                                            onSelect={(data) => onhandleSelect(data, 'gst_state')}
                                            placeholder="Select GST State"
                                        />
                                    </div>

                                </div>
                            </div>
                        )}

                        {activeTab === 3 && (
                            <div className='pr_procssBx' id="addPartyContent">
                                <div className='pr_procssBx_grid'>
                                    <div className='singleDiv'>
                                        <div className="form-check form-switch">
                                            <label className="form-check-label" htmlFor="tds_declaration_form"> TDS Declaration form </label>
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="tds_declaration_form"
                                                checked={isAttachement}
                                                onChange={(e) => setIsAttachement(!isAttachement)}
                                            />
                                        </div>
                                        {
                                            isAttachement && (
                                                <div className='mt-3'>
                                                    <Attachement
                                                        attachments={attachments}
                                                        setAttachments={(e) => setAttachments(e)}
                                                    />
                                                    {/* <label className="attachFilelabel">
                                                        <span className="title"> + Attach Form </span>
                                                        <input
                                                            className="FileUpload1"
                                                            name="booking_attachment"
                                                            id="file-input"
                                                            onChange={(e) => handleFileChange(e)}
                                                            type="file"
                                                            multiple
                                                        />
                                                    </label>

                                                    <div>
                                                        <ul
                                                            className={attachments?.name ? "_attachedFile_bx" : "_withOutAttach_bx"}
                                                        >
                                                            {
                                                                attachments?.name ? (
                                                                    <li>
                                                                        <span className='_delAttach' onClick={(e) => setAttachments([])}>
                                                                            <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill="none">
                                                                                <path d="M6 0C2.65714 0 0 2.65714 0 6C0 9.34286 2.65714 12 6 12C9.34286 12 12 9.34286 12 6C12 2.65714 9.34286 0 6 0ZM8.31429 9L6 6.68571L3.68571 9L3 8.31429L5.31429 6L3 3.68571L3.68571 3L6 5.31429L8.31429 3L9 3.68571L6.68571 6L9 8.31429L8.31429 9Z" fill="black" />
                                                                            </svg>
                                                                        </span>
                                                                        {attachments?.type === 'application/pdf' ? (
                                                                            <img className='_pdfIcon'
                                                                                src={Images.PDFICON}
                                                                                alt=""
                                                                            />
                                                                        ) : (
                                                                            <img
                                                                                src={(attachments?.name && URL?.createObjectURL(attachments)) ? URL?.createObjectURL(attachments) : ''}
                                                                                alt=""
                                                                            />
                                                                        )}
                                                                    </li>
                                                                ) : <></>
                                                            }
                                                        </ul>
                                                    </div> */}
                                                </div>
                                            )
                                        }
                                    </div>
                                    <div className='singleDiv'>
                                        <div className="form-check form-switch">
                                            <label className="form-check-label" htmlFor="tds_deduction"> TDS Applicable </label>
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                id="tds_deduction"
                                                checked={isTds}
                                                onChange={(e) => setIsTds(!isTds)}
                                            />
                                        </div>
                                        {
                                            isTds && (
                                                <div className='singleDiv mt-3'>
                                                    <CustomSelect
                                                        options={masterData?.tds_section_list?.map((el) => {
                                                            return {
                                                                label: el?.name,
                                                                value: el?.id
                                                            }
                                                        })}
                                                        name="tds_deduction"
                                                        label="TDS Percentage"
                                                        value={formValue?.tds_deduction}
                                                        placeholder="Select TDS Percentage"
                                                        error={formError["tds_deduction.label"]}
                                                        // focus={!!(typeof formError === "object" && formError?.tds_deduction)}
                                                        // error={!!(typeof formError === "object") ? formError?.tds_deduction : ""}
                                                        onSelect={(data) => onhandleSelect(data, 'tds_deduction')}
                                                    />
                                                </div>
                                            )
                                        }
                                    </div>
                                </div>
                            </div>
                        )}

                    </div>

                </div>

                <div className='offcanvas-footer'>
                    {
                        (activeTab === 3) ? (
                            <React.Fragment>
                                <AddButton onClick={(e) => handleClick(e)} disabled={loading} name={loading ? 'Loading...' : 'Add Supplier'} />
                                <button
                                    className={isButtonDisabled ? '_addNewComm_btn_disabled' : '_addNewComm_btn'}
                                    onClick={handleResetFormAndSubmit}
                                    disabled={isButtonDisabled}

                                >
                                    Add & New
                                </button>
                            </React.Fragment>
                        ) : (activeTab === 2 ? (
                            <AddButton onClick={(e) => handleClick(e)} name='Continue' />
                        ) : (
                            <AddButton onClick={(e) => handleClick(e)} disabled={!formValue?.name || !formValue?.as_of_date} name='Continue' />
                        ))
                    }

                </div>
            </div>

        </div>
    )
}

export default AddEditSupplier
