import { getPayment } from "_services";
import { utils } from "helper";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import StorageService from "utils/StorageServices/StorageServices";
import { IoChevronBack, IoChevronForward } from "react-icons/io5";
import CustomPagination from "components/CustomPagination";

const CompletedTab = (props) => {
  const { activeTab, userlist, filter, setFilter } = props;
  const [paymentList, setPaymentList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [statusList, setStatusList] = useState([]);
  const itemsPerPage = 10;
  const [totalItems, setTotalItems] = useState(0);
  const [offSet, setOffset] = useState(1);
  const columns = [
    { header: "Date", accessor: "tADate" },
    { header: "Related", accessor: "TripNumber" },
    { header: "Requested User", accessor: "createAt" },
    { header: "Voucher No", accessor: "voucher_no" },
    { header: "Category", accessor: "category" },
    { header: "Type", accessor: "cateType" },
    { header: "From Account", accessor: "fromAcc" },
    { header: "To Account", accessor: "toAcc" },
    { header: "Description", accessor: "description" },
    { header: "Receipt", accessor: "credit_amount" },
    { header: "Payment", accessor: "debit_amount" },
    { header: "Transaction Details", accessor: "" },
    { header: "Voucher Number", accessor: "" },
    { header: "Attachment", accessor: "" },
  ];

  const accessorList = (data) => {
    const listArray = data?.map((item, i) => ({
      ...item,
      serial: i + 1,
      tADate: (
        <>
          <div className="_dateNtime">
            <span className="_tblText">
              {utils.formatDateMonth(item?.date)}
            </span>
          </div>
        </>
      ),
      transactionType: (
        <>
          <div>
            {item?.sub_type_name} | {item?.type_name}
          </div>
        </>
      ),
      TripNumber: (
        <>
          <div className="__locationWid_bx">
            <Link to={`/trip/addtrip/dispatched/${item?.trip?._id}`}>
              {" "}
              {`Trip-#${item?.trip?.trip_number}`}{" "}
            </Link>
          </div>
        </>
      ),
      category: (
        <>
          {" "}
          <div className="__locationWid_bx">
            {item?.sub_type_name} | {item?.type_name}
          </div>
        </>
      ),
      cateType: (
        <>
          {" "}
          <div className="__locationWid_bx">
            <span className="_tblText">
              {item?.expense_category?.name ? item?.expense_category?.name : ""}
            </span>
          </div>
        </>
      ),
      fromAcc: (
        <>
          {" "}
          <div className="__locationWid_bx">
            {item?.account_sub_category_name} | {item?.account_name}
          </div>
        </>
      ),
      toAcc: (
        <>
          {" "}
          <div className="__locationWid_bx">
            {item?.trip?.supplier_id?.name}
          </div>
        </>
      ),
      createAt: (
        <>
          <div className="__locationWid_bx">
            <span>
              {
                userlist?.find((i) => i?.user_id == item?.created_by)
                  ?.company_user_name
              }
            </span>
          </div>
        </>
      ),
      action: (
        <>
          <button>Approve</button>
          <button>Hold</button>
          <button>Reject</button>
          <button>Attachment (0)</button>
        </>
      ),
    }));
    return listArray;
  };

  useEffect(() => {
    handlePaymentAprovel();
  }, [activeTab, filter, offSet]);

  const handlePaymentAprovel = async () => {
    try {
      const res = await getPayment({
        ...filter,
        offset: offSet,
        company_id: StorageService?.getCompanyId(),
        approval_status: activeTab,
      });

      if (res?.responseCode === 200) {
        setPaymentList(res?.data?.list);
        setIsLoading(false);
        setTotalItems(res?.data?.total_record);
      } else {
        setIsLoading(false);
      }
      console.log(res);
    } catch (error) {}
  };

  const handlePageChange = (page) => {
    setOffset(page);
  };

  return (
    <div className="_transactionTable">
      <CustomeTable
        columns={columns}
        data={accessorList(paymentList?.length ? paymentList : [])}
        isLoading={isLoading}
      />
      <div className="paginationFt_avt ">
        <CustomPagination
          totalItems={totalItems}
          itemsPerPage={itemsPerPage}
          currentPage={offSet}
          onPageChange={handlePageChange}
        />
      </div>
    </div>
  );
};

export default CompletedTab;

const CustomeTable = ({ columns, data, isLoading }) => {
  return (
    <table className="">
      <thead>
        <tr>
          {columns.map((column, index) => (
            <th key={index}>
              <div className="_minTh_wid">{column.header}</div>
            </th>
          ))}

          <th></th>
        </tr>
      </thead>

      <tbody>
        {!isLoading ? (
          <>
            {" "}
            {data.length > 0 ? (
              data?.map((row, rowIndex) => {
                return (
                  <tr key={rowIndex}>
                    {columns.map((column, colIndex) => (
                      <td key={colIndex}>{row[column.accessor]}</td>
                    ))}
                  </tr>
                );
              })
            ) : (
              <tr>
                <td>No data</td>
              </tr>
            )}
          </>
        ) : (
          <>
            <tr>
              <td>Loadding...</td>
            </tr>
            ;
          </>
        )}
      </tbody>
    </table>
  );
};
