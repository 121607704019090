import React, { useEffect, useState } from 'react'
import { CommonModal, CustomSelect } from 'components'
import { getAddressBookDropdownListData, getUnitDropdownListData } from '_services/dropdownServices/dropdownService';
import StorageService from 'utils/StorageServices/StorageServices';
import { errorSchema, utils } from 'helper';
import Loading from 'pages/Loading';

const SelectUnitLrModal = (props) => {

  let { isOpen, closeModal, selectUnit, nextModal } = props;

  const [unitDropdownData, setUnitDropdownData] = useState([])
  const [loading, setLoading] = useState(false);
  const [formValue, setFormValue] = useState({
    unit_id: {},
  });
  const [formError, setFormError] = useState('');

  const getDropdownDataUnitList = async () => {
    try {
      let object = {
        company_id: StorageService.getCompanyId(),
        limit: 100,
        offset: 1,
        search: '',
      }
      // const res = await getUnitDropdownListData(object); // change api unit to account

      const res = await getAddressBookDropdownListData(object); // change api unit to account
      if (res?.responseCode == 200) {
        setLoading(false);
        setUnitDropdownData(res?.data?.list)
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      // throw error;
    }
  }

  useEffect(() => {
    setLoading(true);
    getDropdownDataUnitList();
  }, [props?.isOpen, StorageService.getCompanyId()])

  const onhandleSelect = async (item, name) => {
    const stateObj = { ...formValue, [name]: item };
    setFormValue(stateObj);

    if (!utils.isObjectKeyEmpty(formError)) {
      const validationResult = await utils.checkFormError(stateObj, errorSchema.selectUnitLrModalSchema);
      if (validationResult === true) {
        setFormError({});
      } else {
        setFormError(validationResult);
      }
    }
  };

  const handleSubmit = async () => {
    const validationResult = await utils?.checkFormError(formValue, errorSchema.selectUnitLrModalSchema);
    if (utils?.isObjectKeyEmpty(validationResult)) {
      setLoading(true);
      selectUnit(formValue?.unit_id);
      closeModal(false);
      setFormError("");

    } else {
      setFormError(validationResult);
      setLoading(false);
    }
  };

  let unitProps = {
    ...props,
    isLoadding: loading,
    disabled: loading,
    onSubmit: (e) => handleSubmit(e),
  }

  const addNewItemHandler = () => {
    nextModal(true);
    closeModal(false);
  }

  useEffect(() => {
    if (unitDropdownData?.length > 0) {
      setFormValue((s) => ({
        ...s,
        unit_id: isOpen?._id ? unitDropdownData?.map((el) => {
          return {
            label: el?.name,
            value: el?._id,
          }
        })?.filter((el) => (el?.value === isOpen?._id) ? el : '')[0] : unitDropdownData?.map((el) => {
          return {
            label: el?.name,
            value: el?._id,
          }
        })[0],
      }))
    }

  }, [unitDropdownData])

  return (
    <div>
      <CommonModal {...unitProps}>
        <div className='addLr_body'>
          <CustomSelect
            options={unitDropdownData?.map((el) => {
              return {
                label: el?.name,
                value: el?._id,
              }
            })}
            isAddButton={true}
            addButtonAction={(e) => addNewItemHandler(e)}
            name="unit_id"
            label="Unit Address"
            placeholder="Unit Address"
            value={formValue?.unit_id ? formValue?.unit_id : ''}
            error={formError["unit_id.label"]}
            onSelect={(data) => onhandleSelect(data, 'unit_id')}
          />
        </div>
      </CommonModal>
    </div>
  )
}

export default SelectUnitLrModal