import React, { useMemo } from 'react'
import { FaStarHalfAlt } from 'react-icons/fa';
import { FaStar } from 'react-icons/fa6';
import moment from 'moment';
import bidderImg from "../../../src/assets/img/bidderImg.png"
import { StartRatings } from 'components';

const RatingList = (props) => {

    let { el } = props;

    // const createAtDate = useMemo(() => {
    //     const postDate = moment(el?.createdAt).utc().local();
    //     const currentDate = moment();
    //     const displayDate =
    //         currentDate.diff(postDate, "days") > 1
    //             ? postDate.format("Do MMMM")
    //             : postDate.fromNow();
    //     if (displayDate.startsWith("in")) {
    //         let date = displayDate?.split("in ")[1];
    //         return date + " ago";
    //     }
    //     return displayDate;
    // }, [el?.createdAt]);

    const createAtDate = useMemo(() => {
        const postDate = moment(el?.createdAt).utc().local();
        const currentDate = moment();

        const diffInYears = currentDate.diff(postDate, "years");
        const diffInMonths = currentDate.diff(postDate, "months");
        const diffInDays = currentDate.diff(postDate, "days");

        let displayDate;

        if (diffInYears >= 1) {
            displayDate = diffInYears === 1 ? "1 year ago" : `${diffInYears} years ago`;
        } else if (diffInMonths >= 1) {
            displayDate = diffInMonths === 1 ? "1 month ago" : `${diffInMonths} months ago`;
        } else if (diffInDays >= 1) {
            displayDate = diffInDays === 1 ? "1 day ago" : `${diffInDays} days ago`;
        } else {
            displayDate = postDate.fromNow(); // for durations like "a few hours ago"
        }

        return displayDate;
    }, [el?.createdAt]);

    return (
        <>
            <div className='_rvBx_lst'>
                <div className='d-flex gap-2'>
                    <div className='_rvBd_imgBx'>
                        {el?.company_id?.company ? <div className='_fstLetter_listCs'> {(el?.company_id?.company[0]).toUpperCase()} </div> : ''}
                        {/* <img src={bidderImg} alt="" /> */}
                    </div>
                    <div className='_rv_bdrDetail'>
                        <h5> {el?.company_id?.company ? el?.company_id?.company : '-'} </h5>
                        <p>{el?.company_id?.city?.label ? el?.company_id?.city?.label : '-'} , {el?.company_id?.state?.label ? el?.company_id?.state?.label : '-'}</p>

                        <div className='_starFillGrp_bx'>
                            <StartRatings
                                defaultValue={el?.rating}
                                isEdit={false}
                                size={10}
                            />
                            <p style={{ marginBottom: '0px', marginLeft: '5px' }} className='mt-1'> {createAtDate} </p>
                        </div>
                    </div>
                </div>

                <div className='_rv_comments'>
                    <p>
                        {el?.review}
                    </p>
                </div>
            </div>
        </>
    )
}

export default RatingList