import { constant } from "constants/constant";
import React, { useContext, useEffect, useState } from "react";
import bidderImg from "../../../src/assets/img/bidderImg.png";
import { FaStar } from "react-icons/fa6";
import { IoCall } from "react-icons/io5";
import { changeBidStatus, getBidCounts, getBidListData, getIndentDetailsApi, getMarketVisiblityApi, getMaterialApi } from "_services";
import StorageService from "utils/StorageServices/StorageServices";
import { utils } from "helper";
import { CommonDatePicker, CommonInput, CustomSelect } from "components";
import { UserContext } from "context";
import { getBranchDropdownListData, getComapnyUser, getConsignorDropdownListData } from "_services/dropdownServices/dropdownService";
import toast from "react-hot-toast";
import { getLorryTypeList } from "_services/accountsServices/lrServices";
import { FiEdit } from "react-icons/fi";

const IndentAndBidDetailModal = (props) => {

  const initialForm = {
    company_id: StorageService.getCompanyId(),
    indent_number: "",
    indent_date: new Date(),
    indent_expiry_date: new Date().setDate(new Date().getDate() + 2),
    consignor_id: "",
    lorry_type_id: "",
    vehicle_type_id: "",
    weight: "",
    material_type_id: "",
    location: [],
    from_location: [],
    to_location: [],
    freight_type: 4,
    freight_rate: "",
    freight_amount: "",
    payment_terms: 1,
    payment_percentage: "",
    branch_id: "",
    assign_to: "",
    is_allow_visibility: 0,
    connection_id: "",
    is_allow_calls: 0,
    responses: 1,
  };

  const initialFrom = {
    from_city: "",
    from_state: "",
    lat: "",
    long: "",
  };
  const initialTo = {
    to_city: "",
    to_state: "",
    lat: "",
    long: "",
    po_list: [],
  };

  const paymentTermsList = [
    {
      label: "Advance",
      value: 1,
    },
    {
      label: "To Pay",
      value: 2,
    },
  ];

  const paymentPer = [
    { label: "90%", value: 90 },
    { label: "80%", value: 80 },
    { label: "70%", value: 70 },
  ];

  let { isOpen, onClose, id } = props;

  const [activeTab, setActiveTab] = useState(2);
  const [subActiveTab, setSubActiveTab] = useState(1);
  const [filter, setFilter] = useState({
    company_id: StorageService.getCompanyId(),
    limit: 10,
    offset: 1,
    type: 2,
    status: props?.activeStatus,
  });
  const itemsPerPage = 50;
  const { masterData, cities, modalZIndex } = useContext(UserContext);
  const [bidList, setBidList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [countArray, setCountArray] = useState(constant?.bidsStatusTabs);
  const [loadingStatus, setLoadingStatus] = useState({});
  const [form, setForm] = useState(initialForm);
  const [fromLoaction, setFromLocation] = useState([initialFrom]);
  const [toLoaction, setToLocation] = useState([initialTo]);
  const [listData, setListData] = useState({
    consignorList: [],
    lorryTypeList: [],
    vehicleTypeList: [],
    freightTypeList: [],
    branchList: [],
    userList: [],
    marketVisiList: [],
    materialList: [],
    responseList: [],
  });


  console.log(activeTab)

  useEffect(() => {
    if (id?._id) {
      fetchData();
      getIndentDetails(id?._id);
    } else {
      fetchData();
    }
  }, [id?._id]);

  useEffect(() => {
    setBidList([]);
    fetchBidListData();
  }, [filter]);

  useEffect(() => {
    fetchBidCountsData();
  }, []);

  const handleOnChange = async (name, value) => {
    setForm((prevForm) => {
      const updatedForm = {
        ...prevForm,
        [name]: value,
      };

      return updatedForm;
    });
  };

  const fetchData = async () => {
    try {
      // setLoading(true);
      const [
        consignorRes,
        lorryTypeRes,
        branchRes,
        userRes,
        marketRes,
        materialRes,
      ] = await Promise.all([
        getDropdownDataConsignorList(),
        getDataLorryTypeList(),
        getBranchList(),
        getComapnyUserList(),
        getMarketList(),
        getMaterialList(),
      ]);

      const consignorList = formatList(
        consignorRes?.data?.consignor_list,
        "name",
        "_id",
        "Self"
      );
      const lorryTypeList = formatList(
        lorryTypeRes?.data?.lorry_type,
        "lorry_type",
        "_id"
      );
      const branchList = formatList(
        branchRes?.data?.branch_list,
        "branch",
        "_id"
      );
      const userList = formatList(userRes, "label", "value");
      const marketVisiList = formatList(
        marketRes?.data,
        "name",
        "_id",
        "All Transport 360 Members"
      );
      const materialList = formatList(materialRes?.data, "name", "_id");

      setListData({
        consignorList,
        lorryTypeList,
        vehicleTypeList: [],
        freightTypeList: formatList(
          masterData?.freight_type_list,
          "name",
          "id"
        ).filter((item) => [6, 4].includes(item.value)),
        branchList,
        userList,
        marketVisiList,
        materialList,
        responseList: formatList(masterData?.plan_responses_list, "name", "id"),
      });

      if (!id) {
        setInitialFormValues(
          lorryTypeList,
          branchList,
          userList,
          consignorList,
          materialList
        );
      }


      // setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Error fetching data", error);
    }
  };

  const formatList = (list, labelKey, valueKey, defaultOption = null) => {
    const formattedList = list?.map((item) => ({
      ...item,
      label: item[labelKey],
      value: item[valueKey],
    }));
    return defaultOption
      ? [{ label: defaultOption, value: "" }, ...formattedList]
      : formattedList;
  };

  const setInitialFormValues = (
    lorryTypeList,
    branchList,
    userList,
    consignorList,
    materialList
  ) => {
    handleOnChange("lorry_type_id", lorryTypeList?.[0]?.value || "");
    handleOnChange("branch_id", branchList?.[0]?.value || "");
    handleOnChange("assign_to", userList?.[0]?.value || "");
    handleOnChange("consignor_id", consignorList?.[0]?.value || "");
    handleOnChange("material_type_id", materialList?.[0]?.value || "");
  };

  useEffect(() => {
    if (form?.lorry_type_id) {
      const findLorry = listData.lorryTypeList?.find(
        (i) => i.value === form.lorry_type_id
      );
      if (findLorry) {
        const vehicleTypeList = formatList(
          findLorry.props[0]?.values,
          "name",
          "_id"
        );
        setListData((prev) => ({ ...prev, vehicleTypeList }));
        handleOnChange("vehicle_type_id", vehicleTypeList?.[0]?.value || "");
      }
    }
  }, [form?.lorry_type_id, listData.lorryTypeList]);

  const commonFetchFuction = async (
    apiMethod,
    payload = {},
    transformer = (res) => res
  ) => {
    try {
      const res = await apiMethod(payload);
      if (res?.responseCode === 200) {
        return transformer(res);
      } else {
        toast.error(res?.responseMessage);
      }
    } catch (error) {
      toast.error(error?.message || "Something went wrong");
    }
  };

  const getIndentDetails = async (id) => {
    try {
      const payload = {
        company_id: StorageService?.getCompanyId(),
        plan_id: id,
      };

      const res = await commonFetchFuction(getIndentDetailsApi, payload);

      if (res?.responseCode === 200) {
        const details = res?.data?.plan;

        handleOnChange("lorry_type_id", details?.lorry_type_id?._id || "");
        setTimeout(() => {
          const fields = {
            company_id: details?.company_id,
            indent_date: details?.indent_date || "",
            indent_expiry_date: details?.indent_expiry_date || "",
            indent_number: details?.indent_number || "",
            consignor_id: details?.consignor_id?._id || "",
            vehicle_type_id: details?.vehicle_type_id?._id || "",
            weight: details?.weight || "",
            material_type_id: details?.material_type_id?._id || "",
            freight_type: details?.freight_type || "",
            freight_amount: details?.freight_amount || "",
            freight_rate: details?.freight_rate || "",
            payment_terms: details?.payment_terms ? details?.payment_terms : 1,
            payment_percentage: details?.payment_percentage || "",
            branch_id: details?.branch_id?._id || "",
            assign_to: details?.assign_to || "",
            is_allow_visibility: details?.is_allow_visibility ? details?.is_allow_visibility : 0,
            connection_id: details?.connection_id || "",
            is_allow_calls: details?.is_allow_calls ? details?.is_allow_calls : 0,
            responses: details?.responses || "",
          };

          Object.entries(fields).forEach(([key, value]) =>
            handleOnChange(key, value)
          );

          setFromLocation(details?.from_location?.length ? details?.from_location : [initialFrom]);

          setToLocation(
            details?.to_location?.map((i) => ({
              ...i,
              po_list: i.po_list?.map((k) => ({
                ...k,
                from_location: k?.from_location,
                from_state: k?.from_state,
                po_date: k?.po_date,
                po_number: k?.po_number,
                to_location: k?.to_location,
                to_state: k?.to_state,
                consignee_id: k?.consignee_id?._id,
                unit_id: k?.unit_id?._id,
                unit_details: k?.unit_id,
                consignee_detials: k?.consignee_id,
              })),
            }))
          );
        }, 0);
      }
    } catch (error) {
      console.error("Error fetching indent details:", error);
    }
  };
  const getDropdownDataConsignorList = async () => {
    const payload = {
      company_id: StorageService.getCompanyId(),
      limit: 100,
      offset: 1,
      search: "",
    };
    return commonFetchFuction(getConsignorDropdownListData, payload);
  };

  const getDataLorryTypeList = async () => {
    return commonFetchFuction(getLorryTypeList);
  };

  const getMarketList = async () => {
    const payload = {
      company_id: StorageService?.getCompanyId(),
      keyword: "",
    };
    return commonFetchFuction(getMarketVisiblityApi, payload);
  };

  const getMaterialList = async () => {
    const payload = {
      company_id: StorageService?.getCompanyId(),
    };
    return commonFetchFuction(getMaterialApi, payload);
  };

  const getBranchList = async () => {
    const payload = {
      company_id: StorageService.getCompanyId(),
      limit: 100,
      offset: 1,
      search: "",
    };
    return commonFetchFuction(getBranchDropdownListData, payload);
  };

  const getComapnyUserList = async () => {
    const payload = {
      company_id: StorageService.getCompanyId(),
      limit: 100,
      offset: 1,
      search: "",
    };
    const transformer = (res) =>
      res?.data?.user_list?.map((el) => ({
        ...el,
        label: el?.company_user_name,
        value: el?.user_id?._id,
      }));

    return commonFetchFuction(getComapnyUser, payload, transformer);
  };

  const fetchBidListData = async () => {
    setLoading(true);
    try {
      let object = {
        ...filter,
        plan_id: id?._id,
      };
      let res = await getBidListData(object);
      if (res?.responseCode === 200) {
        if (props?.id) {
          const filteredData = res?.data.filter(
            (item) => item.plan_id._id === props?.id
          );

          setBidList(res?.data);
        }
        // setBidList(res?.data)
        // setTotalItems(res?.data?.total_record)
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const fetchBidCountsData = async () => {
    setLoading(true);
    try {
      let object = {
        type: 2,
        company_id: StorageService.getCompanyId(),
        plan_id: id?._id,
      };
      let res = await getBidCounts(object);
      if (res?.responseCode === 200) {
        let dataArray = [];
        for (let i = 0; i < constant?.bidsStatusTabs?.length; i++) {
          for (let j = 0; j < Object.values(res?.data)?.length; j++) {
            if (i == j) {
              dataArray.push({
                ...constant?.bidsStatusTabs[i],
                count: Object.values(res?.data)[j],
              });
            }
          }
        }

        setCountArray(dataArray);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  const statusReturn = (key) => {
    switch (key) {
      case 0:
        return (
          <div className="_bidStatus_bxs">
            <span className="_pending_bidSts">Pending</span>
          </div>
        );
        break;

      case 1:
        return (
          <div className="_bidStatus_bxs">
            <span className="_accepted_bidSts">Accepted</span>
          </div>
        );
        break;
      case 2:
        return (
          <div className="_bidStatus_bxs">
            <span className="_rejected_bidSts">withdraw</span>
          </div>
        );
        break;
      case 3:
        return (
          <div className="_bidStatus_bxs">
            <span className="_closed_bidSts">rejected</span>
          </div>
        );
        break;
      case 4:
        return (
          <div className="_bidStatus_bxs">
            <span className="_closed_bidSts">mark as placed</span>
          </div>
        );
        break;
      case 5:
        return (
          <div className="_bidStatus_bxs">
            <span className="_closed_bidSts">closed</span>
          </div>
        );
        break;

      default:
        break;
    }
  };

  const handleStatusLoading = (id, isLoading, actionType) => {
    setLoadingStatus((s) => ({
      ...s,
      [id]: {
        ...s[id],
        [actionType]: isLoading,
      },
    }));
  };

  const handleStatusChange = async (data, tab_Status, action) => {
    handleStatusLoading(data?._id, true, action);
    try {
      let object = {
        plan_id: data?.plan_id?._id,
        bid_id: data?._id,
        status: tab_Status,
      };
      let res = await changeBidStatus(object);
      if (res?.responseCode === 200) {
        fetchBidCountsData();
        fetchBidListData();
        handleStatusLoading(data?._id, false, action);
      }
    } catch (error) {
      handleStatusLoading(data?._id, false, action);
    }
  };

  return (
    <>
      <div className="offcanvas-header lrd-header">
        <h5 className="offcanvas-title">
          {" "}
          {` View Indent #${id?.indent_number}`}{" "}
          <span className="_lrPending_status">Active</span>
        </h5>
        <button
          type="button"
          className="btn-close"
          onClick={() => onClose(!isOpen)}
        ></button>
      </div>

      <div className="addPr_flBx _indentView">
        <ul
          className="nav nav-tabs cstNew_tabs px-0"
          id="addParty"
          role="tablist"
        >
          {constant.indentAndBidsDetailTabs?.map((item, indx) => (
            <li
              className="nav-item"
              role="presentation"
              key={indx + item?.value}
            >
              <button
                className={
                  "nav-link " + (activeTab === item?.id ? "active" : "")
                }
                type="button"
                aria-selected={activeTab === item?.id}
                onClick={(e) => {
                  setActiveTab(item.id);
                }}
              >
                <span className="_tbTitle">{item?.title}</span>
              </button>
            </li>
          ))}
        </ul>

        {activeTab === 1 && <div className="offcanvas-body">
        <div className="pr_procssBx" style={{ marginBottom: "9px" }}>
          <div className="_lrdMax">
            <div className="flxInr">
              <div className="halfDiv">
                <CommonDatePicker
                  name="indent_date"
                  label="Indent Date"
                  startDate={form?.indent_date}
                 
                  value={form.indent_date}
                  disabled={true}
                />
              </div>

              <div className="halfDiv">
                <CommonDatePicker
                  name="indent_expiry_date"
                  label="Indent Expiry Date"
                  startDate={form?.indent_expiry_date}
                  value={form.indent_expiry_date}
                  disabled={true}
                />
              </div>

              <div className="halfDiv">
                <CommonInput
                  type="text"
                  label="Indent Number"
                  placeholder="Indent Number"
                  name="indent_number"
                  value={form.indent_number}
                  disabled={true}
                />
                <p className="_bottomPLine">Leave Blank to Auto Generate</p>
              </div>

              <div className="halfDiv">
                <CustomSelect
                  options={listData?.consignorList}
                  label="Select Party"
                  placeholder="Select Party"
                  value={listData?.consignorList?.find(
                    (i) => i?.value == form.consignor_id
                  )}
                  disabled={true}
                />
                <p className="_bottomPLine">
                  Visible Internally. Not visible to Others
                </p>
              </div>

              <div className="halfDiv">
                <CustomSelect
                  options={listData?.lorryTypeList}
                  label="Select Fleet"
                  placeholder="Select Fleet"
                  value={listData?.lorryTypeList?.find(
                    (i) => i?.value == form.lorry_type_id
                  )}
                  disabled={true}
                />
              </div>

              <div className="halfDiv">
                <CustomSelect
                  options={listData?.vehicleTypeList}
                  label="Fleet Type"
                  placeholder="Select Fleet Type"
                  value={listData?.vehicleTypeList?.find(
                    (i) => i?.value == form.vehicle_type_id
                  )}
                  disabled={true}
                />
              </div>

              <div className="halfDiv _relatePos">
                <CommonInput
                  type="text"
                  label="Weight"
                  placeholder="Weight"
                  name="weight"
                  value={form?.weight}
                  disabled={true}
                />

                <span className='_weightSpan' > Ton </span>
              </div>

              <div className="halfDiv">
                <CustomSelect
                  options={listData?.materialList}
                  label="Material"
                  placeholder="Select Material"
                  name="material_type_id"
                  value={listData?.materialList?.find(
                    (i) => i?.value == form?.material_type_id
                  )}
                  disabled={true}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="_sltIndent_bx" style={{ marginBottom: "9px" }}>
          <div className="_sltHead">
            <h4>Select Location</h4>
          </div>

          <div className="container-fluid _verLine_cts">
            <div
              className="row _btmRow_cts mt-0 pb-0 "
              style={{ zIndex: "10" }}
            >
              {fromLoaction?.map((data, index1) => {
                return (
                  <div className="col-md-12">
                    <div className="_addRouts_flex">
                      <div className="_cityInput_bx">
                        <CustomSelect
                          label="From City"
                          component_class="vehicle_number_trip"
                          options={cities}
                          dynamicIndex={index1}
                          value={{
                            label: data?.from_city
                              ? `${data?.from_city}, ${data?.from_state}`
                              : "",
                            value: `${data?.from_city}`,
                          }}
                         
                          disabled={true}
                        />
                      </div>

                      <div className="_addDel_bx">
                        {!fromLoaction[index1]?.from_city ? (
                          <button className="_pickN_dropBtn">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                            >
                              <path
                                d="M8.61539 0C6.33902 0.0276205 4.16368 0.944178 2.55393 2.55393C0.944178 4.16368 0.0276205 6.33902 0 8.61539C0.0276205 10.8917 0.944178 13.0671 2.55393 14.6768C4.16368 16.2866 6.33902 17.2031 8.61539 17.2308C10.8917 17.2031 13.0671 16.2866 14.6768 14.6768C16.2866 13.0671 17.2031 10.8917 17.2308 8.61539C17.2031 6.33902 16.2866 4.16368 14.6768 2.55393C13.0671 0.944178 10.8917 0.0276205 8.61539 0ZM13.5385 9.23077H9.23077V13.5385H8V9.23077H3.69231V8H8V3.69231H9.23077V8H13.5385V9.23077Z"
                                fill="#1956F6"
                              ></path>
                            </svg>{" "}
                            <span>Add Pickup</span>
                          </button>
                        ) : (
                          <button
                            className="_pickN_dropBtn"
                            disabled={true}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18"
                              height="18"
                              viewBox="0 0 18 18"
                              fill="none"
                            >
                              <path
                                d="M8.61539 0C6.33902 0.0276205 4.16368 0.944178 2.55393 2.55393C0.944178 4.16368 0.0276205 6.33902 0 8.61539C0.0276205 10.8917 0.944178 13.0671 2.55393 14.6768C4.16368 16.2866 6.33902 17.2031 8.61539 17.2308C10.8917 17.2031 13.0671 16.2866 14.6768 14.6768C16.2866 13.0671 17.2031 10.8917 17.2308 8.61539C17.2031 6.33902 16.2866 4.16368 14.6768 2.55393C13.0671 0.944178 10.8917 0.0276205 8.61539 0ZM13.5385 9.23077H9.23077V13.5385H8V9.23077H3.69231V8H8V3.69231H9.23077V8H13.5385V9.23077Z"
                                fill="#1956F6"
                              />
                            </svg>{" "}
                            <span>Add Pickup</span>
                          </button>
                        )}
                        {fromLoaction?.length > 1 && (
                          <button
                            className="_deleteBtn"
                           
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="17"
                              viewBox="0 0 16 17"
                              fill="none"
                            >
                              <path
                                d="M2.83333 17C2.31389 17 1.86937 16.8152 1.49978 16.4456C1.13019 16.076 0.945074 15.6312 0.944444 15.1111V2.83333H0V0.944444H4.72222V0H10.3889V0.944444H15.1111V2.83333H14.1667V15.1111C14.1667 15.6306 13.9819 16.0754 13.6123 16.4456C13.2427 16.8158 12.7979 17.0006 12.2778 17H2.83333ZM12.2778 2.83333H2.83333V15.1111H12.2778V2.83333ZM4.72222 13.2222H6.61111V4.72222H4.72222V13.2222ZM8.5 13.2222H10.3889V4.72222H8.5V13.2222Z"
                                fill="#C41F1F"
                              ></path>
                            </svg>
                            <span>Delete</span>
                          </button>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
              {toLoaction?.map((data, index) => {
                return (
                  <>
                    <div className="col-md-12">
                      <div className="_addRouts_flex">
                        <div className="_cityInput_bx">
                          <CustomSelect
                            label="To City"
                            component_class="vehicle_number_trip"
                            options={cities}
                            dynamicIndex={index}
                           
                            value={{
                              label: data?.to_city
                                ? `${data?.to_city}, ${data?.to_state}`
                                : "",
                              value: `${data?.to_city}`,
                            }}
                           
                            disabled={true}
                          />
                        </div>

                        <div className="_addDel_bx">
                          {!toLoaction[index]?.to_city ? (
                            <button className="_pickN_dropBtn">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                              >
                                <path
                                  d="M8.61539 0C6.33902 0.0276205 4.16368 0.944178 2.55393 2.55393C0.944178 4.16368 0.0276205 6.33902 0 8.61539C0.0276205 10.8917 0.944178 13.0671 2.55393 14.6768C4.16368 16.2866 6.33902 17.2031 8.61539 17.2308C10.8917 17.2031 13.0671 16.2866 14.6768 14.6768C16.2866 13.0671 17.2031 10.8917 17.2308 8.61539C17.2031 6.33902 16.2866 4.16368 14.6768 2.55393C13.0671 0.944178 10.8917 0.0276205 8.61539 0ZM13.5385 9.23077H9.23077V13.5385H8V9.23077H3.69231V8H8V3.69231H9.23077V8H13.5385V9.23077Z"
                                  fill="#1956F6"
                                />
                              </svg>{" "}
                              <span>Add Drop</span>
                            </button>
                          ) : (
                            <button
                              className="_pickN_dropBtn"
                           
                              disabled={true}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                              >
                                <path
                                  d="M8.61539 0C6.33902 0.0276205 4.16368 0.944178 2.55393 2.55393C0.944178 4.16368 0.0276205 6.33902 0 8.61539C0.0276205 10.8917 0.944178 13.0671 2.55393 14.6768C4.16368 16.2866 6.33902 17.2031 8.61539 17.2308C10.8917 17.2031 13.0671 16.2866 14.6768 14.6768C16.2866 13.0671 17.2031 10.8917 17.2308 8.61539C17.2031 6.33902 16.2866 4.16368 14.6768 2.55393C13.0671 0.944178 10.8917 0.0276205 8.61539 0ZM13.5385 9.23077H9.23077V13.5385H8V9.23077H3.69231V8H8V3.69231H9.23077V8H13.5385V9.23077Z"
                                  fill="#1956F6"
                                />
                              </svg>{" "}
                              <span>Add Drop</span>
                            </button>
                          )}

                          {toLoaction?.length > 1 && (
                            <button
                              className="_deleteBtn"
                            
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="17"
                                viewBox="0 0 16 17"
                                fill="none"
                              >
                                <path
                                  d="M2.83333 17C2.31389 17 1.86937 16.8152 1.49978 16.4456C1.13019 16.076 0.945074 15.6312 0.944444 15.1111V2.83333H0V0.944444H4.72222V0H10.3889V0.944444H15.1111V2.83333H14.1667V15.1111C14.1667 15.6306 13.9819 16.0754 13.6123 16.4456C13.2427 16.8158 12.7979 17.0006 12.2778 17H2.83333ZM12.2778 2.83333H2.83333V15.1111H12.2778V2.83333ZM4.72222 13.2222H6.61111V4.72222H4.72222V13.2222ZM8.5 13.2222H10.3889V4.72222H8.5V13.2222Z"
                                  fill="#C41F1F"
                                ></path>
                              </svg>
                              <span>Delete</span>
                            </button>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      {data?.po_list?.map((po, poIndex) => {
                        return (
                          <>
                            <div className="_poDetail_bx mb-2">
                              <ul>
                                <li>
                                  <div className="__pdxTx">PO Date</div>{" "}
                                  <div className="__pdseTx">
                                    {utils?.formateDateCalander(po?.po_date)}
                                  </div>
                                </li>
                                <li>
                                  <div className="__pdxTx">PO Number</div>{" "}
                                  <div className="__pdseTx">{po?.po_number}</div>
                                </li>
                                <li>
                                  <div className="__pdxTx">Pickup Location</div>{" "}
                                  <div>
                                    <span className="_fstAddress_tx">
                                      {po?.from_location}
                                    </span>{" "}
                                    <span className="_scdAddress_tx">
                                      {po?.unit_details
                                        ? `${po?.unit_details?.code} , ${po?.unit_details?.name}`
                                        : ""}
                                    </span>
                                  </div>
                                </li>
                                <li>
                                  <div className="__pdxTx">Drop Location</div>{" "}
                                  <div>
                                    <span className="_fstAddress_tx">
                                      {po?.to_location}
                                    </span>{" "}
                                    <span className="_scdAddress_tx">
                                      {po?.consignee_detials
                                        ? `${po?.consignee_detials?.code} , ${po?.consignee_detials?.name}`
                                        : ""}
                                    </span>
                                  </div>
                                </li>
                              </ul>
                              <div>
                                <button
                                  className="__edit_btn"
                                
                                  disabled={true}
                                >
                                  <FiEdit />
                                </button>
                                <button
                                  className="__delM_btn"
                                 
                                  disabled={true}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="17"
                                    viewBox="0 0 16 17"
                                    fill="none"
                                  >
                                    <path
                                      d="M2.83333 17C2.31389 17 1.86937 16.8152 1.49978 16.4456C1.13019 16.076 0.945074 15.6312 0.944444 15.1111V2.83333H0V0.944444H4.72222V0H10.3889V0.944444H15.1111V2.83333H14.1667V15.1111C14.1667 15.6306 13.9819 16.0754 13.6123 16.4456C13.2427 16.8158 12.7979 17.0006 12.2778 17H2.83333ZM12.2778 2.83333H2.83333V15.1111H12.2778V2.83333ZM4.72222 13.2222H6.61111V4.72222H4.72222V13.2222ZM8.5 13.2222H10.3889V4.72222H8.5V13.2222Z"
                                      fill="#C41F1F"
                                    />
                                  </svg>
                                </button>
                              </div>
                            </div>
                            <span className={"input_drop_error"}>
                              
                            </span>
                          </>
                        );
                      })}

                      <div className="_addDel_bx bg-white pt-2">
                        <button
                          className="_pickN_dropBtn"
                          disabled={true
                          }
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="18"
                            viewBox="0 0 18 18"
                            fill="none"
                          >
                            <path
                              d="M8.61539 0C6.33902 0.0276205 4.16368 0.944178 2.55393 2.55393C0.944178 4.16368 0.0276205 6.33902 0 8.61539C0.0276205 10.8917 0.944178 13.0671 2.55393 14.6768C4.16368 16.2866 6.33902 17.2031 8.61539 17.2308C10.8917 17.2031 13.0671 16.2866 14.6768 14.6768C16.2866 13.0671 17.2031 10.8917 17.2308 8.61539C17.2031 6.33902 16.2866 4.16368 14.6768 2.55393C13.0671 0.944178 10.8917 0.0276205 8.61539 0ZM13.5385 9.23077H9.23077V13.5385H8V9.23077H3.69231V8H8V3.69231H9.23077V8H13.5385V9.23077Z"
                              fill="#1956F6"
                            ></path>
                          </svg>{" "}
                          <span>Add PO & Location</span>
                        </button>
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>

        <div className="pr_procssBx">
          <div className="_lrdMax">
            <div className="flxInr">
              <div className="__flxseBx">
                <h6>Freight Type</h6>

                <div className="_flxOtr">
                  {listData?.freightTypeList?.map((freight) => (
                    <div className="_dualCheckr_bx">
                      <input
                        className="form-check-input"
                        id={freight?.value}
                        type="radio"
                        name="freight_type"
                        value={freight?.value}
                        checked={form?.freight_type == freight?.value}
                        disabled={true}
                      />
                      <label
                        htmlFor={freight?.value}
                        className="form-check-label"
                      >
                        {freight?.label}
                      </label>
                    </div>
                  ))}
                </div>

                <div className="_cityInput_bx">
                  {form?.freight_type == 4 ? (
                    <CommonInput
                      type="text"
                      label="Freight Amount"
                      placeholder="Freight Amount"
                      name="freight_amount"
                      value={form?.freight_amount}
                      disabled={true}
                    />
                  ) : (
                    <CommonInput
                      type="text"
                      label="Freight Rate"
                      placeholder="Freight Rate"
                      name="freight_rate"
                      value={form?.freight_rate}
                      disabled={true}
                    />
                  )}
                </div>
              </div>

              <div className="__flxseBx">
                <h6>Payment Terms</h6>

                <div className="_flxOtr">
                  {paymentTermsList?.map((payment) => {
                    return (
                      <div className="_dualCheckr_bx">
                        <input
                          className="form-check-input"
                          id={payment?.value}
                          type="radio"
                          name="payment_terms"
                          value={payment?.value}
                          checked={form?.payment_terms == payment?.value}
                          disabled={true}
                        />
                        <label
                          htmlFor={payment?.value}
                          className="form-check-label"
                        >
                          {payment?.label}
                        </label>
                      </div>
                    );
                  })}

                  {form?.payment_terms == 1 && (
                    <div className="__numPercen_btBx">
                      {paymentPer?.map((i) => (
                        <button
                          value={i?.value}
                          name="payment_percentage"
                          disabled={true}
                        >
                          {i?.label}
                        </button>
                      ))}
                      <>
                        <input
                          type="text"
                          name="payment_percentage"
                          className={
                            "anvInputError" 
                          }
                          
                          value={form?.payment_percentage}
                          disabled={true}
                        />
                        <div>%</div>
                        
                      </>
                    </div>
                  )}
                </div>
              </div>

              <div className="_cityInput_bx">
                <CustomSelect
                  label="Branch"
                  options={listData?.branchList}
                  placeholder="Select Branch"
                
                  value={listData?.branchList?.find(
                    (i) => i?.value == form?.branch_id
                  )}
                  disabled={true}
                />
              </div>

              <div className="_cityInput_bx">
                <CustomSelect
                  label="Assign to"
                  options={listData?.userList}
                  value={listData?.userList?.find(
                    (i) => i?.value == form?.assign_to
                  )}
                  placeholder="Select"
                  disabled={true}
                />
              </div>

              <div className=" _cityInput_bx">
                <div className="_flxOtr d-flex justify-content-between align-items-center">
                  <h6 style={{ margin: "0" }}>Market Visiblity / Allow Bids</h6>

                  <div
                    className="__numPercen_btBx"
                    style={{ flex: "0 0 111px", margin: "0" }}
                  >
                    <button
                      className={form?.is_allow_visibility == 1 ? "active" : ""}
                      name="is_allow_visibility"
                      value={1}
                      disabled={true}
                    >
                      Yes
                    </button>
                    <button
                      className={form?.is_allow_visibility == 0 ? "active" : ""}
                      name="is_allow_visibility"
                      value={0}
                      
                      disabled={true}
                    >
                      No
                    </button>
                  </div>
                </div>
                {form?.is_allow_visibility == 1 && (
                  <CustomSelect
                    label="Set Market Visiblity"
                    options={listData?.marketVisiList}
                    placeholder="Select Connection"
                    value={listData?.marketVisiList?.find(
                      (i) => i?.value == form?.connection_id
                    )}
                    disabled={true}
                  />
                )}
              </div>

              <div className=" _cityInput_bx">
                <div className="_flxOtr d-flex justify-content-between align-items-center">
                  <h6 style={{ margin: "0" }}>Allow Calls</h6>

                  <div
                    className="__numPercen_btBx"
                    style={{ flex: "0 0 111px", margin: "0" }}
                  >
                    <button
                      className={form?.is_allow_calls == 1 ? "active" : ""}
                      name="is_allow_calls"
                      value={1}
                      disabled={true}
                    >
                      Yes
                    </button>
                    <button
                      className={form?.is_allow_calls == 0 ? "active" : ""}
                      name="is_allow_calls"
                      value={0}
                      disabled={true}
                    >
                      No
                    </button>
                  </div>
                </div>
                <CustomSelect
                  label="Response"
                  options={listData?.responseList}
                  placeholder="Select Response"
                  isDropdownTop={true}
                  value={listData?.responseList?.find(
                    (i) => i?.value == form?.responses
                  )}
                  disabled={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>}

        {activeTab === 2 && (
          <div>
            <ul
              className="nav nav-tabs cstNew_tabs px-0"
              id="addParty"
              role="tablist"
            >
              {countArray?.map((item, indx) => (
                <li
                  className="nav-item"
                  role="presentation"
                  key={indx + item?.value}
                >
                  <button
                    className={
                      "nav-link " +
                      (filter?.status === item?.id ? "active" : "")
                    }
                    type="button"
                    aria-selected={filter?.status === item?.id}
                    onClick={(e) => setFilter({ ...filter, status: item.id })}
                  >
                    <span className="_tbTitle">{item?.title}</span>
                    <span className="_counts">{item?.count}</span>
                  </button>
                </li>
              ))}
            </ul>

            {filter?.status == 0 && (
              <>
                <div className="offcanvas-body">
                  <div className="_bidTable">
                    <table>
                      <thead>
                        <tr>
                          <th>
                            <div className="_bidTh_txt">Bidder Details</div>
                          </th>

                          <th>
                            <div className="_bidTh_txt">Status</div>
                          </th>

                          <th>
                            <div className="_bidTh_txt">Date of Biding</div>
                          </th>

                          <th>
                            <div className="_bidTh_txt">Rates</div>
                          </th>

                          <th>
                            <div className="_bidTh_txt _actAction">Actions</div>
                          </th>
                        </tr>
                      </thead>

                      {bidList?.length > 0 &&
                        bidList?.map((bid) => {
                          return (
                            <tbody>
                              <tr>
                                <td>
                                  <div className="_bidR_bxs">
                                    <div className="__bidR_usrBx">
                                      <span className="_bidR_verifyIcon">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="18"
                                          height="18"
                                          viewBox="0 0 18 18"
                                          fill="none"
                                        >
                                          <circle
                                            cx="9.5"
                                            cy="9.5"
                                            r="6.5"
                                            fill="white"
                                          />
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M9 18C10.1819 18 11.3522 17.7672 12.4442 17.3149C13.5361 16.8626 14.5282 16.1997 15.364 15.364C16.1997 14.5282 16.8626 13.5361 17.3149 12.4442C17.7672 11.3522 18 10.1819 18 9C18 7.8181 17.7672 6.64778 17.3149 5.55585C16.8626 4.46392 16.1997 3.47177 15.364 2.63604C14.5282 1.80031 13.5361 1.13738 12.4442 0.685084C11.3522 0.232792 10.1819 -1.76116e-08 9 0C6.61305 3.55683e-08 4.32387 0.948211 2.63604 2.63604C0.948212 4.32387 0 6.61305 0 9C0 11.3869 0.948212 13.6761 2.63604 15.364C4.32387 17.0518 6.61305 18 9 18ZM8.768 12.64L13.768 6.64L12.232 5.36L7.932 10.519L5.707 8.293L4.293 9.707L7.293 12.707L8.067 13.481L8.768 12.64Z"
                                            fill="#00934C"
                                          />
                                        </svg>
                                      </span>
                                      {bid?.company_id?.company ? (
                                        <div className="_fstLetter">
                                          {" "}
                                          {(bid?.company_id?.company[0]).toUpperCase()}{" "}
                                        </div>
                                      ) : (
                                        ""
                                      )}

                                      <div className="_ratingBx">
                                        <FaStar />
                                        <span>
                                          {bid?.company_id?.rating
                                            ? bid?.company_id?.rating
                                            : ""}{" "}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="w-100">
                                      <p className="tl_txt">
                                        {bid?.company_id?.company
                                          ? bid?.company_id?.company
                                          : "-"}
                                      </p>
                                      <p className="tsm_txt">
                                        {bid?.company_id?.city?.label},{" "}
                                        {bid?.company_id?.state?.label}
                                      </p>
                                    </div>
                                  </div>
                                </td>

                                <td>{statusReturn(bid?.status)}</td>

                                <td>
                                  <div className="_dobBid">
                                    <p className="tsm_txt_gry">Bid on</p>
                                    <p className="tl_txt">
                                      {utils.formatDate(bid?.createdAt)}
                                    </p>
                                  </div>
                                </td>

                                <td>
                                  <div className="_rateBid">
                                    <p className="tsm_txt_gry">Amount</p>
                                    <p className="tl_txt">{`Rs. ${bid?.amount}`}</p>
                                  </div>
                                </td>

                                <td>
                                  <div className="_actionOtr_bx">
                                    <button className="_callBtn">
                                      <IoCall />
                                    </button>

                                    <div className="_actionInr_bx">
                                      <button
                                        className=" _acceptSts"
                                        onClick={(e) =>
                                          handleStatusChange(bid, 1, "accept")
                                        }
                                        disabled={
                                          loadingStatus[bid?._id]?.accept
                                        }
                                      >
                                        {loadingStatus[bid?._id]?.accept
                                          ? "Loading..."
                                          : "Accept"}
                                      </button>
                                      <button
                                        className=" _rejectSts"
                                        onClick={(e) =>
                                          handleStatusChange(bid, 3, "reject")
                                        }
                                        disabled={
                                          loadingStatus[bid?._id]?.reject
                                        }
                                      >
                                        {loadingStatus[bid?._id]?.reject
                                          ? "Loading..."
                                          : "Reject"}
                                      </button>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          );
                        })}

                      {loading ? <p className="p-2"> Loading... </p> : <></>}
                      {bidList?.length === 0 && !loading && (
                        <p className="p-2"> No Data Found </p>
                      )}
                    </table>
                  </div>
                </div>
              </>
            )}

            {filter?.status == 1 && (
              <>
                <div className="offcanvas-body">
                  <div className="_bidTable">
                    <table>
                      <thead>
                        <tr>
                          <th>
                            <div className="_bidTh_txt">Bidder Details</div>
                          </th>

                          <th>
                            <div className="_bidTh_txt">Status</div>
                          </th>

                          <th>
                            <div className="_bidTh_txt">Date of Biding</div>
                          </th>

                          <th>
                            <div className="_bidTh_txt">Rates</div>
                          </th>

                          <th>
                            <div className="_bidTh_txt _actAction">Actions</div>
                          </th>
                        </tr>
                      </thead>
                      {bidList?.length > 0 &&
                        bidList?.map((bid) => {
                          return (
                            <tbody>
                              <tr>
                                <td>
                                  <div className="_bidR_bxs">
                                    <div className="__bidR_usrBx">
                                      <span className="_bidR_verifyIcon">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="18"
                                          height="18"
                                          viewBox="0 0 18 18"
                                          fill="none"
                                        >
                                          <circle
                                            cx="9.5"
                                            cy="9.5"
                                            r="6.5"
                                            fill="white"
                                          />
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M9 18C10.1819 18 11.3522 17.7672 12.4442 17.3149C13.5361 16.8626 14.5282 16.1997 15.364 15.364C16.1997 14.5282 16.8626 13.5361 17.3149 12.4442C17.7672 11.3522 18 10.1819 18 9C18 7.8181 17.7672 6.64778 17.3149 5.55585C16.8626 4.46392 16.1997 3.47177 15.364 2.63604C14.5282 1.80031 13.5361 1.13738 12.4442 0.685084C11.3522 0.232792 10.1819 -1.76116e-08 9 0C6.61305 3.55683e-08 4.32387 0.948211 2.63604 2.63604C0.948212 4.32387 0 6.61305 0 9C0 11.3869 0.948212 13.6761 2.63604 15.364C4.32387 17.0518 6.61305 18 9 18ZM8.768 12.64L13.768 6.64L12.232 5.36L7.932 10.519L5.707 8.293L4.293 9.707L7.293 12.707L8.067 13.481L8.768 12.64Z"
                                            fill="#00934C"
                                          />
                                        </svg>
                                      </span>
                                      {bid?.company_id?.company ? (
                                        <div className="_fstLetter">
                                          {" "}
                                          {(bid?.company_id?.company[0]).toUpperCase()}{" "}
                                        </div>
                                      ) : (
                                        ""
                                      )}

                                      <div className="_ratingBx">
                                        <FaStar />
                                        <span>
                                          {bid?.company_id?.rating
                                            ? bid?.company_id?.rating
                                            : ""}{" "}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="w-100">
                                      <p className="tl_txt">
                                        {bid?.company_id?.company
                                          ? bid?.company_id?.company
                                          : "-"}
                                      </p>
                                      <p className="tsm_txt">
                                        {bid?.company_id?.city?.label},{" "}
                                        {bid?.company_id?.state?.label}
                                      </p>
                                    </div>
                                  </div>
                                </td>

                                <td>{statusReturn(bid?.status)}</td>

                                <td>
                                  <div className="_dobBid">
                                    <p className="tsm_txt_gry">Bid on</p>
                                    <p className="tl_txt">
                                      {" "}
                                      {utils.formatDate(bid?.createdAt)}
                                    </p>
                                  </div>
                                </td>

                                <td>
                                  <div className="_rateBid">
                                    <p className="tsm_txt_gry">Amount</p>
                                    <p className="tl_txt">{`Rs. ${bid?.amount}`}</p>
                                  </div>
                                </td>

                                <td>
                                  <div className="_actionOtr_bx">
                                    <button className="_callBtn">
                                      <IoCall />
                                    </button>

                                    <div className="_actionInr_bx">
                                      <button
                                        className=" _rejectSts"
                                        onClick={(e) =>
                                          handleStatusChange(bid, 3, "reject")
                                        }
                                        disabled={
                                          loadingStatus[bid?._id]?.reject
                                        }
                                      >
                                        {loadingStatus[bid?._id]?.reject
                                          ? "Loading..."
                                          : "Reject"}
                                      </button>
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          );
                        })}
                      {loading ? <p className="p-2"> Loading... </p> : <></>}
                      {bidList?.length === 0 && !loading && (
                        <p className="p-2"> No Data Found </p>
                      )}
                    </table>
                  </div>
                </div>
              </>
            )}

            {filter?.status == 3 && (
              <>
                <div className="offcanvas-body">
                  <div className="_bidTable">
                    <table>
                      <thead>
                        <tr>
                          <th>
                            <div className="_bidTh_txt">Bidder Details</div>
                          </th>

                          <th>
                            <div className="_bidTh_txt">Status</div>
                          </th>

                          <th>
                            <div className="_bidTh_txt">Date of Biding</div>
                          </th>

                          <th>
                            <div className="_bidTh_txt">Rates</div>
                          </th>

                          <th>
                            <div className="_bidTh_txt">Remarks</div>
                          </th>
                        </tr>
                      </thead>

                      {bidList?.length > 0 &&
                        bidList?.map((bid) => {
                          return (
                            <tbody>
                              <tr>
                                <td>
                                  <div className="_bidR_bxs">
                                    <div className="__bidR_usrBx">
                                      <span className="_bidR_verifyIcon">
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="18"
                                          height="18"
                                          viewBox="0 0 18 18"
                                          fill="none"
                                        >
                                          <circle
                                            cx="9.5"
                                            cy="9.5"
                                            r="6.5"
                                            fill="white"
                                          />
                                          <path
                                            fill-rule="evenodd"
                                            clip-rule="evenodd"
                                            d="M9 18C10.1819 18 11.3522 17.7672 12.4442 17.3149C13.5361 16.8626 14.5282 16.1997 15.364 15.364C16.1997 14.5282 16.8626 13.5361 17.3149 12.4442C17.7672 11.3522 18 10.1819 18 9C18 7.8181 17.7672 6.64778 17.3149 5.55585C16.8626 4.46392 16.1997 3.47177 15.364 2.63604C14.5282 1.80031 13.5361 1.13738 12.4442 0.685084C11.3522 0.232792 10.1819 -1.76116e-08 9 0C6.61305 3.55683e-08 4.32387 0.948211 2.63604 2.63604C0.948212 4.32387 0 6.61305 0 9C0 11.3869 0.948212 13.6761 2.63604 15.364C4.32387 17.0518 6.61305 18 9 18ZM8.768 12.64L13.768 6.64L12.232 5.36L7.932 10.519L5.707 8.293L4.293 9.707L7.293 12.707L8.067 13.481L8.768 12.64Z"
                                            fill="#00934C"
                                          />
                                        </svg>
                                      </span>
                                      {bid?.company_id?.company ? (
                                        <div className="_fstLetter">
                                          {" "}
                                          {(bid?.company_id?.company[0]).toUpperCase()}{" "}
                                        </div>
                                      ) : (
                                        ""
                                      )}

                                      <div className="_ratingBx">
                                        <FaStar />
                                        <span>
                                          {bid?.company_id?.rating
                                            ? bid?.company_id?.rating
                                            : ""}{" "}
                                        </span>
                                      </div>
                                    </div>
                                    <div className="w-100">
                                      <p className="tl_txt">
                                        {bid?.company_id?.company
                                          ? bid?.company_id?.company
                                          : "-"}
                                      </p>
                                      <p className="tsm_txt">
                                        {bid?.company_id?.city?.label},{" "}
                                        {bid?.company_id?.state?.label}
                                      </p>
                                    </div>
                                  </div>
                                </td>

                                <td>{statusReturn(bid?.status)}</td>

                                <td>
                                  <div className="_dobBid">
                                    <p className="tsm_txt_gry">Bid on</p>
                                    <p className="tl_txt">
                                      {utils.formatDate(bid?.createdAt)}
                                    </p>
                                  </div>
                                </td>

                                <td>
                                  <div className="_rateBid">
                                    <p className="tsm_txt_gry">Amount</p>
                                    <p className="tl_txt">{`Rs. ${bid?.amount}`}</p>
                                  </div>
                                </td>

                                <td>
                                  <div className="_reRemark_bx">
                                    <p className="tsm_txt_gry">
                                      {utils?.formatDateMonth(bid.updatedAt)} |{" "}
                                      {utils?.formatTime(bid?.updatedAt)}
                                    </p>
                                    <p className="tl_txt">
                                      {`Rejected by User ${bid?.user_id?.full_name}`}
                                    </p>
                                  </div>
                                </td>
                              </tr>
                            </tbody>
                          );
                        })}
                      {loading ? <p className="p-2"> Loading... </p> : <></>}
                      {bidList?.length === 0 && !loading && (
                        <p className="p-2"> No Data Found </p>
                      )}
                    </table>
                  </div>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default IndentAndBidDetailModal;
