// import React from 'react';

// class ErrorBoundary extends React.Component {
//     constructor(props) {
//         super(props);
//         this.state = { hasError: false };
//     }

//     static getDerivedStateFromError(error) {
//         return { hasError: true };
//     }

//     componentDidCatch(error, errorInfo) {
//         // You can log the error to an error reporting service here
//         console.error(error, errorInfo);
//     }

//     render() {
//         if (this.state.hasError) {
//             return <h1>Something went wrong. Please try again later.</h1>;
//         }

//         return this.props.children;
//     }
// }

// export default ErrorBoundary;

import React from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';

const ErrorFallback = ({ error, resetErrorBoundary }) => {
    return (
        <div role="alert">
            <h1>Something went wrong. Please try again later.</h1>
            <p>{error.message}</p>
            <button onClick={resetErrorBoundary}>Try again</button>
        </div>
    );
};

const ErrorBoundary = ({ children }) => {
    return (
        <ReactErrorBoundary
            FallbackComponent={ErrorFallback}
            onReset={() => {
                // Reset any state or do any cleanup if needed
            }}
            onError={(error, errorInfo) => {
                // Log error details here, for example to an external service
                console.error("Caught by ErrorBoundary:", error, errorInfo);
            }}
        >
            {children}
        </ReactErrorBoundary>
    );
};

export default ErrorBoundary;

// import React from 'react';
// import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';

// // Silent fallback component that shows nothing
// const ErrorFallback = () => null;

// const ErrorBoundary = ({ children }) => {
//     return (
//         <ReactErrorBoundary
//             FallbackComponent={ErrorFallback} // No UI shown for errors
//             onReset={() => {
//                 // Optional: Reset any state or perform cleanup if needed
//             }}
//             onError={(error, errorInfo) => {
//                 // Log error details to the console or an error reporting service
//                 console.error("Error caught by ErrorBoundary:", error, errorInfo);
//             }}
//         >
//             {children}
//         </ReactErrorBoundary>
//     );
// };

// export default ErrorBoundary;

