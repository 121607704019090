import React, { useState } from 'react';

function LoaderButton({
  onClick,
  isLoadingText = 'Loading...',
  children,
  className = '',
  disabled = false,
  loaderClass = 'spinner'
}) {
  const [isLoading, setIsLoading] = useState(false);

  const handleClick = async () => {
    setIsLoading(true);
    try {
      await onClick();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <button
      onClick={handleClick}
      disabled={isLoading || disabled}
      className={`loader-button ${className}`}
    >
      {isLoading ? (
        <span className={loaderClass}></span>
      ) : (
        children
      )}
      {isLoading && isLoadingText}
    </button>
  );
}

export default LoaderButton;
