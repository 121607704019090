import { Images } from "constants";
import React, { useCallback, useEffect, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import "../../assets/css/modal.css";
import { CommonButton, CommonInput, CommonModal, CropModal, CustomSelect } from "components";
import { City, State } from "country-state-city";
import { useDispatch, useSelector } from "react-redux";
import { ImageUploadAvtar, account } from "features";
import { UserContext } from "context";
import debounce from 'lodash.debounce';
import StorageService from "utils/StorageServices/StorageServices";
import { errorSchema, utils } from "helper";
import ImageCrop from "components/common/ImageCrop";

const AddAccountModal = ({ ...props }) => {
  const initForm = {
    full_name: "",
    email_id: "",
    city: {},
    state: {},
    avatar: "",
    language: "en"
  };

  const initDrop = {
    city: "",
    state: "",
  };

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading, error, data } = useSelector((state) => state.auth);
  const { cities, setShowCreateCompanyModal, setCommonError, setShowAddAcountModal, setCities,setUserData } = React.useContext(UserContext);
  const [formValue, setFormValue] = useState(initForm);
  const [formError, setFormError] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [filteredCities, setFilteredCities] = useState([]);
  const [commondata, setCommondata] = useState({
    
    disbale: false,
    isGstChecked: false,
    croperopen: false
});

const [logo, setLogo] = useState({
  file: null,
  url: ''
})
const [croppedImage, setCroppedImage] = useState(null);
const [showModal, setShowModal] = useState(false);
  const [image, setImage] = useState(null);
  const [imageLoading,setImageLoading] = useState(false)
  const [isLoadding,setIsLoadding] = useState(false)
  const [formCityValue,setFormCityValue] = useState('')
  //.......city ........//
  useEffect(() => {
    if (cities) {
      const optionsCity = cities?.map((d) => {
        return {
          ...d,
          label: `${d?.name}, ${d?.stateCode}`,
           value: `${d?.name}`
        };
      });
      setFilteredCities(optionsCity);
    } else {
      setCities(JSON.parse(StorageService.getCities()))
    }

  }, [cities])


  const handleOnChange = async (name, value) => {

    const stateObj = { ...formValue, [name]: value };
    if(name==="city"){
      setFormCityValue({label:`${value?.name}, ${value?.stateCode}`,value:`${value?.name}, ${value?.stateCode}`})
    }
    setFormValue(stateObj);
    setCommonError(false)
    if (!!formError) {
      const validationResult = await utils.checkFormError(stateObj, errorSchema.addProfileModalSchema);
      if (validationResult === true) {
        setFormError("");
      } else {
        setFormError(validationResult);
      }
    }
  };

  console.log(formValue)
  const handleCityChange = (data) => {
    handleOnChange("city", {
      countryCode: data?.countryCode,
      latitude: data?.latitude,
      longitude: data?.longitude,
      name: data?.name,
      stateCode: data?.stateCode,
    })
  }

  useEffect(() => {
    if (formValue.city) handleOnChange("state", State.getStateByCodeAndCountry(formValue.city.stateCode, formValue.city.countryCode))
  }, [formValue.city])

  const handleAccount = async (e) => {

    e.preventDefault();
    const validationResult = await utils.checkFormError(
      { ...formValue }, errorSchema.addProfileModalSchema

    );
    try {
      if (isEdit === true) {
        // setIsLoadding(true);
        // handleUpdate();
      } else {
        if (validationResult === true) {
          setIsLoadding(true);
          
          const res = await dispatch(account(formValue));
          if (res?.payload?.responseCode === 200) {
            if (res?.payload?.data?.user_detail?.company_list?.length == 0) {
              setShowAddAcountModal(false)
              setShowCreateCompanyModal(true)
              StorageService.setUserExist(1)
              StorageService.setLocalItem("userData",JSON.stringify(res?.payload?.data))
          setUserData(res?.payload?.data)
              allClear()
            }else{
              StorageService.setUserExist(res?.payload?.data?.user_exists)
              StorageService.setLocalItem("userData",JSON.stringify(res?.payload?.data))
              setUserData(res?.payload?.data)
              setShowAddAcountModal(false)
            }
            setIsLoadding(false);
          } else {
            console.log("error")
          }
        } else {
          setIsLoadding(false);
          setFormError(validationResult);
        }
      }


    } catch (error) {
      throw error;
    }
  };

  const handleAccountInhance = {
    ...props,
    onSubmit: handleAccount,
    isLoadding:isLoadding,
    disabled:isLoadding
  }

  const allClear = () => {
    setFormValue(initForm)
  }

async function createFile(path) {
  setImageLoading(true)
  let response = await fetch(path);
  let data = await response.blob();
  let metadata = {
      type: 'image/jpeg'
  };
  let file = new File([data], "test.jpg", metadata);
  const formData = new FormData()
  formData.append("avatar", file)
  if (formData) {
    const res = await dispatch(ImageUploadAvtar(formData));
    if (res?.payload?.responseCode === 200) {
        setFormValue({...formValue,avatar:res?.payload?.data?.path})
        setImageLoading(false)
        setShowModal(false)
    }

} 
  setLogo({
      file: file,
      url: path
  })
}

const handleImageUpload = (e) => {
  const file = e.target.files[0];
  if (file) {
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
      setShowModal(true);
    };
    reader.readAsDataURL(file);
  }
};


const handleCrop = async(croppedArea) => {
  await createFile(croppedArea)
  setCroppedImage(croppedArea);
  // You can use croppedArea to get the cropped image
};

  return (
    <div>
      {props.isOpen && (

        <CommonModal {...handleAccountInhance}>
          <div className="modal-body ">
            <div>
              <div className="_addUser_imgBx">
                <img className="" src={croppedImage?croppedImage:Images.USERLIMG} alt="" />
              </div>
              {image && (
                <CropModal
                show={showModal}
                onHide={() => setShowModal(false)}
                image={image}
                handleCrop={handleCrop}
                imageLoading={imageLoading}
              /> )}
              <div className="_fileUpdate">
                <input type="file" name="" id="_addFile" onChange={handleImageUpload} />
                <label for="_addFile">
                  <span>Upload your profile photo</span>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                  >
                    <circle
                      cx="7.54242"
                      cy="7.54242"
                      r="7.54242"
                      fill="#1956F6"
                    />
                    <path
                      d="M14.9124 5.97747C14.52 4.03118 13.4605 2.49006 11.8381 1.34543C10.8891 0.67567 9.83431 0.259479 8.68423 0.0834744C8.4898 0.0531558 8.28946 0.059062 8.09896 0C7.70615 0 7.31374 0 6.92094 0C6.76271 0.0291373 6.60055 0.0169311 6.44193 0.0385872C5.65239 0.14608 4.90102 0.382328 4.19019 0.737882C3.23612 1.2151 2.40643 1.85376 1.72433 2.67984C0.819067 3.77603 0.245995 5.01988 0.0413272 6.42989C0.0177117 6.59369 0.031881 6.76064 0 6.92365C0 7.33629 0 7.74855 0 8.16119C0.0220412 8.16828 0.0263707 8.18521 0.0287323 8.20569C0.0507735 8.4002 0.0641556 8.59668 0.097611 8.78922C0.361712 10.3051 1.00406 11.6399 2.08132 12.7397C3.97883 14.6769 6.28843 15.4128 8.95187 14.951C10.577 14.669 11.9633 13.8867 13.0708 12.6613C14.8011 10.7485 15.4214 8.5006 14.9124 5.97747ZM7.52904 14.3233C3.77377 14.3178 0.752549 11.2797 0.761208 7.51781C0.769867 3.77761 3.80565 0.755994 7.54872 0.761113C11.2993 0.766625 14.3213 3.79808 14.3173 7.55167C14.3138 11.3021 11.2792 14.3292 7.52904 14.3233Z"
                      fill="#1956F6"
                    />
                    <path
                      d="M11.8976 6.90648C10.7367 6.90255 9.57519 6.90058 8.41432 6.90976C8.22674 6.91107 8.17165 6.86844 8.17362 6.67299C8.18411 5.51994 8.17952 4.36689 8.17821 3.2145C8.17755 2.86098 7.9926 2.60256 7.70008 2.53369C7.27312 2.43334 6.91108 2.7298 6.90977 3.1922C6.90649 4.35312 6.90321 5.51469 6.91371 6.67561C6.91567 6.87631 6.85337 6.91107 6.67038 6.90976C5.5259 6.90189 4.38077 6.90517 3.23629 6.90583C2.80867 6.90648 2.51747 7.16228 2.51419 7.53548C2.51091 7.90868 2.80342 8.17431 3.2258 8.17562C3.79836 8.17759 4.37027 8.17628 4.94284 8.17628C5.52328 8.17628 6.10437 8.18415 6.68415 8.17169C6.8632 8.16775 6.91502 8.20711 6.91305 8.39535C6.90321 9.53987 6.90715 10.685 6.90846 11.8296C6.90911 12.2736 7.16621 12.5714 7.54465 12.5707C7.92439 12.5694 8.17821 12.2736 8.17886 11.8276C8.17952 10.6909 8.18542 9.5543 8.17362 8.41764C8.17099 8.20579 8.2333 8.16906 8.43006 8.17103C9.57454 8.18087 10.7197 8.17694 11.8641 8.17562C12.2196 8.17497 12.4793 7.99329 12.5502 7.70338C12.6532 7.27575 12.3587 6.90779 11.8976 6.90648Z"
                      fill="white"
                    />
                  </svg>
                </label>
              </div>

              <div className="_inputsInx_bx">
                <CommonInput
                  type="text"
                  placeholder="Full Name"
                  label="Full Name"
                  value={formValue?.full_name}
                  name="full_name"
                  focus={!!formError?.full_name}
                  error={formError.full_name}
                  onChange={({ target: { name, value } }) =>
                    handleOnChange(name, value)
                  }
                />
                <CommonInput
                  type="email"
                  placeholder="Email Address (Optional)"
                  label="Email Address (Optional)"
                  value={formValue?.email_id ? formValue?.email_id : ""}
                  name="email_id"
                  focus={!!formError?.email_id}
                  error={formError.email_id}
                  onChange={({ target: { name, value } }) =>
                    handleOnChange(name, value)
                  }
                />

                
                <CustomSelect
                  options={filteredCities}
                  onSelect={handleCityChange}
                  placeholder="Select a city"
                  value={formCityValue}
                  error={formError["city.name"
                  ]
                  }
                />
              </div>
            </div>
            {error && <small className="text-danger">{error?.responseMessage}</small>}
          </div>

        </CommonModal>
      )}
     
    </div>
  )
};

export default AddAccountModal;
