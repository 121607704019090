import React from "react";
import LoaderButton from "../LoaderButton";

const CommonCanvas = ({
  isOpen,
  closeModal,
  modalTitle = "",
  submitTitle = "",
  cancleTitle = "",
  children,
  isLoadding = false,
  isLoadingSave=false,
  onSubmit,
  disabled = false,
  modalMainClass,
  headerPart = false,
  footerPart = false,
  modalZIndex,
  cancelButtonTitle = "Cancel",
}) => {
  return (
    <div>
      <div
        className={`offcanvas offcanvas-end common_offCanvas ${
          isOpen ? "show" : ""
        }`}
        tabIndex={-1}
        style={{ visibility: isOpen ? "visible" : "hidden" }}
      >
        {isOpen && (
          <>
            <div className="offcanvas-header">
              <h5 className="offcanvas-title"> {modalTitle} </h5>
              <button
                type="button"
                className="btn-close"
                onClick={closeModal}
              ></button>
            </div>

            {children}
            {!footerPart ? (
              <div className="offcanvas-footer">
                <button
                  className=" _submitOn_modal"
                  disabled={disabled}
                  onClick={onSubmit}
                >
                  {submitTitle}
                </button>
                {/* <LoaderButton
                  onClick={onSubmit}
                  isLoading={isLoadding} 
                  isLoadingText="Processing..."
                  className="_submitOn_modal"
                  disabled={isLoadding}
                >
                  {submitTitle}
                </LoaderButton> */}
              </div>
            ) : (
              <div className="offcanvas-footer">
                
                <LoaderButton
                  onClick={() => onSubmit(0)}
                  isLoading={isLoadding} // Loading state for Approval button
                  isLoadingText="Processing..."
                  className="_submitOn_modal"
                  disabled={isLoadding}
                >
                  Approval
                </LoaderButton>

                <LoaderButton
                  onClick={() => onSubmit(1)}
                  isLoading={isLoadingSave} // Loading state for Save button
                  isLoadingText="Processing..."
                  className="_submitOn_modal"
                  disabled={isLoadingSave}
                >
                  Save
                </LoaderButton>
              </div>
            )}
          </>
        )}
      </div>
      <div className={isOpen ? "offBack_drop" : ""}></div>
    </div>
  );
};

export default CommonCanvas;
